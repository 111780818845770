import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";

class ExportGoogleReportCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    generateAverageScore(location){
      var total = location.total * 5;
      var subTotal = (location.fives * 5) + (location.fours * 4) + (location.threes * 3) + (location.twos * 2) + (location.ones * 1);
      var finalScore = 0;
      if(subTotal>0){
          finalScore = (subTotal/total*5).toFixed(2);    
      }
      return finalScore;
    }

    cleanData(dirtyData){

        let exportData = [];
        exportData.push({ 'Name': 'Aggregate Totals', 'Avg': this.generateAverageScore(this.props.aggregateData), 'Total': this.props.aggregateData.total, '5 Star': this.props.aggregateData.fives, '4 Star': this.props.aggregateData.fours, '3 Star': this.props.aggregateData.threes, '2 Star': this.props.aggregateData.twos, '1 Star': this.props.aggregateData.ones  });
        for (var i = dirtyData.length - 1; i >= 0; i--) {
            var thisLine = dirtyData[i];
            exportData.push({ 'Name': thisLine.name, 'Avg': this.generateAverageScore(thisLine), 'Total': thisLine.total, '5 Star': thisLine.fives, '4 Star': thisLine.fours, '3 Star': thisLine.threes, '2 Star': thisLine.twos, '1 Star': thisLine.ones  });
        }
        
        return exportData.reverse();
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
        )

    }
}

ExportGoogleReportCSV.propTypes = {
    csvData: PropTypes.array.isRequired,
    aggregateData: PropTypes.object.isRequired,
    fileName: PropTypes.string.isRequired
};

export default ExportGoogleReportCSV;