import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import {addSinglePersonToGroup, addMultiplePeopleToGroup, backToGroupList} from "../../actions";
import "./AddPeopleToGroup.scss";

class AddPeopleToGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols:  null,
            rows: null,
            dataLoaded: false,
            errors: { 'personName': false, 'email': null, 'orgName': null, 'mobileno': null }
        };
        this.inlineError = false;
        this.handleFormChange = this.handleFormChange.bind(this);

    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    } 

    newPeopleToGroup(e){
        e.preventDefault();
        this.props.addMultiplePeopleToGroup(this.props.tvClient, this.state.rows, this.props.match.params.documentId).then(() => {
            this.setState({ rows: null, cols: null, dataLoaded: false });
            window.scrollTo(0,0);
        });
    }

    newPersonToGroup(e) {
        e.preventDefault();

        if(!this.orgName.value){
            this.setState({ errors: {'orgName': true}});
        } else if(!this.personName.value){
            this.setState({ errors: {'personName': true}});
        } else if(!this.email.value){
            this.setState({ errors: {'email': true}});
        } else{
            this.props.addSinglePersonToGroup(
                this.props.tvClient,
                this.personName.value,
                this.email.value,
                null,
                this.orgName.value,
                this.props.match.params.documentId
            ).then(()=>{
                this.orgName.value = '';
                this.email.value = '';
                this.personName.value = '';
            });           
        }


    }

    fileHandler(event) {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
            this.setState({
              cols: resp.cols,
              rows: resp.rows,
              dataLoaded: true
            });
          }
        });               
    }    

    render() {
        return <div>
            <h1 className="page-header">
                Add People To Group
                <small>Add people one at a time or in bulk here.</small>
                <small className="backLink"><button className="simulateAnchor" onClick={this.props.backToGroupList.bind(this)} >Go Back</button></small>
            </h1>
            <Form onSubmit={this.newPersonToGroup.bind(this)} className="case-list-container">

                <legend>Single Addition</legend>

                {this.props.addPersonToGroupError && <Alert variant="danger">
                    {this.props.addPersonToGroupError.message}
                </Alert>}
                <FormGroup controlId="orgName">
                    <FormLabel>Organization Name *</FormLabel>
                    <FormControl type="text" placeholder="Company ABC" ref={ref => this.orgName = ref}/>
                    {this.state.errors.orgName && <Alert variant="danger">
                        Organization is required.
                    </Alert>}
                </FormGroup>
                <FormGroup controlId="personName">
                    <FormLabel>Full Name *</FormLabel>
                    <FormControl type="text" placeholder="John Doe" ref={ref => this.personName = ref}/>
                    {this.state.errors.personName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>
                <FormGroup controlId="email">
                    <FormLabel>Email *</FormLabel>
                    <FormControl type="text" placeholder="jay@test.com" ref={ref => this.email = ref}/>
                    {this.state.errors.email && <Alert variant="danger">
                        Email is required.
                    </Alert>}
                </FormGroup>               
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingPersonToGroup} className="pull-left">
                        {this.props.addingPersonToGroup ? 'Adding' : 'Add'}
                    </Button>
                </FormGroup>

            </Form><br/><br/><br/>
            <Form onSubmit={this.newPeopleToGroup.bind(this)} className="case-list-container">

                <legend>Batch Addition <small>(Download the <a href="/WelliQ-AddPeopleToGroup.xlsx">Excel Template</a>)</small></legend>
                <p>Rows Processed: {this.props.peopleAdded} / {this.props.totalPeople>0 ? this.props.totalPeople : '-'}</p>
                {this.props.addPersonToGroupError && <Alert variant="danger">
                    {this.props.addPersonToGroupError.message}
                </Alert>}

                <FormGroup controlId="locationName">
                    <FormLabel>Upload *</FormLabel>
                    <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
                </FormGroup>
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingPeopleToGroup} className="pull-left">
                        {this.props.addingPeopleToGroup ? 'Adding' : 'Add'}
                    </Button>
                    {this.props.addingPeopleToGroup && <Spinner/>}
                </FormGroup>
                {this.state.dataLoaded && 
                    <div className="case-list-container">
                        <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                    </div>
                }


            </Form>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        peopleAdded: state.addPeopleToGroup.peopleAdded,
        totalPeople: state.addPeopleToGroup.totalPeople,
        addingPeopleToGroup: state.addPeopleToGroup.addingPeople,
        addingPersonToGroup: state.addPersonToGroup.addingPerson,
        addPersonToGroupError: state.addPersonToGroup.addPersonError,
        tvClient: state.login.tvClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addSinglePersonToGroup: (...params) => dispatch(addSinglePersonToGroup(...params)),
        addMultiplePeopleToGroup: (...params) => dispatch(addMultiplePeopleToGroup(...params)),
        backToGroupList: (...params) => dispatch(backToGroupList(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPeopleToGroup);
