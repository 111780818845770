import React, {Component} from "react";
import {connect} from "react-redux";
import {
    FormControl,
    Table
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {reopenSpecificEvent, updateSpecificEvent, viewCustomSpecificEvent } from "../../actions";
import "./ViewEvent.scss";
import dateFormat from "dateformat";
import queryString from 'query-string'
import { Link } from 'react-router-dom';


class ViewCustomEvent extends Component {
    constructor(props) {
        
        super(props);

        this.state = {
            currentDetails: [],
            fields: { 'resolved': 0, 'action': '', 'category': ''},
            eventerror: '',
            historyField: queryString.parse(this.props.location.search)
        };

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() { 
        let isRefresh =this.props.currentEvent === null;
        if(isRefresh){
            this.props.viewCustomSpecificEvent(this.props.tvClient, this.props.match.params.eventId);
        }
        window.scrollTo(0, 0);
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }    

    determineStatus(status, action){
        if(status===true || status==='true'){
            return 'Closed';
        } else{
            if(action!==undefined && action!==null && action.toString().length>0){
                return 'Pending';
            } else {
                return 'Open';
            }
        }
    }    

    handleReopenEvent(){
        this.props.reopenSpecificEvent(this.props.tvClient, this.props.match.params.eventId, this.props.currentEvent.action, 0, this.props.adminAttr, this.props.currentEvent.category).then(()=>{
            this.props.viewCustomSpecificEvent(this.props.tvClient, this.props.match.params.eventId);
            window.scrollTo(0, 0);
        })
    }

    handleEventUpdate(e){
        this.setState({ eventerror: ''});
        var theCategory = this.state.fields.category;
        if(theCategory===''){
            if(this.props.currentEvent.category!==undefined){
                theCategory = this.props.currentEvent.category;
            }
        }

        var tempDate = new Date();
        // eslint-disable-next-line no-useless-concat
        var dateAddedText = ' ['+tempDate.toDateString()+' '+tempDate.getHours()+':'+tempDate.getMinutes()+']'+'\n';

        if(this.state.fields.resolved===undefined){
            this.setState({ eventerror: 'You must choose Yes or No to resolve this.'});
        } else if(this.state.fields.resolved.toString()==="1"){
            if(this.state.fields.action===undefined || this.state.fields.action.trim()===''){
                this.setState({ eventerror: 'You must provide a reason/action for resolving this event.'});
            } else{
                // update
                e.target.style.visibility = 'hidden';
                var actionText =  this.props.currentEvent.action!==null ? this.props.currentEvent.action.trim() : '';
                this.props.updateSpecificEvent(this.props.tvClient, this.props.match.params.eventId, (this.state.fields.action.trim() + dateAddedText + actionText), this.state.fields.resolved, this.props.adminAttr, theCategory);
            }
        } else{
            
            if(this.state.fields.action===undefined || this.state.fields.action.trim()===''){
                this.setState({ eventerror: 'You must provide a reason/action for resolving this event.'});
            } else{
                // update
                e.target.style.visibility = 'hidden';
                var actionText1 =  this.props.currentEvent.action!==null ? this.props.currentEvent.action.trim() : '';
                this.props.updateSpecificEvent(this.props.tvClient, this.props.match.params.eventId, (this.state.fields.action.trim() + dateAddedText + actionText1), this.state.fields.resolved, this.props.adminAttr, theCategory);
            }
        }
    }

    render() {
        return <div>
            <h1 className="page-header">
               View Event Data
                {(this.state.historyField.last && this.state.historyField.last!=='none') && <small className="backLink"><Link to={`/reports/events?refresh=0&searching=${this.state.historyField.searching}`}>Go Back</Link></small>}
            </h1>
            {this.props.currentEvent===null && <Spinner/>}
            {this.props.currentEvent!==null && <div className="case-list-container">
                   
                <Table className="case-list surveyReportView eventData">
                    <tbody>
                            <tr>
                                <td className="reportCell">Status</td>
                                <td className="reportValue">{this.determineStatus(this.props.currentEvent.addressed, this.props.currentEvent.action)}&nbsp; &nbsp; 
                                { (!this.props.isViewOnly && this.props.currentEvent.addressed) && <button onClick={this.handleReopenEvent.bind(this)} className="simulateAnchor small">(Re-open / Update)</button> }  

                                </td>
                            </tr>
                        <tr>
                            <td className="reportCell">Event ID</td>
                            <td className="reportValue">{this.props.currentEvent.event_id}</td>
                        </tr>
                        <tr>
                            <td className="reportCell">Event Type</td>
                            <td className="reportValue">{this.props.currentEvent.event_type} </td>
                        </tr>                       
                        <tr>
                            <td className="reportCell">Date Created</td>
                            <td className="reportValue">{dateFormat(this.props.currentEvent.created, "fullDate") + ' at ' + dateFormat(this.props.currentEvent.created, "shortTime")}</td>
                        </tr> 
                        <tr>
                            <td className="reportCell">Location</td>
                            <td className="reportValue">{this.props.currentEvent.name}</td>
                        </tr>  
                        {(this.props.currentEvent.associated_staff!==null && this.props.currentEvent.associated_staff!==undefined) && <tr>
                            <td className="reportCell">Associated Staff Member</td>
                            <td className="reportValue">{this.props.currentEvent.associated_staff}</td>
                        </tr> }
                        <tr>
                            <td className="reportCell">Event Category</td>
                            {(!this.props.isViewOnly && this.props.currentEvent.addressed!==true) && 
                            <td className="reportValue"><FormControl type="text" onChange={this.handleFormChange} defaultValue={(this.props.currentEvent.category!==undefined && this.props.currentEvent.category!=='undefined') ? this.props.currentEvent.category : 'None'} name="category" /> </td>}
                            {(!this.props.isViewOnly && this.props.currentEvent.addressed===true) && 
                            <td className="reportValue">{(this.props.currentEvent.category==='' || this.props.currentEvent.category===null || this.props.currentEvent.category===undefined) ? 'None' : this.props.currentEvent.category} </td>}

                        </tr>                                                

                            { !this.props.isViewOnly && <tr>
                                <td className="reportCell">New Action Taken:</td>
                                <td className="reportValue textEntryArea"> <FormControl as="textarea" id="action" name="action" onChange={this.handleFormChange} placeholder="Enter your new actions to resolve here..." /> </td>
                            </tr> }
                            <tr>
                                <td className="reportCell">Previous Actions</td>
                                <td className="reportValue textEntryArea"><FormControl as="textarea" id="previousaction" name="previousaction" defaultValue={this.props.currentEvent.action} disabled="disabled" /> </td>
                            </tr>

                            { (!this.props.isViewOnly && this.props.currentEvent.addressed!==true) && <tr>
                                <td className="reportCell">Mark as Resolved?</td>
                                <td className="reportValue">
                                <div className="radio">
                                  <label>
                                    <input type="radio" name="resolved" value="1" onChange={this.handleFormChange} />
                                    Yes
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input type="radio" name="resolved" value="0"  onChange={this.handleFormChange} />
                                    No
                                  </label>
                                </div>
                                </td>
                            </tr>}
                            { (!this.props.isViewOnly && !this.props.currentEvent.addressed) && <tr>
                                <td colSpan="2" className="reportValue buttonAction"><button onClick={this.handleEventUpdate.bind(this)} className="btn btn-primary">UPDATE</button>
                                <p className="errorMsg">{this.state.eventerror}</p></td>
                            </tr> }                        
                    </tbody>
                </Table>                          

                <h2>Custom Event Data</h2>
                <Table className="case-list surveyReportView">
                    <tbody>              
                           <tr>
                                <td className="reportCell">Name</td>
                                <td className="reportValue">{this.props.currentEvent.patient_name}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Phone</td>
                                <td className="reportValue">{this.props.currentEvent.phone}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Event Details</td>
                                <td className="reportValue">{this.props.currentEvent.detail}</td>
                            </tr>                      

                    </tbody>
                </Table>                                          

                </div>
            }

           
        </div>
    }
}

const mapStateToProps = state => {
    return {
        currentEvent: state.eventView.currentEvent,
        viewEventError: state.eventView.error,
        tvClient: state.login.tvClient,
        adminAttr: state.login.user.attributes,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewCustomSpecificEvent: (...params) => dispatch(viewCustomSpecificEvent(...params)),
        updateSpecificEvent: (...params) => dispatch(updateSpecificEvent(...params)),
        reopenSpecificEvent: (...params) => dispatch(reopenSpecificEvent(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomEvent);
