import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row,
    Accordion, Card, Nav, Navbar
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import ReactAvatarEditor from "react-avatar-editor";
import "./../NewStaff/NewStaff.scss";
import DeleteStaffOverlay from "./../Overlays/DeleteStaffOverlay";
import RequestStaffPhotoOverlay from "./../Overlays/RequestStaffPhotoOverlay";
import {redeemStaffPoints, creditStaffPoints, updateStaff, viewStaff, listDepartments, listLocations} from "../../actions";
import QRCode from "qrcode-react";

class EditStaff extends Component {

    componentWillMount() { 

        if(this.props.departments===undefined || this.props.departments===null || this.props.departments.length===0){
            this.props.listDepartments(this.props.tvClient, 'and', null, 'asc', 1, 100);
        }


         this.props.listLocations(this.props.tvClient).then(() => {
            let defaultValue = [];
            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                defaultValue.push(this.props.locations[i].location_id);
            }
            this.setState({
                primaryLocation: defaultValue
            });
        });
    

        return this.props.viewStaff(this.props.tvClient, this.props.match.params.documentId).then(()=>{
            var dob = null;
            var hire = null;

            if(this.props.staffData.dob!==null){
                dob = formatDate(this.props.staffData.dob);
            }
            if(this.props.staffData.hire_date!==null){
                hire = formatDate(this.props.staffData.hire_date);
            }
            this.setState({ birthDate: dob, hireDate: hire })
        });
    }

    constructor(props) {
        
        super(props);
        
        this.state = {
            staffFiles: [],
            staffFileUrls: [],
            primaryLocation: 0,
            errors: { 'name': false, 'title': false, 'primaryDepartment': false, 'redeemReasonError': false, 'redeemPointError': false, 'creditReasonError': false, 'creditPointError': false },
            editLoading: true,
            departmentId: 0,
            changed: false,
            image: null,
            allowZoomOut: true,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 180,
            height: 180,
            hireDate: null,
            birthDate: null,
            showStaffDetails: true,
            showStaffPoints: false,
            activeKey: 1
        };

        this.redeemPoints = this.redeemPoints.bind(this);
        this.creditPoints = this.creditPoints.bind(this);
        this.handleHireDateChange = this.handleHireDateChange.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);

    }

    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:'image/jpeg'});
    }

    handleHireDateChange(from) {
      this.setState({ hireDate: from });
    }
    handleBirthDateChange(to) {
      this.setState({ birthDate: to });
    } 

    getDefaultLocations(){
        let defaultValue = [];
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
            defaultValue.push(this.props.locations[i].location_id);
        }

       return defaultValue;
    } 

    getDefaultLocationsToSubmit(){
         let defaultValue = [];
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
            defaultValue.push(this.props.locations[i].location_id);
        }

       return defaultValue;
    }        

    refreshHandler(){
        window.history.back();
    }

    handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showStaffDetails:true, showStaffPoints:false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===2){
        this.setState({ showStaffDetails:false, showStaffPoints:true, activeKey: selectedKey });
      } 
    }

    redeemPoints(){
        var redeemPointAmount = this.redeemAmount.value.trim();
        var redeemPointReason = this.redeemReason.value.trim();

        var validRedemption = true;

        if(redeemPointAmount===undefined || redeemPointAmount===null || redeemPointAmount===''){
            this.setState({ errors: {'redeemPointError': true, 'redeemReasonError': false, 'redeemMaxError': false }});
        } else if(parseInt(redeemPointAmount)>parseInt(this.props.totalPoints)){
            this.setState({ errors: {'redeemPointError': false, 'redeemReasonError': false, 'redeemMaxError': true }});
        } else if(redeemPointReason===undefined || redeemPointReason===null || redeemPointReason===''){
            this.setState({ errors: {'redeemPointError': false, 'redeemReasonError': true, 'redeemMaxError': false }});
        } else{
           
            if(validRedemption){
                this.setState({errors: {'redeemPointError': false, 'redeemReasonError': false,  'redeemMaxError': false } });
                this.props.redeemStaffPoints(this.props.tvClient, this.props.match.params.documentId, redeemPointAmount, redeemPointReason).then(()=>{
                    document.getElementById('redeemPoints').value='';
                    document.getElementById('redeemReason').value='';
                });

            }
        }
    }

    creditPoints(){
        var creditPointAmount = this.creditAmount.value.trim();
        var creditPointReason = this.creditReason.value.trim();

        var validCredit = true;

        if(creditPointAmount===undefined || creditPointAmount===null || creditPointAmount===''){
            this.setState({ errors: {'creditPointError': true, 'creditReasonError': false }});
        } else if(creditPointReason===undefined || creditPointReason===null || creditPointReason===''){
            this.setState({ errors: {'creditPointError': false, 'creditReasonError': true }});
        } else{
           
            if(validCredit){
                this.setState({errors: {'creditPointError': false, 'creditReasonError': false } });
                this.props.creditStaffPoints(this.props.tvClient, this.props.match.params.documentId, creditPointAmount, creditPointReason).then(()=>{
                    document.getElementById('creditPoints').value='';
                    document.getElementById('creditReason').value='';
                });

            }
        }
    }



    editStaff(e) {
        e.preventDefault();
  
        let newFiles = this.state.staffFiles.length > 0;
        let files = this.state.staffFiles;
        if(!newFiles){
           files = this.props.staffData.staffimageid;
        } else {
            var theStaffImage = files;
            if(this.state.image!==null){
                var baseURL = this.editor.getImageScaledToCanvas().toDataURL('image/jpeg', 0.85);
                files[0] = this.dataURLtoBlob(baseURL);
            }
        }

        var hireDate = null;
        var birthDate = null;
        var dateError = false;
        
        if(this.state.hireDate!==undefined && this.state.hireDate!==null){
            hireDate = dateFormat(this.state.hireDate, "shortDate");  
        }
        if(this.state.birthDate!==undefined && this.state.birthDate!==null){
            birthDate = dateFormat(this.state.birthDate, "shortDate");    
        }            

        if(this.state.hireDate===undefined){
            this.setState({ errors: {'hireDate': true }});
        } else if(this.state.birthDate===undefined){
            this.setState({ errors: {'birthDate': true }});   
        } if(!this.staffName.value){
            this.setState({ errors: {'name': true, 'title': false, 'primaryDepartment': false }});
        } else if(!this.staffTitle.value){
            this.setState({ errors: {'name': false, 'title': true, 'primaryDepartment': false }});
        } else if(!this.state.primaryDepartment && !this.state.changed){

            var options = document.getElementById('primaryDepartment').options;
            var value = [];

            for (var i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                  value.push(options[i].value);
                }
            }   
           
            this.setState({
                primaryDepartment: value,
                changed: true
            }, function(){
                if(value.length>0){
                    
                    var locationField = this.state.primaryLocation;
                    var locoptions = document.getElementById('primaryLocation').options;
                    var locvalue = [];
                    for (var i = 0, l = locoptions.length; i < l; i++) {
                        if (locoptions[i].selected) {
                          locvalue.push(locoptions[i].value);
                        }
                        if(locoptions[i]==='0' || locoptions[i]===0){
                            locvalue = locationField;
                            break;
                        }
                    }   
                    locationField = locvalue;
                    if(locationField[0]===0 || locationField[0]===undefined || locationField[0]===null || locationField[0]==="0"){
                        locationField = this.getDefaultLocationsToSubmit();
                    }                                    

                    this.props.updateStaff(
                        this.props.tvClient,
                        this.props.match.params.documentId,
                        newFiles,
                        this.staffName.value,
                        this.staffTitle.value,
                        this.staffEmail.value,
                        this.staffPhone.value,
                        this.staffBio.value,
                        this.state.primaryDepartment,
                        locationField,
                        files,
                        this.props.staffData.creatorId,
                        this.props.staffData.active,
                        true,
                        this.props.staffData.created,
                        this.staffGoogleId.value,
                        hireDate,
                        birthDate
                    ); 
                } else{
                    this.setState({ errors: {'name': false, 'title': false, 'primaryDepartment': true }});
                }
                 
            }.bind(this));
            
        } else if(!this.state.primaryDepartment){
            this.setState({ errors: {'name': false, 'title': false, 'primaryDepartment': true }});
        } else{
            var locationField = this.state.primaryLocation;
            var locoptions = document.getElementById('primaryLocation').options;
            var locvalue = [];
            for (var i = 0, l = locoptions.length; i < l; i++) {
                if (locoptions[i].selected) {
                  locvalue.push(locoptions[i].value);
                }
                if(locoptions[i]==='0' || locoptions[i]===0){
                    locvalue = locationField;
                    break;
                }
            }   
            locationField = locvalue;
            if(locationField[0]===0 || locationField[0]===undefined || locationField[0]===null || locationField[0]==="0"){
                locationField = this.getDefaultLocationsToSubmit();
            }  

           this.props.updateStaff(
                this.props.tvClient,
                this.props.match.params.documentId,
                newFiles,
                this.staffName.value,
                this.staffTitle.value,
                this.staffEmail.value,
                this.staffPhone.value,
                this.staffBio.value,
                this.state.primaryDepartment,
                locationField,
                files,
                this.props.staffData.creatorId,
                this.props.staffData.active,
                true,
                this.props.staffData.created,
                this.staffGoogleId.value,
                hireDate,
                birthDate
            );   

        } 
              
    }


    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryDepartment: value,
            changed: true
        });
    }    

    handleLocationList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        var foundAll = false;
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
              if(options[i].value===0 || options[i].value==="0"){
                foundAll=true;
                break;
              }
            }
        }   

        if(foundAll){
            this.setState({
                primaryLocation: this.getDefaultLocations()
            });
        }
        else{
            this.setState({
                primaryLocation: value
            });            
        }

    }

    requestPhotoFromStaff(){
        //e.preventDefault();
        var elems = document.getElementsByClassName('requestStaffButton');
        for (var i = elems.length - 1; i >= 0; i--) {
            elems[i].remove();
        }
        // change the text of the link too
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();

        this.fileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            staffFiles: [...e.target.files],
            image: e.target.files[0],
            // Set object URLs one time so that images don't re-render when state changes
            staffFileUrls: [...e.target.files].map(staffFile => URL.createObjectURL(staffFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }

  getDataUrl = img => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL();
  };


  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius
      }
    });
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateLeft = e => {
    e.preventDefault();

    this.setState({
      rotate: this.state.rotate - 90
    });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value);
    this.setState({ borderRadius });
  };

  handleXPosition = e => {
    const x = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, x } });
  };

  handleYPosition = e => {
    const y = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, y } });
  };

  handleWidth = e => {
    const width = parseInt(e.target.value);
    this.setState({ width });
  };

  handleHeight = e => {
    const height = parseInt(e.target.value);
    this.setState({ height });
  };

  logCallback(e) {
    // eslint-disable-next-line
    console.log("callback", e);
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] });
  };

    render() {
        return <div>
            <h1 className="page-header">
             {this.props.staffData && <div className="qrcodeImage"><QRCode size={100} value={window.location.protocol + '//' + window.location.host + '/#/me/' + this.props.staffData.qrcode} logo={process.env.PUBLIC_URL + '/qrlogo.jpg'} /><a className="peerReviewLink" target="_blank" href={window.location.protocol + '//' + window.location.host + '/#/me/' + this.props.staffData.qrcode}>Peer Review link</a></div>}
                Edit Staff
                <small>Please update any of the following staff information</small>
                <small className="backLink"><Link to={'/staff?refresh=0'}>Go Back</Link>&nbsp; &nbsp; <Link to={'/staff/report/' + this.props.match.params.documentId}>Analyze</Link> &nbsp; &nbsp; {this.props.staffData && <RequestStaffPhotoOverlay staffDetail={this.props.staffData} parentRefresh={this.requestPhotoFromStaff.bind(this)}/>} &nbsp; &nbsp;{this.props.staffData && <DeleteStaffOverlay staffDetail={this.props.staffData} parentRefresh={this.refreshHandler.bind(this)}/>} </small>
                
            </h1>
            <div className="case-list-container">
                <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={1} href="#">
                      Staff Details
                    </Nav.Link>   
                    { (this.props.staffData && this.props.staffData.showPeerReview==='true' && !this.props.isViewOnly) && <Nav.Link eventKey={2} href="#">
                      Staff Points ({this.props.totalPoints} pts)
                      </Nav.Link> 
                    }                         
                  </Nav>
                </Navbar>
            </div>
            {this.state.viewingStaff && <Spinner/>}

            {(this.props.staffData && this.state.showStaffDetails) && <Form onSubmit={this.editStaff.bind(this)} className="case-list-container">

                {this.props.viewStaffError && <Alert variant="danger">
                    {this.props.viewStaffError.message}
                </Alert>}

                <FormGroup controlId="staffname" className="componentSpacer">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="John Doe" defaultValue={this.props.staffData.name} ref={ref => this.staffName = ref}/>
                    {this.state.errors.name && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="staffTitle">
                    <FormLabel>Title *</FormLabel>
                    <FormControl type="text" placeholder="Dr. / Nurse / Admin" defaultValue={this.props.staffData.title} ref={ref => this.staffTitle = ref}/>
                    {this.state.errors.title && <Alert variant="danger">
                        Title is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="staffEmail">
                    <FormLabel>Email</FormLabel>
                    <FormControl type="email" placeholder="name@hospital.com" defaultValue={this.props.staffData.email} ref={ref => this.staffEmail = ref}/>
                </FormGroup>              

                <FormGroup controlId="staffPhone">
                    <FormLabel>Phone</FormLabel>
                    <FormControl type="text" placeholder="555-555-5555" defaultValue={this.props.staffData.phone} ref={ref => this.staffPhone = ref}/>
                </FormGroup>

                <FormGroup controlId="staffGoogleId">
                    <FormLabel>Staff Google Place Id</FormLabel>
                    <FormControl type="text" defaultValue={(this.props.staffData.reviews!==undefined && this.props.staffData.reviews!==null) ? this.props.staffData.reviews.google : ''} ref={ref => this.staffGoogleId = ref}/>
                </FormGroup>

                <FormGroup controlId="staffBio">
                    <FormLabel>Description</FormLabel>
                    <FormControl as="textarea" placeholder="Enter a brief bio here..." defaultValue={this.props.staffData.bio} ref={ref => this.staffBio = ref}/>
                </FormGroup> 

                <FormGroup controlId="primaryDepartment" className="adminDepartmentSelection">
                    <FormLabel>Choose Departments *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this)} multiple defaultValue={this.props.staffData.departments}>
                        {Object.values(this.props.departments).map(d => {
                            if(d.name!=='Facility' && d.name!=='Overall Staff Impression' && d.name!=='Overall Meals Impression' && d.name!=='Room Readiness'){
                                    return <option value={d.key} key={d.key}>{d.name}</option>
                            }
                        })}
                    </FormControl>                    

                    {this.state.errors.primaryDepartment && <Alert variant="danger">
                        A primary department is required.
                    </Alert>}
                </FormGroup>     

                <FormGroup controlId="primaryLocation" className="adminLocationSelection">
                    <FormLabel>Choose Locations *</FormLabel>
                    <FormControl as="select" placeholder="select"  onChange={this.handleLocationList.bind(this)} defaultValue={this.props.staffData.locations} multiple>
                        <option value="0">All Locations</option>
                        {Object.values(this.props.locations).map(d => {
                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                        })}
                    </FormControl>                    


                    {this.state.errors.primaryLocation && <Alert variant="danger">
                        A location is required.
                    </Alert>}
                </FormGroup> 

<Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      Optional Attributes &#8628;
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <FormGroup controlId="hireDate" className="adminLocationSelection">
                        <p><b>HIRE DATE (MM/DD/YYYY)</b></p>
                        <div>
                        <DayPickerInput formatDate={formatDate} parseDate={parseDate} placeholder={`${formatDate(new Date())}`} value={this.props.staffData.hire_date!==null ? `${formatDate(this.props.staffData.hire_date)}` : ''} id="hireDate" name="hireDate" onDayChange={this.handleHireDateChange}
                            dayPickerProps={{
                              selectedDays: this.state.selectedDay,
                              disabledDays: {
                                after: new Date(),
                              },
                            }} />
                            </div>
                        </FormGroup>
                        {this.state.errors.hireDate && <Alert variant="danger">
                        Your date is not properly formatted
                        </Alert>}
                        <FormGroup controlId="birthDate" className="adminLocationSelection">
                        <p><b>BIRTH DATE (MM/DD/YYYY)</b></p>
                        <div>
                        <DayPickerInput formatDate={formatDate} parseDate={parseDate} placeholder={`${formatDate(new Date())}`} id="birthDate" name="birthDate" value={this.props.staffData.dob!==null ? `${formatDate(this.props.staffData.dob)}` : ''} onDayChange={this.handleBirthDateChange}
                            dayPickerProps={{
                              selectedDays: this.state.selectedDay,
                              disabledDays: {
                                after: new Date(),
                              },
                            }} />
                            </div>
                        </FormGroup>
                        {this.state.errors.birthDate && <Alert variant="danger">
                        Your date is not properly formatted
                        </Alert>}
                        <FormGroup controlId="other-id" className="adminLocationSelection">
                        <p><b>Internal ID</b></p>
                        <div><p>{this.props.staffData.other_id!==null ? this.props.staffData.other_id : 'Not Set'}</p></div>
                        </FormGroup>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  </Accordion>

                  <br/> 
                <Row><Col className="thumbnail-col" md={4} key={1}>
{this.props.staffData.staffimageid && <img className="editStaffImgThumb" alt={this.props.staffData.staffimageid} src={this.props.staffData.staffimageid.startsWith('http') ? this.props.staffData.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + this.props.staffData.staffimageid} /> || <img className="editStaffImgThumb" alt="default" src={process.env.PUBLIC_URL + '/staff_assets/default-employee-avatar.png'} />} </Col>                  
                </Row>
                <legend className="uploadPhotoHeader">Upload New Photo</legend>
            
                <p>(Headshot - 180px x 180px)</p>

                <Row>
                {
                    this.state.staffFileUrls.map(url => {
                        return <Col className="thumbnail-col" key={url}>
                            
<ReactAvatarEditor
            ref={this.setEditorRef}
            scale={parseFloat(this.state.scale)}
            width={this.state.width}
            height={this.state.height}
            position={this.state.position}
            onPositionChange={this.handlePositionChange}
            rotate={parseFloat(this.state.rotate)}
            borderRadius={this.state.width / (100 / this.state.borderRadius)}
            onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
            onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
            onImageReady={this.logCallback.bind(this, "onImageReady")}
            image={url}
            className="editor-canvas"
          /><br/>
          Zoom:
        <input
          name="scale"
          type="range"
          onChange={this.handleScale}
          min={this.state.allowZoomOut ? "0.1" : "1"}
          max="2"
          step="0.01"
          defaultValue="1"
        />
        <br />
        <p>Rotate:
        <button onClick={this.rotateLeft}>Left</button>
        <button onClick={this.rotateRight}>Right</button></p>

                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.viewingStaff ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                    )}

                </div>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingStaff} className="float-right">
                        Update
                    </Button>
                </FormGroup>


            </Form>}

 {(this.props.staffData && this.state.showStaffPoints) && <div className="case-list-container componentSpacer">
    
    
    <h3>Redeem Points</h3>
{ !this.props.isViewOnly && <table className="table">
                                <thead>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Reason</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><FormControl type="number" id="redeemPoints" placeholder="Ex: 500" ref={ref => this.redeemAmount = ref}/></td>
                                        <td><FormControl type="text" id="redeemReason" placeholder="Ex: Merchandise" ref={ref => this.redeemReason = ref}/></td>
                                        <td><button className="btn btn-primary" onClick={this.redeemPoints}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>}
        {this.state.errors.redeemPointError && <Alert variant="danger">The number of points to redeem is required.</Alert>}
        {this.state.errors.redeemReasonError && <Alert variant="danger">The reason to redeem is required.</Alert>}
        {this.state.errors.redeemMaxError && <Alert variant="danger">The number of points is more than the staff member has available.</Alert>}                            
    
    <h3>Credit Points</h3>
{ !this.props.isViewOnly && <table className="table">
                                <thead>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Reason</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><FormControl type="number" id="creditPoints" placeholder="Ex: 500" ref={ref => this.creditAmount = ref}/></td>
                                        <td><FormControl type="text" id="creditReason" placeholder="Ex: Return" ref={ref => this.creditReason = ref}/></td>
                                        <td><button className="btn btn-primary" onClick={this.creditPoints}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>}
        {this.state.errors.creditPointError && <Alert variant="danger">The number of points to credit is required.</Alert>}
        {this.state.errors.creditReasonError && <Alert variant="danger">The reason for the credit is required.</Alert>}                            


    <h3>Point Activity</h3>
    <table className="case-list table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Reason</th>
                <th className="text-center">Points</th>
            </tr>
        </thead>
        <tbody>
        {
            this.props.pointHistory.map((c, i) => {
                return <tr className={(i % 2)===0 ? "surveyRow statDeptContainer reportBadgeContainer intakeListRow" : "surveyRow intakeListRow altRowColor statDeptContainer reportBadgeContainer"}  key={i}>
                    <td>{dateFormat(c.created, "shortDate")}</td>
                    <td>{c.reason}</td>
                    <td className="text-center">{c.points}</td>
                </tr>
            })
        }
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td className="text-center totalBox"><b>{this.props.totalPoints}</b></td>
        </tr>
        </tbody>
    </table>
 </div> }

        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingStaff: state.viewStaff.viewingStaff,
        viewStaffError: state.viewStaff.viewStaffError,
        bytesLoaded: state.viewStaff.bytesLoaded,
        bytesTotal: state.viewStaff.bytesTotal,
        tvClient: state.login.tvClient,
        staffData: state.viewStaff.staffData,
        totalPoints: state.viewStaff.totalPoints,
        pointHistory: state.viewStaff.pointHistory,
        departments: state.deptList.departments,
        locations: state.locationList.locations,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewStaff: (...params) => dispatch(viewStaff(...params)),
        updateStaff: (...params) => dispatch(updateStaff(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params)),
        creditStaffPoints: (...params) => dispatch(creditStaffPoints(...params)),
        redeemStaffPoints: (...params) => dispatch(redeemStaffPoints(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStaff);

