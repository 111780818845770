import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";

class ExportAddressableEventsCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    determineStatus(status, action){
        var finalStatus = 'Open';
        if(status===true || status==='true'){
            return 'Closed';
        } else{
            if(action!==undefined && action!==null && action.toString().length>0){
                return 'Pending';
            } else {
                return 'Open';
            }
        }
    }

    cleanData(dirtyData){
        let exportData = [];

        for (var i = dirtyData.length - 1; i >= 0; i--) {
            var thisLine = dirtyData[i];

            var myStaff = thisLine.staff;
            if(myStaff!==undefined && myStaff!==null){
                try{
                    myStaff = JSON.parse(myStaff.personnel);    
                } catch(error){
                    if(myStaff.personnel!==undefined && myStaff.personnel.length>0){
                        myStaff = myStaff.personnel;
                    } else{
                        myStaff = undefined;
                    }
                }
            } else {
                if(thisLine.associated_staff!==null){
                    myStaff = [];
                    myStaff.push({first_name: thisLine.associated_staff});
                }
            }

            exportData.push({ 'Event Id': thisLine.event_id, 'Location': thisLine.location_name, 'Type': thisLine.event_overview, 'Completed Date': thisLine.created, 'Status': this.determineStatus(thisLine.addressed, thisLine.action), 'Category': thisLine.category!==null ? thisLine.category.replace(/(\r\n|\n|\r|\")/gm,"") : 'None', 'Editor History': thisLine.admin!==null ? thisLine.admin.replace(/(\r\n|\n|\r|\")/gm,"") : 'None Listed', 'Action': thisLine.action!==null ? thisLine.action.replace(/(\r\n|\n|\r|\")/gm,"") : 'None', 'Custom Detail': (thisLine.detail!==undefined && thisLine.detail!==null && thisLine.detail!=='') ? thisLine.detail.replace(/(\r\n|\n|\r|\")/gm,"") : thisLine.survey_data.replace(/(\")/gm,""), 'Patient Name': (this.props.withNames && thisLine.name!==undefined && thisLine.name!==null) ? thisLine.name.replace(/(\r\n|\n|\r|\")/gm,"") : 'Not Included' ,'Extra Staff Detail': (myStaff!==undefined && myStaff!==null && myStaff.length>0) ? ("" + (myStaff[0].first_name!==undefined ? myStaff[0].first_name : '' + ' ') + " " + (myStaff[0].last_name!==undefined ? myStaff[0].last_name : '') +"") : ''  });
        }
        
        
        return exportData.reverse();
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
           
        )

    }
}

ExportAddressableEventsCSV.propTypes = {
    csvData: PropTypes.array.isRequired,
    withNames: PropTypes.bool.isRequired,
    fileName: PropTypes.string.isRequired
};

export default ExportAddressableEventsCSV;