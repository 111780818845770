import React, {Component} from "react";
import {
    Table,
    Dropdown,
    FormControl
} from "react-bootstrap";
import { viewSurvey, listCampaignResultsReport,  listCampaigns, sendEmployerReminder } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges"; 
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class CampaignsReport extends Component {

    constructor(props) {
        super(props);
       // this.props.listEmployers(this.props.tvClient.accessToken);
        this.props.listCampaigns(this.props.tvClient);

        this.state = {
            emptySet: [{'name':'No Data','value': 1}],
            filter: '',
            currentFilter: 8,
            emptySet: [{'name':'No Data','value': 1}],
            activeKey: 1,
            campaignFilter: 0,
            currentCampaignName: ' All Campaigns ',
        };
    }   

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id);
    }   

    handleCampaignChange(campaignKey){
      if(campaignKey==='-1'){
       
        this.setState({ campaignFilter: 0, currentCampaignName: ' All Campaigns '});
      } else{
        this.props.listCampaignResultsReport(this.props.accessToken, campaignKey);
        var campaignName = '';
        for(var i=0; i<this.props.campaigns.length;i++){
          if(this.props.campaigns[i].uid===campaignKey){
            campaignName=this.props.campaigns[i].name;
            break;
          }
        }
        this.setState({ campaignFilter: campaignKey, currentCampaignName: campaignName});  
      }
      
    }

    sendReminder(sentId, event){
      event.preventDefault();
      event.persist();
      this.props.sendEmployerReminder(this.props.tvClient, sentId).then(() => {
          var el = document.createElement("span");
          el.innerHTML = "SENT";
          event.target.insertAdjacentElement("beforebegin", el);
          event.target.remove();
      }); 
    }

    render() {
       return <div>
            <h1 className="page-header">
                Campaigns Report
  
             {(this.props.campaigns) && <div className="float-right locationSpecificReportFilter">
                     <Dropdown
                          title='Refine by Campaign'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleCampaignChange.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Refine by Campaign
                          </Dropdown.Toggle>
                          <Dropdown.Menu>  
                      {Object.values(this.props.campaigns).map((d) => {
                        return  <Dropdown.Item eventKey={d.uid} key={d.uid}>{d.name}</Dropdown.Item>
                      })}  <Dropdown.Divider />
                          <Dropdown.Item eventKey="-1">Reset Filter</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
            </div> }

            </h1>

            <div>
              <div className="case-list-container">
                    <div><h4>{this.state.campaignFilter===0 ? "Choose a campaign for results" : "Showing Results for: " + this.state.currentCampaignName}</h4></div>
                    {(this.props.campaignResults && this.state.campaignFilter!==0 && !this.props.campaignResultsLoading) && <div>
                      <small>Click employer name to view survey</small>
                      <table className="col-md-12 table table-hover">
                            <thead>

                                <tr className="reportBadgeContainer table-headings">
                                    <th>Employer</th>
                                    <th>Person</th>
                                    <th>Sent On</th>
                                    <th className="text-center">Reminder</th>
                                    <th className="text-center">Opened</th>
                                    <th className="text-center">Completed</th>
                                </tr>
                            </thead>

                           <tbody>
                                        
                                    {
                                        this.props.campaignResults.map(c => {
                                            return <tr className="statDeptContainer actionableRecord reportBadgeContainer" key={c.survey_id}>
                                                    <td><button className="simulateAnchor" onClick={()=> this.viewSurvey(c.survey_id)}>{c.employername}</button></td>
                                                    <td>{c.personname}</td>
                                                    <td>{dateFormat(c.sentdate, "shortDate")}</td>
                                                    <td className="text-center">{(!c.completed && !c.reminded) && <button className="simulateAnchor" onClick={this.sendReminder.bind(this, c.sent_id)}>Send</button>}{(!c.completed && c.reminded) && <span>Sent</span>}</td>
                                                    <td className={"text-center " + (c.opened!=='1000000000' ? 'yesColumn' : 'noColumn')}>{c.opened !== '1000000000' ? 'Yes' : 'No'}</td>
                                                    <td className={"text-center " + (c.completed ? 'yesColumn' : 'noColumn')}>{c.completed ? 'Yes' : 'No'}</td>
                                                  </tr>
                                        })
                                    }  

                              </tbody> 
                                      
                    </table> </div>}
                    {this.props.campaignResultsLoading && <Spinner/>} 
                    {(this.props.campaignResults && this.props.campaignResults.length===0 && this.state.campaignFilter!==0 && !this.props.campaignResultsLoading) && <p>No results found</p>}
              </div>
              
           </div> 
        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        campaigns: state.campaignList.campaigns,
        campaignResultsLoading: state.campaignResults.loading,
        campaignResults: state.campaignResults.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listCampaigns: (...params) => dispatch(listCampaigns(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params)),
        listCampaignResultsReport: (...params) => dispatch(listCampaignResultsReport(...params)),
        sendEmployerReminder: (...params) => dispatch(sendEmployerReminder(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsReport);
