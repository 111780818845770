class PatientDocument {
    constructor(name, email, phone, type, creator, survey, extra) {
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.type = type;
        this.creator = creator;
        this.survey = survey;
        this.extra = extra;
    }
}

export default PatientDocument;