import React, {Component} from "react";
import {
    Alert,
    FormControl,
    Table,
    Nav, Navbar,
    Button,
    FormLabel,
    Form,
    FormGroup
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { addMultipleMappings, listDepartments, listLocations, listJourneys, listGroupJourneys, listJourneyMappings, createJourneyMapping} from "../../actions";
import "./DepartmentList.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import DeleteDepartmentOverlay from "./../Overlays/DeleteDepartmentOverlay";
import DeleteJourneyMappingOverlay from "./../Overlays/DeleteJourneyMappingOverlay";
import PaginationArea from "./../Utilities/PaginationArea";
import queryString from 'query-string';

class DepartmentList extends Component {
    constructor(props) {
        super(props);

        let activeTab = 1;
        let showJourneysTab=false;
        let showDeptListTab=true;
        let showMappingTab=false;

        const queryStringValues = queryString.parse(props.location.search);

        if(queryStringValues.active!==null && queryStringValues.active !== undefined){
            activeTab = parseInt(queryStringValues.active, 10);
            if(activeTab===2){
                showDeptListTab=false;  showJourneysTab=true; showMappingTab=false;
            } else if(activeTab===3){
                showDeptListTab=false;  showJourneysTab=false; showMappingTab=true;
            }
        }

        this.state = {
            fields: { 'role': null},
            sortKey: null,
            sortDirection: 'asc',
            activeKey: activeTab,
            activeMappingKey: 7,
            cols:  null,
            rows: null,
            showDeptList: showDeptListTab,
            showJourneys: showJourneysTab,
            showMappings: showMappingTab,
            currentFilterLocation: ' All Locations ',
            errors: { 'apptCode': false, 'journeyCode': false, 'dupeCode': false }
        };
        this.handleFormChange = this.handleFormChange.bind(this);
        this.addJourneyMapping = this.addJourneyMapping.bind(this);
        this.props.listLocations(this.props.tvClient);
    }

    handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showDeptList: true, showJourneys: false, showMappings: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===2){
        this.setState({ showDeptList: false, showJourneys: true, showMappings: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===3){
        this.setState({ showDeptList: false, showJourneys: false, showMappings: true, activeKey: selectedKey });
      } 
    }    

    handleMappingSelect(selectedKey){
    if(parseInt(selectedKey)===7){
        this.setState({ activeMappingKey: 7 });
      } else if(parseInt(selectedKey)===8){
        this.setState({ activeMappingKey: 8 });
      } 
    }

    componentDidMount() {
        this.changeListPage(1);
    }

    changeListPage(page) {
        let filter = {
            caseId: {
                type: 'wildcard',
                value: '*'
            }
        };

        if (this.state.dueBefore) {
            filter.dueDate = {
                type: 'range',
                value: {
                    lte: this.state.dueBefore
                }
            };
        }

        let sort = [];
        if (this.state.sortKey) {
            let sortObj = {};
            sortObj[this.state.sortKey] = this.state.sortDirection;
            sort.push(sortObj);
        }

        this.props.listDepartments(this.props.tvClient, 'and', filter, this.state.sortDirection, page, 15);
        this.props.listJourneys(this.props.tvClient);
        this.props.listGroupJourneys(this.props.tvClient);
        this.props.listJourneyMappings(this.props.tvClient);
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    } 

    newBatchMapping(e){
        e.preventDefault();
        this.props.addMultipleMappings(this.props.tvClient, this.state.rows, this.props.journeyMapping).then(() => {
            this.setState({ rows: null, cols: null, dataLoaded: false });
            window.scrollTo(0,0);
        }); 
    };

    addJourneyMapping(){
        var userApptCode = this.apptCode.value.trim();
        var userJourneyCode = this.state.fields['journey'];
        var userFrequencyCode = this.state.fields['frequency'];
        var userLocationCode = this.state.fields['location'];

        var validMapping = true;
        if(userFrequencyCode===undefined || userFrequencyCode===null || userFrequencyCode===''){
            userFrequencyCode='1';
        }
        if(userApptCode===undefined || userApptCode===null || userApptCode===''){
            this.setState({ errors: {'apptCode': true, 'journeyCode': false, 'dupeCode': false}});
        } else if(userJourneyCode===undefined || userJourneyCode===null || userJourneyCode===''){
            this.setState({ errors: {'apptCode': false, 'journeyCode': true, 'dupeCode': false}});
        } else{
            // look for existing mapping
            for(var i=0; i<this.props.journeyMapping.length; i++){
                if(this.props.journeyMapping[i].appointment_code.toString()===userApptCode.toString()){
                    if(this.props.journeyMapping[i].location_id!==null){
                        if(this.props.journeyMapping[i].location_id.toString()===userLocationCode.toString()){
                            validMapping=false;
                            this.setState({errors: {'dupeCode': true } });
                            break;
                        }                        
                    } else {
                        if(this.props.journeyMapping[i].location_id===null && userLocationCode.toString().trim().length===0){
                            validMapping=false;
                            this.setState({errors: {'dupeCode': true } });
                            break;
                        }  
                    }
                }
            }

            if(validMapping){
                this.setState({errors: {'apptCode': false, 'journeyCode': false, 'dupeCode': false } });

                this.props.createJourneyMapping(this.props.tvClient, userApptCode, userJourneyCode, userFrequencyCode, userLocationCode).then(()=>{
                    this.props.listJourneyMappings(this.props.tvClient);
                    this.apptCode.value = '';
                    document.getElementById('journey').value='';
                    document.getElementById('location').value='';
                    document.getElementById('frequency').value='1';
                    let fields = this.state.fields;
                      fields['journey'] = '';
                      fields['frequency'] = '1';
                      fields['location'] = '';
                      this.setState({
                        fields
                      });
                }); 
            }
        }
    }

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: this.props.locations[i].location_id, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });     
            break;
          }
        }
      }
    }

    getLocationName(id){
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(id)){
            return(this.props.locations[i].name);
          }
        }        
    }

    toggleSortDirection(key) {
        return () => {
            let newSortDirection = 'desc';
            if (this.state.sortKey === key && this.state.sortDirection === 'desc') {
                newSortDirection = 'asc';
            }

            this.setState({
                sortKey: key,
                sortDirection: newSortDirection
            }, () => this.changeListPage(this.props.paginationInfo.current_page));
        };
    }

    sortIndicator(key) {
        if (this.state.sortKey === key && this.state.sortDirection === 'desc') {
            return <FontAwesomeIcon icon={faArrowUp}/>;
        } else if (this.state.sortKey === key && this.state.sortDirection === 'asc') {
            return <FontAwesomeIcon icon={faArrowDown}/>;
        }
    }

    fileHandler(event) {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
            this.setState({
              cols: resp.cols,
              rows: resp.rows,
              dataLoaded: true
            });
          }
        });               
    }; 

    dueBeforeChange(event) {
        this.setState({
            dueBefore: event.target.value || null
        }, () => this.changeListPage(this.props.paginationInfo.current_page));
    }

    tableHeader(sortKey, label) {
        return <th className="sortable" onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    refreshHandler(){
        this.changeListPage(1);
    }

    render() {
        return <div>
            <h1 className="page-header">
                Departments
                <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={1} href="#">
                      View List
                    </Nav.Link>   
                    <Nav.Link eventKey={2} href="#">
                      Journeys
                    </Nav.Link>
                    <Nav.Link eventKey={3} href="#">
                      EHR Mapping
                    </Nav.Link>
                  </Nav>
                </Navbar>
            </h1>

            {(this.state.showDeptList) && <div className="case-list-container">
             { !this.props.isViewOnly && <Link to="/departments/new" className="btn btn-primary float-right">+ Add Department</Link>}
                {this.props.listError && <Alert variant="danger">{this.props.listError.message}</Alert>}

                <div className="case-list-summary">{this.props.departments.length}
                    {' '}department{this.props.departments.length === 1 ? '' : 's'} listed.
                </div>

                <Table className="case-list sortable">
                    <thead>
                    <tr>
                        {this.tableHeader('name', '')}
                        {this.tableHeader('name', 'Department Name')}
                        {this.tableHeader('created', 'Created')}
                        { !this.props.isViewOnly && <th>Actions</th> }
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {

                        this.props.departments.map(c => {
                                return <tr key={c.key}>
                                    <td className='deptImageAdmin'><img alt={c.name} src={ (c.icon!==null && c.icon.startsWith('http')) ? c.icon : process.env.PUBLIC_URL + '/department_assets/' + c.icon} /></td>
                                    <td>{c.name}</td>
                                    <td>{c.created.substring(0,10)}</td>
                                    { !this.props.isViewOnly && <td><Link to={'/departments/edit/' + c.key}>Edit</Link>    {c.key && <DeleteDepartmentOverlay departmentDetail={c} parentRefresh={this.refreshHandler.bind(this)}/>}
                                    </td>}
                                </tr>

                        })
                    }
                    </tbody>
                </Table>
                {this.props.deptLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />
            </div>
            }
                {(this.state.showJourneys ) && <div className="case-list-container">
                    {this.props.journeysLoading && <Spinner/>}
                 <div>

                    {
                        this.props.journeys.map(c => {
                            return  <div key={c.journey_id} className="locationContainer journeyContainer">
                                      <h5>{c.name}</h5>
                                      { !this.props.isViewOnly && <Link to={'/journey/edit/'+ c.journey_id}>Edit / View</Link>}
                                </div>   
                        })
                    } 

                    {
                        this.props.groupJourneys.map(c => {
                            return  <div key={c.journey_id} className="locationContainer journeyContainer">
                                      <h5>{c.name}</h5>
                                      { !this.props.isViewOnly && <Link to={'/journey/edit/'+ c.journey_id}>Edit / View</Link>}
                                </div>   
                        })
                    }           

                    { !this.props.isViewOnly && <Link to="/journey/new" className="btn btn-primary">Add New</Link> }
                </div>              


                    </div>
                }

                  {(this.state.showMappings ) && <div className="case-list-container">
                        <p className="text-danger">Only modify these if you know what you are doing.</p>
                         <div>
                            <Navbar className="submenuNav" variant="light" expand="lg">
                              <Nav activeKey={this.state.activeMappingKey} onSelect={this.handleMappingSelect.bind(this)}>
                                <Nav.Link eventKey={7} href="#">
                                  Single Add
                                </Nav.Link>   
                                <Nav.Link eventKey={8} href="#">
                                  Bulk Add
                                </Nav.Link>
                              </Nav>
                            </Navbar>
                            <br/>
                            { (!this.props.isViewOnly && this.state.activeMappingKey===7) && <div>
                                <h3>Add New EHR Mapping</h3>
                            { !this.props.isViewOnly && <table className="table">
                                <thead>
                                    <tr>
                                        <th>Appointment Code</th>
                                        <th>Journey</th>
                                        <th>Location</th>
                                        <th>Frequency</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><FormControl type="text" placeholder="Ex: EH500" ref={ref => this.apptCode = ref}/></td>
                                        <td><div>
                      <FormControl as="select" placeholder="select" name="journey" id="journey" onChange={this.handleFormChange.bind(this)}>
                            <option value='' key=''>Select</option>
                            {Object.values(this.props.journeys).map(d => {
                                return <option value={d.journey_id} key={d.journey_id}>{d.name}</option>
                            })}
                        </FormControl></div></td>
                                        <td>
                                            <div>
                                              <FormControl as="select" placeholder="select" name="location" id="location" onChange={this.handleFormChange.bind(this)}>
                                                    <option value='' key=''>All</option>
                                                    {Object.values(this.props.locations).map(d => {
                                                        return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                                                    })}
                                                </FormControl>
                                            </div>

                                        </td>
                                        <td><div>
                      <FormControl as="select" placeholder="select" name="frequency" id="frequency" onChange={this.handleFormChange.bind(this)}>
                            <option value='1' key='1'>Every Visit</option><option value='2' key='2'>Every 2 Visits</option>
                            <option value='3' key='3'>Every 3 Visits</option><option value='4' key='4'>Every 4 Visits</option>
                            <option value='5' key='5'>Every 5 Visits</option><option value='6' key='6'>Every 6 Visits</option>
                            <option value='7' key='7'>Every 7 Visits</option><option value='8' key='8'>Every 8 Visits</option>
                            <option value='9' key='9'>Every 9 Visits</option><option value='10' key='10'>Every 10 Visits</option>
                        </FormControl></div></td>
                                        <td><button className="btn btn-primary" onClick={this.addJourneyMapping}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>}
                            {this.state.errors.apptCode && <Alert variant="danger">Appointment Code is required.</Alert>}
                            {this.state.errors.journeyCode && <Alert variant="danger">Journey is required.</Alert>}
                            {this.state.errors.dupeCode && <Alert variant="danger">That Appointment Code is already mapped.</Alert>}


                            </div>}
                            { (!this.props.isViewOnly && this.state.activeMappingKey===8) && <div>
                                <h3>Bulk Add EHR Mappings</h3>
                                <hr/>
                                <p>After a bulk upload you will need to refresh this page manually</p>
                                <Form onSubmit={this.newBatchMapping.bind(this)} >

                                    <legend><small>(Download the <a href="/WelliQ-AddBatchMappings.xlsx">Excel Template</a>)</small></legend>
                                    <p>Rows Processed: {this.props.mappingsAdded} / {this.props.totalMappings>0 ? this.props.totalMappings : '-'}</p>
                                    <FormGroup controlId="locationName">
                                        <FormLabel>Upload *</FormLabel>
                                        <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
                                        <Button type="submit" disabled={this.props.addingMultipleMappings} className="btn btn-primary pull-left">
                                                {this.props.addingMultipleMappings ? 'Adding' : 'Add'}
                                            </Button>
                                            {this.props.addingMultipleMappings && <Spinner/>}
                                    </FormGroup>
                                    
                                    {this.state.dataLoaded && 
                                        <div className="case-list-container">
                                            <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                                        </div>
                                    }
                                </Form>


                            </div>}

                           
                            <h3>Existing Mappings <small>({this.props.journeyMapping.length})</small></h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Appointment Code</th>
                                        <th>Journey</th>
                                        <th>Location</th>
                                        <th>Frequency</th>
                                        { !this.props.isViewOnly && <th>Actions</th> }
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.props.journeyMapping.map(c => {
                                        return  <tr key={c.uid}>
                                                  <td>{c.appointment_code}</td>
                                                  <td>{c.name}</td>
                                                  <td>{(c.location_id===undefined || c.location_id===null) ? 'All' : this.getLocationName(c.location_id)}</td>
                                                  <td>{(c.priority===1 || c.priority==='1') ? 'Every Visit' : 'Every ' + c.priority + ' Visits'}</td>
                                                  { !this.props.isViewOnly && <td>
                                                    <Link to={'/ehrmapping/edit/' + c.uid}>Edit</Link>
                                                    {c.uid && <DeleteJourneyMappingOverlay mappingDetail={c} />}
                                                  </td> }
                                            </tr>   
                                    })
                                } 
                                </tbody>
                            </table>            
                            {this.props.journeyMapping.length===0 && <div><p>No Mappings found</p></div> }
                        </div>
                    </div>
                }

                <footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer> 
        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        departments: state.deptList.departments,
        deptLoading: state.deptList.loading,
        journeys: state.journeyList.journeys,
        journeyMapping: state.journeyMappingList.journeyMappings,
        groupJourneys: state.groupJourneyList.journeys,
        journeysLoading: state.journeyList.loading,
        paginationInfo: state.deptList.paginationInfo,
        paginationItems: state.deptList.paginationInfo.pageList,
        listError: state.deptList.error,
        locations: state.locationList.locations,
        mappingsAdded: state.addMultipleMappings.mappingsAdded,
        totalMappings: state.addMultipleMappings.totalMappings,
        addingMultipleMappings: state.addMultipleMappings.addingMultipleMappings,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};


const mapDispatchToProps = dispatch => {
    return {
        listDepartments: (...params) => dispatch(listDepartments(...params)),
        listJourneys: (...params) => dispatch(listJourneys(...params)),
        listGroupJourneys: (...params) => dispatch(listGroupJourneys(...params)),
        listJourneyMappings: (...params) => dispatch(listJourneyMappings(...params)),
        createJourneyMapping: (...params) => dispatch(createJourneyMapping(...params)),
        addMultipleMappings: (...params) => dispatch(addMultipleMappings(...params)),
        listLocations: (...params) => dispatch(listLocations(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentList);
