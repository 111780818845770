import React, {Component} from "react";
import PropTypes from "prop-types";

import "./StaffStatus.scss";

class StaffStatus extends Component {
    static staffStatus(status) {
        return {
                true: 'Active',
                false: 'Inactive'
            }[status] || status;
    }

    render() {
        return <span className={`staff-status-${this.props.status}`}>{this.props.bullet && '\u2022 '}{StaffStatus.staffStatus(this.props.status)}</span>;
    }
}

StaffStatus.propTypes = {
    status: PropTypes.bool,
    bullet: PropTypes.bool
};

export default StaffStatus;
