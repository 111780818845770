import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    Row
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateEndorsement, viewEndorsement, listDepartments} from "../../actions";

class EditEndorsement extends Component {

    componentDidMount() { 
        if(this.props.departments===undefined || this.props.departments===null || this.props.departments.length===0){
            this.props.listDepartments(this.props.tvClient, 'and', null, 'asc', 1, 100);
        }
        this.props.viewEndorsement(this.props.tvClient, this.props.match.params.documentId).then(()=>{
            var badgeType = 'staff';
            if(this.props.badgeData.department_only===true){
                badgeType = 'dept';
            } else if(this.props.badgeData.peers_only===true){
                badgeType = 'peer';
            }
            let fields = this.state.fields;
            fields['role'] = badgeType;
            this.setState({fields})
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            fields: { 'role': null},
            endorsementFiles: [],
            endorsementFileUrls: [],
            changed: false,
            errors: { 'name': false, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': false, 'role': false }
        };
        this.handleFormChange = this.handleFormChange.bind(this);
    } 

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    } 

    editEndorsement(e) {
        e.preventDefault();

        let newFiles = this.state.endorsementFiles.length > 0;
        let files = this.state.endorsementFiles;
        if(!newFiles){
           files = this.props.badgeData.image;
        }

        if(!this.endorsementName.value){
            this.setState({ errors: {'name': true, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': false, 'role': false }});
        } else if(!this.badgePriority.value){
            this.setState({ errors: {'name': false, 'badgePriority': true, 'primaryDepartment': false, 'badgeLocalization': false, 'role': false }});
        } else if(!this.badgeLocalization.value && this.isJsonString(this.badgeLocalization.value.trim())){
            this.setState({ errors: {'name': false, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': true, 'role': false }});
        } else if(!this.state.fields['role']){
            this.setState({ errors: {'name': false, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': false, 'role': true }});
        } else if(!this.state.primaryDepartment && !this.state.changed){
            var options = document.getElementById('primaryDepartment').options;
            var value = [];

            for (var i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                  value.push(options[i].value);
                }
            }   
            this.setState({
                primaryDepartment: value,
                changed: true
            }, function(){
                if(value.length>0){
                    var points = 1;
                    if(this.badgePoints.value.trim().length>0){
                        points = this.badgePoints.value.trim();
                    }
                    this.props.updateEndorsement(
                        this.props.tvClient,
                        this.props.match.params.documentId,
                        this.endorsementName.value.trim(),
                        this.state.fields['role'],
                        this.badgePriority.value.trim(),
                        this.badgeLocalization.value.trim(),
                        value,
                        newFiles,
                        files,
                        points
                    );   
                } else{
                    this.setState({ errors: {'name': false, 'title': false, 'primaryDepartment': true }});
                }
                 
            }.bind(this));


        } else if(!this.state.primaryDepartment){
            this.setState({ errors: {'name': false, 'badgePriority': false, 'primaryDepartment': true, 'badgeLocalization': false, 'role': false }});
        } else{
            var points = 1;
            if(this.badgePoints.value.trim().length>0){
                points = this.badgePoints.value.trim();
            }
            this.props.updateEndorsement(
                this.props.tvClient,
                this.props.match.params.documentId,
                this.endorsementName.value.trim(),
                this.state.fields['role'],
                this.badgePriority.value.trim(),
                this.badgeLocalization.value.trim(),
                this.state.primaryDepartment,
                newFiles,
                files,
                points
            );    

        }       
    }

    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryDepartment: value,
            changed: true
        });
    }    

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }  

    handleBrowseImagesClick(e) {
        e.preventDefault();
        this.fileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            endorsementFiles: [...e.target.files],
            endorsementFileUrls: [...e.target.files].map(endorsementFile => URL.createObjectURL(endorsementFile))
        });
    }

    render() {
        return <div>
            <h1 className="page-header">
                Edit Endorsement Badge
                <small>Please update any of the following endorsement information</small>
                <small className="backLink"><a href="#" onClick={() => window.history.back()} >Go Back</a></small>
            </h1>
            
            {this.state.viewingBadge && <Spinner/>}

            {this.props.badgeData && <Form onSubmit={this.editEndorsement.bind(this)} className="new-case-form">

                {this.props.viewBadgeError && <Alert variant="danger">
                    {this.props.viewBadgeError.message}
                </Alert>}

                <FormGroup controlId="endorsementName">
                    <FormLabel>Badge Name *</FormLabel>
                    <FormControl type="text" placeholder="Attentive" defaultValue={this.props.badgeData.description} ref={ref => this.endorsementName = ref}/>
                    {this.state.errors.name && <Alert variant="danger">
                        Badge name is required.
                    </Alert>}
                </FormGroup>

                <div className="form-group">
                    <label htmlFor="role">Endorsement is for: <span className="text-danger">*</span></label>      
                    <div className="radio">
                      <label>
                        {this.props.badgeData.department_only ? <input type="radio" name="role" value="dept" checked="checked" onChange={this.handleFormChange}></input> : <input type="radio" name="role" value="dept" onChange={this.handleFormChange}></input>}
                        Department Level
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        {this.props.badgeData.staff_only ? <input type="radio" name="role" value="staff" checked="checked" onChange={this.handleFormChange}></input> : <input type="radio" name="role" value="staff" onChange={this.handleFormChange}></input>}
                        Staff Level
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        {this.props.badgeData.peers_only ? <input type="radio" name="role" value="peer" checked="checked" onChange={this.handleFormChange}></input> : <input type="radio" name="role" value="peer" onChange={this.handleFormChange}></input>}
                        Peer Only
                      </label>
                    </div>
                    {this.state.errors.role && <Alert variant="danger">
                        You must choose a level for this badge.
                    </Alert>}
                </div>

                <FormGroup controlId="badgePriority">
                    <FormLabel>Priority *</FormLabel>
                    <FormControl type="text" placeholder="100" defaultValue={this.props.badgeData.priority} ref={ref => this.badgePriority = ref}/>
                    {this.state.errors.badgePriority && <Alert variant="danger">
                        Badge priority is required.
                    </Alert>}
                </FormGroup>
                <FormGroup controlId="badgePoints">
                    <FormLabel>Badge Point Value*</FormLabel>
                    <FormControl type="number" defaultValue={this.props.badgeData.points} ref={ref => this.badgePoints = ref}/>
                    {this.state.errors.badgePoints && <Alert variant="danger">
                        Badge points are required.
                    </Alert>}
                </FormGroup>


                <FormGroup controlId="badgeLocalization">
                    <FormLabel>Localized Text</FormLabel>
                    <FormControl as="textarea" defaultValue={this.props.badgeData.localization_options!==undefined ? JSON.stringify(this.props.badgeData.localization_options, undefined, 2) : JSON.stringify({}, undefined, 2)} ref={ref => this.badgeLocalization = ref}/>
                </FormGroup>                

                <FormGroup controlId="primaryDepartment">
                    <FormLabel>Choose Departments *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this)} defaultValue={this.props.badgeData.department}>
                        {Object.values(this.props.departments).map(d => {
                            return <option value={d.key} key={d.key}>{d.name}</option>
                        })}
                    </FormControl>                    

                    {this.state.errors.primaryDepartment && <Alert variant="danger">
                        A department is required.
                    </Alert>}
                </FormGroup>                         

                <Row><Col className="thumbnail-col" md={4} key={1}>
{this.props.badgeData.image && <img className="editStaffImgThumb" alt={this.props.badgeData.image} src={this.props.badgeData.image.startsWith('http') ? this.props.badgeData.image : process.env.PUBLIC_URL + '/endorsement_assets/' + this.props.badgeData.image} />} </Col>                  
                </Row>
                <legend>Upload New Photo</legend>
                <p>(Badge - 145px x 145px)</p>

                <Row>
                {
                    this.state.endorsementFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.viewingBadge ? "Uploading" : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                    )}

                </div>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingBadge} className="float-right">
                        Update
                    </Button>
                </FormGroup>


            </Form>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingBadge: state.viewEndorsement.viewingBadge,
        viewBadgeError: state.viewEndorsement.viewBadgeError,
        tvClient: state.login.tvClient,
        badgeData: state.viewEndorsement.badgeData,
        departments: state.deptList.departments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewEndorsement: (...params) => dispatch(viewEndorsement(...params)),
        updateEndorsement: (...params) => dispatch(updateEndorsement(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEndorsement);

