import React, {Component} from "react";
import {
    Alert,
    FormControl,
    Table,
    Nav, Navbar
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faRedo } from '@fortawesome/free-solid-svg-icons'
import {getAuthTokens, getReviewLocations, refreshGoogleAccountLocations} from "../../actions";
import "./ReviewPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import DeleteDepartmentOverlay from "./../Overlays/DeleteDepartmentOverlay";
import DeleteJourneyMappingOverlay from "./../Overlays/DeleteJourneyMappingOverlay";
import PaginationArea from "./../Utilities/PaginationArea";
import queryString from 'query-string';
import dateFormat from "dateformat";

class ViewAccountLocations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: { 'role': null}
        };
    }

    renderRatingPercent(param) {
        return (param*20).toString()+'%'
    }

    refreshLocations() {
        this.props.refreshGoogleAccountLocations(this.props.tvClient, 'GOOGLE', this.props.match.params.accountId);
    }

    componentDidMount() {
        this.props.getReviewLocations(this.props.tvClient, 'GOOGLE',this.props.match.params.accountId);
        /*this.props.getAuthTokens(this.props.tvClient).then(()=>{
            this.props.getReviewLocations(this.props.tvClient, 'GOOGLE');
        });*/
    }

    render() {
        return <div>
            <h1 className="page-header">
                Locations<button className="btn btn-primary reviewRefreshBtn" disabled={this.props.reviewsLoading} onClick={this.refreshLocations.bind(this)}><FontAwesomeIcon icon={faRedo}/></button>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Go Back</button></small>
            </h1>
            <div className="case-list-container">
                {(this.props.reviewLocations && this.props.reviewLocations.length > 0) && <Table className="case-list sortable reviewLocationList">
                   <thead>
                       <tr>
                           <th>&nbsp;</th>
                           <th className="text-center">Rating</th>
                           <th className="text-center"># of Reviews</th>
                       </tr>
                   </thead>
                    <tbody>
                    {
                        this.props.reviewLocations.map(c => {
                                return <tr key={c.uid}>
                                    <td><p><Link to={'/reviews/view/' + c.uid}>{c.location_name}</Link></p>
                                        <p>{c.address.addressLines[0]!==undefined ? c.address.addressLines[0] : ''}</p>
                                        <p>{c.address.locality}, {c.address.administrativeArea}, {c.address.postalCode}</p>
                                    </td>
                                    <td className="text-center">
                                        {(c.avg_rating!=='N/A') && <div className="rating">
                                            <div className="rating-upper" style={{width: this.renderRatingPercent(c.avg_rating)}}>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                            </div>
                                            <div className="rating-lower">
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                                <span>★</span>
                                            </div>
                                        </div>}
                                    <p className='averageRatingScore'>{c.avg_rating!=='N/A' ? c.avg_rating.substr(0,4) + '/5' : 'N/A'}</p></td>
                                    <td className="text-center">{c.total_reviews}</td>
                                </tr>
                        })
                    }
                    </tbody>
                </Table>}
            </div>    
                
        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        reviewLocations: state.reviewLocationList.locations,
        reviewsLoading: state.reviewLocationList.loading,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getReviewLocations: (...params) => dispatch(getReviewLocations(...params)),
        refreshGoogleAccountLocations: (...params) => dispatch(refreshGoogleAccountLocations(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAccountLocations);
