import React, {Component} from "react";
import {
    Alert,
    Table,
    Row,
    Col,
    Image,
    Nav,
    Navbar,
    Form,
    Dropdown,
    FormGroup,
    Button,
    FormControl
} from "react-bootstrap";
import {listStaff, runKeywordCommand, listDepartments, refreshSettingEndorsements, enablePCCFunctionality, listLocationMappings, createLocationMapping, settingsPage, updateClientSettings} from "../../actions";
import "./SettingsPage.scss";
import Spinner from "react-spinner";
import { Link } from 'react-router-dom';
import DepartmentDropdown from "./../Utilities/DepartmentDropdown";
import DeleteAdminOverlay from "./../Overlays/DeleteAdminOverlay";
import DeleteEndorsementOverlay from "./../Overlays/DeleteEndorsementOverlay";
import DeleteLocationMappingOverlay from "./../Overlays/DeleteLocationMappingOverlay";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import QRCode from "qrcode-react";
import queryString from 'query-string';

class SettingsPage extends Component {

    componentDidMount() {
        // after we mount the component
        this.props.settingsPage(this.props.tvClient).then(()=>{
            this.setState({intakeOn: this.props.settingsData.clientSettings[0].extra_info.intakeForm, intakeTouched: false});
        });
        this.props.listLocationMappings(this.props.tvClient);
    }

    refreshHandler(){
        this.props.settingsPage(this.props.tvClient).then(()=>{
            this.setState({intakeOn: this.props.settingsData.clientSettings[0].extra_info.intakeForm, intakeTouched: false});
        });
    }

    constructor(props) {
        
        super(props);
        let activeTab = 3;
        let showQrCodeTab=false;
        let showEndorsementsTab=false;
        let showUsersTab=false;
        let showLocationsTab=true;
        let showConfiguration=false;
        let showLocationMappingTab=false;
        const queryStringValues = queryString.parse(props.location.search);

        if(queryStringValues.active!==null && queryStringValues.active !== undefined){
            activeTab = parseInt(queryStringValues.active, 10);
            if(activeTab===2){
                showQrCodeTab=false; showEndorsementsTab=true; showUsersTab=false; showLocationsTab=false; showLocationMappingTab=false;
            }
            if(activeTab===3){
                showQrCodeTab=false; showEndorsementsTab=false; showUsersTab=false; showLocationsTab=true; showLocationMappingTab=false;
            }
            if(activeTab===4){
             showQrCodeTab=false; showEndorsementsTab=false; showUsersTab=true; showLocationsTab=false; showLocationMappingTab=false;  
            }
            if(activeTab===6){
             showQrCodeTab=false; showEndorsementsTab=false; showUsersTab=false; showLocationsTab=false; showLocationMappingTab=true;    
            }
        }

        this.state = {
           showQrCode: showQrCodeTab,
           showEndorsements: showEndorsementsTab,
           showLocations: showLocationsTab,
           showUsers: showUsersTab,
           showLocationMapping: showLocationMappingTab,
           activeKey: activeTab,
           logoFiles: [],
           logoFileUrls: [],
           primaryDepartment: '0',
           primaryDepartmentName: 'All Departments',
           pccOn: false,
           intakeOn: false,
           intakeTouched: false,
           fields: { 'role': null},
           errors: { 'apptCode': false, 'journeyCode': false, 'dupeCode': false }
        };
        this.addLocationMapping = this.addLocationMapping.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
                let filter = {
            staffId: {
                type: 'wildcard',
                value: '*'
            }
        };
        
        this.props.refreshSettingEndorsements(this.props.tvClient, 0);    
        
        this.props.listDepartments(this.props.tvClient, 'and', filter, 'asc', 1, 100);
        this.filterByDepartment = this.filterByDepartment.bind(this);
    }   

    addLocationMapping(){
        var emrCode = this.emrCode.value.trim();
        var locationGroup = this.locationGroup.value.trim();
        var userLocationCode = this.state.fields['location'];
        var validMapping = true;
        if(emrCode===undefined || emrCode===null || emrCode===''){
            this.setState({ errors: {'emrCode': true, 'locationCode': false, 'dupeCode': false}});
        } else if(userLocationCode===undefined || userLocationCode===null || userLocationCode===''){
            this.setState({ errors: {'emrCode': false, 'locationCode': true, 'dupeCode': false}});
        } else{
            // look for existing mapping
            for(var i=0; i<this.props.locationMapping.length; i++){
                if(this.props.locationMapping[i].location_resource.toString()===emrCode.toString()){
                    validMapping=false;
                    this.setState({errors: {'dupeCode': true } });
                    break;
                }
            }

            if(validMapping){
                this.setState({errors: {'emrCode': false, 'locationCode': false, 'dupeCode': false } });
                this.props.createLocationMapping(this.props.tvClient, emrCode, userLocationCode, locationGroup).then(()=>{
                    this.props.listLocationMappings(this.props.tvClient);
                    this.emrCode.value = '';
                    this.locationGroup.value = '';
                    document.getElementById('location').value=''
                    let fields = this.state.fields;
                      fields['location'] = '';
                      this.setState({
                        fields
                      });
                });
            }
        }
    }

    filterByDepartment(selectedKey){
        
        if(selectedKey!=='0'){
            let filter =  selectedKey
            let deptName = 'This Department';

            for(var i=0; i<this.props.deptList.length; i++){
                if(this.props.deptList[i].key===selectedKey){
                    deptName = this.props.deptList[i].name;
                    break;
                }
            }
            this.props.refreshSettingEndorsements(this.props.tvClient, selectedKey);          
            this.setState({'primaryDepartment': selectedKey, 'primaryDepartmentName': deptName});
        } else{
             let filter = null;
             this.props.refreshSettingEndorsements(this.props.tvClient, selectedKey);  
            this.setState({'primaryDepartment': selectedKey, 'primaryDepartmentName': 'All Departments'});
        }
    }


    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();
        this.fileInput.click();
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    } 

    onChangeAction(e){
            var boolIntake = (this.state.intakeOn==='true' || this.state.intakeOn===true) ? true : false;
           this.setState({intakeOn: !boolIntake});
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            logoFiles: [...e.target.files],
            // Set object URLs one time so that images don't re-render when state changes
            logoFileUrls: [...e.target.files].map(logoFile => URL.createObjectURL(logoFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }    

    enablePCC(e){
        e.preventDefault();
        this.props.enablePCCFunctionality(this.props.tvClient, this.pccUUID.value).then(()=>{
            this.setState({'pccOn': true});
        });
        
    }   

    runKeyword(e){
        e.preventDefault();
        if(this.welliqkey.value!==undefined){
            if(this.welliqkey.value.startsWith('welliq')){
                this.props.runKeywordCommand(this.props.tvClient,this.welliqkey.value.trim());
            }
        }
    } 

    updateClientSettings(e){
        e.preventDefault();
        var validTextMessage = false;
        var validEmailMessage = false;
        var validEmployerMessage = false;

        if(this.textMessage.value!==undefined && this.textMessage.value!==null && this.textMessage.value.trim()!==''){
            validTextMessage = this.isJsonString(this.textMessage.value.trim());
        }

        if(this.emailMessage.value!==undefined && this.emailMessage.value!==null && this.emailMessage.value.trim()!==''){
            validEmailMessage = this.isJsonString(this.emailMessage.value.trim());
        }

        if(this.employerMessage.value!==undefined && this.employerMessage.value!==null && this.employerMessage.value.trim()!==''){
            validEmployerMessage = this.isJsonString(this.employerMessage.value.trim());
        }

        var finalPeerPointJSON = '{}';
        if(this.props.settingsData.showPeerReview===true){
            // Need to check that there may be something there
            if(this.pointConfiguration.value!==undefined && this.pointConfiguration.value!==null && this.pointConfiguration.value.trim()!==''){
                if(this.isJsonString(this.pointConfiguration.value.trim())){
                    finalPeerPointJSON = this.pointConfiguration.value;
                }
            }
        }

        if(validEmployerMessage && validTextMessage && validEmailMessage){
         var intakeDept = ((this.primaryFeedbackDepartment===undefined || this.primaryFeedbackDepartment===null) ? '' : this.primaryFeedbackDepartment.value)
         this.props.updateClientSettings(
                this.props.tvClient,
                this.companyName.value,
                this.textMessage.value,
                this.emailMessage.value,
                this.employerMessage.value,
                this.state.logoFiles,
                this.publicReviewEmail.value,
                this.state.intakeOn,
                intakeDept, finalPeerPointJSON).then(()=>{
                    this.refreshHandler();
                });    
            window.scrollTo(0,0); 
            this.setState({ logoFileUrls: [], logoFiles: []}) 
            
        } 


    }

    handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showQrCode: true, showEndorsements: false, showLocations: false, showUsers:false, showConfiguration:false, showLocationMapping: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===2){
        this.setState({ showQrCode: false, showEndorsements: true, showLocations: false, showUsers:false, showConfiguration:false, showLocationMapping: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===3){
        this.setState({ showQrCode: false, showEndorsements: false, showLocations: true, showUsers: false, showConfiguration:false, showLocationMapping: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===4){
        this.setState({ showQrCode: false, showEndorsements: false, showLocations: false, showUsers:true, showConfiguration:false, showLocationMapping: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===5){
        this.setState({ showQrCode: false, showEndorsements: false, showLocations: false, showUsers:false, showConfiguration:true, showLocationMapping: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===6){
        this.setState({ showQrCode: false, showEndorsements: false, showLocations: false, showUsers:false, showConfiguration:false, showLocationMapping: true, activeKey: selectedKey });
      }

    }

    tableHeader(sortKey, label) {
        return <th className="sortable"
                   onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    render() {
        return <div>
            <h1 className="page-header">
                Settings

                <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={3} href="#">
                      Locations
                    </Nav.Link> 
                    { (!this.props.isLocationOnly) && <Nav.Link eventKey={6} href="#">
                      Location Mapping
                    </Nav.Link>   }
                    { (!this.props.isLocationOnly) && <Nav.Link eventKey={2} href="#">
                      Endorsements
                    </Nav.Link> }
                    { (!this.props.isLocationOnly) && <Nav.Link eventKey={4} href="#">
                      Users
                    </Nav.Link> }
                    { (!this.props.isViewOnly && !this.props.isLocationOnly) && <Nav.Link eventKey={5} href="#">
                      Configuration
                    </Nav.Link> }                       
                  </Nav>
                </Navbar>
            </h1>

 { (this.props.settingsData && this.state.showQrCode) && <div className="case-list-container">
                {this.props.settingsError && <Alert variant="danger">{this.props.settingsError.message}</Alert>}

                <Table className="case-list sortable settingsHeader">
                    <thead>
                    <tr>
                        <th>Organization QR Code</th>
                        <th>Name</th>
                        <th>URL</th>
                    </tr>
                    </thead>
                    {this.props.settingsData.orgDetail[0]!==undefined && <tbody>
                        <tr>
                        <td> <QRCode size={100} value={window.location.protocol + '//' + window.location.host + '/#/s/' + this.props.settingsData.orgDetail[0].url_id} logo={process.env.PUBLIC_URL + '/qrlogo.jpg'} /></td>
                        <td>{process.env.CLIENT_NAME}</td>
                        <td><a href={window.location.protocol + '//' + window.location.host + '/#/s/' + this.props.settingsData.orgDetail[0].url_id}>{window.location.protocol + '//' + window.location.host + '/#/s/' + this.props.settingsData.orgDetail[0].url_id}</a></td>
                        </tr>
                    </tbody> }
                </Table>
                {this.props.settingsData.orgDetail[0]===undefined && <div><p>None available.</p></div> }
                <Table className="case-list sortable settingsHeader">
                    <thead>
                    <tr>
                        <th>Department QR Codes</th>
                        <th>Name</th>
                        <th>URL</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.settingsData.deptDetail.map(c => {
                            return <tr key={c.url_id}>
                                <td> <QRCode size={100} value={window.location.protocol + '//' + window.location.host + '/#/d/' + c.url_id} logo={process.env.PUBLIC_URL + '/qrlogo.jpg'} /></td>
                                <td>{c.name} </td>
                                <td><a href={window.location.protocol + '//' + window.location.host + '/#/d/' + c.url_id}>{window.location.protocol + '//' + window.location.host + '/#/d/' + c.url_id}</a></td>
                                </tr>
                        })
                    }

                       
                    </tbody>
                </Table>                
            </div> }

{(this.props.settingsData && this.state.showLocationMapping ) && <div className="case-list-container">
                        <p className="text-danger">Only modify these if you know what you are doing.</p>
                         <div>

                            { !this.props.isViewOnly && <h3>Add New EHR Mapping</h3> }
                            { !this.props.isViewOnly &&<table className="table">
                                <thead>
                                    <tr>
                                        <th>Location Code</th>
                                        <th>Business Line</th>
                                        <th>Location</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><FormControl type="text" placeholder="Ex: 12" ref={ref => this.emrCode = ref}/></td>
                                        <td><FormControl type="text" placeholder="Optional" ref={ref => this.locationGroup = ref}/></td>
                                        <td><div>
                      <FormControl as="select" placeholder="select" name="location" id="location" onChange={this.handleFormChange.bind(this)}>
                            <option value='' key=''>Select</option>
                            {Object.values(this.props.settingsData.locations).map(d => {
                                return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                            })}
                        </FormControl></div></td>
                                        <td><button className="btn btn-primary" onClick={this.addLocationMapping}>Add</button></td>
                                    </tr>
                                </tbody>
                            </table>}
                            {this.state.errors.emrCode && <Alert variant="danger">EMR Code is required.</Alert>}
                            {this.state.errors.locationCode && <Alert variant="danger">Location is required.</Alert>}
                            {this.state.errors.dupeCode && <Alert variant="danger">That EMR Location Code is already mapped.</Alert>}

                            <h3>Existing Mappings</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>EMR Code</th>
                                        <th>Location</th>
                                        <th>Group</th>
                                        {!this.props.isViewOnly && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.props.locationMapping.map(c => {
                                        return  <tr key={c.uid}>
                                                  <td>{c.location_resource}</td>
                                                  <td>{c.name}</td>
                                                  <td>{(c.location_resource_alt==='' || c.location_resource_alt===null) ? 'None' : c.location_resource_alt}</td>
                                                  { !this.props.isViewOnly && <td>
                                                    <Link to={'/locationmapping/edit/' + c.uid}>Edit</Link>
                                                    {c.uid && <DeleteLocationMappingOverlay mappingDetail={c} />}
                                                  </td> }
                                            </tr>   
                                    })
                                } 
                                </tbody>
                            </table>            
                            {this.props.locationMapping.length===0 && <div><p>No Mappings found</p></div> }
                        </div>
                    </div>
                }



 { (this.props.settingsData && this.state.showEndorsements) && <div className="case-list-container">
                {this.props.settingsError && <Alert variant="danger">{this.props.settingsError.message}</Alert>}
 {(this.props.deptList) && <div className="customLocationRange float-right">

                       <Dropdown
                          title='Choose a Department'
                          id='filterDeptSurvey'
                          drop="down" onSelect={this.filterByDepartment}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.primaryDepartmentName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                    <Dropdown.Item eventKey={0}>All Departments</Dropdown.Item>
                      {Object.values(this.props.deptList).map(d => {
                        return  <Dropdown.Item eventKey={d.key} key={d.key}>{d.name}</Dropdown.Item>
                      })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div> }                 
                    { !this.props.isViewOnly && <Link className="btn btn-primary" to={'/endorsement/new'}>Add New</Link>}<br/><br/>
 
                
            <div>

                    {
                        this.props.endorsements.map(c => {
                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className="adminBadgeView">
                                      {c.staff_only && <p className='hoverBadgeType'>STAFF</p>}
                                      {c.department_only && <p className='hoverBadgeType'>DEPT</p>}
                                      {!c.staff_only && !c.department_only && <p className='hoverBadgeType'>PEER</p>}
                                      <img alt={c.description} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p className="adminBadgeText">
                                        {c.description}
                                        <br/>{ !this.props.isViewOnly && <Link to={'/endorsement/edit/' + c.endorsement_id}>Edit</Link>}&nbsp;{ !this.props.isViewOnly && <DeleteEndorsementOverlay endorsementDetail={c.endorsement_id}/>}

                                      </p>
                                    
                                    </label>

                                  </div>
                        })
                    }   
                    { this.props.endorsements.length===0 && <div><p>No endorsements to show.</p></div> }   


            </div>                
            </div> }


        { (this.props.settingsData && this.state.showLocations) && <div className="case-list-container">
                {this.props.settingsError && <Alert variant="danger">{this.props.settingsError.message}</Alert>}
                    <div>
                    {
                        this.props.settingsData.locations.map(c => {

                            if(this.props.isLocationOnly){
                                if(this.props.roleLocations!==undefined){
                                    for(var i=0; i<this.props.roleLocations.length;i++){
                                        if(this.props.roleLocations[i].toString()===c.location_id.toString()){
                                            return <div key={c.location_id} className="locationContainer">
                                                  <h5>{c.name}</h5>
                                                  <p>{c.address1}</p>
                                                  <p>{c.address2}</p>
                                                  <p>{c.city}, {c.state}, {c.zip}</p>
                                                  <p>{c.phone}</p>
                                                  { (c.google_id!==undefined && c.google_id!==null && c.google_id!=='') && <a target="_blank" className="alignLeft15" href={'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + c.google_id}>View on Google</a>}
                                                  { !this.props.isViewOnly && <Link to={'/locations/edit/' + c.location_id}>Edit</Link>} 
                                            </div> 
                                        }
                                    }
                                }
                            } else{
                                return <div key={c.location_id} className="locationContainer">
                                          <h5>{c.name}</h5>
                                          <p>{c.address1}</p>
                                          <p>{c.address2}</p>
                                          <p>{c.city}, {c.state}, {c.zip}</p>
                                          <p>{c.phone}</p>
                                          { (c.google_id!==undefined && c.google_id!==null && c.google_id!=='') && <a target="_blank" className="alignLeft15" href={'https://www.google.com/maps/search/?api=1&query=Google&query_place_id=' + c.google_id}>View on Google</a>}
                                          { !this.props.isViewOnly && <Link to={'/locations/edit/' + c.location_id}>Edit</Link>} 
                                    </div>     
                            }
                            
                        })
                    }      
                    { (!this.props.isViewOnly && !this.props.isLocationOnly) && <Link className="btn btn-primary" to={'/locations/new'}>Add New</Link>} 
                </div>              
            </div>
         }


        { (this.props.settingsData && this.state.showUsers) && <div className="case-list-container">
                {this.props.settingsError && <Alert variant="danger">{this.props.settingsError.message}</Alert>}
                    <div>  
                    { !this.props.isViewOnly && <Link to={'/users/new'} className="btn btn-primary newUserButton">Add New User</Link> }
                </div>  
                <Table className="case-list sortable settingsHeader">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Role</th>
                        { !this.props.isViewOnly && <th>Action</th> }
                    </tr>
                    </thead>
                    <tbody className="userActionList">
                    {
                        this.props.settingsData.admins.map(c => {
                            return <tr key={c.admin_id}>
                                <td>{c.name}</td>
                                <td>{c.email} </td>
                                <td>{c.mobile}</td>
                                <td>{c.role}</td>   
                                { !this.props.isViewOnly && <td>
                                    <Link to={'/admin/password/' + c.admin_id}>Reset Password</Link> 
                                    <Link to={'/admin/edit/' + c.admin_id}>Edit</Link> {c.admin_id && <DeleteAdminOverlay staffDetail={c} parentRefresh={this.refreshHandler.bind(this)}/>}
                                </td>}
                                </tr>
                        })
                    }

                    </tbody>
                </Table>            

            </div>
         }

        { (this.props.settingsData && this.state.showConfiguration && !this.props.isViewOnly) && <div className="case-list-container">
            <p className="text-danger">Only edit these if you know what you are doing.</p>
            {(this.props.settingsData && this.state.pccOn===false && (this.props.settingsData.clientSettings[0].emr!==undefined && this.props.settingsData.clientSettings[0].emr==='pcc')) && <Form onSubmit={this.enablePCC.bind(this)} encType="multipart/form-data">
                {this.props.settingsError && <Alert variant="danger">{this.props.settingsError.message}</Alert>} 
                <Table className="case-list sortable settingsHeader">
                    <thead>
                    <tr>
                        <th>EHR</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody className="userActionList">
                    <tr>
                        <td>PointClickCare</td>
                        <td><FormControl type="text" placeholder="UUID"  ref={ref => this.pccUUID = ref}/></td>
                    </tr>
                    
                    <tr>
                        <td>&nbsp;</td>
                        <td> <FormGroup className="submit-buttons">
                                <Button type="submit" disabled={this.props.enablePcc.loading} className="float-right">
                                    Enable
                                </Button>
                            </FormGroup>
                        </td>
                    </tr>
                    </tbody>
                </Table>            
                </Form> 
                }            
            <Form onSubmit={this.updateClientSettings.bind(this)} encType="multipart/form-data">
                {this.props.settingsError && <Alert variant="danger">{this.props.settingsError.message}</Alert>} 
                <Table className="case-list sortable settingsHeader">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody className="userActionList">
                    <tr>
                        <td>Name</td>
                        <td><FormControl type="text" placeholder="Company Name" defaultValue={this.props.settingsData.clientSettings[0]!==undefined ? this.props.settingsData.clientSettings[0].name : ''} ref={ref => this.companyName = ref}/></td>
                    </tr>
                    <tr>
                        <td>Text Message JSON</td>
                        <td><FormControl as="textarea" placeholder="Enter your Multi-lingual JSON" defaultValue={this.props.settingsData.clientSettings[0]!==undefined ? JSON.stringify(this.props.settingsData.clientSettings[0].text_message, undefined, 2) : ''} ref={ref => this.textMessage = ref}/></td>
                    </tr>
                    <tr>
                        <td>Email Message JSON</td>
                        <td><FormControl as="textarea" placeholder="Enter your Multi-lingual JSON" defaultValue={this.props.settingsData.clientSettings[0]!==undefined ? JSON.stringify(this.props.settingsData.clientSettings[0].email_message, undefined, 2) : ''} ref={ref => this.emailMessage = ref}/></td>
                    </tr> 
                    <tr>
                        <td>Employer Message JSON</td>
                        <td><FormControl as="textarea" placeholder="Enter your Multi-lingual JSON" defaultValue={this.props.settingsData.clientSettings[0]!==undefined ? JSON.stringify(this.props.settingsData.clientSettings[0].employer_message, undefined, 2) : ''} ref={ref => this.employerMessage = ref}/></td>
                    </tr> 
                    {this.props.settingsData.showPeerReview===true && 
                        <tr>
                            <td>Peer to Peer Point Configuration</td>
                            <td><FormControl as="textarea" placeholder="Enter your point configuration" defaultValue={this.props.settingsData.clientSettings[0]!==undefined ? JSON.stringify(this.props.settingsData.clientSettings[0].extra_info.pointConfig, undefined, 2) : ''} ref={ref => this.pointConfiguration = ref}/></td>
                        </tr> 
                    }
                    <tr>
                        <td>Google Review Notification Email (if enabled)</td>
                        <td><FormControl type="email" placeholder="A single email address" defaultValue={(this.props.settingsData.clientSettings[0]!==undefined && this.props.settingsData.clientSettings[0].extra_info.publicReviewEmail!==undefined) ? this.props.settingsData.clientSettings[0].extra_info.publicReviewEmail : ''} ref={ref => this.publicReviewEmail = ref}/></td>
                    </tr>
                    
                        <tr>
                            <td>Incentive Wheel Enabled</td>
                            <td>{(this.props.settingsData.incentiveEnabled==='true' || this.props.settingsData.incentiveEnabled===true) ? 'Yes' : 'No'}</td>
                        </tr> 

                        {(this.props.settingsData.clientSettings[0]!==undefined && this.props.settingsData.clientSettings[0].extra_info.intakeForm!==undefined) && 
                        <tr>
                            <td>Intake Form Enabled</td>
                            <td><input type="checkbox" checked={(this.state.intakeOn==='true'||this.state.intakeOn===true) ? true : false} onChange={this.onChangeAction.bind(this)}/><p><small>Will require re-login to reflect navigation changes</small></p></td>
                        </tr> 
                        }
                        {(this.state.intakeOn==='true'||this.state.intakeOn===true) &&
                        <tr>
                            <td>Intake Endorsement Dept.</td>
                            <td><DepartmentDropdown selectedItem={(this.props.settingsData.clientSettings[0]!==undefined && this.props.settingsData.clientSettings[0].extra_info.intakeDept!==undefined) ? this.props.settingsData.clientSettings[0].extra_info.intakeDept : ''} inputRef={ref => this.primaryFeedbackDepartment = ref}/></td>
                        </tr>
                        }

                    <tr><td> <Col className="thumbnail-col" md={4}>
                    {this.props.settingsData.clientSettings[0]!==undefined && <Image src={this.props.settingsData.clientSettings[0].logo} thumbnail></Image>}</Col>
                <legend>Upload New Logo</legend>
                <p>(Dimensions: 270px x 75px)</p>  
                </td><td>
                <Row>
                {
                    this.state.logoFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>
                <div className="progress-box">
                        <div>
                            <input name="files" type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                </div>
                </td>
                </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td> <FormGroup className="submit-buttons">
                                <Button type="submit" disabled={this.props.updatingSettings} className="float-right">
                                    Update
                                </Button>
                            </FormGroup>
                        </td>
                    </tr>
                    </tbody>
                </Table>            
                </Form>
                <Form onSubmit={this.runKeyword.bind(this)} encType="multipart/form-data">
                    <h2>Advanced Configurations</h2>
                    <Table className="case-list sortable settingsHeader">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                        </thead>
                        <tbody className="userActionList">
                        <tr>
                            <td>Keyword</td>
                            <td><FormControl type="text" placeholder="Well iQ Key"  ref={ref => this.welliqkey = ref}/></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td> <FormGroup className="submit-buttons">
                                    <Button type="submit" disabled={this.props.updatingSettings} className="float-right">
                                        Run
                                    </Button>
                                </FormGroup>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Form>
            </div>
         }

            {this.props.settingsLoading && <Spinner/>}
            <footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer> 
        </div>; 
    }

}

const mapStateToProps = state => {
    return {
        enablePcc: state.enablePcc,
        settingsLoading: state.settingsPage.settingsLoading,
        settingsData: state.settingsPage.settingsData,
        endorsements: state.settingsEndorsements.endorsements,
        updatingSettings: state.updateSettings.updatingSettings,
        tvClient: state.login.tvClient,
        deptList: state.deptList.departments,
        locationMapping: state.locationMappingList.locationMappings,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly',
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listDepartments: (...params) => dispatch(listDepartments(...params)),
        listStaff: (...params) => dispatch(listStaff(...params)),
        updateClientSettings: (...params) => dispatch(updateClientSettings(...params)),
        settingsPage: (...params) => dispatch(settingsPage(...params)),
        listLocationMappings: (...params) => dispatch(listLocationMappings(...params)),
        createLocationMapping: (...params) => dispatch(createLocationMapping(...params)),
        enablePCCFunctionality: (...params) => dispatch(enablePCCFunctionality(...params)),
        refreshSettingEndorsements: (...params) => dispatch(refreshSettingEndorsements(...params)),
        runKeywordCommand: (...params) => dispatch(runKeywordCommand(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
