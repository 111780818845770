import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";

class ExportPatSatReportCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }


    cleanData(dirtyData){
      
        let exportData = [];
        let journeyName = 'All Journeys';
        if(this.props.journeyName!==undefined && this.props.journeyName!==null && this.props.journeyName.length>0){
            journeyName = this.props.journeyName;
        }

        for (var i = this.props.locationData.length - 1; i >= 0; i--) {
            var thisLine = this.props.locationData[i];
            let ones = 0, twos=0, threes=0, fours=0, fives=0;
            for(var n=0; n<thisLine.length; n++){
             if(thisLine[n].name===5){ fives = thisLine[n].value;} 
             else if(thisLine[n].name===4){ fours = thisLine[n].value;}
             else if(thisLine[n].name===3){ threes = thisLine[n].value;}
             else if(thisLine[n].name===2){ twos = thisLine[n].value;}
             else if(thisLine[n].name===1){ ones = thisLine[n].value;} 
            }
            exportData.push({ 'Location': thisLine[0].locationname, 'Journey': journeyName, 'NPS': thisLine.scoreText, '5 Star': fives, '4 Star': fours, '3 Star': threes, '2 Star': twos, '1 Star': ones   });
        }
        


        return exportData.reverse();
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
        )

    }
}

ExportPatSatReportCSV.propTypes = {
    csvData: PropTypes.array.isRequired,
    locationData: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    journeyName: PropTypes.string
};

export default ExportPatSatReportCSV;