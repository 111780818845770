import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    Row
} from "react-bootstrap";
import {createStaff, listDepartments, createEndorsement} from "../../actions";

class NewEndorsement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { 'role': null},
            endorsementFiles: [],
            endorsementFileUrls: [],
            errors: { 'name': false, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': false, 'role': false }
        };
        this.inlineError = false;
        let filter = {
            staffId: {
                type: 'wildcard',
                value: '*'
            }
        };

        this.props.listDepartments(this.props.tvClient, 'and', filter, 'asc', 1, 100);
        this.handleFormChange = this.handleFormChange.bind(this);

    }


    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    } 

    newEndorsement(e) {
        e.preventDefault();

        if(!this.endorsementName.value){
            this.setState({ errors: {'name': true, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': false, 'role': false }});
        } else if(!this.badgePriority.value){
            this.setState({ errors: {'name': false, 'badgePriority': true, 'primaryDepartment': false, 'badgeLocalization': false, 'role': false }});
        } else if(!this.state.primaryDepartment){
            this.setState({ errors: {'name': false, 'badgePriority': false, 'primaryDepartment': true, 'badgeLocalization': false, 'role': false }});
        } else if(!this.badgeLocalization.value && this.isJsonString(this.badgeLocalization.value.trim())){
            this.setState({ errors: {'name': false, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': true, 'role': false }});
        } else if(!this.state.fields['role']){
            this.setState({ errors: {'name': false, 'badgePriority': false, 'primaryDepartment': false, 'badgeLocalization': false, 'role': true }});
        }
        else{
            var points = 1;
            if(this.badgePoints.value.trim().length>0){
                points = this.badgePoints.value.trim();
            }
            this.props.createEndorsement(
                this.props.tvClient,
                this.endorsementName.value.trim(),
                this.state.fields['role'],
                this.badgePriority.value.trim(),
                this.badgeLocalization.value.trim(),
                this.state.primaryDepartment,
                this.state.endorsementFiles,
                points
            );    

        }
    }

    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryDepartment: value
        });
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }    

    handleBrowseImagesClick(e) {
        e.preventDefault();

        this.fileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            endorsementFiles: [...e.target.files],
            // Set object URLs one time so that images don't re-render when state changes
            endorsementFileUrls: [...e.target.files].map(endorsementFile => URL.createObjectURL(endorsementFile))
        });
    }

    render() {
        return <div>
            <h1 className="page-header">
                Add Endorsement
                <small>Please carefully enter the below information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Cancel</button></small>
            </h1>
            <Form onSubmit={this.newEndorsement.bind(this)} className="new-case-form">

                <legend>Endorsement Information</legend>

                {this.props.addEndorsementError && <Alert variant="danger">
                    {this.props.addEndorsementError.message}
                </Alert>}


                <FormGroup controlId="endorsementName">
                    <FormLabel>Badge Name *</FormLabel>
                    <FormControl type="text" placeholder="Attentive" ref={ref => this.endorsementName = ref}/>
                    {this.state.errors.name && <Alert variant="danger">
                        Badge name is required.
                    </Alert>}
                </FormGroup>

                <div className="form-group">
                    <label htmlFor="role">Endorsement is for: <span className="text-danger">*</span></label>      
                    <div className="radio">
                      <label>
                        <input type="radio" name="role" value="dept" onChange={this.handleFormChange}/>
                        Department Level
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="role" value="staff" onChange={this.handleFormChange}/>
                        Staff Level
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="role" value="peer" onChange={this.handleFormChange}/>
                        Peer Only
                      </label>
                    </div>                    
                    {this.state.errors.role && <Alert variant="danger">
                        You must choose a level for this badge.
                    </Alert>}
                </div>

                <FormGroup controlId="badgePriority">
                    <FormLabel>Priority *</FormLabel>
                    <FormControl type="text" placeholder="100" ref={ref => this.badgePriority = ref}/>
                    {this.state.errors.badgePriority && <Alert variant="danger">
                        Badge priority is required.
                    </Alert>}
                </FormGroup>
                <FormGroup controlId="badgePoints">
                    <FormLabel>Badge Point Value</FormLabel>
                    <FormControl type="number" defaultValue="1" ref={ref => this.badgePoints = ref}/>
                    {this.state.errors.badgePoints && <Alert variant="danger">
                        Badge points are required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="badgeLocalization">
                    <FormLabel>Localized Text</FormLabel>
                    <FormControl as="textarea" defaultValue={JSON.stringify({'EN': 'English Name', 'ES': 'Spanish Name'}, undefined, 2)} ref={ref => this.badgeLocalization = ref}/>
                </FormGroup>                  

                <FormGroup controlId="primaryDepartment">
                    <FormLabel>Choose Department(s) *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this)} multiple>
                        {Object.values(this.props.departments).map(d => {
                            return <option value={d.key} key={d.key}>{d.name}</option>
                        })}
                    </FormControl>                    

                    {this.state.errors.primaryDepartment && <Alert variant="danger">
                        At least one department is required.
                    </Alert>}
                </FormGroup>

                <legend>Upload Endorsement Badge</legend>
                <p>(Badge - 145px x 145px)</p>

                <Row>
                {
                    this.state.endorsementFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.addingEndorsement ? (
                        <h3>Uploading</h3>
                    ) : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                    )}

                </div>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingEndorsement} className="float-right">
                        Add
                    </Button>
                </FormGroup>
            </Form>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingEndorsement: state.addEndorsement.addingEndorsement,
        addEndorsementError: state.addEndorsement.addEndorsementError,
        tvClient: state.login.tvClient,
        approver: state.login.user.user_id,
        departments: state.deptList.departments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listDepartments: (...params) => dispatch(listDepartments(...params)),
        createEndorsement: (...params) => dispatch(createEndorsement(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEndorsement);
