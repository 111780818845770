import React, {Component} from "react";
import {Alert, Button, Form, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import "./AuthForm.scss";

class AuthForm extends Component {
    /* First line in should be the logo of the company : <img className="company-logo-top" src="/WorkPartnersOHS.png" alt="Logo"/> */

    render() {
        return <div className="auth-form-container">
            <Form onSubmit={this.props.onSubmit.bind(this)}>
                <img className="company-logo-top" src="/newlogo.png" alt="Logo"/>  

                {this.props.authError && <Alert className="alert alert-danger">
                    {this.props.authError.message}
                </Alert>}

                {this.props.children}

                <FormGroup>
                    <Button type="submit" disabled={this.props.authInProgress}>
                        {this.props.submitLabel}
                    </Button>
                </FormGroup>
                
                <div className="bizLearnMore">
                    <a className="bizLearnMore" target="_blank" rel="noopener noreferrer" href="https://www.welliq.org">Learn more about Well iQ</a>
                </div>
            </Form>
            <div className="auth-form-background"/>
        </div>;
    }
}

AuthForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    authError: PropTypes.object,
    authInProgress: PropTypes.bool,
    submitLabel: PropTypes.string
};

export default AuthForm;
