import React, {Component} from "react";
import {
    Table,
    Dropdown,
    FormControl
} from "react-bootstrap";
import { listPublicReviewReport } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges"; 
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Link } from 'react-router-dom';
import ExportGoogleReportCSV from './../../Utilities/ExportGoogleReportCSV';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class YearlyPublicReviewReport extends Component {

    constructor(props) {
        super(props);
       // this.props.listEmployers(this.props.tvClient.accessToken);
        //this.props.listCampaigns(this.props.tvClient);

        this.state = {
            emptySet: [{'name':'No Data','value': 1}],
            currentFilter: 8,
            filter: 'thismonth',
            reportFrom: null,
            reportTo: null,
            emptySet: [{'name':'No Data','value': 1}],
            activeKey: 1,
            showCustomDate: false,
            currentFilterName: '  This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
        };

        this.generateAverageScore  = this.generateAverageScore.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);
    }   


    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
      this.setState({ filter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
    } 

      componentDidMount(){
        this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
      }

    generateAverageScore(location){
      var total = location.total * 5;
      var subTotal = (location.fives * 5) + (location.fours * 4) + (location.threes * 3) + (location.twos * 2) + (location.ones * 1);
      var finalScore = 0;
      if(subTotal>0){
          finalScore = (subTotal/total*5).toFixed(2);    
      }
      return finalScore;
    }

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
        });
      } else if(selectedKey==2){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
            this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
        });
      } else if(selectedKey==3){
        this.setState({ filter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
        });
      } else if(selectedKey==4){
        this.setState({ filter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
        });
      } else if(selectedKey==5){
        this.setState({ filter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ', currentFilter: selectedKey }, () => {
          this.props.listPublicReviewReport(this.props.accessToken, 'GOOGLE', this.state.filter, null, null);
        });
        
      }
    }

    render() {
       return <div>
            <h1 className="page-header">
                Public Reviews Report
  
                  <div className="float-right">
                    <Dropdown
                      title='Filter Report By Date'
                      id='filterSurvey'
                      drop="down" onSelect={this.handleFilter.bind(this)}
                    >
                      <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                        {this.state.currentFilterName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>                    
                        <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                        <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                        <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                        <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                        <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                        <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

                  <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
                  </div>}


            </h1>

            <div>
              <div className="case-list-container">
                    {(!this.props.reportLoading) && <div>
                      <p className="stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span></p>
                         <table className="table table-striped table-bordered table-hover publicReviewTable">
                          <thead>
                            <tr>
                              <th className="first table-blank" scope="col">Name</th>
                              <th scope="col" className="table-blank">Avg</th>
                              <th scope="col" className="table-blank">Count</th>
                              <th scope="col" className="table-success">5 Star</th>
                              <th scope="col" className="table-info">4 Star</th>
                              <th scope="col" className="table-warning">3 Star</th>
                              <th scope="col" className="table-secondary">2 Star</th>
                              <th scope="col" className="table-danger">1 Star</th>
                            </tr>
                          </thead>
                          <tbody>

                    {
                        this.props.reportResults.map(c => {
                            return <tr key={c.id}>
                              <td className="first"><Link to={'/reviews/view/' + c.id}>{c.name}</Link> <p><small>{(c.address!==undefined && c.address.length>0) ? c.address[0] : ''}</small></p></td>
                              <td>{this.generateAverageScore(c)}</td>
                              <td>{c.total}</td>
                              <td><p>{(c.fives>0) ? c.fives : '-'}</p><p className='reviewReportPercent'>{(c.fives>0) ? Math.round(c.fives/c.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(c.fours>0) ? c.fours : '-'}</p><p className='reviewReportPercent'>{(c.fours>0) ? Math.round(c.fours/c.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(c.threes>0) ? c.threes : '-'}</p><p className='reviewReportPercent'>{(c.threes>0) ? Math.round(c.threes/c.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(c.twos>0) ? c.twos : '-'}</p><p className='reviewReportPercent'>{(c.twos>0) ? Math.round(c.twos/c.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(c.ones>0) ? c.ones : '-'}</p><p className='reviewReportPercent'>{(c.ones>0) ? Math.round(c.ones/c.total*100).toFixed(1)+'%' : ''}</p></td>
                            </tr>
                        })
                    }                            

                            
                            {(!this.props.reportLoading && this.props.aggregateResult) && <tr className="table-active reviewReportTotalLine">
                              <td className="first">Aggregate Totals</td>
                              <td>{this.generateAverageScore(this.props.aggregateResult)}</td>
                              <td>{this.props.aggregateResult.total}</td>
                              <td><p>{(this.props.aggregateResult.fives>0) ? this.props.aggregateResult.fives : '-'}</p><p className='reviewReportPercent'>{(this.props.aggregateResult.fives>0) ? Math.round(this.props.aggregateResult.fives/this.props.aggregateResult.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(this.props.aggregateResult.fours>0) ? this.props.aggregateResult.fours : '-'}</p><p className='reviewReportPercent'>{(this.props.aggregateResult.fours>0) ? Math.round(this.props.aggregateResult.fours/this.props.aggregateResult.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(this.props.aggregateResult.threes>0) ? this.props.aggregateResult.threes : '-'}</p><p className='reviewReportPercent'>{(this.props.aggregateResult.threes>0) ? Math.round(this.props.aggregateResult.threes/this.props.aggregateResult.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(this.props.aggregateResult.twos>0) ? this.props.aggregateResult.twos : '-'}</p><p className='reviewReportPercent'>{(this.props.aggregateResult.twos>0) ? Math.round(this.props.aggregateResult.twos/this.props.aggregateResult.total*100).toFixed(1)+'%' : ''}</p></td>
                              <td><p>{(this.props.aggregateResult.ones>0) ? this.props.aggregateResult.ones : '-'}</p><p className='reviewReportPercent'>{(this.props.aggregateResult.ones>0) ? Math.round(this.props.aggregateResult.ones/this.props.aggregateResult.total*100).toFixed(1)+'%' : ''}</p></td>
                            </tr>}
                          </tbody>
                        </table>  

                       </div>}
                    {this.props.reportLoading && <Spinner/>} 
                    {!this.props.reportLoading && <ExportGoogleReportCSV csvData={this.props.reportResults} aggregateData={this.props.aggregateResult} fileName='GoogleReviewReport.csv' />}
              </div>
              
           </div> 
        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        reportLoading: state.publicReviewReportData.loading,
        reportResults: state.publicReviewReportData.results,
        aggregateResult: state.publicReviewReportData.aggregateResult
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listPublicReviewReport: (...params) => dispatch(listPublicReviewReport(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YearlyPublicReviewReport);
