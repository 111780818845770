import React, {Component} from "react";
import PropTypes from "prop-types";

class IndividualStaffBadges extends Component {

    render() {
        
        var badges = this.props.items;
        var badgeList = [];

        if(badges===null || badges===undefined){
            badges = [];
        }
        
        for (var i = badges.length - 1; i >= 0; i--) {
            var found = false;
            for (var j = badgeList.length - 1; j >= 0; j--) {
                if(badges[i]!==null && badges[j]!==null){
                    if(badges[i].image === badgeList[j].image){
                        found = true;
                        if(badgeList[j].count === undefined){
                            badgeList[j].count = 1;
                        } else{
                            badgeList[j].count = parseInt(badgeList[j].count,10)+1;
                        }
                        break;
                    } 
                }
            }
            if(!found && badges[i]!==null){
                    badges[i].count = 1;
                    badgeList.push(badges[i]);
            }
        }

        badgeList.sort(function(a,b){
            return b.count - a.count;
        });

        return <tbody className="badgeListContainer">
            {
                        badgeList.map(c => {
                            return <tr className="reportBadgeContainer" key={c.image} alt={'Given ' + c.count + ' times'}><td><img className='img-responsive' src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image} alt={'Given ' + c.count + ' times'}/></td><td>{c.description}</td><td className="text-center totalTdValue">{c.count}</td></tr>
                        })
            }
        </tbody>;
    }
}

IndividualStaffBadges.propTypes = {
    items: PropTypes.array.isRequired
};

export default IndividualStaffBadges;

