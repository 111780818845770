import React, {Component} from "react";
import {FormControl} from "react-bootstrap";
import {connect} from "react-redux";


class DepartmentDropdown extends Component {
    render() {
        return <FormControl as="select" placeholder="select" defaultValue={this.props.selectedItem} ref={this.props.inputRef}>
            <option value="noSelection">Select Department</option>
            {Object.values(this.props.departmentIdtoName).map(d => {
                return <option value={d.key} key={d.key}>{d.name}</option>
            })}
        </FormControl>;
    }
}

const mapStateToProps = state => {
    return {
        departmentIdtoName: state.deptList.departments
    }
};

export default connect(mapStateToProps)(DepartmentDropdown);

