import React, {Component} from "react";
import {
    Alert,
    FormControl,
    Table,
    Nav, Navbar
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { faArrowUp, faArrowDown, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getAuthTokens, getReviewLocations, getReviewAccounts, refreshGoogleAccounts} from "../../actions";
import "./ReviewPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import DeleteDepartmentOverlay from "./../Overlays/DeleteDepartmentOverlay";
import DeleteJourneyMappingOverlay from "./../Overlays/DeleteJourneyMappingOverlay";
import PaginationArea from "./../Utilities/PaginationArea";
import queryString from 'query-string';

class ReviewPage extends Component {
    constructor(props) {
        super(props);

        let activeTab = 1;
        let showDeptListTab=true;

        const queryStringValues = queryString.parse(props.location.search);

        if(queryStringValues.active!==null && queryStringValues.active !== undefined){
            activeTab = parseInt(queryStringValues.active, 10);
            if(activeTab===2){
                showDeptListTab=false; 
            } else if(activeTab===3){
                showDeptListTab=false; 
            }
        }

        this.state = {
            fields: { 'role': null},
            activeKey: activeTab,
            showDeptList: showDeptListTab
        };
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showDeptList: true, activeKey: selectedKey });
      } 
    }    

    componentDidMount() {
        this.props.getAuthTokens(this.props.tvClient).then(()=>{
            if(this.props.reviewTokens.hasToken!==undefined && this.props.reviewTokens.hasToken){
                this.props.getReviewAccounts(this.props.tvClient, 'GOOGLE');    
            }
        });
    }

    refreshAccounts() {
        this.props.refreshGoogleAccounts(this.props.tvClient, 'GOOGLE');
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    } 

    render() {
        return <div>
            <h1 className="page-header">
                Public Reviews
                <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={1} href="#">
                      Google
                    </Nav.Link>   
                  </Nav>
                </Navbar>
            </h1>
            {this.props.reviewTokensLoading && <Spinner/>}
            {(this.props.reviewTokens.hasToken!==undefined && !this.props.reviewTokens.hasToken) && <div className="case-list-container">
                <h3>Login Required</h3>
                <p className='googleExplainText'>In order for you to manage your Google Reviews from within the Well iQ platform you must login to your Google Account and grant Well iQ permission to access your information.  After you complete the validation with Google <span className="googleNotification">you will need to log back into Well iQ</span> so that we can associate your accounts correctly. </p>
                <a href={this.props.reviewTokens.authUrl}><img width="191px" className="googleSignin" src={process.env.PUBLIC_URL + '/btn_google_signin_dark_normal_web@2x.png'}/></a>
               
            </div> }
             {(this.props.reviewTokens.hasToken!==undefined && this.props.reviewTokens.hasToken) && <div className="case-list-container">
                <h3>Accounts <button className="hidden btn btn-primary reviewRefreshBtn" disabled={this.props.reviewAccountsLoading} onClick={this.refreshAccounts.bind(this)}><FontAwesomeIcon icon={faRedo}/></button></h3>
                {(this.props.reviewAccounts && this.props.reviewAccounts.length > 0) && <Table className="case-list sortable reviewLocationList">
                   <thead>
                       <tr className="titleRow">
                           <th>Account Name:</th>
                       </tr>
                   </thead>
                    <tbody>
                    {
                        this.props.reviewAccounts.map(c => {
                                return <tr key={c.uid}>
                                    <td><p><Link to={'/reviewlocations/view/' + c.uid}>{c.account_name}</Link> <small>- {c.location_count} Locations</small></p>

                                    </td>
                                </tr>
                        })
                    }
                    </tbody>
                </Table>}
               
            </div> }
                      

        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        reviewTokens: state.reviewTokens.token,
        reviewLocations: state.reviewLocationList.locations,
        reviewAccounts: state.reviewAccountList.accounts,
        reviewAccountsLoading: state.reviewAccountList.loading,
        reviewTokensLoading: state.reviewTokens.loading,
        reviewTokensError: state.reviewTokens.error,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getAuthTokens: (...params) => dispatch(getAuthTokens(...params)),
        getReviewLocations: (...params) => dispatch(getReviewLocations(...params)),
        getReviewAccounts: (...params) => dispatch(getReviewAccounts(...params)),
        refreshGoogleAccounts: (...params) => dispatch(refreshGoogleAccounts(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage);
