import React, {Component} from "react";
import {
    Alert,
    FormControl,
    Nav, Navbar
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { getSsoJwt } from "../../actions";
import "./ReviewPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import queryString from 'query-string';

class ReputationPage extends Component {
    constructor(props) {
        super(props);

        let activeTab = 1;
        let showDeptListTab=true;

        const queryStringValues = queryString.parse(props.location.search);

        if(queryStringValues.active!==null && queryStringValues.active !== undefined){
            activeTab = parseInt(queryStringValues.active, 10);
            if(activeTab===2){
                showDeptListTab=false; 
            } else if(activeTab===3){
                showDeptListTab=false; 
            }
        }

        this.state = {
            fields: { 'role': null},
            activeKey: activeTab,
            showDeptList: showDeptListTab,
            repSpinner: true
        };

        this.hideSpinner = this.hideSpinner.bind(this); 
    }

    hideSpinner(){
        this.setState({ repSpinner: false });
    }

    componentDidMount() {
        this.props.getSsoJwt(this.props.tvClient);
    }

    render() {
        return <div>
            {(this.props.reviewTokensLoading||this.state.repSpinner) && <div style={{marginTop: "25px"}}><Spinner /></div>}
            {(this.props.reviewTokens!==undefined && this.props.reviewTokens!==null) && 
                <iframe onLoad={this.hideSpinner} src={this.props.reviewTokens} id='reputationFrame' frameBorder="0" height={1080}></iframe>
            }
                      
        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        reviewTokens: state.reviewTokens.jwt,
        reviewTokensLoading: state.reviewTokens.loading,
        reviewTokensError: state.reviewTokens.error,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly'
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getSsoJwt: (...params) => dispatch(getSsoJwt(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReputationPage);
