import React, {Component}  from "react";
import {connect} from "react-redux";
import MainNavBar from "./../Navbar/Navbar";
import {Route, Redirect, withRouter } from "react-router-dom";
import Flash from "./../Flash";
import Login from "./../Login/Login";
import AdminDashboard from "./../AdminDashboard/AdminDashboard";
import MyProfile from "./../MyProfile/MyProfile";
import SurveyList from "./../SurveyList/SurveyList";
import IntakeList from "./../IntakeList/IntakeList";
import ViewIntake from "./../ViewIntake/ViewIntake";
import ViewSurvey from "./../ViewSurvey/ViewSurvey";
import ViewEvent from "./../ViewEvent/ViewEvent";
import ViewCustomEvent from "./../ViewEvent/ViewCustomEvent";
import DepartmentList from "./../DepartmentList/DepartmentList";
import ReviewPage from "./../ReviewPage/ReviewPage";
import ReputationPage from "./../ReviewPage/ReputationPage";
import ViewLocationReviews from "./../ReviewPage/ViewLocationReviews";
import ViewAccountLocations from "./../ReviewPage/ViewAccountLocations";
import NewDepartment from "./../NewDepartment/NewDepartment";
import EditDepartment from "./../EditDepartment/EditDepartment";
import EditLocationMapping from "./../EditLocationMapping/EditLocationMapping";
import NewJourney from "./../NewJourney/NewJourney";
import NewCustomEvent from "./../NewCustomEvent/NewCustomEvent";
import EditJourney from "./../EditJourney/EditJourney";
import StaffList from "./../StaffList/StaffList";
import NewStaff from "./../NewStaff/NewStaff";
import NewEndorsement from "./../NewEndorsement/NewEndorsement";
import EditEndorsement from "./../EditEndorsement/EditEndorsement";
import EditStaff from "./../EditStaff/EditStaff";
import ReplyToReview from "./../ReviewPage/ReplyToReview";
import ReportsPage from "./../Reports/ReportsPage/ReportsPage";
import NpsReport from "./../Reports/NpsReport/NpsReport";
import TopBehaviorsReport from "./../Reports/TopBehaviorsReport/TopBehaviorsReport"; 
import SpecificBehaviorReport from "./../Reports/SpecificBehaviorReport/SpecificBehaviorReport"; 
import WrittenStaffFeedbackReport from "./../Reports/WrittenStaffFeedbackReport/WrittenStaffFeedbackReport";
import PeerWrittenStaffFeedbackReport from "./../Reports/WrittenStaffFeedbackReport/PeerWrittenStaffFeedbackReport";
import CampaignsReport from "./../Reports/CampaignsReport/CampaignsReport";
import DeptEndorsementReport from "./../Reports/DeptEndorsementReport/DeptEndorsementReport";
import PeerDeptEndorsementReport from "./../Reports/DeptEndorsementReport/PeerDeptEndorsementReport";
import StaffEndorsementReport from "./../Reports/StaffEndorsementReport/StaffEndorsementReport";
import PeerStaffEndorsementReport from "./../Reports/StaffEndorsementReport/PeerStaffEndorsementReport";
import PeerStaffPointReport from "./../Reports/StaffEndorsementReport/PeerStaffPointReport";
import YearlyPublicReviewReport from "./../Reports/YearlyPublicReviewReport/YearlyPublicReviewReport";
import WrittenDeptFeedbackReport from "./../Reports/WrittenDeptFeedbackReport/WrittenDeptFeedbackReport";
import EventsReport from "./../Reports/EventsReport/EventsReport";
import LocationSpecificReport from "./../Reports/LocationSpecificReport/LocationSpecificReport";
import PeerLocationSpecificReport from "./../Reports/LocationSpecificReport/PeerLocationSpecificReport";
import PeerLocationDrillDownReport from "./../Reports/LocationSpecificReport/PeerLocationDrillDownReport";
import IntakeReport from "./../Reports/IntakeReport/IntakeReport";
import EmployerSpecificReport from "./../Reports/EmployerSpecificReport/EmployerSpecificReport";
import IndividualStaffReport from "./../Reports/IndividualReport/IndividualStaffReport";
import SettingsPage from "./../SettingsPage/SettingsPage";
import NewLocation from "./../NewLocation/NewLocation";
import EditLocation from "./../EditLocation/EditLocation";
import NewGroup from "./../NewGroup/NewGroup";
import EditGroup from "./../EditGroup/EditGroup";
import AddPeopleToGroup from "./../AddPeopleToGroup/AddPeopleToGroup";
import NewUser from "./../NewUser/NewUser";
import EditAdminPassword from "./../EditAdminPassword/EditAdminPassword";
import EditAdmin from "./../EditAdmin/EditAdmin";
import NewCampaign from "./../NewCampaign/NewCampaign";
import EditCampaign from "./../EditCampaign/EditCampaign";
import ServeCheckin from "./../ServeCheckin/ServeCheckin";
import EditEHRMapping from "./../EditEHRMapping/EditEHRMapping";
import "./../SurveyApp/SurveyApp.scss";
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { routerActions } from 'connected-react-router';

const locationHelper = locationHelperBuilder({});

function defaultPathForRole(role) {
    switch (role) {
        case 'admin':
            return '/admin_dashboard';
        case 'viewonly':
            return '/admin_dashboard';
        case 'patient':
            return '/patient_dashboard';
        case 'frontdesk':
            return '/sms';
        case 'eventsonly':
            return '/reports/events';
        case 'locationonly':
            return '/reports';  
        case 'surveysreportsonly':
            return '/reports';          
        default:
            return '';
    }
}


function authenticatedFailureRedirectPath(state, ownProps) {
    if (!state || !state.login || !state.login.user) {
    	var redirectPath = locationHelper.getRedirectQueryParam(ownProps);
    	if(redirectPath===undefined){
    		redirectPath = '/login';
    	}
        // If the user is not authenticated, then send them to the automatically chosen login path
        return redirectPath;
    } else {
        // If the user is authenticated and we get here, then that means they tried to load a page that their role
        // doesn't allow. So, the best we can do is send them to the default page for their role.
        return defaultPathForRole(state.login.user.attributes.role);
    }
}

const allowAuthenticatedRedirectBack = location => location.action !== 'REPLACE';

const UserIsNotAuthenticated = connectedRouterRedirect({
	authenticatedSelector: state => state.login !== null && !state.login.user,
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsNotAuthenticated',
    redirectPath: (state, ownProps) => {
        if (!state || !state.login || !state.login.user) {
            return '';
        }
        return locationHelper.getRedirectQueryParam(ownProps) || defaultPathForRole(state.login.user.attributes.role);
    },
    allowRedirectBack: false
});

const UserIsAuthenticatedAdmin = connectedRouterRedirect({
    authenticatedSelector: state => state.login.user !== null && state.login.user !== undefined && state.login.user.attributes !== undefined && (state.login.user.attributes.role === 'admin' || state.login.user.attributes.role === 'viewonly' || state.login.user.attributes.role === 'locationonly'),
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsAuthenticatedAdmin',
    redirectPath: authenticatedFailureRedirectPath,
    allowRedirectBack: allowAuthenticatedRedirectBack
});

const UserIsAuthenticatedAdminOrSimpleViewer = connectedRouterRedirect({
    authenticatedSelector: state => state.login.user !== null && state.login.user !== undefined && state.login.user.attributes !== undefined && (state.login.user.attributes.role === 'admin' || state.login.user.attributes.role === 'viewonly' || state.login.user.attributes.role === 'locationonly' || state.login.user.attributes.role === 'surveysreportsonly'),
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsAuthenticatedAdmin',
    redirectPath: authenticatedFailureRedirectPath,
    allowRedirectBack: allowAuthenticatedRedirectBack
});

const UserIsAuthenticatedAdminOrFrontDesk = connectedRouterRedirect({
    authenticatedSelector: state => state.login.user !== null && state.login.user !== undefined && state.login.user.attributes !== undefined && (state.login.user.attributes.role === 'admin' || state.login.user.attributes.role === 'frontdesk' || state.login.user.attributes.role === 'viewonly' || state.login.user.attributes.role === 'locationonly'),
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsAuthenticatedAdminOrFrontDesk',
    redirectPath: authenticatedFailureRedirectPath,
    allowRedirectBack: allowAuthenticatedRedirectBack
});

const UserIsAuthenticatedAdminOrFrontDeskOrEventsOnly = connectedRouterRedirect({
    authenticatedSelector: state => state.login.user !== null && state.login.user !== undefined && state.login.user.attributes !== undefined && (state.login.user.attributes.role === 'admin' || state.login.user.attributes.role === 'frontdesk' || state.login.user.attributes.role === 'viewonly' || state.login.user.attributes.role === 'eventsonly' || state.login.user.attributes.role === 'locationonly' || state.login.user.attributes.role ==='surveysreportsonly'),
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsAuthenticatedAdminOrFrontDeskOrEventsOnly',
    redirectPath: authenticatedFailureRedirectPath,
    allowRedirectBack: allowAuthenticatedRedirectBack
});

const UserIsAuthenticatedAdminsOrEventsOnly= connectedRouterRedirect({
    authenticatedSelector: state => state.login.user !== null && state.login.user !== undefined && state.login.user.attributes !== undefined && (state.login.user.attributes.role === 'admin' || state.login.user.attributes.role === 'eventsonly' || state.login.user.attributes.role === 'viewonly' || state.login.user.attributes.role === 'locationonly' || state.login.user.attributes.role==='surveysreportsonly'),
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsAuthenticatedEventsOnly',
    redirectPath: authenticatedFailureRedirectPath,
    allowRedirectBack: allowAuthenticatedRedirectBack
});


class App extends Component {
	constructor(props){
		super(props);
	}
	render() {
	    return (
	      <div className="App">
	      	<MainNavBar/>
	      	<Flash/>
	        	    <Route path="/login" component={UserIsNotAuthenticated(Login)}/>
	                <Route path="/admin_dashboard" component={UserIsAuthenticatedAdmin(AdminDashboard)}/>
                    <Route exact path="/surveys" component={UserIsAuthenticatedAdminOrSimpleViewer(SurveyList)}/>
                    <Route path="/surveys/view/:surveyId" component={UserIsAuthenticatedAdminOrSimpleViewer(ViewSurvey)}/>
                    <Route exact path="/departments" component={UserIsAuthenticatedAdmin(DepartmentList)}/>
                    <Route path="/departments/new" component={UserIsAuthenticatedAdmin(NewDepartment)}/>
                    <Route path="/departments/edit/:departmentId" component={UserIsAuthenticatedAdmin(EditDepartment)}/>
                    <Route path="/locationmapping/edit/:mappingId" component={UserIsAuthenticatedAdmin(EditLocationMapping)}/>
                    <Route exact path="/reviews" component={UserIsAuthenticatedAdmin(ReviewPage)}/>
                    <Route exact path="/reputation" component={UserIsAuthenticatedAdmin(ReputationPage)}/>
                    <Route path="/journey/new" component={UserIsAuthenticatedAdmin(NewJourney)}/>
                    <Route path="/journey/edit/:documentId" component={UserIsAuthenticatedAdmin(EditJourney)}/>
                    <Route exact path="/staff" component={UserIsAuthenticatedAdmin(StaffList)}/>
                    <Route path="/staff/new" component={UserIsAuthenticatedAdmin(NewStaff)}/>
                    <Route path="/staff/edit/:documentId" component={UserIsAuthenticatedAdmin(EditStaff)}/>
                    <Route path="/staff/report/:documentId" component={UserIsAuthenticatedAdmin(IndividualStaffReport)}/>
                    <Route path="/reviews/view/:locationId" component={UserIsAuthenticatedAdmin(ViewLocationReviews)}/>
                    <Route path="/reviewlocations/view/:accountId" component={UserIsAuthenticatedAdmin(ViewAccountLocations)}/>
                    <Route path="/reviews/reply/:reviewId" component={UserIsAuthenticatedAdmin(ReplyToReview)}/>
                    <Route exact path="/reports" component={UserIsAuthenticatedAdminsOrEventsOnly(ReportsPage)}/>
                    <Route path="/reports/nps" component={UserIsAuthenticatedAdminOrSimpleViewer(NpsReport)}/>
                    <Route path="/reports/behaviors" component={UserIsAuthenticatedAdminOrSimpleViewer(TopBehaviorsReport)}/>
                    <Route path="/reports/specificbehavior/:behaviorId" component={UserIsAuthenticatedAdmin(SpecificBehaviorReport)}/>
                    <Route path="/reports/stafffeedback" component={UserIsAuthenticatedAdminOrSimpleViewer(WrittenStaffFeedbackReport)}/>
                    <Route path="/reports/campaigns" component={UserIsAuthenticatedAdmin(CampaignsReport)}/>
                    <Route path="/reports/deptendorsements" component={UserIsAuthenticatedAdminOrSimpleViewer(DeptEndorsementReport)}/>
                    <Route path="/reports/staffendorsements" component={UserIsAuthenticatedAdminOrSimpleViewer(StaffEndorsementReport)}/>
                    <Route path="/reports/yearlypublicratings" component={UserIsAuthenticatedAdmin(YearlyPublicReviewReport)}/>
                    <Route path="/reports/peerdeptendorsements" component={UserIsAuthenticatedAdmin(PeerDeptEndorsementReport)}/>
                    <Route path="/reports/peerstaffendorsements" component={UserIsAuthenticatedAdmin(PeerStaffEndorsementReport)}/>
                    <Route path="/reports/peerstaffpoints" component={UserIsAuthenticatedAdmin(PeerStaffPointReport)}/>
                    <Route path="/reports/peerstafffeedback" component={UserIsAuthenticatedAdmin(PeerWrittenStaffFeedbackReport)}/>
                    <Route path="/reports/deptfeedback" component={UserIsAuthenticatedAdminOrSimpleViewer(WrittenDeptFeedbackReport)}/>
                    <Route path="/reports/events" component={UserIsAuthenticatedAdminsOrEventsOnly(EventsReport)}/>
                    <Route path="/events/view/:eventId" component={UserIsAuthenticatedAdminsOrEventsOnly(ViewEvent)}/>
                    <Route path="/events/custom/view/:eventId" component={UserIsAuthenticatedAdminsOrEventsOnly(ViewCustomEvent)}/>
                    <Route path="/events/new" component={UserIsAuthenticatedAdminsOrEventsOnly(NewCustomEvent)}/>
                    <Route path="/reports/locationreport" component={UserIsAuthenticatedAdminOrSimpleViewer(LocationSpecificReport)}/>
                    <Route path="/reports/peerlocationreport" component={UserIsAuthenticatedAdmin(PeerLocationSpecificReport)}/>
                    <Route path="/reports/peer/location/view/" component={UserIsAuthenticatedAdmin(PeerLocationDrillDownReport)}/>
                    <Route path="/reports/intakereport" component={UserIsAuthenticatedAdmin(IntakeReport)}/>
                    <Route path="/reports/employerfeedback" component={UserIsAuthenticatedAdmin(EmployerSpecificReport)}/>
                    <Route exact path="/settings" component={UserIsAuthenticatedAdmin(SettingsPage)}/>
                    <Route path="/endorsement/new" component={UserIsAuthenticatedAdmin(NewEndorsement)}/>
                    <Route path="/endorsement/edit/:documentId" component={UserIsAuthenticatedAdmin(EditEndorsement)}/>
                    <Route path="/locations/new" component={UserIsAuthenticatedAdmin(NewLocation)}/>
                    <Route path="/locations/edit/:documentId" component={UserIsAuthenticatedAdmin(EditLocation)}/>
                    <Route path="/users/new" component={UserIsAuthenticatedAdmin(NewUser)}/>
                    <Route path="/admin/password/:documentId" component={UserIsAuthenticatedAdmin(EditAdminPassword)}/>
                    <Route path="/admin/edit/:documentId" component={UserIsAuthenticatedAdmin(EditAdmin)}/>
                    <Route path="/ehrmapping/edit/:documentId" component={UserIsAuthenticatedAdmin(EditEHRMapping)}/>
                    <Route exact path="/sms" component={UserIsAuthenticatedAdminOrFrontDesk(ServeCheckin)}/>
                    <Route path="/groups/new" component={UserIsAuthenticatedAdmin(NewGroup)}/> 
                    <Route path="/group/edit/:documentId" component={UserIsAuthenticatedAdmin(EditGroup)}/> 
                    <Route path="/group/people/:documentId" component={UserIsAuthenticatedAdmin(AddPeopleToGroup)}/> 
                    <Route path="/campaign/new" component={UserIsAuthenticatedAdmin(NewCampaign)}/>
	                <Route path="/myprofile" component={UserIsAuthenticatedAdminOrFrontDeskOrEventsOnly(MyProfile)}/>
                    <Route path="/campaign/edit/:documentId" component={UserIsAuthenticatedAdmin(EditCampaign)}/> 
                    <Route exact path="/intake" component={UserIsAuthenticatedAdminOrFrontDesk(IntakeList)}/>
                    <Route path="/intake/view/:surveyId" component={UserIsAuthenticatedAdminOrFrontDesk(ViewIntake)}/>
                    <Redirect exact from="/" to="/login" component={App}/>
	      </div>
	    )
	  }
}

export default withRouter(connect(false)(App));
