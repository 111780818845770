import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row,
    Accordion, Card
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import ReactAvatarEditor from "react-avatar-editor";
import { requestTheStaffPhoto, updateStaffPhoto } from "../../survey-actions";

class RequestStaffPhoto extends Component {

    /*
    - Need to add functions in the actions to account for viewing request and updating request
    - Need to retrieve the DB Entry for this request based on the URL param
    - That response needs to have the client logo in addition to whether it is complete or not
    - If it is complete we need to simply say thank you and potentially have a "request new link"
    - If it is not complete we surface up the avatar window and we make it bigger because this is a one off
    - Once it is uploaded and trimmed to size we will need to forward it to the server with no admin restrictions
    - If successful show a nice message and away we go
    */

   /* componentWillMount() { 
        return this.props.viewStaff(this.props.tvClient, this.props.match.params.documentId);
    } */

    constructor(props) {
        
        super(props);
        
        this.state = {
            staffFiles: [],
            staffFileUrls: [],
            errors: { 'photo': false },
            changed: false,
            image: null,
            allowZoomOut: true,
            completed: false,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 360,
            height: 360
        };

        this.props.requestTheStaffPhoto(this.props.tvClient, this.props.match.params.request_url).then(()=>{
            this.setState({'completed': this.props.requestData.request.completed});
        });

    }

    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:'image/jpeg'});
    }
       

    editStaff(e) {
        e.preventDefault();
  
        var theStaffImage = this.state.staffFiles;
        if(this.state.image!==null){
            var baseURL = this.editor.getImageScaledToCanvas().toDataURL('image/jpeg', 0.85);
            theStaffImage[0] = this.dataURLtoBlob(baseURL);
                this.props.updateStaffPhoto(
                    this.props.tvClient,
                    theStaffImage[0],
                    this.props.requestData.request.request_id
                ).then(()=>{
                    this.setState({'completed': true});
                }); 
            this.setState({ errors: {'photo': false }});  
        } else{
            this.setState({ errors: {'photo': true }}); 
        }
           
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();

        this.fileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            staffFiles: [...e.target.files],
            image: e.target.files[0],
            // Set object URLs one time so that images don't re-render when state changes
            staffFileUrls: [...e.target.files].map(staffFile => URL.createObjectURL(staffFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }

  getDataUrl = img => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL();
  };


  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius
      }
    });
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateLeft = e => {
    e.preventDefault();

    this.setState({
      rotate: this.state.rotate - 90
    });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value);
    this.setState({ borderRadius });
  };

  handleXPosition = e => {
    const x = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, x } });
  };

  handleYPosition = e => {
    const y = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, y } });
  };

  handleWidth = e => {
    const width = parseInt(e.target.value);
    this.setState({ width });
  };

  handleHeight = e => {
    const height = parseInt(e.target.value);
    this.setState({ height });
  };

  logCallback(e) {
    // eslint-disable-next-line
    console.log("callback", e);
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] });
  };

    render() {
        return  <div className="container actualSurvey">
            <h1 className="page-header">
                Update Your Photo
                <small>Please provide a photo for your organization</small>
            </h1>
            {this.state.requestingData && <Spinner/>}
            {(this.props.requestData && (!this.state.completed)) && <div>

            
            <Form onSubmit={this.editStaff.bind(this)} className="new-case-form requestPhotoForm">
                            {(this.props.requestData.client.logo===null || this.props.requestData.client.logo===undefined) ? '' : <img alt="" className="inSurveyLogo" src={this.props.requestData.client.logo} alt="Logo"/>}
                <legend>
                    <ul>
                        <li>Use a work appropriate photo</li>
                        <li>This is a headshot so make sure we can see your face</li>
                        <li>Use the photo tool to scale and crop</li>
                    </ul>
                </legend>
                <br/>

                <Row>
                {
                    this.state.staffFileUrls.map(url => {
                        return <Col className="thumbnail-col" key={url}>
                            
    <ReactAvatarEditor
            ref={this.setEditorRef}
            scale={parseFloat(this.state.scale)}
            width={this.state.width}
            height={this.state.height}
            position={this.state.position}
            onPositionChange={this.handlePositionChange}
            rotate={parseFloat(this.state.rotate)}
            borderRadius={this.state.width / (100 / this.state.borderRadius)}
            onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
            onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
            onImageReady={this.logCallback.bind(this, "onImageReady")}
            image={url}
            className="editor-canvas"
          /><br/>
          Zoom:
        <input
          name="scale"
          type="range"
          onChange={this.handleScale}
          min={this.state.allowZoomOut ? "0.1" : "1"}
          max="2"
          step="0.01"
          defaultValue="1"
        />
        <br />
        <p>Rotate:
        <button onClick={this.rotateLeft}>Left</button>
        <button onClick={this.rotateRight}>Right</button></p>

                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.requestingData ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                    )}

                </div>
                {this.state.errors.photo && <Alert variant="danger">
                        There has been an error updating your photo.  Please double check your upload.
                    </Alert>}
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.requestingData} >
                        Update
                    </Button>
                    <div><br/><small>*By clicking the update button you are providing consent for this photo to be used</small></div>
                </FormGroup>



            </Form>
            </div>}

             {(this.props.requestData && (this.props.requestData.request.completed || this.state.completed)) && <div className="case-list-container">
                  {(this.props.requestData.client.logo===null || this.props.requestData.client.logo===undefined) ? '' : <img alt="" className="inSurveyLogo" src={this.props.requestData.client.logo} alt="Logo"/>}
                  <br/><br/>
                <h2>Thank you for completing your upload!</h2>
                <p>Congratulations you've earned your first recognition points!</p>
             </div>}

        </div>
    }
}

const mapStateToProps = state => {
    return {
        requestingData: state.viewPhotoRequest.requestingData,
        viewRequestError: state.viewPhotoRequest.viewRequestError,
        tvClient: state.login.tvClient,
        requestData: state.viewPhotoRequest.requestData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        requestTheStaffPhoto: (...params) => dispatch(requestTheStaffPhoto(...params)),
        updateStaffPhoto: (...params) => dispatch(updateStaffPhoto(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestStaffPhoto);

