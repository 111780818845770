import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert} from "react-bootstrap";
import "./Flash.scss";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import { withRouter } from 'react-router-dom';

/*
<CSSTransition classNames="flash-message" enter={false} timeout={{ exit: 500 }}>
*/

class Flash extends Component {

    render() {
        return <TransitionGroup>
            {
                this.props.messages.map((message, i) => (<CSSTransition key={i} classNames="flash-message" enter={false} timeout={{ exit: 500 }}><Alert variant={message.style} key={message.key}>{message.message}</Alert></CSSTransition>))
            }
        </TransitionGroup>;
    }
}

const mapStateToProps = state => {
    return {
        messages: state.flash,
    };
};

export default withRouter(connect(mapStateToProps)(Flash));
