import React, {Component} from "react";
import PropTypes from "prop-types";

import "./SurveyStatus.scss";

class ListNames extends Component {
    static listNames(items) {
        let totalLength = items.length;
        let finalLength = items.length;
        if(totalLength===0){
            return 'None selected'
        } else{
            let returnString = '';
            let maxSize = 2;
            if(totalLength<=maxSize){
                maxSize = totalLength;
            }
            if(totalLength===1){
                var theName = '';
                if(items[0].name!==undefined){ theName=items[0].name }
                return theName;
            } else{
                for (var i = maxSize - 1; i >= 0; i--) {
                    if(items[i].name!==undefined){

                        if(i!==0){
                            returnString = returnString  + items[i].name + ', ';    
                        } else{
                            returnString += items[i].name;
                        }

                    } else{
                        finalLength--;
                    }
                }
                if(finalLength>maxSize){
                    if(returnString===''){
                        return 'None selected';
                    } else{
                        returnString = returnString + ' + ' + (finalLength - maxSize) + ' More';    
                    }
                    
                }
                return returnString;                
            }

        }

    }

    render() {
        return <span className='listingNames'>{ListNames.listNames(this.props.items)}</span>;
    }
}

ListNames.propTypes = {
    items: PropTypes.array.isRequired
};

export default ListNames;
