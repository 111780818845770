import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import { withRouter } from 'react-router-dom';
import {updateMyProfilePassword, getPwdRestrictions} from "../../actions";

class MyProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: { 'name': '' , 'email': false, 'mobile': false },
            editLoading: true
        };

    }  

    componentDidMount() { 
      return this.props.getPwdRestrictions(this.props.tvClient);
    }

    editAdmin(e) {
        e.preventDefault();
        let errors = {};
        var upperRegex = new RegExp("(?=.*[A-Z])"),
        lowerRegex = new RegExp("(?=.*[a-z])"),
        numberRegex = new RegExp("(?=.*[0-9])");
        // eslint-disable-next-line
        var specialRegex = new RegExp("(?=.[!@#\$%\^&\(\))\?\*\+])");

        if(this.newPassword.value.length < this.props.pwdRestrictions.minlengthreq ){
            errors["password"] = "Your password must be at least "+ this.props.pwdRestrictions.minlengthreq +" characters long";
        }
        else if(this.props.pwdRestrictions.uppercasereq.toString()==='true' && !upperRegex.test(this.newPassword.value)){
            errors["password"] = "Your password must have an uppercase character";
        }
        else if(this.props.pwdRestrictions.lowercasereq.toString()==='true' && !lowerRegex.test(this.newPassword.value)){
            errors["password"] = "Your password must have a lowercase character"; 
        }
        else if(this.props.pwdRestrictions.numberreq.toString()==='true' && !numberRegex.test(this.newPassword.value)){
            errors["password"] = "Your password must have a number"; 
        }
        else if(this.props.pwdRestrictions.specialreq.toString()==='true' && !specialRegex.test(this.newPassword.value)){
            errors["password"] = "Your password must have a special character [!@#$%^&()?*+]"; 
        }
        else if(this.newPassword.value!==this.newPasswordConfirm.value){
            errors["password"] = "Your passwords must match"; 
        } else{
           this.props.updateMyProfilePassword(
                this.props.tvClient,
                this.newPassword.value,
                this.newPasswordConfirm.value,
                this.props.tv_id
            ).then(()=>{
                window.location.reload(true);
            });             
        }
          this.setState({
            errors: errors
          }); 
                
    }

    render() {
        return <div>
            <h1 className="page-header">
                Change Password
                <small>Update the password below for {this.props.adminData && <span>{this.props.adminData.name}</span>}</small>
            </h1>
            
            {this.state.viewingAdmin && <Spinner/>}

            {this.props.adminData && <div className="case-list-container"><Form onSubmit={this.editAdmin.bind(this)} className="pull-left new-case-form">

                <FormGroup controlId="newPassword">
                    <FormLabel>Password *</FormLabel>
                    <FormControl type="password" autoComplete="new-password" ref={ref => this.newPassword = ref}/>
                </FormGroup>    
                <FormGroup controlId="newPasswordConfirm">
                    <FormLabel>Confirm *</FormLabel>
                    <FormControl type="password" autoComplete="new-password" ref={ref => this.newPasswordConfirm = ref}/>
                </FormGroup>          
                 {this.state.errors.password && <Alert variant="danger">
                        {this.state.errors.password}
                    </Alert>}

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingAdmin} className="float-right">
                        Update
                    </Button>
                </FormGroup>


            </Form></div>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        adminData: state.login.user.attributes,
        tv_id: state.login.user.user_id,
        pwdRestrictions: state.pwdRestrictions.pwdRestrictions
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMyProfilePassword: (...params) => dispatch(updateMyProfilePassword(...params)),
        getPwdRestrictions: (...params) => dispatch(getPwdRestrictions(...params))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile));

