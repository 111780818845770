import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {internalApiClient, editDepartment, listMappings, listLocations, updateLocationMapping} from "../../actions";
import "./EditLocationMapping.scss";

class EditLocationMapping extends Component {
    constructor(props) {
        
        super(props);

        this.state = {
            currentDetails: [],
            editLoading: true,
            locationId: null
        };

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentWillMount() { 
        window.scrollTo(0, 0);
        this.props.listLocations(this.props.tvClient);
        internalApiClient.getLocationMapping(this.props.tvClient, this.props.match.params.mappingId).then((mappingData) => {

            this.emrCode.value = mappingData[0].location_resource;
            this.locationGroup.value = mappingData[0].location_resource_alt;
            this.locationId.value = mappingData[0].location_id;
            this.setState({
                editLoading: false,
                mappingId: mappingData[0].uid,
                locationId: mappingData[0].location_id
            })

        });
    }

    handleFormChange(e) {
      this.setState({
        locationId: e.target.value
      });
    } 

    updateMapping(e) {
        e.preventDefault();

        this.props.updateLocationMapping(
            this.props.tvClient,
            this.state.mappingId,
            this.emrCode.value,
            this.locationGroup.value,
            this.locationId.value
        ); 
    }

    render() {
        return <div>
            <h1 className="page-header">
                Edit Location Mapping
                <small>Please enter the following mapping information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()}>Go Back</button></small>
            </h1>
            
            {this.state.editLoading && <Spinner/>}
            <div className="case-list-container">
               <table className="table">
                    <thead>
                        <tr>
                            <th>Location Code</th>
                            <th>Business Line</th>
                            <th>Location</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><FormControl type="text" placeholder="Ex: 12" ref={ref => this.emrCode = ref}/></td>
                            <td><FormControl type="text" placeholder="Optional" ref={ref => this.locationGroup = ref}/></td>
                            <td><div>
                              <FormControl as="select" placeholder="select" defaultValue={this.locationId} name="location" id="location" onChange={this.handleFormChange.bind(this)} ref={ref => this.locationId = ref}>
                                    <option value='' key=''>Select</option>
                                    {Object.values(this.props.locations).map(d => {
                                        return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                                    })}
                                </FormControl></div></td>
                            <td><button className="btn btn-primary" onClick={this.updateMapping.bind(this)}>Update</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        editingDepartment: state.editDepartment.editingDepartment,
        editDepartmentError: state.editDepartment.editDepartmentError,
        tvClient: state.login.tvClient,
        locations: state.locationList.locations,
        departments: state.deptList.departments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editDepartment: (...params) => dispatch(editDepartment(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        updateLocationMapping: (...params) => dispatch(updateLocationMapping(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocationMapping);
