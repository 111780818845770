import PropTypes from 'prop-types';
import React from 'react';

const StaffProfileMenuItem = ({user}) => (
  <div>
    <img
      alt={user.name}
      src={user.staffimageid.startsWith('http') ? user.staffimageid : process.env.PUBLIC_URL + '/staff_assets/default-employee-avatar.png'}
      style={{
        height: '24px',
        marginRight: '10px',
        width: '24px',
      }}
    />
    <span>{user.name}</span>
  </div>
);

StaffProfileMenuItem.propTypes = {
  user: PropTypes.shape({
    staffimageid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default StaffProfileMenuItem;