import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";


class ExportIntakeResultsCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    cleanData(dirtyData){
      
        let exportData = [];
        let journeyName = 'All Journeys';
        if(this.props.journeyName!==undefined && this.props.journeyName!==null && this.props.journeyName.length>0){
            journeyName = this.props.journeyName + ' Journey';
        }

        for (let i = this.props.badgeData.length - 1; i >= 0; i--) {
            let thisLine = this.props.badgeData[i];
            if(thisLine.endorsement_id!==999){
                exportData.push({ 'name': thisLine.description, 'total': thisLine.total });    
            }
            
        } 
        
        exportData.push({'name': '', 'total': ''});

        if(this.props.summaryData.total!==undefined){
            exportData.push({ 'name': 'Not Opened', 'total': this.props.summaryData.unopened});
            exportData.push({ 'name': 'Symptoms', 'total': this.props.summaryData.symptoms});
            exportData.push({ 'name': 'No Symptoms', 'total': this.props.summaryData.nosymptoms});
            exportData.push({ 'name': 'Sent', 'total': this.props.summaryData.total});
        }
        exportData.push({'name': journeyName, 'total': ''});
        exportData.push({'name': '', 'total': ''});

        return exportData.reverse();
    }

    allHeaders(dirtyData){
        let headers = [];
        headers.push({ 'label': 'Name', 'key': 'name'});
        headers.push({ 'label': 'Total', 'key': 'total'});
        return headers;
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.badgeData)} headers={this.allHeaders()} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
        )

    }
}

ExportIntakeResultsCSV.propTypes = {
    badgeData: PropTypes.array.isRequired,
    summaryData: PropTypes.object.isRequired,
    fileName: PropTypes.string.isRequired,
    journeyName: PropTypes.string
};

export default ExportIntakeResultsCSV;
