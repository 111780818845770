import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateAdminPassword, viewAdmin } from "../../actions";

class EditAdminPassword extends Component {

    componentDidMount() { 
        return this.props.viewAdmin(this.props.tvClient, this.props.match.params.documentId);
    }

    constructor(props) {
        
        super(props);

        this.state = {
            errors: { 'name': '' , 'email': false, 'mobile': false },
            editLoading: true
        };

    }  

    editAdmin(e) {
        e.preventDefault();
      
        var upperRegex = new RegExp("(?=.*[A-Z])"),
            lowerRegex = new RegExp("(?=.*[a-z])"),
            numberRegex = new RegExp("(?=.*[0-9])");
        // eslint-disable-next-line
        var specialRegex = new RegExp("(?=.[!@#\$%\^&\(\))\?\*\+])");

        if(this.newPassword.value.length < this.props.pwdRestrictions.minlengthreq ){
            this.setState({ errors: {'name': 'Your password must be at least '+ this.props.pwdRestrictions.minlengthreq +' characters long'}});
        }
        else if(this.props.pwdRestrictions.uppercasereq.toString()==='true' && !upperRegex.test(this.newPassword.value)){
            this.setState({ errors: {'name': 'Your password must have an uppercase character'}});
        }
        else if(this.props.pwdRestrictions.lowercasereq.toString()==='true' && !lowerRegex.test(this.newPassword.value)){
            this.setState({ errors: {'name': 'Your password must have a lowercase character'}});
        }
        else if(this.props.pwdRestrictions.numberreq.toString()==='true' && !numberRegex.test(this.newPassword.value)){
            this.setState({ errors: {'name': 'Your password must have a number'}});
        }
        else if(this.props.pwdRestrictions.specialreq.toString()==='true' && !specialRegex.test(this.newPassword.value)){
            this.setState({ errors: {'name': 'Your password must have a special character [!@#$%^&()?*+]'}});
        }
        else if(this.newPassword.value!==this.newPasswordConfirm.value){
            this.setState({ errors: {'name': 'Your passwords do not match' }});
        } else{
           this.props.updateAdminPassword(
                this.props.tvClient,
                this.newPassword.value,
                this.newPasswordConfirm.value,
                this.props.adminData.tv_id
            );             
        }
          
                
    }

    render() {
        return <div>
            <h1 className="page-header">
                Change Password
                <small>Update the password below for {this.props.adminData && <span>{this.props.adminData.name}</span>}</small>
            </h1>
            
            {this.state.viewingAdmin && <Spinner/>}

            {this.props.adminData && <Form onSubmit={this.editAdmin.bind(this)} className="new-case-form">

                {this.props.viewAdminError && <Alert variant="danger">
                    {this.props.viewAdminError.message}
                </Alert>}

                <FormGroup controlId="newPassword">
                    <FormLabel>Password *</FormLabel>
                    <FormControl type="password" autoComplete="new-password" ref={ref => this.newPassword = ref}/>
                </FormGroup>    
                <FormGroup controlId="newPasswordConfirm">
                    <FormLabel>Confirm *</FormLabel>
                    <FormControl type="password" autoComplete="new-password" ref={ref => this.newPasswordConfirm = ref}/>
                </FormGroup>          
                 {this.state.errors.name && <Alert variant="danger">
                        {this.state.errors.name}
                    </Alert>}

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingAdmin} className="float-right">
                        Update
                    </Button>
                </FormGroup>


            </Form>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingAdmin: state.viewAdmin.viewingAdmin,
        viewAdminError: state.viewAdmin.viewAdminError,
        tvClient: state.login.tvClient,
        adminData: state.viewAdmin.adminData,
        pwdRestrictions: state.viewAdmin.pwdRestrictions
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewAdmin: (...params) => dispatch(viewAdmin(...params)),
        updateAdminPassword: (...params) => dispatch(updateAdminPassword(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminPassword);

