import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Table
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {viewSurvey } from "../../actions";
import "./ViewSurvey.scss";
import dateFormat from "dateformat";
import queryString from 'query-string'


class ViewSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDetails: [],
            historyField: queryString.parse(this.props.location.search)
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let isRefresh =this.props.currentSurvey === null;
        if(isRefresh){
            this.props.viewSurvey(this.props.tvClient, this.props.match.params.surveyId);
        }
    }

    render() {
        return <div>
            <h1 className="page-header">
               View Survey
               <small className="backLink">
                    {(this.state.historyField.from && this.state.historyField.from!=='none') && <Link to={'/'+ this.state.historyField.from +'?refresh=0&dateFilter=' + this.state.historyField.date + '&locationFilter=' + this.state.historyField.location }>Go Back</Link>}
                    {(!this.state.historyField.from || this.state.historyField.from==='none') && <Link to={'/surveys?refresh=0'}>Go Back</Link> }
               </small>
            </h1>
            {this.props.currentSurvey===null && <Spinner/>}
            {this.props.currentSurvey!==null && <div className="case-list-container">
                    
                <Table className="case-list surveyReportView">
                    <tbody>
                        {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.survey_data.nps!==null) && 
                        <tr>
                            <td className="reportCell">Overall Score</td>
                            <td className="reportValue">{this.props.currentSurvey.data!==undefined ? this.props.currentSurvey.data.survey_data.nps : ''}</td>
                        </tr>
                        }
                       
                           <tr>
                                <td className="reportCell">Name</td>
                                <td className="reportValue">{this.props.currentSurvey.person!==undefined ? this.props.currentSurvey.person[0].document.name : 'Unavailable'} {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.respondent_type==='Employer') ? '(Employer)' : ''}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Email</td>
                                <td className="reportValue">{this.props.currentSurvey.person!==undefined ? this.props.currentSurvey.person[0].document.email : 'Unavailable'}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Phone</td>
                                <td className="reportValue">{this.props.currentSurvey.person!==undefined ? this.props.currentSurvey.person[0].document.phone : 'Unavailable'}</td>
                            </tr>                      
                        <tr>
                            <td className="reportCell">Date Created</td>
                            <td className="reportValue">{this.props.currentSurvey.data!==undefined ? dateFormat(this.props.currentSurvey.data.created, "fullDate") + ' at ' + dateFormat(this.props.currentSurvey.data.created, "shortTime") : ''}</td>
                        </tr>
                        <tr>
                        <td className="reportCell">Location</td>
                            <td className="reportValue">{this.props.currentSurvey.data!==undefined ? this.props.currentSurvey.data.name : ''} {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.respondent_type==='Employer') ? 'All Locations' : ''}</td>
                        </tr>  
                        {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.survey_data.intakeResults!==undefined) && <tr>
                            <td className="reportCell">Intake Form</td>
                            <td className="reportValue">
                            {
                                        this.props.currentSurvey.data.survey_data.intakeResults.intakeData.badges.map(c => {
                         

                                            var localeValue = '';
                                            if(c.localization_options!==undefined && c.localization_options!==null){
                                              let tempValue = c.localization_options["EN"];
                                              if(tempValue!==undefined){
                                                localeValue = tempValue;
                                              }
                                            }
                                            if(c.image!==undefined){
                                                return <div className="nopad isBadge text-center intakeBadgeC" key={c.endorsement_id}>
                                                    <label>
                                                      <img alt="" className={"img-responsive"} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                                      <p className="intakeBadgeName">{localeValue}</p>
                                                    </label>
                                                  </div>    
                                            } else{
                                                return "";
                                            }
                                          
                                        })
                                    }      

                                    {this.props.currentSurvey.data.survey_data.intakeResults.intakeData.badges.length===0 ? 'No Badges Selected' : ''}
                            </td>   
                        </tr>}
                        <tr>
                        <td className="reportCell">Status</td>
                            <td className="reportValue">{(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.completed) ? 'Complete' : 'In Progress'}</td>
                        </tr>
                        {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.survey_data.overallFeedback!==undefined && this.props.currentSurvey.data.survey_data.overallFeedback!==null && this.props.currentSurvey.data.survey_data.overallFeedback.length>0) && 
                        <tr>
                            <td className="reportCell">Overall Feedback</td>
                            <td className="reportValue">{this.props.currentSurvey.data.survey_data.overallFeedback}</td>
                        </tr>
                        }     
                    </tbody>
                </Table>                                          
                {(this.props.currentSurvey.data.survey_data.departments) && <div>
                    {
                        this.props.currentSurvey.data.survey_data.departments.map(c => {
                            var totalBadges = 0;
                            var feedback = undefined;
                            if(c.badges!==undefined){
                                totalBadges = c.badges.length;
                            }
                            if(c.feedback!==undefined){
                                feedback = c.feedback;
                            }

                            if(totalBadges>0 || feedback !== undefined){
                                return <Table key={c.key} className="case-list surveyReportView"><tbody>
                                    <tr><td className="reportCell">Department</td><td className="reportValue">{c.name}</td></tr>
                                    <tr className="spanningReportCell"><td colSpan="2">{totalBadges}{' '}Endorsement{' '}{totalBadges>1 ? 's' : ''} {feedback===undefined ? '& No written feedback provided' : '& Written feedback provided below'}</td></tr>
                                    {totalBadges>0 ? <tr className="spanningReportCell"><td colSpan="2">{ c.badges.map(b => { return <div className="nopad isBadge text-center" key={b.endorsement_id}><label className="adminBadgeView"><img alt={b.description} src={b.image.startsWith('http') ? b.image : process.env.PUBLIC_URL + '/endorsement_assets/' + b.image}/><p>{b.description}</p></label></div>})}</td></tr> : null}
                                    {feedback!==undefined ? <tr><td className="reportCell">Feedback</td><td className="reportValue">{c.feedback}</td></tr> : null}
                                </tbody></Table>
                            }
                            else { return "" }
                            
                        })
                    }
                    </div>}

                    {(this.props.currentSurvey.data.survey_data.staff) && <div>

                    {
                        this.props.currentSurvey.data.survey_data.staff.map(c => {
                            var totalBadges = 0;
                            var feedback = undefined;
                            if(c.badges!==undefined){
                                totalBadges = c.badges.length;
                            }
                            if(c.feedback!==undefined){
                                feedback = c.feedback;
                            }
                            return <Table key={c.staff_id} className="case-list surveyReportView"><tbody>
                                    <tr><td className="reportCell staffImageCell"><img alt={c.description} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid}/></td><td className="reportValue">{c.name}<p className="reportStaffTitle">{c.title}</p><p className="reportStaffTitle">{c.bio}</p></td></tr>
                                    <tr className="spanningReportCell"><td colSpan="2">{totalBadges}{' '}Endorsement{' '}{totalBadges>1 ? 's' : ''} {feedback===undefined ? '& No written feedback provided' : '& Written feedback provided below'}</td></tr>
                                    {totalBadges>0 ? <tr className="spanningReportCell"><td colSpan="2">{ c.badges.map(b => { return <div className="nopad isBadge text-center" key={b.endorsement_id}><label className="adminBadgeView"><img alt={b.description} src={b.image.startsWith('http') ? b.image : process.env.PUBLIC_URL + '/endorsement_assets/' + b.image}/><p>{b.description}</p></label></div>})}</td></tr> : null}
                                    {feedback!==undefined ? <tr><td className="reportCell">Feedback</td><td className="reportValue">{c.feedback}</td></tr> : null}
                                </tbody></Table>
                        })
                    }               

                    </div>}
                </div>
            }
        </div>
    }
}

const mapStateToProps = state => {
    return {
        currentSurvey: state.surveyView.currentSurvey,
        viewSurveyError: state.surveyView.error,
        tvClient: state.login.tvClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewSurvey: (...params) => dispatch(viewSurvey(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSurvey);
