import React, {Component} from "react";
import {
    Dropdown
} from "react-bootstrap";
import { listJourneys, listLocationMappingGroups, viewAdminDashboardOverviewCustom, viewAdminDashboardOverview, viewAdminDashboardNps, viewLocationNpsData, viewCustomLocationNpsData, viewCustomAdminDashboardNps } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import {PieChart, Pie, Legend, Cell, Label} from 'recharts';
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import ExportPatSatReportCSV from './../../Utilities/ExportPatSatReportCSV';
import MomentLocaleUtils, {formatDate, parseDate,} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class NpsReport extends Component {

    constructor(props) {
        
        super(props);
        this.state = {
          activeKey: 1,
          currentFilter: 2,
          filter: 'thismonth',
          currentFilterName: ' This Month ',
          showCustomDate: false,
          reportFrom: undefined,
          reportTo: undefined,
          businessLine: null,
          selectedJourney: null,
          selectedJourneyName: '',
          roleLocations: null
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);

        this.props.listJourneys(this.props.tvClient);
    }   

    handleJourneyFilter(selectedKey){
       if(selectedKey.toString()==='-1'){
              this.setState({ selectedJourney: null, selectedJourneyName: ' All Journeys '}, ()=>{
                if(this.state.filter==='custom'){
                  this.props.viewCustomAdminDashboardNps(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                  this.props.viewCustomLocationNpsData(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                  this.props.viewAdminDashboardOverviewCustom(this.props.accessToken, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                } else{
                  this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                  this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
                  this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney);         
                }
              });
      } else{
          for(var i=0; i<this.props.journeys.length; i++){
            if(parseInt(this.props.journeys[i].journey_id)===parseInt(selectedKey)){
              this.setState({ selectedJourney: selectedKey, selectedJourneyName: this.props.journeys[i].name }, ()=>{
                if(this.state.filter==='custom'){
                  this.props.viewCustomAdminDashboardNps(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                  this.props.viewCustomLocationNpsData(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                  this.props.viewAdminDashboardOverviewCustom(this.props.accessToken, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                } else{
                  this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                  this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
                  this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney);         
                }
              });
              break; 
            }
            
          }
      }
      
    }

    handleLocationFilter(selectedKey){
      if(selectedKey.toString()==='-1'){
              this.setState({ businessLine: null}, ()=>{
                if(this.state.filter==='custom'){
                  this.props.viewCustomAdminDashboardNps(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                  this.props.viewCustomLocationNpsData(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                  this.props.viewAdminDashboardOverviewCustom(this.props.accessToken, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
                } else{
                  this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                  this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
                  this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);         
                }
              });

      } else{

          for(var i=0; i<this.props.locationMapping.length; i++){
            if(parseInt(i)===parseInt(selectedKey)){
              this.setState({ businessLine: this.props.locationMapping[i].location_resource_alt}, ()=>{
                if(this.state.filter==='custom'){
                  this.props.viewCustomAdminDashboardNps(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                  this.props.viewCustomLocationNpsData(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                  this.props.viewAdminDashboardOverviewCustom(this.props.accessToken, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                } else{
                  this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
                  this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
                  this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);         
                }
              });
              break;
            }
          }

      }
      
    }

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    componentDidMount(){
        let roleLocations = null;
        if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
            roleLocations = this.props.roleLocations;
         } 
         this.setState({roleLocations: roleLocations});
        this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter, this.state.businessLine, this.state.selectedJourney, roleLocations);
        this.props.viewLocationNpsData(this.props.accessToken, this.state.filter, this.state.businessLine, this.state.selectedJourney, roleLocations);
        this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter, this.state.businessLine, this.state.selectedJourney, roleLocations);
        this.props.listLocationMappingGroups(this.props.accessToken);
    } 

    tableHeader(sortKey, label) {
        return <th className="sortable"
                   onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
      this.props.viewCustomAdminDashboardNps(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
      this.props.viewCustomLocationNpsData(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
      this.props.viewAdminDashboardOverviewCustom(this.props.accessToken, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
      this.setState({ filter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
    } 

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
          this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
          this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);         
        });
      } else if(selectedKey==2){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
          this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);       
          this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);   
        });
      } else if(selectedKey==3){
        this.setState({ filter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
          this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
          this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
        });
      } else if(selectedKey==4){
        this.setState({ filter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
          this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
          this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
        });
      } else if(selectedKey==5){
        this.setState({ filter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);
          this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations); 
          this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month ', currentFilter: selectedKey }, () => {
          this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,this.state.roleLocations);
          this.props.viewLocationNpsData(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney, this.state.roleLocations);    
          this.props.viewAdminDashboardOverview(this.props.accessToken, this.state.filter,this.state.businessLine, this.state.selectedJourney,  this.state.roleLocations);
        });
        
      }
    }

    render() {
       return <div className="reportingPages">
            <h1 className="page-header">
                Overall Patient Satisfaction
                    <div className="float-right">
                        <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                        <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                          {this.state.currentFilterName}
                        </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                              <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                              <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                              <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                              <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                              <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                              <Dropdown.Divider/>
                              <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {(this.props.journeys!==null && this.props.journeys.length>0) && <div className="float-right extraRightMargin">
                            <Dropdown
                              title='Choose Journey'
                              id='filterSurvey'
                              drop="down" onSelect={this.handleJourneyFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                 {this.state.selectedJourneyName!=='' ? this.state.selectedJourneyName : ' Choose Journey '}
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                              <Dropdown.Item eventKey={-1}>All Journeys</Dropdown.Item>
                                {Object.values(this.props.journeys).map((d,counter) => {
                                return  <Dropdown.Item eventKey={d.journey_id} key={d.journey_id}>{d.name}</Dropdown.Item>
                              })}
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                      }

                      {(this.props.locationMapping!==null && this.props.locationMapping.length>0) && <div className="float-right extraRightMargin">
                            <Dropdown
                              title='Choose Business Line'
                              id='filterSurvey'
                              drop="down" onSelect={this.handleLocationFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                 {this.state.businessLine!==null ? this.state.businessLine : ' Choose Business Line '}
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                              <Dropdown.Item eventKey={-1}>All Business Lines</Dropdown.Item>
                                {Object.values(this.props.locationMapping).map((d,counter) => {
                                return  <Dropdown.Item eventKey={counter} key={d.location_resource_alt}>{d.location_resource_alt}</Dropdown.Item>
                              })}
                                </Dropdown.Menu>
                            </Dropdown>
                    </div> }                         

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
          </div> }

            </h1>
            <p className="case-list-container stand-out-line float-right">Showing data for {(this.state.businessLine!==null && this.state.businessLine!=='') ? this.state.businessLine + ' - ' : ''} {(this.state.selectedJourneyName!==null && this.state.selectedJourneyName!=='') ? this.state.selectedJourneyName + ' - ' : ''} {this.state.currentFilterName}</p>
            <div className="case-list-container piechart col-md-12">

                  <div className="col-md-4 nps-survey-container float-left">
                   {this.props.npsLoading && <Spinner/>}
                     {this.props.npsData &&  <div>
                        <h5>Patient Satisfaction Score</h5>
                       <PieChart width={350} height={250} id="mainscore">
                       <Pie isAnimationActive={false} data={this.props.npsData} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#8884d8" >
                        {
                          this.props.npsData.map((entry, index) => <Cell key={entry.value} fill={ entry.color }/>)
                        }
                        <Label width={100} position="center">{this.props.npsRating}</Label>
                       </Pie>
                        <Legend layout="vertical" align="right" verticalAlign="middle" />
                       </PieChart> 
                        </div>
                   }
                   {(this.props.npsData && this.props.npsData.length===0) && <p>No data to display.</p>}
                  </div>

                  <div className="col-md-7 nps-survey-container float-right">
                   {this.props.npsLoading && <Spinner/>}
                     {this.props.npsData &&  <div>
                        <h5>NPS Survey Responses</h5>
                            <table className="npsSurveyTable">
                            <tbody>

                                        <tr>
                                            <th>Value</th>
                                            <th>Name</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </tbody>

                            <tbody>
                                        
                                {
                                    this.props.npsData.map(c => {
                                        return <tr className="statDeptContainer actionableRecord" key={c.name}>
                                                <td>{c.name}</td>
                                                <td>{this.renderScoreToName(c.name)}</td>
                                                <td>{c.value}</td>
                                              </tr>
                                    })
                                }  


                            </tbody> 
                                        

                        </table>

                        </div>
                   }
                   {(this.props.npsData && this.props.npsData.length===0) && <p>No data to display.</p>}
                  {this.props.overviewData &&  <div>
                        <h5>Overall Survey Statistics</h5>
                            <table className="npsSurveyTable">
                                  <tbody>

                                       {(this.props.overviewData && this.props.overviewData.completedSurveys[0].count>0 && this.props.overviewData.openedSurveys[0].count>0 || this.props.overviewData.totalSurveys[0].count>0) && <tr>
                                            <th>Statistic Name</th>
                                            <th>Percentage</th>
                                            <th>Quantity</th>
                                        </tr>}
                                    </tbody>

                            <tbody>
                                {(this.props.overviewData && this.props.overviewData.completedSurveys[0].count>0 && this.props.overviewData.openedSurveys[0].count>0 || this.props.overviewData.totalSurveys[0].count>0) && <tr className="statDeptContainer actionableRecord">
                                    <td>Total Sent</td>
                                    <td>100%</td>
                                    <td>{this.props.overviewData.totalSurveys[0].count}</td>
                                </tr> }

                                {(this.props.overviewData && this.props.overviewData.completedSurveys[0].count>0 && this.props.overviewData.openedSurveys[0].count>0 || this.props.overviewData.totalSurveys[0].count>0) && <tr className="statDeptContainer actionableRecord">
                                    <td>Total Opened</td>
                                    <td>{this.props.overviewData.openedSurveys[0].count>0 && <span>{Math.round(100 * (this.props.overviewData.openedSurveys[0].count / this.props.overviewData.totalSurveys[0].count))}% </span>}</td>
                                    <td>{this.props.overviewData.openedSurveys[0].count} </td>
                                </tr> }

                             {(this.props.overviewData && this.props.overviewData.completedSurveys[0].count>0 && this.props.overviewData.openedSurveys[0].count>0 || this.props.overviewData.totalSurveys[0].count>0) && <tr className="statDeptContainer actionableRecord">
                                    <td>Total Completed</td>
                                    <td>{this.props.overviewData.openedSurveys[0].count>0 && <span>{Math.round(100 * (this.props.overviewData.completedSurveys[0].count / this.props.overviewData.openedSurveys[0].count))}%</span>}</td>
                                    <td>{this.props.overviewData.completedSurveys[0].count}</td>
                                </tr> }
                                                            
                             {(this.props.overviewData && this.props.overviewData.surveyTypeData && this.props.overviewData.surveyTypeData.length>0) && <tr className="statDeptContainer actionableRecord">
                                    <td><b>Sent By Text</b></td>
                                    <td>{this.props.overviewData.surveyTypeData[0].total>0 && <span>{Math.round(100 * (this.props.overviewData.surveyTypeData[0].total / this.props.overviewData.surveyTypeData[0].denominator))}%</span>}</td>
                                    <td>{this.props.overviewData.surveyTypeData[0].total}</td>
                                </tr> }

                                {(this.props.overviewData && this.props.overviewData.surveyTypeData && this.props.overviewData.surveyTypeData.length>1) && <tr className="statDeptContainer actionableRecord">
                                    <td><b>Sent By Email</b></td>
                                    <td>{this.props.overviewData.surveyTypeData[1].total>0 && <span>{Math.round(100 * (this.props.overviewData.surveyTypeData[1].total / this.props.overviewData.surveyTypeData[1].denominator))}%</span>}</td>
                                    <td>{this.props.overviewData.surveyTypeData[1].total}</td>
                                </tr> }
                                          
                                
                                {(this.props.overviewData && this.props.overviewData.completedSurveys[0].count==0 && this.props.overviewData.openedSurveys[0].count==0 && this.props.overviewData.totalSurveys[0].count==0) &&
                                  <tr>
                                    <td></td>
                                    <td>No data to display.</td>
                                  </tr>
                                }


                            </tbody> 
                                        
                        </table>
                        {(this.props.overviewData && this.props.overviewData.completedSurveys[0].count>0 && this.props.overviewData.openedSurveys[0].count>0 || this.props.overviewData.totalSurveys[0].count>0) && <small className="float-right">*Slight variance with NPS Responses may occur depending on survey dates</small>}
                        </div>
                   }



                  </div>

                                                     
            </div>
            <div className="case-list-container">
             {this.props.locationNpsData && <h2 className="headerSpacer">Location Specific Information</h2>}
            </div>
        { this.props.locationNpsData && <div>
                                { this.props.locationNpsData.length===0 && <p className="case-list-container">No data for dates provided.</p> }
                                {
                                    this.props.locationNpsData.map((c, index) => {
                                      let inLocation = true;
                                      if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                                        if(this.props.roleLocations!==undefined){
                                          if(!this.props.roleLocations.includes(c[index].location_id.toString())){
                                            inLocation=false;
                                          }
                                        }
                                      }

                                      if(inLocation){
                                        return <div className="case-list-container piechart col-md-12 reportBlockspacer" key={index}><div className="col-md-4 nps-survey-container float-left">
                                           {this.props.locationNpsLoading && <Spinner/>}
                                             <div>
                                                <h5>{c.placeName} Score</h5>
                                               <PieChart width={350} height={250} >
                                               <Pie isAnimationActive={false} data={c} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#8884d8" >
                                                { 

                                                  c.map((entry, index) =>  {
                                                            return <Cell key={entry.value} fill={ entry.color }/>
                                                    })
                                                }
                                                <Label width={100} position="center">{c.scoreText}</Label>
                                               </Pie>
                                                <Legend layout="vertical" align="right" verticalAlign="middle" />
                                               </PieChart> 
                                                </div>
                                          </div> <div className="col-md-7 nps-survey-container float-right">

                                                <h5>NPS Survey Responses</h5>
                                                <table className="npsSurveyTable">
                                                    <tbody>

                                                                <tr>
                                                                    <th>Value</th>
                                                                    <th>Name</th>
                                                                    <th>Quantity</th>
                                                                </tr>
                                                            </tbody>

                                                    <tbody>
                                                                
                                                        {
                                                            c.map(d => {
                                                                return <tr className="statDeptContainer actionableRecord" key={d.name}>
                                                                        <td>{d.name}</td>
                                                                        <td>{this.renderScoreToName(d.name)}</td>
                                                                        <td>{d.value}</td>
                                                                      </tr>
                                                            })
                                                        }  

                                                    </tbody> 
                                                                

                                                </table>

                                          </div></div>
                                      }

                                    })
                                }  
             {(!this.props.npsLoading && !this.props.locationNpsLoading && (this.props.locationNpsData!==undefined && this.props.locationNpsData.length>0)) && <div className="case-list-container"><ExportPatSatReportCSV csvData={this.props.npsData} locationData={this.props.locationNpsData} journeyName={this.state.selectedJourneyName} fileName='PatientSatisfactionReport.csv' /></div> }      
            </div>
        }

        </div>; 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        npsData: state.npsCpt.npsData,
        npsLoading: state.npsCpt.npsLoading,
        npsRating: state.npsCpt.npsRating,
        locationNpsData: state.locationNpsCpt.locationNpsData,
        locationNpsLoading: state.locationNpsCpt.locationNpsLoading,
        locationMapping: state.locationMappingGroupList.mappings,
        overviewLoading: state.overviewCpt.overviewLoading,
        overviewData: state.overviewCpt.overviewData,
        journeys: state.journeyList.journeys,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewAdminDashboardNps: (...params) => dispatch(viewAdminDashboardNps(...params)),
        viewLocationNpsData: (...params) => dispatch(viewLocationNpsData(...params)),
        viewCustomLocationNpsData: (...params) => dispatch(viewCustomLocationNpsData(...params)),
        viewCustomAdminDashboardNps: (...params) => dispatch(viewCustomAdminDashboardNps(...params)),
        viewAdminDashboardOverview: (...params) => dispatch(viewAdminDashboardOverview(...params)),
        viewAdminDashboardOverviewCustom: (...params) => dispatch(viewAdminDashboardOverviewCustom(...params)),
        listLocationMappingGroups: (...params) => dispatch(listLocationMappingGroups(...params)),
        listJourneys: (...params) => dispatch(listJourneys(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NpsReport);
