import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateCampaign, viewCampaign, listGroups } from "../../actions";
import DeleteCampaignOverlay from "./../Overlays/DeleteCampaignOverlay";

class EditCampaign extends Component {

    componentDidMount() {
        return this.props.viewCampaign(this.props.tvClient, this.props.match.params.documentId);
    }

    constructor(props) {
        
        super(props);
        this.props.listGroups(this.props.tvClient);
        this.state = {
            errors: { 'campaignName': false, 'selectedGroup': false },
            editLoading: true,
            selectedGroup: 0,
            changed: false
        };

    }

    deleteCampaign(e){
        console.log('deleting this campaign');
    }

    editCampaign(e) {
        e.preventDefault();

        if(!this.campaignName.value){
            this.setState({ errors: {'campaignName': true}});
        } else{
           var selectedGroup = this.state.selectedGroup[0];
           if(selectedGroup===undefined || selectedGroup===null){
                selectedGroup = this.props.campaignData.group_list_id;
           }
           this.props.updateCampaign(
                this.props.tvClient,
                this.props.match.params.documentId,
                this.campaignName.value,
                selectedGroup
            );            
        }        
    }


    handleGroupList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            selectedGroup: value
        });
    }   

    render() {
        return <div>
            <h1 className="page-header">
                Edit Campaign
                <small>Please update any of the following campaign information <DeleteCampaignOverlay campaignDetail={this.props.match.params.documentId} /> </small>
            </h1>
            
            {this.state.viewingCampaign && <Spinner/>}

            {this.props.campaignData && <Form onSubmit={this.editCampaign.bind(this)} className="new-case-form">

                {this.props.viewCampaignError && <Alert variant="danger">
                    {this.props.viewCampaignError.message}
                </Alert>}

                <FormGroup controlId="locationName">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="Text Blast..."  defaultValue={this.props.campaignData.name} ref={ref => this.campaignName = ref}/>
                    {this.state.errors.campaignName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="selectedGroup">
                    <FormLabel>Choose Group *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={this.props.campaignData.group_list_id} onChange={this.handleGroupList.bind(this)}>
                        <option value="0">Choose a group</option>
                        {Object.values(this.props.groups).map(d => {
                            return <option value={d.uid} key={d.uid}>{d.name}</option>
                        })}
                    </FormControl>                    
                    {this.state.errors.selectedGroup && <Alert variant="danger">
                        A group is required.
                    </Alert>}
                </FormGroup>                

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingCampaign} className="pull-left">
                        Update
                    </Button>
                </FormGroup>

            </Form>}

        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingCampaign: state.viewCampaign.viewingCampaign,
        viewCampaignError: state.viewCampaign.viewCampaignError,
        campaignData: state.viewCampaign.campaignData,
        tvClient: state.login.tvClient,
        groups: state.groupList.groups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewCampaign: (...params) => dispatch(viewCampaign(...params)),
        listGroups: (...params) => dispatch(listGroups(...params)),
        updateCampaign: (...params) => dispatch(updateCampaign(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);

