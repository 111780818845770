import React, {Component} from "react";
import {connect} from "react-redux";
import localizedData from "./../Localization/Localization";
import {surveyBegin, trackReviewReferClicks, rewardPrizeTickets, restartPatientSurvey, beginNewSurvey, getDeptStaff, cachedResults, executePersonalSurvey, updateSurvey, createPatient, getUserDetails} from "../../survey-actions";
import "react-spinner/react-spinner.css";
import ReactTurntable from "react-turntable";
import "react-turntable/assets/index.css";
// Make sure moment.js has the required locale data
import {Modal, Button} from "react-bootstrap";
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import "./../ServeSurvey.scss";

class ServeSurvey extends Component {

    constructor(props){
        super(props);

        let language = 'EN';
        if(props.location && props.location.query && props.location.query.lang!==null && props.location.query.lang !== undefined){
          if(props.location.query.lang.toUpperCase()==='ES' || props.location.query.lang.toUpperCase() === 'EN' || props.location.query.lang.toUpperCase() === 'FR'){
            localizedData.setLanguage(props.location.query.lang.toUpperCase());
            language = localizedData.getLanguage();
          }
        }

        var prizes = ['1 Raffle Entry', '2 Raffle Entries', '1 Raffle Entry', '2 Raffle Entries', '3 Raffle Entries', '2 Raffle Entries', '2 Raffle Entries','1 Raffle Entry']; 
        this.saveSurveyStatus = this.saveSurveyStatus.bind(this);
        this.executeSurvey = this.executeSurvey.bind(this);
        
        this.state = {
          showNextButton: true,
          currentQuestion: 0,
          showDepartmentJourneyScreen: false,
          showStaffJourneyScreen: false,
          selectedDepartments: [],
          showLocationBar: false,
          selectedStaff: [],
          currentStaffNumber: null,
          currentStaffDetail: {},
          currentDeptNumber: null,
          currentDeptDetail: {},
          currentDeptStaffList: null,
          completedSurvey: false,
          notYourSurvey: false,
          confirmOpen: false,
          confirmRating: 1,
          staff: null,
          restartedSurvey: false,
          executed: false,
          cyclingStaff: false,
          cyclingDept: false,
          cachedStaffRequests : [],
          fullyLoaded: false,
          syncSurvey: false,
          newSurvey: false,
          spun: false,
          intakeResult: { badges: [] },
          prizeWinnings: null,
          showReviewPage: false,
          savedSurvey: { nps: null, selectedDay: null, departments: [], staff: [], completed: false},
          errors: {},
          language: language,
          options: {
              prizes,
              width: 500,
              height: 500,
              primaryColor: "#145a8c",
              secondaryColor: "#3a8390",
              fontStyle:{
                  color:"#fff",
                  size:"15px",
                  fontVertical:true,
                  fontWeight:"bold",
                  fontFamily:"sans-serif"
              },
              speed: 800,                  
              duration: 5000,               
              clickText:"Spin"
          }

        };
        this.props.surveyBegin(this.props.tvClient, this.props.match.params.survey_url).then(() => {

            if(this.props.surveyData.existingSurvey!==undefined){
                                  //console.log(this.props.surveyData);
                if(this.props.surveyData.language!==undefined&&this.props.surveyData.language!==null){
                  localizedData.setLanguage(this.props.surveyData.language);
                  this.setState({ showReviewPage: this.props.surveyData.incentiveWheel, savedSurvey: this.props.surveyData.existingSurvey, fullyLoaded: true, language: this.props.surveyData.language });
                } else{
                  this.setState({ showReviewPage: this.props.surveyData.incentiveWheel, savedSurvey: this.props.surveyData.existingSurvey, fullyLoaded: true });  
                }
            } else{
                var newLanguage = this.props.surveyData.urlInfo[0].survey_lang;
                if(newLanguage !== undefined&& newLanguage !== null){
                  localizedData.setLanguage(newLanguage);
                  this.setState({language: newLanguage});
                }
                if(this.props.surveyData.recentlyCompleted===true){
                  this.setState({ completedSurvey: true });
                } else if(this.props.surveyData.notYourSurvey===true){
                  this.setState({ notYourSurvey: true });
                }else{
                  var currentSurvey = this.state.savedSurvey;
                  currentSurvey.selectedDay = new Date(this.props.surveyData.urlInfo[0].created);
                  this.setState({ showReviewPage: this.props.surveyData.incentiveWheel, fullyLoaded: true, newSurvey: false, savedSurvey: currentSurvey });  
                }
                
            }
        });
    }
 
    startPrizeDrawing(){
      var els = document.getElementsByClassName('react-turntable-section-btn');
      for(var i = 0; i<els.length; i++){
        els[i].classList.add('removeItem');
      }
      return true;
    }

    finishPrizeDrawing(prize){
      if(this.state.spun===true && (this.state.savedSurvey.nps === 4 || this.state.savedSurvey.nps === 5)){
        this.state.setState({ showReviewPage: true });
        //this.state.setState({ showReviewPage: false });
      }
      else{
        let finalPrize = localizedData.you_won + ' ' + prize + '!';
        this.setState({ spun: true, prizeWinnings: finalPrize });
        this.props.rewardPrizeTickets(this.props.tvClient, prize, this.props.surveyData.urlInfo[0].url_id).then(()=>{
          //if(this.state.savedSurvey.nps === 4 || this.state.savedSurvey.nps === 5){
            this.setState({showReviewPage: true});  
            //this.setState({ showReviewPage: false, completedSurvey: true });
          //} else{
            //this.setState({ showReviewPage: false, completedSurvey: true });
          //}
        });
      }
    }

    executeSurvey(){
      if(this.state.executed===false){
        this.props.executePersonalSurvey(this.props.tvClient, this.props.surveyData.urlInfo[0]).then(()=>{
          this.setState({executed: true }); 
        }); 
      }
    }

    saveSurveyStatus(){
        if(this.state.syncSurvey){
            this.props.updateSurvey(this.props.tvClient, this.state.savedSurvey, this.props.surveyData.urlInfo[0], this.state.currentQuestion, false, localizedData.getLanguage()); 
            this.setState({syncSurvey: false, newSurvey: false}); 
        }
    }

   updateLocalizedWheelText(){
    if(localizedData.getLanguage()==='ES'){
      // Update the Spin Button Text
      var spinButton = document.getElementsByClassName('react-turntable-section-btn');
      for(var i=0; i<spinButton.length; i++){
        spinButton[i].innerText='Girar';
        spinButton[i].innerHTML='Girar';
      }
    } 
   } 

  updateReviewInfo(site){
    console.log('clicked on the review');
    /* onClick={()=> {this.updateReviewInfo.bind(this,"google")}} */
  }

   restartSurvey(e){
    this.props.restartPatientSurvey(this.props.tvClient, this.props.surveyData.urlInfo[0], this.props.surveyData).then( () => {
      this.setState({ restartedSurvey: true, fullyLoaded: true });
    });
   }

    handleNpsClick(rating){
      if(rating.toString()==='1' || rating.toString()==='2'){
          this.setState({ confirmOpen: true, confirmRating: rating });
      } else{
        let survey = this.state.savedSurvey;
        let sync = false;
        let originalNps = this.state.savedSurvey.nps;
        survey.nps = rating;
        survey.completed = true;

        if(originalNps!==survey.nps){
            sync = true;
        }

        var locationbar = false;
        
        for(var i=0; i<survey.staff.length; i++){
          if(survey.staff[i].reviews!==undefined && survey.staff[i].reviews!==null && survey.staff[i].reviews.google!==undefined && survey.staff[i].reviews.google!==null){
            locationbar=true;
            break;
          }
        }

        this.setState({ syncSurvey: sync, savedSurvey: survey, showLocationBar: locationbar }, () => { this.advanceSurveyFrom(this); this.updateLocalizedWheelText(); window.parent.postMessage('SURVEY_COMPLETE', '*'); });
      }

    }

  handleClose(e) {
    this.setState({ confirmOpen: false });
  }

  handleConfirm(e){
    let survey = this.state.savedSurvey;
    let sync = false;
    let originalNps = this.state.savedSurvey.nps;
    survey.nps = this.state.confirmRating;
    survey.completed = true;


        var locationbar = false;
        console.log(survey.staff.length);
        for(var i=0; i<survey.staff.length; i++){
                    if(survey.staff[i].reviews!==undefined && survey.staff[i].reviews!==null && survey.staff[i].reviews.google!==undefined && survey.staff[i].reviews.google!==null){
            locationbar=true;
            break;
          }
        }

    if(originalNps!==survey.nps){
        sync = true;
    }

    this.setState({ syncSurvey: sync, savedSurvey: survey }, () => { this.advanceSurveyFrom(this); this.updateLocalizedWheelText(); });          
  }    

    retrieveUserDetails(){
        this.props.getUserDetails(this.props.surveyData).then(() => {
            var theQuestion = this.props.surveyData.questionInfo.length-2;
            this.setState({ fullyLoaded: true, currentQuestion: theQuestion });
        });
    }

    localizeDepartment(selectedDept){
      let finalName = selectedDept.name;
      if(localizedData.getLanguage()==='ES'){
        if(selectedDept.name_locale_es!==null && selectedDept.name_locale_es!==undefined){
          finalName = selectedDept.name_locale_es;
        }
      }

      return finalName;
    }

    chooseDepartments(selectedDept, e){
         e.stopPropagation()
        var currentDepartments = this.state.savedSurvey.departments;
        var originalDepts = this.state.savedSurvey.departments.slice(); 
        let sync = false;
        var foundIt = false;
        for (var i = 0, l = currentDepartments.length; i < l; i++) {
            if(currentDepartments[i].key === selectedDept.key) {
              foundIt=true;
              e.target.classList.remove("activeDept");
              e.target.parentElement.classList.remove("addCheckMark");
              currentDepartments.splice(i, 1);
              break;
            }
        }   

        if(!foundIt){
            e.target.classList.add("activeDept");
            e.target.parentElement.classList.add("addCheckMark");
            currentDepartments.push(selectedDept);
        }

        if(currentDepartments!==originalDepts){
            sync = true;
        }

        var currentSurvey = this.state.savedSurvey;
        currentSurvey.departments = currentDepartments;
        this.setState({
            savedSurvey: currentSurvey,
            syncSurvey: sync
        });

    }

    chooseStaff(selectedStaff, e){
        e.stopPropagation();
        var originalStaff= this.state.savedSurvey.staff.slice(); 
        let sync = false;
        var currentStaff = this.state.savedSurvey.staff;
        var foundIt = false;
        for (var i = 0, l = currentStaff.length; i < l; i++) {
            if(currentStaff[i].staff_id === selectedStaff.staff_id) {
              foundIt=true;
              e.target.classList.remove("activeDept");
              e.target.parentElement.classList.remove("addCheckMark");
              currentStaff.splice(i, 1);
              break;
            }
        }   

        if(!foundIt){
            e.target.classList.add("activeDept");
            e.target.parentElement.classList.add("addCheckMark");
            currentStaff.push(selectedStaff);
        }

        if(currentStaff!==originalStaff){
            sync = true;
        }

        var currentSurvey = this.state.savedSurvey;
        currentSurvey.staff = currentStaff;        

        this.setState({
            savedSurvey: currentSurvey,
            syncSurvey: sync
        });

    }

    chooseIntakeEndorsement(selectedEndorsement, person, e){
        e.stopPropagation();
        if(person.badges!==undefined){
            var foundOne = false;
            for (var j = person.badges.length - 1; j >= 0; j--) {
                if(person.badges[j].endorsement_id===selectedEndorsement.endorsement_id){
                    // remove it
                    foundOne = true;
                    person.badges.splice(j, 1);
                    this.setState({ intakeResult: person });
                    e.target.classList.remove("activeDept");
                    e.target.parentElement.classList.remove("addCheckMark");
                    break;
                }
            }
            if(!foundOne){
                person.badges.push(selectedEndorsement);
                this.setState({ intakeResult: person });
                e.target.classList.add("activeDept");
                e.target.parentElement.classList.add("addCheckMark");
            }
            
        }
        else{
            person.badges=[];
            person.badges.push(selectedEndorsement);
            this.setState({ intakeResult: person });
            e.target.classList.add("activeDept");
            e.target.parentElement.classList.add("addCheckMark");
        } 
    }

    chooseStaffEndorsement(selectedEndorsement, person, e){
        e.stopPropagation();
        let originalStaffEndorsements = this.state.savedSurvey.staff.splice();

        for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
            if(this.state.savedSurvey.staff[i].staff_id===person.staff_id){
                if(person.badges!==undefined){
                    var foundOne = false;
                    for (var j = person.badges.length - 1; j >= 0; j--) {
                        if(person.badges[j].endorsement_id===selectedEndorsement.endorsement_id){
                            // remove it
                            foundOne = true;
                            person.badges.splice(j, 1);
                            this.setState({ currentStaffDetail: person });
                            e.target.classList.remove("activeDept");
                            e.target.parentElement.classList.remove("addCheckMark");
                            break;
                        }
                    }
                    if(!foundOne){
                        person.badges.push(selectedEndorsement);
                        this.setState({ currentStaffDetail: person });
                        e.target.classList.add("activeDept");
                        e.target.parentElement.classList.add("addCheckMark");
                    }
                    
                }
                else{
                    person.badges=[];
                    person.badges.push(selectedEndorsement);
                    this.setState({ currentStaffDetail: person });
                    e.target.classList.add("activeDept");
                    e.target.parentElement.classList.add("addCheckMark");
                }
                break;
            }
        }

        if(originalStaffEndorsements!==this.state.savedSurvey.staff){
            this.setState({ syncSurvey: true })
        }
    }

    createNewSurvey(){
        this.props.beginNewSurvey(this.props.surveyData).then(() => {
            this.setState({ fullyLoaded: true, newSurvey: true });
        });
    }

    chooseDepartmentEndorsement(selectedEndorsement, department, e){
        e.stopPropagation();
        let originalDeptEndorsements = this.state.savedSurvey.departments.splice();
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
            if(this.state.savedSurvey.departments[i].key===department.key){
                if(department.badges!==undefined){
                    var foundOne = false;
                    for (var i = department.badges.length - 1; i >= 0; i--) {
                        if(department.badges[i].endorsement_id===selectedEndorsement.endorsement_id){
                            // remove it
                            foundOne = true;
                            department.badges.splice(i, 1);
                            this.setState({ currentDeptDetail: department });
                            e.target.classList.remove("activeDept");
                            e.target.parentElement.classList.remove("addCheckMark");
                            break;
                        }
                    }
                    if(!foundOne){
                        department.badges.push(selectedEndorsement);
                        this.setState({ currentDeptDetail: department });
                        e.target.classList.add("activeDept");
                        e.target.parentElement.classList.add("addCheckMark");
                    }
                    
                }
                else{
                    department.badges=[];
                    department.badges.push(selectedEndorsement);
                    this.setState({ currentDeptDetail: department });
                    e.target.classList.add("activeDept");
                    e.target.parentElement.classList.add("addCheckMark");
                }
                break;
            }
        }
        if(originalDeptEndorsements!==this.state.savedSurvey.departments){
            this.setState({ syncSurvey: true })
        }
    }


    generateClassNames(endorsement, entity){
        if(entity.badges!==undefined){
            var foundIt = false;
            for (var i = entity.badges.length - 1; i >= 0; i--) {
                if(entity.badges[i].endorsement_id===endorsement.endorsement_id){
                    foundIt=true;
                    break;
                }
            }
            if(foundIt){
                return true;
            }
            else{
                return false;
            }
        } else{
            return false;
        }
    }


    findNextPage(){
        let newSurveyData = this.props.surveyData;
        
        for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
            if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
                newSurveyData.currentPage = this.props.surveyData.questionInfo[i+1].page_id;
                newSurveyData.firstPage = false;
                break;
            }
        }

        return newSurveyData;
    }

    findPreviousPage(){
        let newSurveyData = this.props.surveyData;
        
        for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
            if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
                newSurveyData.currentPage = this.props.surveyData.questionInfo[i-1].page_id;
                if(this.props.surveyData.questionInfo[i-2]===undefined){
                    newSurveyData.firstPage = true;
                    this.setState({ showDepartmentJourneyScreen: false, showStaffJourneyScreen: false })
                }
                break;
            }
        }

        return newSurveyData;
    }

    handleStaffFeedbackChange(event){
        //event.stopPropagation();
        if(event.target.value!==undefined && event.target.value!==null){
            var person = this.state.currentStaffDetail;
            person.feedback = event.target.value;
            this.setState({ currentStaffDetail: person, syncSurvey: true });            
        }
    }

    handleDeptFeedbackChange(event){
        //event.stopPropagation();
        if(event.target.value!==undefined && event.target.value!==null){
            var dept = this.state.currentDeptDetail;
            dept.feedback = event.target.value;
            this.setState({ currentDeptDetail: dept, syncSurvey: true });            
        }
    }

    isDepartmentSelected(dept){
        var isSelected = false;
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
           if(this.state.savedSurvey.departments[i].key === dept){
            isSelected=true;
           }
        }
        return isSelected;
    }

    isStaffSelected(staff){
        var isSelected = false;
        for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
           if(this.state.savedSurvey.staff[i].staff_id === staff){
            isSelected=true;
           }
        }
        return isSelected;
    }

    staffCached(){
        var strDepts = '';
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
            strDepts += this.state.savedSurvey.departments[i].key;
        }  

        var foundIt = false;

        for (var j = this.state.cachedStaffRequests.length - 1; j >= 0; j--) {
            if(this.state.cachedStaffRequests[j].depts===strDepts){
                foundIt = true;
                break;
            }
        }
    

        return foundIt;

    }

    backwardDepartmentJourney(currentStep, from){

          var orderedDepts = this.props.surveyData.urlInfo[0].dept_list;
          var departmentDetails = this.props.surveyData.departments;
         
          // Find where we are in the list
          var currentJourneyDept = this.state.currentDeptStaffList.details.key;
          var currentOrderedPos = 0;
          for(var i=0; i<orderedDepts.length; i++){
            if(currentJourneyDept.toString()===orderedDepts[i].toString()){
              currentOrderedPos = i;
              break;
            }
          }

          if(currentOrderedPos===0 && from!=='staff'){
            // at the beginning so next one goes to page 1
            this.setState({
                surveyData: this.findPreviousPage(),
                currentQuestion: this.state.currentQuestion-1,
                showDepartmentJourneyScreen: false,
                showStaffJourneyScreen: false
            }); 
          }
          else if(currentOrderedPos===0 && from==='staff'){
            this.setState({
                showDepartmentJourneyScreen: true,
                showStaffJourneyScreen: false
            });             
          } else if(from==='staff'){
            // Should set the department detail here 
            this.setState({ showDepartmentJourneyScreen: true, showStaffJourneyScreen: false });
          } else{
            this.setState({ showDepartmentJourneyScreen: true, showStaffJourneyScreen: false, 'currentDeptStaffList': this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos-1]] }, ()=>{
              this.backwardStaffEndorsementJourney(currentStep, 'last');
            });

          }
    }

    submitIntakeForm(){
        var currentSurvey = this.state.savedSurvey;
        var intakeBadges = (this.state.intakeResult.badges!==undefined && this.state.intakeResult.badges.length>0) ? false : true;
        var finalIntakeData = this.state.intakeResult;
        if(this.state.intakeResult.badges!==undefined){
            if(this.state.intakeResult.badges.length===1){
                if(this.state.intakeResult.badges[0].endorsement_id===999){
                    intakeBadges=true;
                    finalIntakeData = { badges: []};
                }
            } else{
                var i=0;
                var found=false;
                for(i; i<this.state.intakeResult.badges.length;i++){
                    if(this.state.intakeResult.badges[i].endorsement_id===999){
                        found=true;
                        break;
                    }
                }
                if(found){
                    this.state.intakeResult.badges.splice(i,1);
                    finalIntakeData.badges = this.state.intakeResult.badges;
                }
                
            }

        }    
    
        currentSurvey.intakeResults = {
            'needIntake': false,
            'intakeStatus': intakeBadges,
            'intakeData': finalIntakeData
        };

        this.setState({ syncSurvey: true, intakeSubmitted: true, savedSurvey: currentSurvey, intakeResult: finalIntakeData },()=>{
            this.saveSurveyStatus();
        });
    }

    advanceDepartmentJourney(currentStep){
          var orderedDepts = this.props.surveyData.urlInfo[0].dept_list;
          var departmentDetails = this.props.surveyData.departments;
          //Need to configure the state for current details

          if(this.state.currentDeptStaffList===null || this.state.surveyData.firstPage===true){
              // First time and first element
              var theStaffList = [];
              var i = 0;
              for(var j=0; j<departmentDetails.length; j++){
                if(departmentDetails[j].key.toString()===orderedDepts[i].toString()){
                  this.props.surveyData.staffForDepartments[orderedDepts[i]].details = departmentDetails[j];
                  break;
                }
              }

              this.setState({ surveyData: this.findNextPage(), 
                              currentQuestion: this.state.currentQuestion+1, 
                              'showDepartmentJourneyScreen': true, 
                              'showStaffJourneyScreen': false,
                              'currentDeptStaffList': this.props.surveyData.staffForDepartments[orderedDepts[i]] });
              this.executeSurvey();

          }
          else{
            // Find where we are in the list
            var currentJourneyDept = this.state.currentDeptStaffList.details.key;
            var currentOrderedPos = 0;
            for(var i=0; i<orderedDepts.length; i++){
              if(currentJourneyDept.toString()===orderedDepts[i].toString()){
                currentOrderedPos = i;
                break;
              }
            }

            if(currentOrderedPos+1 < orderedDepts.length){

              var deptStaff = [];
              if(this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos+1]] !== undefined){
                  // no staff are listed for this department
                 
                for(var k=0; k<departmentDetails.length; k++){
                  if(departmentDetails[k].key.toString()===orderedDepts[currentOrderedPos+1].toString()){
                    this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos+1]].details = departmentDetails[k];
                    break;
                  }
                }

                deptStaff = this.props.surveyData.staffForDepartments[orderedDepts[currentOrderedPos+1]];
                this.setState({ 'showDepartmentJourneyScreen': true, 'showStaffJourneyScreen': false, 'currentDeptStaffList': deptStaff });
              }
              else{
                deptStaff = [];
                for(var j=0; j<departmentDetails.length; j++){
                  if(departmentDetails[j].key.toString()===orderedDepts[currentOrderedPos+1].toString()){
                    deptStaff.details = departmentDetails[j];
                    break;
                  }
                 }    

                 if(deptStaff.details.display){

                      var currentDepartments = this.state.savedSurvey.departments;
                      var originalDepts = this.state.savedSurvey.departments.slice(); 
                      let sync = false;

                      if(currentDepartments.length===0){
                        sync = true;
                        currentDepartments.push(deptStaff.details);
                      }

                      var currentSurvey = this.state.savedSurvey;
                      currentSurvey.departments = currentDepartments;

                     this.setState({
                        currentDeptDetail: deptStaff.details,
                        showStaffJourneyScreen: false,
                        showDepartmentJourneyScreen: false,
                        savedSurvey: currentSurvey,
                        syncSurvey: sync,
                        surveyData: this.findNextPage(),
                        currentQuestion: this.state.currentQuestion+1});

                 }
              }

            }             
            this.saveSurveyStatus();
          }

    }

    advanceStaffEndorsementsJourney(currentStep, from){

        var originalStaff= this.state.savedSurvey.staff.slice();
        var currentStaffIs = this.state.currentStaffDetail;
        var currStaffOrderNumber = -1;

        var allDepartmentStaff = [];
        for(var i=0; i<originalStaff.length; i++){
          if(originalStaff[i].department_id.toString()===this.state.currentDeptStaffList.details.key){
            allDepartmentStaff.push(originalStaff[i]);
          }
        }

        for(var j=0; j<allDepartmentStaff.length; j++){
          if(currentStaffIs.staff_id!==undefined){
              if(currentStaffIs.staff_id===allDepartmentStaff[j].staff_id){
                currStaffOrderNumber = j+1;
              }
            }
        }

        if(from==='first' && allDepartmentStaff.length!==0){
            this.setState({ currentStaffDetail: allDepartmentStaff[0], showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        } else if(currStaffOrderNumber>=allDepartmentStaff.length || allDepartmentStaff.length===0){
          // Means that we need to go to the next department
          this.advanceDepartmentJourney(currentStep);
        }
        else{
          var newStaffToShow = (currentStaffIs.staff_id!==undefined) ? allDepartmentStaff[currStaffOrderNumber] : allDepartmentStaff[0];
          this.setState({ currentStaffDetail: newStaffToShow, showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        }

        window.scrollTo(0,0);

    }

    backwardStaffEndorsementJourney(currentStep, from){

        if(from==='facility'){

          this.setState({
            surveyData: this.findPreviousPage(),
            currentQuestion: this.state.currentQuestion-1
          })

        }
        var originalStaff= this.state.savedSurvey.staff.slice();
        var currentStaffIs = this.state.currentStaffDetail;
        var currStaffOrderNumber = -1;

        var allDepartmentStaff = [];
        for(var i=0; i<originalStaff.length; i++){
          if(originalStaff[i].department_id.toString()===this.state.currentDeptStaffList.details.key){
            allDepartmentStaff.push(originalStaff[i]);
          }
        }

        for(var j=0; j<allDepartmentStaff.length; j++){
           if(currentStaffIs.staff_id!==undefined){
              if(currentStaffIs.staff_id===allDepartmentStaff[j].staff_id){
                currStaffOrderNumber = j-1;
              }
            }
        }

        if( (from==='last' || from==='facility') && allDepartmentStaff.length!==0){
          this.setState({ currentStaffDetail: allDepartmentStaff[allDepartmentStaff.length-1], showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        } else if(currStaffOrderNumber<0 || allDepartmentStaff.length===0){
          // Means that we need to go to the last department
          this.backwardDepartmentJourney(currentStep, 'staff');
        }
        else{
          var newStaffToShow = (currentStaffIs.staff_id!==undefined) ? allDepartmentStaff[currStaffOrderNumber] : allDepartmentStaff[0];
          this.setState({ currentStaffDetail: newStaffToShow, showDepartmentJourneyScreen: false, showStaffJourneyScreen: true });
        }
        window.scrollTo(0,0);
    }


    advanceSurveyFrom(currentStep, requirements){

        if(requirements==='staff'){
            var strDepts = '';
            for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
                strDepts += this.state.savedSurvey.departments[i].key;
            }

            if(!this.staffCached()){
                this.props.getDeptStaff(this.props.tvClient, this.state.savedSurvey.departments).then((response)=> {
                    var newRequest = { 'depts': strDepts, 'staff': this.props.staffData };
                    var currStaffRequests = this.state.cachedStaffRequests;
                    currStaffRequests.push(newRequest);
                    this.setState({ cachedStaffRequests: currStaffRequests });
                });                
            }
            else{
                for (var z = this.state.cachedStaffRequests.length - 1; z >= 0; z--) {
                    if(this.state.cachedStaffRequests[z].depts === strDepts){
                        this.props.cachedResults(this.state.cachedStaffRequests[z].staff);
                    }
                }
            }

        } 

        this.setState({
            surveyData: this.findNextPage(),
            currentQuestion: this.state.currentQuestion+1
        });

        if(requirements!=="submit"){
            this.saveSurveyStatus();    
        }
        
    }


    backwardSurveyFrom(currentStep){
        this.setState({
            surveyData: this.findPreviousPage(),
            currentQuestion: this.state.currentQuestion-1
        }); 
    }

    trackReviewReferLink(param, ev){
        if(param==='refer' || param==='google' || param==='google-staff' || param==='healthgrades' || param==='facebook' || param==='yelp'){
            this.props.trackReviewReferClicks(this.props.tvClient,param,this.props.match.params.survey_url);
        }
    }

    changeLocale(ev, param){
        localizedData.setLanguage(param);
        this.setState({language: param});
    }

    render() {
        return  <div className="container actualSurvey">

      {this.props.surveyData && (this.state.fullyLoaded && (this.props.surveyData.needIntake===true&&!this.state.intakeSubmitted===true)) ?  <div> <div className="navbar row text-center firstPage">
      </div><div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img alt="" className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img alt="" className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <p className="surveyPadTop"><b>{localizedData.instruction_line_1}.</b><br/>{localizedData.intake_instruction_line_1}</p>
          <p className="intakeBadgeText">{localizedData.intake_choose_badges}</p>

            <div className="badgeSelector intakeForm">
                    {
                        this.props.surveyData.intakeEndorsements.map(c => {
         

                            var localeValue = localizedData[c.localization_key];
                            if(c.localization_options!==undefined && c.localization_options!==null){
                              let tempValue = c.localization_options[this.state.language];
                              if(tempValue!==undefined){
                                localeValue = tempValue;
                              }
                            }

                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className={"badge-checkbox" + (this.generateClassNames(c, this.state.intakeResult) ? ' addCheckMark' : '')}>
                                      <img alt="" onClick={(ev) => this.chooseIntakeEndorsement(c, this.state.intakeResult, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.intakeResult) ? ' activeDept' : '')} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p>{localeValue}</p>
                                    </label>
                                  </div>
                        
                          
                        })
                    }  
                    <div className="nopad isBadge text-center" key={999}>
                        <label className={"badge-checkbox" + (this.generateClassNames({'endorsement_id': 999}, this.state.intakeResult) ? ' addCheckMark' : '')}>
                          <img alt="" onClick={(ev) => this.chooseIntakeEndorsement({'endorsement_id': 999}, this.state.intakeResult, ev) } className={"img-responsive" + (this.generateClassNames({'endorsement_id': 999}, this.state.intakeResult) ? ' activeDept' : '')} src={process.env.PUBLIC_URL + '/endorsement_assets/metexpectations.png'}/>
                          <p>{localizedData.intake_no_symptoms}</p>
                        </label>
                    </div>
            </div>

          <div className="container inlineNextButton intakeSubmit"> <button type="button" disabled={this.state.intakeResult.badges.length===0} onClick={ (ev) => this.submitIntakeForm(this)} className="btn btn-primary nextBtn employerBtn">{localizedData.submit}></button></div>
          <div className="row cardInstructions text-right" ><p>Language: <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'EN')}>English</button> / <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'ES')}>Español</button></p></div>
                    
       </div>

       </div> : null
   }


      {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-1" && (this.props.surveyData.needIntake!==true||this.state.intakeSubmitted===true)) ?  <div> <div className="navbar row text-center firstPage">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.backwardSurveyFrom.bind(this)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
      </div><div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img alt="" className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img alt="" className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <p className="surveyPadTop">{localizedData.instruction_line_1}!</p>
          {this.props.surveyData.intakeForm && <p><b>{localizedData.checkin_complete}</b> {localizedData.intake_result} <b>{this.state.savedSurvey.intakeResults.intakeStatus===true ? 'no' : ''}</b> {localizedData.intake_badges}</p>}
          <p>{localizedData.instruction_line_3}</p>
          <p>{localizedData.thank_you}</p>
          
          <div className="row cardInstructions text-right" ><p>Language: <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'EN')}>English</button> / <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'ES')}>Español</button></p></div>
                    <div className="container inlineNextButton"> {this.props.surveyData && (this.state.showNextButton) ? <button type="button" onClick={ (ev) => this.advanceDepartmentJourney(this)} className="btn btn-primary nextBtn employerBtn">{localizedData.next}></button> : null }</div>
       </div>

       </div> : null
   }


      {this.props.surveyData && (this.state.fullyLoaded && this.state.showDepartmentJourneyScreen) ?  <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.backwardDepartmentJourney.bind(this, null)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
        <p>Akeso Occupational Health</p>       
      </div><div className="row cardholder staffList">
          <h5 className="surveyPageTitle">{this.localizeDepartment(this.state.currentDeptStaffList.details)}</h5> 
          <p className="surveyPageInstruction">{localizedData[this.props.surveyData.questionInfo[this.state.currentQuestion].page_id]}</p> 
            { (this.state.currentDeptStaffList!==null) && this.state.currentDeptStaffList.map(c => {

                            return  <div className="nopad col-xs text-center" key={c.staff_id}>
                                        <label className={"image-checkbox" + (this.isStaffSelected(c.staff_id) ? ' addCheckMark' : '')}>
                                          <img alt={c.name} onClick={(ev) => this.chooseStaff(c, ev) } className={"img-responsive" + (this.isStaffSelected(c.staff_id) ? ' activeDept' : '')} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid} />
                                          <p className="sDeptName">{c.title === 'Dr.' ? c.title : '' } {c.name}</p>
                                        </label>
                                      </div>
                        })
              } 

              <div className="nopad col-xs text-center notsureaboutit" key='notsureaboutit'>
                <label className="image-checkbox">
                  <img alt="I'm not sure" onClick={ (ev) => this.advanceStaffEndorsementsJourney(this, 'first') } className="img-responsive img-unknown" src={process.env.PUBLIC_URL + '/unknown-employee.png'} />
                  <p className="sDeptName">{localizedData.notsure}</p>
                </label>
              </div>

<div className="navbar fixed-bottom">  <div><img alt="" className="footerLogo" src="/newlogo.png" alt="Logo"/><p className="footerLogoText">Your Voice Matters</p></div>{this.props.surveyData && (this.state.showNextButton) ? <button type="button" onClick={ (ev) => this.advanceStaffEndorsementsJourney(this, 'first')} className="btn btn-primary nextBtn">{localizedData.next}></button> : null } </div>
          <br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'EN')}>English</button> / <button className="simulateAnchor" onClick={(ev) => this.changeLocale(this, 'ES')}>Español</button></p></div>
       </div></div> : null
   }

{this.props.surveyData && (this.state.showStaffJourneyScreen && this.state.fullyLoaded) ?   <div className="innerContent"> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.backwardStaffEndorsementJourney.bind(this, null)} className="simulateAnchor backButton">&lt; {localizedData.back}</button> : null }
        <p>Akeso Occupational Health</p>       
      </div>
      <h5 className="surveyPageTitle separator">{localizedData.formatString(localizedData.how_did, this.state.currentStaffDetail.name)}</h5>    
        <div id="staffList">
            <div className="staffHeader">
              <div className="staffImage">

                <img alt="" className="img-responsive" alt={this.state.currentStaffDetail.name} src={this.state.currentStaffDetail.staffimageid.startsWith('http') ? this.state.currentStaffDetail.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + this.state.currentStaffDetail.staffimageid} />
                <div className="staffDetails">
                  <p className="name">{this.state.currentStaffDetail.name}, {this.state.currentStaffDetail.title}</p>
                  <p className="department">{this.state.currentStaffDetail.bio}</p>
                  <div className="mostFrequentBadge">
                    <div className="theBadge"></div>
                    <div className="badgeDetails">
                      <p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
                      <p className="badgeDescription">{localizedData.badge_instruction_staff}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="menu">
              <p>{localizedData.choose_badges} <span className="extraInfo">( {localizedData.scroll_for_more} )</span></p>
            </div>
            <div className="badgeSelector">
                    {
                        this.props.surveyData.staffEndorsements.map(c => {
                          if(c.department!==undefined && c.department!==null && c.department!=='' && (c.department.toString()!==this.state.currentStaffDetail.department_id.toString())){
                            return;
                          } else {

                            var localeValue = localizedData[c.localization_key];
                            if(c.localization_options!==undefined && c.localization_options!==null){
                              let tempValue = c.localization_options[this.state.language];
                              if(tempValue!==undefined){
                                localeValue = tempValue;
                              }
                            }

                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className={"badge-checkbox" + (this.generateClassNames(c, this.state.currentStaffDetail) ? ' addCheckMark' : '')}>
                                      <img alt="" onClick={(ev) => this.chooseStaffEndorsement(c, this.state.currentStaffDetail, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.currentStaffDetail) ? ' activeDept' : '')} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p>{localeValue}</p>
                                    </label>
                                  </div>
                          }
                          
                        })
                    }      
            </div>

            <div className="menu">
              <p>{localizedData.write_feedback}</p>
            </div>
            <textarea placeholder={localizedData.feedback_placeholder} id="staffFeedback" onChange={this.handleStaffFeedbackChange.bind(this)} value={this.state.currentStaffDetail.feedback!==undefined ? this.state.currentStaffDetail.feedback : ''} />
            <div className="navbar fixed-bottom"> {this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.advanceStaffEndorsementsJourney.bind(this, null)} className="btn btn-primary nextBtn">{localizedData.next}></button> : null }</div>
        </div>

      </div> : null
   }

    {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-3") ?   <div className="innerContent"> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={(ev)=> this.backwardStaffEndorsementJourney(ev, 'facility')} className="backButton simulateAnchor">&lt; {localizedData.back}</button> : null }
        <p>Akeso Occupational Health</p>       
      </div>
      <h5 className="surveyPageTitle separator">{localizedData.how_was} {this.localizeDepartment(this.state.currentDeptDetail)}?</h5>    
        <div id="staffList">
            <div className="staffHeader">
              <div className="staffImage">

                <img className="img-responsive deptImageFix" alt={this.state.currentDeptDetail.name} src={this.state.currentDeptDetail.icon.startsWith('http') ? this.state.currentDeptDetail.icon : process.env.PUBLIC_URL + '/department_assets/' + this.state.currentDeptDetail.icon} />
                <div className="staffDetails">
                  <p className="name">{this.localizeDepartment(this.state.currentDeptDetail)}</p>
                  <div className="mostFrequentBadge">
                    <div className="theBadge"></div>
                    <div className="badgeDetails">
                      <p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
                      <p className="badgeDescription">{localizedData.badge_instruction_dept}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="menu">
              <p>{localizedData.choose_badges} <span className="extraInfo">( {localizedData.scroll_for_more} )</span></p>
            </div>
            <div className="badgeSelector">
                    {
                        this.props.surveyData.deptEndorsements.map(c => {
                          if(c.department!==undefined && c.department!==null && c.department!=='' && (c.department.toString()!==this.state.currentDeptDetail.key.toString())){
                            return;
                          } else {
                            var localeValue = localizedData[c.localization_key];
                            if(c.localization_options!==undefined && c.localization_options!==null){
                              let tempValue = c.localization_options[this.state.language];
                              if(tempValue!==undefined){
                                localeValue = tempValue;
                              }
                            }

                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className={"badge-checkbox" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' addCheckMark' : '')}>
                                      <img alt="" onClick={(ev) => this.chooseDepartmentEndorsement(c, this.state.currentDeptDetail, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' activeDept' : '')} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p>{localeValue}</p>
                                    </label>
                                  </div>
                          }
                        })
                    }      

            </div>

            <div className="menu">
              <p>{localizedData.write_feedback}</p>
            </div>
            <textarea placeholder={localizedData.feedback_placeholder} id="deptFeedback" onChange={this.handleDeptFeedbackChange.bind(this)} value={this.state.currentDeptDetail.feedback!==undefined ? this.state.currentDeptDetail.feedback : ''} />
            <div className="navbar fixed-bottom">{this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.advanceSurveyFrom.bind(this)} className="btn btn-primary nextBtn">{localizedData.next}></button> : null }</div>
        </div>

      </div> : null
   }

      {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-4") ?  <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <button onClick={this.backwardSurveyFrom.bind(this)} className="backButton simulateAnchor">&lt; {localizedData.back}</button> : null }
        <p>Akeso Occupational Health</p>              
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData[this.props.surveyData.questionInfo[this.state.currentQuestion].page_id]}</h5>
            <div className="card mx-auto">
              <a  onClick={this.handleNpsClick.bind(this, 1)} className="card-img-sad overallCard">Poor</a>
              <p className="text-center smallerText">{localizedData.lowest}</p>
          </div>
                    <div className="card mx-auto">
              <a  onClick={this.handleNpsClick.bind(this, 2)} className="card-img-meh overallCard">Not Good</a>
          </div> 
                    <div className="card mx-auto">
              <a  onClick={this.handleNpsClick.bind(this, 3)} className="card-img-okay overallCard">Okay</a>
          </div>  
                    <div className="card mx-auto" >
              <a  onClick={this.handleNpsClick.bind(this, 4)} className="card-img-good overallCard">Good</a>
          </div> 

            <div className="card mx-auto">
              <a  onClick={this.handleNpsClick.bind(this, 5)} className="card-img-great overallCard">Great</a>
              <p className="text-center smallerText">{localizedData.highest}</p>
          </div> 

        <Modal size="sm" show={this.state.confirmOpen} onHide={this.handleClose.bind(this)}>
          <Modal.Header className="modalPadding" >
            <Modal.Title>{localizedData.confirmRating}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{localizedData.confirmSentence}<b>{this.state.confirmRating}</b>{localizedData.confirmSentenceTwo}5?</Modal.Body>
          <Modal.Footer>
            <Button  onClick={this.handleClose.bind(this)}>
              {localizedData.confirmNo}
            </Button>
            <Button onClick={this.handleConfirm.bind(this)}>
              {localizedData.confirmYes}
            </Button>
          </Modal.Footer>
        </Modal>          

          <div className="row cardInstructions"><p>{localizedData.nps_choose}</p></div>         
          <br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <a  onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a  onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>
              <div className="fixed-bottom text-center">
       <div><img alt="" className="footerLogo" src="/newlogo.png" alt="Logo"/><p className="footerLogoText">Your Voice Matters</p></div>
       </div>
       </div></div> : null
   }

 {this.props.surveyData && (this.props.surveyData.currentPage==="page-5" && this.state.fullyLoaded && !this.state.showReviewPage && !this.state.completedSurvey) ?   <div> <div className="navbar row text-center">
        <p>Akeso Occupational Health</p>       
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData.spinWheel}</h5> 
           <div className="prizeSpinContainer">
            <div className="prizeTicker"/>
            <ReactTurntable {...this.state.options} onStart={this.startPrizeDrawing.bind(this)} onComplete={this.finishPrizeDrawing.bind(this)}/>
          </div>
          <a className="ruleLink hidden hide"  target="_blank">{localizedData.viewrules}</a>       
       </div></div> : null
   }

 {this.props.surveyData && (this.props.surveyData.currentPage==="page-5" && this.state.fullyLoaded && this.state.showReviewPage && (this.state.savedSurvey.nps===4 || this.state.savedSurvey.nps===5)) ?   <div> <div className="navbar row text-center">
        <p>Akeso Occupational Health</p>       
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{this.state.prizeWinnings}</h5> 

          {(!this.props.surveyData.urlInfo[0].google_id && !this.props.surveyData.urlInfo[0].yelp_id && !this.props.surveyData.urlInfo[0].facebook_id && !this.props.surveyData.urlInfo[0].healthgrades) && <h5 className="surveyPageTitle">{localizedData.thank_you}</h5>}
          {(this.props.surveyData.urlInfo[0].google_id || this.props.surveyData.urlInfo[0].yelp_id || this.props.surveyData.urlInfo[0].facebook_id || this.props.surveyData.urlInfo[0].healthgrades) && <p className="surveyPageInstruction text-center">{localizedData.reviewPromptText}</p>}
          {
                        this.state.savedSurvey.staff.map(c => {
                          if(c.reviews!==undefined && c.reviews!==null && c.reviews.google!==undefined && c.reviews.google!==null){
                            return <div key={c.staff_id} className="reviewPageAlignCenter">
                              <div className="menu">
                                <p className="centerLocationText">{localizedData.formatString(localizedData.review_on_google, c.name)}</p>
                              </div>
                              <div className="col-md-12">
                                <label className="badge-checkbox float-left staffList">
                                  <img alt={c.name} className={"img-responsive"} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid} />
                                </label>
                                <a onTouchStart={this.trackReviewReferLink.bind(this, 'google-staff')} href={'https://search.google.com/local/writereview?placeid=' + c.reviews.google} className="float-left maxWidthStaffGoogle" ><img alt="Google Reviews" src="./google-reviews.png" /></a>
                              </div>
                              
                            </div>
                          } else {
                            return;
                          }
                        })
          }
 {((this.props.surveyData.urlInfo[0].google_id || this.props.surveyData.urlInfo[0].yelp_id || this.props.surveyData.urlInfo[0].facebook_id || this.props.surveyData.urlInfo[0].healthgrades) && this.state.showLocationBar ) && <div className="menu"><p className="centerLocationText">{localizedData.review_our_location}</p></div>}
          {this.props.surveyData.urlInfo[0].google_id && <a onTouchStart={this.trackReviewReferLink.bind(this, 'google')} onClick={this.trackReviewReferLink.bind(this, 'google')} href={'https://search.google.com/local/writereview?placeid=' + this.props.surveyData.urlInfo[0].google_id} className="reviewSite" ><img alt="Google Reviews" src="./google-reviews.png" /></a>}
          {this.props.surveyData.urlInfo[0].yelp_id &&  <a onTouchStart={this.trackReviewReferLink.bind(this, 'yelp')} onClick={this.trackReviewReferLink.bind(this, 'yelp')} href={this.props.surveyData.urlInfo[0].yelp_id} className="reviewSite" ><img alt="" src="./yelp_fullcolor.png" /></a> }
          {this.props.surveyData.urlInfo[0].facebook_id &&  <a onTouchStart={this.trackReviewReferLink.bind(this, 'facebook')} onClick={this.trackReviewReferLink.bind(this, 'facebook')} href={this.props.surveyData.urlInfo[0].facebook_id} className="reviewSite" ><img alt="" src="./facebook.jpg" /></a> }
          {this.props.surveyData.urlInfo[0].healthgrades &&  <a onTouchStart={this.trackReviewReferLink.bind(this, 'healthgrades')} onClick={this.trackReviewReferLink.bind(this, 'healthgrades')} href={this.props.surveyData.urlInfo[0].healthgrades} className="reviewSite" ><img alt="" src="./healthgrades.jpg" /></a> }  
       </div></div> : null
   }

 {this.props.surveyData && (this.props.surveyData.currentPage==="page-5" && this.state.fullyLoaded && this.state.showReviewPage && (this.state.savedSurvey.nps===1 || this.state.savedSurvey.nps===2 || this.state.savedSurvey.nps===3)) ?   <div> <div className="row text-center">
 {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <h5 className="surveyPageTitle mt-0">{localizedData.completed_survey}</h5> 
          {this.state.prizeWinnings && <h5 className="surveyPrizeWinnings">{this.state.prizeWinnings}</h5> }
          {(this.props.surveyData.urlInfo[0].google_id || this.props.surveyData.urlInfo[0].yelp_id || this.props.surveyData.urlInfo[0].facebook_id || this.props.surveyData.urlInfo[0].healthgrades) && <p className="surveyPageInstruction externalSurveyLinks beforeExternalLinks text-center poorReviewText">{localizedData.reviewPromptTextLower} {this.props.surveyData.urlInfo[0].google_id && <a href={'https://search.google.com/local/writereview?placeid=' + this.props.surveyData.urlInfo[0].google_id} className="reviewSite" >Google Reviews</a>}  {this.props.surveyData.urlInfo[0].yelp_id &&  <a href={this.props.surveyData.urlInfo[0].yelp_id} className="reviewSite" >Yelp! Reviews</a>  } {this.props.surveyData.urlInfo[0].facebook_id &&   <a href={this.props.surveyData.urlInfo[0].facebook_id} className="reviewSite" >Facebook Reviews</a> } {this.props.surveyData.urlInfo[0].healthgrades &&   <a href={this.props.surveyData.urlInfo[0].healthgrades} className="reviewSite" >HealthGrades</a> }</p>}  
       </div></div> : null
   }


 {this.props.surveyData && (this.state.completedSurvey) ?   <div> <div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <h5 className="surveyPageTitle">{localizedData.completed_survey}</h5> 
          {this.state.prizeWinnings && <h5 className="surveyPrizeWinnings">{localizedData.you_won} {this.state.prizeWinnings}</h5> }
       </div></div> : null
   }

 {this.props.surveyData && (this.state.notYourSurvey && !this.state.restartedSurvey) ?   <div> <div className="row cardholder serveSurvey">
          {(this.props.surveyData.urlInfo[0].logo===null || this.props.surveyData.urlInfo[0].logo===undefined) ? <img className="inSurveyLogo" src="/newlogo.png" alt="Logo"/> : <img className="inSurveyLogo" src={this.props.surveyData.urlInfo[0].logo} alt="Logo"/>}
          <h5 className="surveyPageTitle">{localizedData.another_device}</h5> 
          <button className="btn btn-primary centerButton" onClick={this.restartSurvey.bind(this)}>{localizedData.start}</button>
       </div></div> : null
   }

    </div>

    }
}

const mapStateToProps = (state) => {
    return {
        surveyData: state.survey.surveyData,
        existingSurvey: state.survey.existingSurvey,
        tvClient: state.login.tvClient,
        staffData: state.staff.staffData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      surveyBegin: (...params) => dispatch(surveyBegin(...params)),
      getDeptStaff: (...params) => dispatch(getDeptStaff(...params)),
      cachedResults: (...params) => dispatch(cachedResults(...params)),
      updateSurvey: (...params) => dispatch(updateSurvey(...params)),
      createPatient: (...params) => dispatch(createPatient(...params)),
      beginNewSurvey: (...params) => dispatch(beginNewSurvey(...params)),
      getUserDetails: (...params) => dispatch(getUserDetails(...params)),
      executePersonalSurvey: (...params) => dispatch(executePersonalSurvey(...params)),
      rewardPrizeTickets: (...params) => dispatch(rewardPrizeTickets(...params)),
      restartPatientSurvey: (...params) => dispatch(restartPatientSurvey(...params)),
      trackReviewReferClicks: (...params) => dispatch(trackReviewReferClicks(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServeSurvey);
