import { createHashHistory } from 'history';
import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise";
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './rootreducer'

export const history = createHashHistory();

export default function configureStore(preloadedState) {
  const store = (process.env.NODE_ENV === 'production') ? createStore(createRootReducer(history), preloadedState, compose(applyMiddleware(routerMiddleware(history),thunk,promise),),) : createStore(createRootReducer(history), preloadedState, compose(applyMiddleware(routerMiddleware(history),thunk,promise,logger),),);

  return store;
}