import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Row,
    Col,
    ProgressBar,
    Image
} from "react-bootstrap";
import {createJourney, listDepartments} from "../../actions";
import "./NewJourney.scss";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
const arrayMove = require('array-move');
const SortableItem = SortableElement(({value}) => <li>{value}</li>);
const MySortableContainer = SortableContainer(({children}) => {
  return <ul>{children}</ul>;
});


class NewJourney extends Component {

    constructor(props) {
        super(props);
        let filter = {
            staffId: {
                type: 'wildcard',
                value: '*'
            }
        };        
        this.props.listDepartments(this.props.tvClient, 'and', filter, 'asc', 1, 100);
        this.state = {
            fields: { 'role': 'false'},
            journeyFiles: [],
            journeyFileUrls: [],
            errors: { 'journeyName': false, 'role': false },
            sequence: []
        };
        this.inlineError = false;
        this.handleFormChange = this.handleFormChange.bind(this);

    }


    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }    


    newJourney(e) {
        e.preventDefault();

        if(!this.journeyName.value){
            this.setState({ errors: {'journeyName': true, }});
        } else{

            var newList = [];
            for (var i = 0; i < this.state.sequence.length; i++) {
               for (var j = this.props.departments.length - 1; j >= 0; j--) {
                    if(this.state.sequence[i]===this.props.departments[j].name){
                        newList.push(this.props.departments[j].key);
                    }
               }
            }

            this.props.createJourney(
                this.props.tvClient,
                this.journeyName.value,
                newList,
                this.state.journeyFiles,
                this.state.fields['role']
            );          
        }
    }

      onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({sequence}) => ({
          sequence: arrayMove(sequence, oldIndex, newIndex),
        }));
      };

    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var currentSequence = [];
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
              currentSequence.push(options[i].innerHTML);
            }
        }   


        this.setState({
            primaryDepartment: value,
            sequence: currentSequence
        });
    }  


    handleBrowseImagesClick(e) {
        e.preventDefault();
        this.fileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            journeyFiles: [...e.target.files],
            // Set object URLs one time so that images don't re-render when state changes
            journeyFileUrls: [...e.target.files].map(journeyFile => URL.createObjectURL(journeyFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }      

    render() {
        return <div>
            <h1 className="page-header">
                Create New Journey Option
                <small>Please enter the following journey information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Cancel</button></small>
            </h1>
            <Form onSubmit={this.newJourney.bind(this)} className="new-case-form">

                <legend>Journey Information</legend>

                {this.props.addLocationError && <Alert variant="danger">
                    {this.props.addLocationError.message}
                </Alert>}

                <FormGroup controlId="journeyName">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="journey name" ref={ref => this.journeyName = ref}/>
                    {this.state.errors.journeyName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>       
                <FormGroup controlId="primaryDepartment">
                    <FormLabel>Choose Departments *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this)} multiple>
                        {Object.values(this.props.departments).map(d => {
                            return <option value={d.key} key={d.key}>{d.name}</option>
                        })}
                    </FormControl>                    

                    {this.state.errors.primaryDepartment && <Alert variant="danger">
                        A primary department is required.
                    </Alert>}
                </FormGroup>                    

                <FormGroup controlId="sequenceDepartments" className="sequenceDepartments">
                    <FormLabel>Sequence Departments *</FormLabel>
                    <MySortableContainer onSortEnd={this.onSortEnd}>
                        {this.state.sequence.map((value, index) => (
                          <SortableItem key={`item-${index}`} index={index} value={value} />
                        ))}
                    </MySortableContainer>
                </FormGroup>
                <div className="form-group">
                <label htmlFor="role">Employer Journey: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="false" onChange={this.handleFormChange}/>
                    No
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="role" value="true" onChange={this.handleFormChange}/>
                    Yes
                  </label>
                </div>
                <div className="errorMsg">{this.state.errors.role}</div>
              </div>
                <legend>Upload Journey Logo</legend>
                <p>(Logo - 293px x 76px)</p>

                <Row>
                {
                    this.state.journeyFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.addingJourney ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <button className="simulateAnchor" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</button>
                        </div>
                    )}

                </div>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingJourney} className="float-right">
                        Add
                    </Button>
                </FormGroup>


            </Form>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingJourney: state.addJourney.addingJourney,
        addJourneyError: state.addJourney.addJourneyError,
        tvClient: state.login.tvClient,
        departments: state.deptList.departments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createJourney: (...params) => dispatch(createJourney(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewJourney);
