import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateJourney, viewJourney, listDepartments } from "../../actions";
import DeleteJourneyOverlay from "./../Overlays/DeleteJourneyOverlay";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
const arrayMove = require('array-move');
const SortableItem = SortableElement(({value}) => <li>{value}</li>);
const MySortableContainer = SortableContainer(({children}) => {
  return <ul>{children}</ul>;
});

class EditJourney extends Component {

    componentWillMount() {

            let filter = {
                staffId: {
                    type: 'wildcard',
                    value: '*'
                }
            };   
        
        this.props.listDepartments(this.props.tvClient, 'and', filter, 'asc', 1, 100).then(() => {  
            this.props.viewJourney(this.props.tvClient, this.props.match.params.documentId).then(() => {

                // need to set the sequence
                var startingSequence = [];
                
                for (var i = 0; i < this.props.journeyData.departments.length; i++) {
                   for (var j = this.props.departments.length - 1; j >= 0; j--) {
                        if(this.props.journeyData.departments[i]===this.props.departments[j].key){
                            startingSequence.push(this.props.departments[j].name);
                        }
                   }
                }
                this.setState({ sequence: startingSequence })
            });

        });
    }

    constructor(props) {
        
        super(props);
    
        this.state = {
            fields: { 'role': 'false'},
            errors: { 'journeyName': false, 'role': false },
            sequence: [],
            journeyFiles: [],
            journeyFileUrls: []            
        };

        this.handleFormChange = this.handleFormChange.bind(this);

    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }   

    deleteJourney(e){
        console.log('deleting this journey');
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();
        this.fileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            journeyFiles: [...e.target.files],
            // Set object URLs one time so that images don't re-render when state changes
            journeyFileUrls: [...e.target.files].map(journeyFile => URL.createObjectURL(journeyFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }   

    editJourney(e) {
        e.preventDefault();

        var departmentList = this.state.primaryDepartment;
        if(departmentList===undefined){
            departmentList = this.props.journeyData.departments;
        }

       if(!this.journeyName.value){
            this.setState({ errors: {'journeyName': true}});
        } else{

            var newList = [];
            for (var i = 0; i < this.state.sequence.length; i++) {
               for (var j = this.props.departments.length - 1; j >= 0; j--) {
                    if(this.state.sequence[i]===this.props.departments[j].name){
                        newList.push(this.props.departments[j].key);
                    }
               }
            }

           this.props.updateJourney(
                this.props.tvClient,
                this.props.match.params.documentId,
                this.journeyName.value,
                newList,
                this.state.journeyFiles
            );           
        }        
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({sequence}) => ({
          sequence: arrayMove(sequence, oldIndex, newIndex),
        }));
      };


    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        var currentSequence = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
              currentSequence.push(options[i].innerHTML);
            }
        }   

        this.setState({
            primaryDepartment: value,
            sequence: currentSequence
        });
    }    



    render() {
        return <div>
            <h1 className="page-header">
                View / Edit Journey
                <small>Please update any of the following journey information </small>
                <small className="backLink"><a href="#" onClick={() => window.history.back()} >Go Back</a> | <DeleteJourneyOverlay locationDetail={this.props.match.params.documentId} /></small>
            </h1>
            
            {(!this.props.journeyData || !this.props.departments || (parseInt(this.props.journeyData.journey_id) !== parseInt(this.props.match.params.documentId))) && <Spinner/>}

            { (this.props.journeyData && this.props.departments && (parseInt(this.props.journeyData.journey_id) === parseInt(this.props.match.params.documentId))) && <Form onSubmit={this.editJourney.bind(this)} className="new-case-form">

                {this.props.viewJourneyError && <Alert variant="danger">
                    {this.props.viewJourneyError.message}
                </Alert>}
                {this.props.journeyData.group_only &&
                  <div className="form-group">
                    <h3>This is a journey for the <b>Employer</b></h3>
                  </div>
                }
                <FormGroup controlId="journeyName">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="North County Office"  defaultValue={this.props.journeyData.name} ref={ref => this.journeyName = ref}/>
                    {this.state.errors.journeyName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="primaryDepartment">
                    <FormLabel>Choose Departments *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={this.props.journeyData.departments} onChange={this.handleDepartmentList.bind(this)} multiple>
                        {Object.values(this.props.departments).map(d => {
                            return <option value={d.key} key={d.key}>{d.name}</option>
                        })}
                    </FormControl>                    

                    {this.state.errors.primaryDepartment && <Alert variant="danger">
                        A primary department is required.
                    </Alert>}
                </FormGroup>   

                { this.state.sequence.length >0 && <FormGroup controlId="sequenceDepartments" className="sequenceDepartments">
                    <FormLabel>Sequence Departments *</FormLabel>
                    <MySortableContainer onSortEnd={this.onSortEnd}>
                        {this.state.sequence.map((value, index) => (
                          <SortableItem key={`item-${index}`} index={index} value={value} />
                        ))}
                    </MySortableContainer>
                </FormGroup>    }            


                 <legend>Upload Journey Logo</legend>
                <p>(Logo - 293px x 76px)</p>
                <Row><Col className="thumbnail-col" md={4} key={1}>
{this.props.journeyData.logo && <img className="editStaffImgThumb" alt={this.props.journeyData.logo} src={this.props.journeyData.logo.startsWith('http') ? this.props.journeyData.logo : process.env.PUBLIC_URL + '/department_assets/' + this.props.journeyData.logo} /> || <span>None</span>} </Col>                  
                </Row>
                <Row>
                {
                    this.state.journeyFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.viewingJourney ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                    )}

                </div>


                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingJourney} className="float-right">
                        Update
                    </Button>
                </FormGroup>


            </Form>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingJourney: state.viewJourney.viewingJourney,
        viewJourneyError: state.viewJourney.viewJourneyError,
        tvClient: state.login.tvClient,
        journeyData: state.viewJourney.journeyData,
        departments: state.deptList.departments 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewJourney: (...params) => dispatch(viewJourney(...params)),
        updateJourney: (...params) => dispatch(updateJourney(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditJourney);

