import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import { listJourneys, listLocations, viewEhrMapping, updateEhrMapping } from "../../actions";
import "./EditEHRMapping.scss";

class EditEHRMapping extends Component {

    componentDidMount() { 
        this.props.listLocations(this.props.tvClient);
        this.props.listJourneys(this.props.tvClient);
        this.props.viewEhrMapping(this.props.tvClient, this.props.match.params.documentId);
    }

    constructor(props) {
        super(props);
        this.state = {
            errors: { 'name': false, 'email': false, 'mobile': false },
            editLoading: true
        };
    }  

    submitEhrMapping(e) {
        e.preventDefault();
        this.props.updateEhrMapping(
            this.props.tvClient,
            this.journeyId.value,
            this.frequency.value,
            this.props.mappingInfo.uid,
            this.locationId.value
        );                
    }

    render() {
        return <div>
            <h1 className="page-header">
                Edit EHR Mapping
                <small>Please update any of the following information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Go Back</button></small>
            </h1>
            
            {this.state.viewingEhrMapping && <Spinner/>}

            {(this.props.mappingInfo && this.props.journeys.length > 0) && <Form onSubmit={this.submitEhrMapping.bind(this)} className="new-case-form">

                {this.props.viewEhrMappingError && <Alert variant="danger">
                    {this.props.viewEhrMappingError.message}
                </Alert>}
                <h2>{this.props.mappingInfo.appointment_code}</h2>
                <FormGroup controlId="journeyId">
                    <FormLabel>Journey *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={this.props.mappingInfo.journey_id} ref={ref => this.journeyId = ref}>
                        <option value='' key=''>Select</option>
                        {Object.values(this.props.journeys).map(d => {
                            return <option value={d.journey_id} key={d.journey_id}>{d.name}</option>
                        })}
                    </FormControl>
                </FormGroup>

                <FormGroup controlId="locationId">
                    <FormLabel>Location *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={this.props.mappingInfo.location_id} ref={ref => this.locationId = ref}>
                        <option value='' key=''>All</option>
                        {Object.values(this.props.locations).map(d => {
                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                        })}
                    </FormControl>
                </FormGroup>                

                <FormGroup controlId="frequency">
                    <FormLabel>Frequency *</FormLabel>
                    <FormControl as="select" defaultValue={this.props.mappingInfo.priority.toString()} ref={ref => this.frequency = ref} placeholder="select" >
                        <option value='1' key='1'>Every Visit</option><option value='2' key='2'>Every 2 Visits</option>
                        <option value='3' key='3'>Every 3 Visits</option><option value='4' key='4'>Every 4 Visits</option>
                        <option value='5' key='5'>Every 5 Visits</option><option value='6' key='6'>Every 6 Visits</option>
                        <option value='7' key='7'>Every 7 Visits</option><option value='8' key='8'>Every 8 Visits</option>
                        <option value='9' key='9'>Every 9 Visits</option><option value='10' key='10'>Every 10 Visits</option>
                    </FormControl>
                </FormGroup>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingAdmin} className="float-right">
                        Update
                    </Button>
                </FormGroup>
            </Form>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        journeys: state.journeyList.journeys,
        viewingEhrMapping: state.viewEhrMapping.viewingEhrMapping,
        viewEhrMappingError: state.viewEhrMapping.viewEhrMappingError,
        tvClient: state.login.tvClient,
        mappingInfo: state.viewEhrMapping.mappingInfo,
        locations: state.locationList.locations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listJourneys: (...params) => dispatch(listJourneys(...params)),
        viewEhrMapping: (...params) => dispatch(viewEhrMapping(...params)),
        updateEhrMapping: (...params) => dispatch(updateEhrMapping(...params)),
        listLocations: (...params) => dispatch(listLocations(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEHRMapping);

