import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row
} from "react-bootstrap";
import {createDepartment, listDepartments} from "../../actions";
import "./NewDepartment.scss";

class NewDepartment extends Component {
    constructor(props) {
        super(props);
        this.props.listDepartments(this.props.tvClient, 'and', null, 'asc', 1, 100);
        this.state = {
            departmentFiles: [],
            departmentFileUrls: [],
            primaryDepartment: null
        };
    }

    newDepartment(e) {
        e.preventDefault();
        this.props.createDepartment(
            this.props.tvClient,
            this.name.value,
            this.esname.value,
            this.state.departmentFiles,
            this.primaryDepartment.value
        );
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();

        this.fileInput.click();
    }

    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryDepartment: value
        });
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            departmentFiles: [...e.target.files],
            // Set object URLs one time so that images don't re-render when state changes
            departmentFileUrls: [...e.target.files].map(departmentFile => URL.createObjectURL(departmentFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }

    render() {
        return <div>
            <h1 className="page-header">
                Add Department
                <small>Please enter the following department information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Cancel</button></small>
            </h1>
            <Form onSubmit={this.newDepartment.bind(this)} className="new-case-form">

                {this.props.addDepartmentError && <Alert variant="danger">
                    {this.props.addDepartmentError.message}
                </Alert>}

                <FormGroup controlId="name">
                    <FormLabel>Department Name</FormLabel>
                    <FormControl type="text" placeholder="Family Medicine, Radiology, etc..." ref={ref => this.name = ref}/>
                </FormGroup>

                <FormGroup controlId="esname">
                    <FormLabel>Department Name (Spanish)</FormLabel>
                    <FormControl type="text" placeholder="Family Medicine, Radiology, etc..." ref={ref => this.esname = ref}/>
                </FormGroup>  

                <FormGroup controlId="primaryDepartment">
                    <FormLabel>Parent Department / Team (Optional)</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this)} ref={ref => this.primaryDepartment = ref} >
                        <option value="">None</option>
                        {Object.values(this.props.departments).map(d => {
                            if(d.name!=='Facility'){
                                return <option value={d.key} key={d.key}>{d.name}</option>
                            }
                        })}
                    </FormControl>      
                </FormGroup>


                <legend>Upload Icon</legend>

                <Row>
                {
                    this.state.departmentFileUrls.map(url => {
                        return <Col className="thumbnail-col" md={4} key={url}>
                            <Image src={url} thumbnail/>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.addingDepartment ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <button className="simulateAnchor" onClick={this.handleBrowseImagesClick.bind(this)}>Browse For Image</button>
                        </div>
                    )}

                </div>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingDepartment} className="float-right">
                        Submit New Department
                    </Button>
                </FormGroup>


            </Form>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingDepartment: state.addDepartment.addingDepartment,
        addDepartmentError: state.addDepartment.addDepartmentError,
        departments: state.deptList.departments,
        bytesLoaded: state.addDepartment.bytesLoaded,
        bytesTotal: state.addDepartment.bytesTotal,
        tvClient: state.login.tvClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createDepartment: (...params) => dispatch(createDepartment(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDepartment);
