/**
 * These reducers update the global application state based on the actions they handle. For the most part, each reducer
 * simply sets loading flags, stores errors, and stores loaded data. To avoid the common boilerplate when creating
 * [Redux Reducers](http://redux.js.org/docs/basics/Reducers.html), we use the the `handleActions` helper from
 * [Redux Actions](https://github.com/acdlite/redux-actions).
 *
 * Each reducer we defined with `handleActions` is available on the store. For instance, when the
 * `DOCTOR_INBOX_LOAD_START` action is dispatched, the `doctorInbox` reducer sets loading=true. That means that
 * store.doctorInbox.loading === true. Any component that wants to display a loading indicator for the doctor inbox
 * can then bind store.doctorInbox.loading to a prop, and reference it in JSX.
 */

import {handleActions} from "redux-actions";

export const overviewCpt = handleActions({
    STATS_OVERVIEW_LOAD_START: state => {
        return {
            overviewLoading: true,
            overviewData: null
        }
    },
    STATS_OVERVIEW_LOAD_SUCCESS:  (state, action) => {
        return {
            overviewLoading: false,
            overviewData: action.payload
        }
    }  
},{});

export const employerSurveyOverviewCpt = handleActions({
    STATS_EMPLOYER_SURVEY_OVERVIEW_LOAD_START: state => {
        return {
            employerSurveyOverviewLoading: true,
            employerSurveyOverviewData: null
        }
    },
    STATS_EMPLOYER_SURVEY_OVERVIEW_LOAD_SUCCESS:  (state, action) => {
        return {
            employerSurveyOverviewLoading: false,
            employerSurveyOverviewData: action.payload
        }
    }  
},{});

export const locationOverviewCpt = handleActions({
    STATS_LOCATION_OVERVIEW_LOAD_START: state => {
        return {
            locationOverviewLoading: true,
            locationOverviewData: null
        }
    },
    STATS_LOCATION_OVERVIEW_LOAD_SUCCESS:  (state, action) => {
        return {
            locationOverviewLoading: false,
            locationOverviewData: action.payload
        }
    }  
},{});

export const endorsementsCpt = handleActions({
    STATS_ENDORSEMENTS_LOAD_START: state => {
        return {
            endorsementsLoading: true,
            endorsementsData: null
        }
    },
    STATS_ENDORSEMENTS_LOAD_SUCCESS:  (state, action) => {
        return {
            endorsementsLoading: false,
            endorsementsData: action.payload
        }
    }  
},{});

export const employerEndorsementsCpt = handleActions({
    STATS_EMPLOYER_ENDORSEMENT_LOAD_START: state => {
        return {
            endorsementsLoading: true,
            endorsementsData: null
        }
    },
    STATS_EMPLOYER_ENDORSEMENT_LOAD_SUCCESS:  (state, action) => {
        return {
            endorsementsLoading: false,
            endorsementsData: action.payload
        }
    }  
},{});

export const deptEngagementCpt = handleActions({
    STATS_DEPT_ENGAGEMENTS_LOAD_START: state => {
        return {
            deptEngagementsLoading: true,
            deptEngagementsData: null
        }
    },
    STATS_DEPT_ENGAGEMENTS_LOAD_SUCCESS:  (state, action) => {
        return {
            deptEngagementsLoading: false,
            deptEngagementsData: action.payload
        }
    }  
},{});

export const staffEngagementCpt = handleActions({
    STATS_STAFF_ENGAGEMENTS_LOAD_START: state => {
        return {
            staffEngagementsLoading: true,
            staffEngagementsData: null
        }
    },
    STATS_STAFF_ENGAGEMENTS_LOAD_SUCCESS:  (state, action) => {
        return {
            staffEngagementsLoading: false,
            staffEngagementsData: action.payload
        }
    }  
},{});

export const actionableData = handleActions({
    STATS_ACTIONABLE_EVENTS_LOAD_START: state => {
        return {
            actionableEventsLoading: true,
            eventsData: null
        }
    },
    STATS_ACTIONABLE_EVENTS_LOAD_SUCCESS:  (state, action) => {
        return {
            actionableEventsLoading: false,
            eventsData: action.payload
        }
    }  
},{});

export const employerNpsCpt = handleActions({
    STATS_EMPLOYER_NPS_LOAD_START: state => {
        return {
            npsLoading: true,
            npsData: null,
            npsRating: null
        }
    },
    STATS_EMPLOYER_NPS_LOAD_SUCCESS:  (state, action) => {
        var score = 0;
        var scoreText = "";
        var totalEntries = 0;
        var totalScore = 0;
        // Setup the array of locations
        var employerArray = [];
       
        for(var j=1; j<6;j++){
            var found = false;
            var defaultRow = { 'name': j.toString(), 'value': parseInt('0')};
            for(var p=0; p<action.payload.length;p++){
                if(parseInt(action.payload[p].name)===j){
                    employerArray.push(action.payload[p]);
                    found = true;
                    break;
                }
            }
            if(!found){
                employerArray.push(defaultRow);
            }
        }

        for (var i = employerArray.length-1; i >= 0; i--) {
            if(employerArray[i].name!==null){
                employerArray[i].name = parseInt(employerArray[i].name);
                employerArray[i].value = parseInt(employerArray[i].value);
                if(employerArray[i].name.toString()==="5"){
                    //action.payload[i].display = "Excellent: " + action.payload[i].value;
                    employerArray[i].color = "#8abe6e";
                    totalEntries += parseInt(employerArray[i].value);
                    totalScore += (5 * parseInt(employerArray[i].value));
                } else if(employerArray[i].name.toString()==="4"){
                   //action.payload[i].display = "Great: "  + action.payload[i].value;
                    employerArray[i].color = "#93ccce";
                    totalEntries += parseInt(employerArray[i].value);
                    totalScore += (4 * parseInt(employerArray[i].value));
                } else if(employerArray[i].name.toString()==="3"){
                    //action.payload[i].display = "Good: "  + action.payload[i].value;
                    employerArray[i].color = "#6eb1b3";
                    totalEntries += parseInt(employerArray[i].value);
                    totalScore += (3 * parseInt(employerArray[i].value));
                } else if(employerArray[i].name.toString()==="2"){
                   // action.payload[i].display = "Okay: "  + action.payload[i].value;
                    employerArray[i].color = "#f8e367";
                    totalEntries += parseInt(employerArray[i].value);
                    totalScore += (2 * parseInt(employerArray[i].value));
                } else if(employerArray[i].name.toString()==="1"){
                    //action.payload[i].display = "Poor: "  + action.payload[i].value;
                    employerArray[i].color = "#e18197";
                    totalEntries += parseInt(employerArray[i].value);
                    totalScore += (1 * parseInt(employerArray[i].value));
                }
            }
        } 

        var totalPossibleScore = totalEntries * 5;
        score = ((totalScore / totalPossibleScore) * 5).toFixed(1);
        scoreText = score + " / 5";

        return {
            npsLoading: false,
            npsData: employerArray.reverse(),
            npsRating: scoreText
        }
    }  
},{});

export const staffNpsCpt = handleActions({
    STATS_STAFF_NPS_LOAD_START: state => {
        return {
            npsLoading: true,
            npsData: null,
            npsRating: null,
            specificRating: 0,
            surveyValue: 0
        }
    },
    STATS_STAFF_NPS_LOAD_SUCCESS:  (state, action) => {
        var score = 0;
        var scoreText = "";
        var totalEntries = 0;
        var totalScore = 0;
        for (var i = action.payload.npsData.length - 1; i >= 0; i--) {
            if(action.payload.npsData[i].name!==null){
                action.payload.npsData[i].name = parseInt(action.payload.npsData[i].name);
                action.payload.npsData[i].value = parseInt(action.payload.npsData[i].value);
                if(action.payload.npsData[i].name.toString()==="5"){
                    //action.payload[i].display = "Excellent: " + action.payload[i].value;
                    action.payload.npsData[i].color = "#8abe6e";
                    totalEntries += parseInt(action.payload.npsData[i].value);
                    totalScore += (5 * parseInt(action.payload.npsData[i].value));
                } else if(action.payload.npsData[i].name.toString()==="4"){
                   //action.payload[i].display = "Great: "  + action.payload[i].value;
                    action.payload.npsData[i].color = "#93ccce";
                    totalEntries += parseInt(action.payload.npsData[i].value);
                    totalScore += (4 * parseInt(action.payload.npsData[i].value));
                } else if(action.payload.npsData[i].name.toString()==="3"){
                    //action.payload[i].display = "Good: "  + action.payload[i].value;
                    action.payload.npsData[i].color = "#6eb1b3";
                    totalEntries += parseInt(action.payload.npsData[i].value);
                    totalScore += (3 * parseInt(action.payload.npsData[i].value));
                } else if(action.payload.npsData[i].name.toString()==="2"){
                   // action.payload[i].display = "Okay: "  + action.payload[i].value;
                    action.payload.npsData[i].color = "#f8e367";
                    totalEntries += parseInt(action.payload.npsData[i].value);
                    totalScore += (2 * parseInt(action.payload.npsData[i].value));
                } else if(action.payload.npsData[i].name.toString()==="1"){
                    //action.payload[i].display = "Poor: "  + action.payload[i].value;
                    action.payload.npsData[i].color = "#e18197";
                    totalEntries += parseInt(action.payload.npsData[i].value);
                    totalScore += (1 * parseInt(action.payload.npsData[i].value));
                }
            }
        } 

        var totalPossibleScore = totalEntries * 5;
        score = ((totalScore / totalPossibleScore) * 5).toFixed(1);
        scoreText = score + " / 5";

        return {
            npsLoading: false,
            npsData: action.payload.npsData,
            npsRating: scoreText,
            specificRating: score,
            surveyValue: action.payload.staffSurveyValue
        }
    }  
},{});


export const npsCpt = handleActions({
    STATS_NPS_LOAD_START: state => {
        return {
            npsLoading: true,
            npsData: null,
            npsRating: null,
            specificRating: 0
        }
    },
    STATS_NPS_LOAD_SUCCESS:  (state, action) => {
        let score = 0;
        let scoreText = "";
        let totalEntries = 0;
        let totalScore = 0;
        for (var i = action.payload.length - 1; i >= 0; i--) {
            if(action.payload[i].name!==null){
                action.payload[i].name = parseInt(action.payload[i].name);
                action.payload[i].value = parseInt(action.payload[i].value);
                if(action.payload[i].name.toString()==="5"){
                    action.payload[i].color = "#8abe6e";
                    totalEntries += parseInt(action.payload[i].value);
                    totalScore += (5 * parseInt(action.payload[i].value));
                } else if(action.payload[i].name.toString()==="4"){
                    action.payload[i].color = "#93ccce";
                    totalEntries += parseInt(action.payload[i].value);
                    totalScore += (4 * parseInt(action.payload[i].value));
                } else if(action.payload[i].name.toString()==="3"){
                    action.payload[i].color = "#6eb1b3";
                    totalEntries += parseInt(action.payload[i].value);
                    totalScore += (3 * parseInt(action.payload[i].value));
                } else if(action.payload[i].name.toString()==="2"){
                    action.payload[i].color = "#f8e367";
                    totalEntries += parseInt(action.payload[i].value);
                    totalScore += (2 * parseInt(action.payload[i].value));
                } else if(action.payload[i].name.toString()==="1"){
                    action.payload[i].color = "#e18197";
                    totalEntries += parseInt(action.payload[i].value);
                    totalScore += (1 * parseInt(action.payload[i].value));
                }
            }
        } 

        let totalPossibleScore = totalEntries * 5;
        score = ((totalScore / totalPossibleScore) * 5).toFixed(1);
        scoreText = score + " / 5";

        return {
            npsLoading: false,
            npsData: action.payload,
            npsRating: scoreText,
            specificRating: score
        }
    }  
},{});

export const locationNpsCpt = handleActions({
    STATS_LOCATION_NPS_LOAD_START: state => {
        return {
            locationNpsLoading: true,
            locationNpsData: null,
            locationNpsRating: null
        }
    },
    STATS_LOCATION_NPS_LOAD_SUCCESS:  (state, action) => {

        // Setup the array of locations
        var locationArray = [];
        var currentLocation = null;
        
        for(var i=0; i<action.payload.length;i++){
            var currentArray = [];
            if(action.payload[i].location_id!==null && currentLocation !== action.payload[i].location_id){
                currentLocation = action.payload[i].location_id; 
                for(var j=1; j<6;j++){
                    var defaultRow = { 'name': j.toString(), 'location_id': currentLocation, 'value': parseInt('0'), 'locationname': action.payload[i].locationname };
                    
                    if(action.payload[i].name.toString() === j.toString() ){
                        defaultRow = { 'name': j.toString(), 'location_id': currentLocation, 'value': parseInt(action.payload[i].value), 'locationname': action.payload[i].locationname };
                    } 
                    
                    currentArray.push(defaultRow);
                }
                locationArray.push(currentArray); 
            } else{
                for(var k=0; k<locationArray.length; k++){
                    for (var m =0; m< locationArray[k].length; m++) {
                        if( (action.payload[i].name.toString() === locationArray[k][m].name.toString()) && (action.payload[i].location_id.toString() === locationArray[k][m].location_id.toString()) ){
                            locationArray[k][m] = { 'name': action.payload[i].name, 'location_id': locationArray[k][m].location_id, 'value': parseInt(action.payload[i].value), 'locationname': locationArray[k][m].locationname.toString()  };
                        } 
                        
                    }
                } 
            }
        }
        
        for(var p=0; p<locationArray.length; p++){
            var score = 0;
            var scoreText = "";
            var totalEntries = 0;
            var totalScore = 0;
            for (i = locationArray[p].length - 1; i >= 0; i--) {
                if(locationArray[p][i].name!==null){
                    locationArray[p][i].name = parseInt(locationArray[p][i].name);
                    if(locationArray[p][i].name.toString()==="5"){
                        //action.payload[i].display = "Excellent: " + action.payload[i].value;
                        locationArray[p][i].color = "#8abe6e";
                        totalEntries += parseInt(locationArray[p][i].value);
                        totalScore += (5 * parseInt(locationArray[p][i].value));
                    } else if(locationArray[p][i].name.toString()==="4"){
                       //action.payload[i].display = "Great: "  + action.payload[i].value;
                        locationArray[p][i].color = "#93ccce";
                        totalEntries += parseInt(locationArray[p][i].value);
                        totalScore += (4 * parseInt(locationArray[p][i].value));
                    } else if(locationArray[p][i].name.toString()==="3"){
                        //action.payload[i].display = "Good: "  + action.payload[i].value;
                        locationArray[p][i].color = "#6eb1b3";
                        totalEntries += parseInt(locationArray[p][i].value);
                        totalScore += (3 * parseInt(locationArray[p][i].value));
                    } else if(locationArray[p][i].name.toString()==="2"){
                       // action.payload[i].display = "Okay: "  + action.payload[i].value;
                        locationArray[p][i].color = "#f8e367";
                        totalEntries += parseInt(locationArray[p][i].value);
                        totalScore += (2 * parseInt(locationArray[p][i].value));
                    } else if(locationArray[p][i].name.toString()==="1"){
                        //action.payload[i].display = "Poor: "  + action.payload[i].value;
                        locationArray[p][i].color = "#e18197";
                        totalEntries += parseInt(locationArray[p][i].value);
                        totalScore += (1 * parseInt(locationArray[p][i].value));
                    }
                }
            } 
            
            var totalPossibleScore = totalEntries * 5;
            score = ((totalScore / totalPossibleScore) * 5).toFixed(1);
            scoreText = score + " / 5";
            locationArray[p] = locationArray[p].reverse();
            locationArray[p].scoreText = scoreText;
            locationArray[p].placeName = locationArray[p][i+1].locationname;

        }

        return {
            locationNpsLoading: false,
            locationNpsData: locationArray
        }
    }  
},{});



export const statsView = handleActions({
    STATS_VIEW_START: state => {
        return {
            stats: null,
            loading: true,
            error: null
        };
    },
    STATS_VIEW_ERROR: (state, action) => {
        return {
            stats: null,
            loading: false,
            error: action.payload
        };
    },
    STATS_VIEW_SUCCESS: (state, action) => {
        return {
            stats: action.payload,
            loading: false,
            error: null
        };
    },
    STATS_SURVEYS_LOAD_START: state => {
        return {
            surveyLoading: true,
            surveyData: null
        }
    },
    STATS_SURVEYS_LOAD_SUCCESS:  (state, action) => {
        var finalSurveyList = [];
        for(var i=0; i<action.payload.length;i++){
            finalSurveyList.push({ 'date': action.payload[i].date, 'surveys': parseInt(action.payload[i].surveys) })
        }
        return {
            surveyLoading: false,
            surveyData: finalSurveyList
        }
    }      
}, {});

export const specificEndorsementCpt = handleActions({
    STATS_SPECIFIC_BADGE_LOAD_START: state => {
        return {
            endorsementLoading: true,
            endorsementData: null,
            loading: true
        }
    },
    STATS_SPECIFIC_BADGE_LOAD_SUCCESS:  (state, action) => {
        return {
            endorsementLoading: false,
            endorsementData: action.payload,
            loading: false
        }
    },
    STATS_SPECIFIC_BADGE_ERROR: (state, action) => {
        return {
            endorsementData: null,
            loading: false,
            error: action.payload
        };
    },
},{});

export const addLocation = handleActions({
    LOCATION_ADD_START: state => {
        return Object.assign({}, state, {
            addingLocation: true,
            addLocationError: null
        });
    },
    LOCATION_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingLocation: false,
            addLocationError: action.payload
        });
    },
    LOCATION_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingLocation: false,
            addLocationError: null
        });
    }
}, {});

export const addCampaign = handleActions({
    CAMP_ADD_START: state => {
        return Object.assign({}, state, {
            addingCampaign: true,
            addCampaignError: null
        });
    },
    CAMP_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingCampaign: false,
            addCampaignError: action.payload
        });
    },
    CAMP_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingCampaign: false,
            addCampaignError: null
        });
    }
}, {});

export const addGroup = handleActions({
    GROUP_ADD_START: state => {
        return Object.assign({}, state, {
            addingGroup: true,
            addGroupError: null
        });
    },
    GROUP_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingGroup: false,
            addGroupError: action.payload
        });
    },
    GROUP_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingGroup: false,
            addGroupError: null
        });
    }
}, {});


export const addJourney = handleActions({
    JOURNEY_ADD_START: state => {
        return Object.assign({}, state, {
            addingJourney: true,
            addJourneyError: null
        });
    },
    JOURNEY_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingJourney: false,
            addJourneyError: action.payload
        });
    },
    JOURNEY_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingJourney: false,
            addJourneyError: null
        });
    }
}, {});

export const mapJourney = handleActions({
    JOURNEY_MAPPING_ADD_START: state => {
        return Object.assign({}, state, {
            addingJourneyMap: true,
            addJourneyMapError: null
        });
    },
    JOURNEY_MAPPING_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingJourneyMap: false,
            addJourneyMapError: action.payload
        });
    },
    JOURNEY_MAPPING_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingJourneyMap: false,
            addJourneyMapError: null
        });
    }
}, {});


export const mapLocation = handleActions({
    LOCATION_MAPPING_ADD_START: state => {
        return Object.assign({}, state, {
            addingLocationMap: true,
            addLocationMapError: null
        });
    },
    LOCATION_MAPPING_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingLocationMap: false,
            addLocationMapError: action.payload
        });
    },
    LOCATION_MAPPING_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingLocationMap: false,
            addLocationMapError: null
        });
    }
}, {});


export const addMultipleStaff = handleActions({
    MULTIPLE_STAFF_ADD_START: state => {
        return Object.assign({}, state, {
            addingMultipleStaff: true,
            addStaffError: null
        });
    },
    MULTIPLE_STAFF_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingMultipleStaff: false,
            addStaffError: action.payload
        });
    },
    MULTIPLE_STAFF_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            addingMultipleStaff: true,
            staffAdded: action.payload.staffAdded,
            totalStaff: action.payload.totalStaff
        });
    },    
    MULTIPLE_STAFF_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingMultipleStaff: false,
            addStaffError: null
        });
    }
}, {});

export const addMultipleMappings = handleActions({
    MULTIPLE_MAPPINGS_ADD_START: state => {
        return Object.assign({}, state, {
            addingMultipleMappings: true,
            addMappingsError: null
        });
    },
    MULTIPLE_MAPPINGS_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingMultipleMappings: false,
            addMappingsError: action.payload
        });
    },
    MULTIPLE_MAPPINGS_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            addingMultipleMappings: true,
            mappingsAdded: action.payload.mappingsAdded,
            totalMappings: action.payload.totalMappings
        });
    },    
    MULTIPLE_MAPPINGS_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingMultipleMappings: false,
            addMappingsError: null
        });
    }
}, {
    addingMultipleMappings: false
});

export const bulkSendMessages = handleActions({
    BULK_SEND_MESSAGES_START: state => {
        return Object.assign({}, state, {
            sendingBulkMessages: true,
            sendBulkMessagesError: null
        });
    },
    BULK_SEND_MESSAGES_ERROR: (state, action) => {
        return Object.assign({}, state, {
            sendingBulkMessages: false,
            sendBulkMessagesError: action.payload
        });
    },
    BULK_SEND_MESSAGES_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            sendingBulkMessages: true,
            bulkMessagesSent: action.payload.messagesSent,
            totalMessages: action.payload.totalMessages,
            errorRecords: action.payload.errors
        });
    },    
    BULK_SEND_MESSAGES_SUCCESS: state => {
        return Object.assign({}, state, {
            sendingBulkMessages: false,
            sendBulkMessagesError: null
        });
    }
}, { errorRecords: [], sendingBulkMessages: false });

export const addMultipleLocations = handleActions({
    MULTIPLE_LOCATION_ADD_START: state => {
        return Object.assign({}, state, {
            addingMultipleLocations: true,
            addLocationsError: null
        });
    },
    MULTIPLE_LOCATION_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingMultipleLocations: false,
            addLocationsError: action.payload
        });
    },
    MULTIPLE_LOCATION_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            addingMultipleLocations: true,
            locationsAdded: action.payload.locationsAdded,
            totalLocations: action.payload.totalLocations
        });
    },    
    MULTIPLE_LOCATION_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingMultipleLocations: false,
            addLocationsError: null
        });
    }
}, {});

export const addPeopleToGroup = handleActions({
    MULTIPLE_PERSON_ADD_START: state => {
        return Object.assign({}, state, {
            addingPeople: true,
            addPeopleError: null
        });
    },
    MULTIPLE_PERSON_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingPeople: false,
            addPersonError: action.payload
        });
    },
    MULTIPLE_PEOPLE_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            addingPeople: true,
            peopleAdded: action.payload.peopleAdded,
            totalPeople: action.payload.totalPeople
        });
    },    
    MULTIPLE_PERSON_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingPeople: false,
            addPeopleError: null
        });
    }
}, {});

export const addPersonToGroup = handleActions({
    PERSON_TO_GROUP_ADD_START: state => {
        return Object.assign({}, state, {
            addingPerson: true,
            addPersonError: null
        });
    },
    PERSON_TO_GROUP_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingPerson: false,
            addPersonError: action.payload
        });
    },
    PERSON_TO_GROUP_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingPerson: false,
            addPersonError: null
        });
    }
}, {});

export const tempStaffAdd = handleActions({
    TEMP_STAFF_ADD_START: state => {
        return Object.assign({}, state, {
            addingStaff: true,
            addStaffError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    TEMP_STAFF_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    TEMP_STAFF_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingStaff: false,
            addStaffError: action.payload
        });
    },
    TEMP_STAFF_ADD_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            addingStaff: false,
            addStaffError: null
        });
    }
}, {});


export const addStaff = handleActions({
    STAFF_ADD_START: state => {
        return Object.assign({}, state, {
            addingStaff: true,
            addStaffError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    STAFF_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    STAFF_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingStaff: false,
            addStaffError: action.payload
        });
    },
    STAFF_ADD_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            addingStaff: false,
            addStaffError: null
        });
    }
}, {});

export const addEndorsement = handleActions({
    ENDORSEMENT_ADD_START: state => {
        return Object.assign({}, state, {
            addingEndorsement: true,
            addEndorsementError: null
        });
    },
    ENDORSEMENT_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingEndorsement: false,
            addEndorsementError: action.payload
        });
    },
    ENDORSEMENT_ADD_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            addingEndorsement: false,
            addEndorsementError: null
        });
    }
}, {});

export const updateSettings = handleActions({
    SETTINGS_UPDATE_START: state => {
        return Object.assign({}, state, {
            updatingSettings: true,
            updateSettingsError: null
        });
    },
    SETTINGS_UPDATE_ERROR: (state, action) => {
        return Object.assign({}, state, {
            updatingSettings: false,
            updateSettingsError: action.payload
        });
    },
    SETTINGS_UPDATE_SUCCESS: state => {
        return Object.assign({}, state, {
            updatingSettings: false,
            updateSettingsError: null
        });
    }
}, {});


export const updateStaff = handleActions({
    STAFF_UPDATE_START: state => {
        return Object.assign({}, state, {
            updatingStaff: true,
            updateStaffError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    STAFF_UPDATE_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    STAFF_UPDATE_ERROR: (state, action) => {
        return Object.assign({}, state, {
            updatingStaff: false,
            updateStaffError: action.payload
        });
    },
    STAFF_UPDATE_SUCCESS: state => {
        return Object.assign({}, state, {
            updatingStaff: false,
            updateStaffError: null
        });
    }
}, {});

export const viewCampaign = handleActions({
    CAMP_VIEW_START: state => {
        return Object.assign({}, state, {
            campaignData: null,
            viewingCampaign: true,
            viewCampaignError: null
        });
    },
    CAMP_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            campaignData: null,
            viewingCampaign: false,
            viewCampaignError: action.payload
        });
    },
    CAMP_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            campaignData: action.payload.campaignData[0],
            viewingCampaign: false,
            viewCampaignError: null
        });
    }
}, {});

export const viewGroup = handleActions({
    GROUP_VIEW_START: state => {
        return Object.assign({}, state, {
            groupData: null,
            peopleData: [],
            viewingGroup: true,
            viewGroupError: null
        });
    },
    GROUP_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            groupData: null,
            peopleData: [],
            viewingGroup: false,
            viewGroupError: action.payload
        });
    },
    GROUP_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            groupData: action.payload.groupData.group[0],
            peopleData: action.payload.groupData.people,
            viewingGroup: false,
            viewGroupError: null
        });
    }
}, {});

export const viewLocation = handleActions({
    LOCATION_VIEW_START: state => {
        return Object.assign({}, state, {
            locationData: null,
            viewingLocation: true,
            viewLocationError: null
        });
    },
    LOCATION_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            locationData: null,
            viewingLocation: false,
            viewLocationError: action.payload
        });
    },
    LOCATION_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            locationData: action.payload.locationData[0],
            viewingLocation: false,
            viewLocationError: null
        });
    }
}, {});


export const viewLocationReview = handleActions({
    LOCATION_REVIEW_VIEW_START: state => {
        return Object.assign({}, state, {
            reviewData: null,
            viewingReview: true,
            viewReviewError: null
        });
    },
    LOCATION_REVIEW_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            reviewData: null,
            viewingReview: false,
            viewReviewError: action.payload
        });
    },
    LOCATION_REVIEW_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            reviewData: action.payload.reviewData[0],
            viewingReview: false,
            viewReviewError: null
        });
    }
}, {});


export const viewJourney = handleActions({
    JOURNEY_VIEW_START: state => {
        return Object.assign({}, state, {
            journeyData: null,
            viewingJourney: true,
            viewJourneyError: null
        });
    },
    JOURNEY_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            journeyData: null,
            viewingJourney: false,
            viewJourneyError: action.payload
        });
    },
    JOURNEY_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            journeyData: action.payload.journeyData[0],
            viewingJourney: false,
            viewJourneyError: null
        });
    }
}, {});

export const adminFlags = handleActions({

    ADMIN_FLAGS_START: state => {
        return Object.assign({}, state, {
            flags: null,
            loading: true
        });
    },
    ADMIN_FLAGS_ERROR: (state, action) => {
        return Object.assign({}, state, {
            flags: null,
            loading: false
        });
    },
    ADMIN_FLAGS_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            flags: action.payload.adminFlags,
            loading: false
        });
    }

}, { loading: false});

export const peerLocationSummary = handleActions({
    VIEW_PEER_LOCATION_SUMMARY_START: state => {
        return Object.assign({}, state, {
            loading: true
        });
    }, 
    VIEW_PEER_LOCATION_SUMMARY_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false
        });
    }, 
    VIEW_PEER_LOCATION_SUMMARY_SUCCESS:  (state,action) => {
        return Object.assign({}, state, {
            loading: false,
            badges: action.payload.totalBadges,
            feedback: action.payload.totalFeedback,
            totalLocationStaff: action.payload.totalLocationStaff,
            totalParticipation: action.payload.staffParticipating
        });
    }
}, {
    badges: [],
    feedback: [],
    totalLocationStaff: [],
    totalParticipation: [],
    loading: false
});

export const editAdminLocations = handleActions({

    EDIT_ADMIN_LOCATION_START: state => {
        return Object.assign({}, state, {
            loading: true
        });
    },
    EDIT_ADMIN_LOCATION_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false
        });
    },
    EDIT_ADMIN_LOCATION_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            loading: false,
            locations: action.payload.userAttributes.locations
        });
    }

}, {
    locations: [],
    loading: false
});

export const pwdRestrictions = handleActions({

    PWD_RESTRICTION_VIEW_START: state => {
        return Object.assign({}, state, {
            pwdRestrictions: null
        });
    },
    PWD_RESTRICTION_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            pwdRestrictions: null
        });
    },
    PWD_RESTRICTION_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            pwdRestrictions: action.payload.pwdRestrictions
        });
    }

}, {});

export const viewAdmin = handleActions({
    ADMIN_VIEW_START: state => {
        return Object.assign({}, state, {
            adminData: null,
            viewingAdmin: true,
            viewAdminError: null,
            pwdRestrictions: null
        });
    },
    ADMIN_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            adminData: null,
            viewingAdmin: false,
            viewAdminError: action.payload,
            pwdRestrictions: null
        });
    },
    ADMIN_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            adminData: action.payload.adminData[0],
            pwdRestrictions: action.payload.pwdRestrictions,
            viewingAdmin: false,
            viewAdminError: null
        });
    }
}, {});

export const viewEhrMapping = handleActions({
    EHRMAPPING_VIEW_START: state => {
        return Object.assign({}, state, {
            mappingInfo: null,
            viewingEhrMapping: true,
            viewEhrMappingError: null
        });
    },
    EHRMAPPING_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            mappingInfo: null,
            viewingEhrMapping: false,
            viewEhrMappingError: action.payload
        });
    },
    EHRMAPPING_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            mappingInfo: action.payload.mappingInfo[0],
            viewingEhrMapping: false,
            viewEhrMappingError: null
        });
    }
}, {});

export const viewStaff = handleActions({
    STAFF_VIEW_START: state => {
        return Object.assign({}, state, {
            staffData: null,
            pointHistory: null,
            viewingStaff: true,
            totalPoints: 0,
            viewStaffError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    STAFF_VIEW_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            staffData: null,
            pointHistory: null,
            totalPoints: 0,
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    STAFF_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            staffData: null,
            totalPoints: 0,
            pointHistory: null,
            viewingStaff: false,
            viewStaffError: action.payload
        });
    },
    STAFF_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            staffData: action.payload.staffData.staff[0],
            pointHistory: action.payload.staffData.pointHistory,
            totalPoints: action.payload.staffData.points[0].total,
            viewingStaff: false,
            viewStaffError: null
        });
    },
    STAFF_UPDATE_POINTS: (state, action) => {
        return Object.assign({}, state, {
            staffData: action.payload.staffData.staff[0],
            pointHistory: action.payload.staffData.pointHistory,
            totalPoints: action.payload.staffData.points[0].total,
            viewingStaff: false,
            viewStaffError: null
        });
    }
}, {});

export const viewEndorsement = handleActions({
    ENDORSEMENT_VIEW_START: state => {
        return Object.assign({}, state, {
            badgeData: null,
            viewingBadge: true,
            viewBadgeError: null
        });
    },
    ENDORSEMENT_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            badgeData: null,
            viewingBadge: false,
            viewBadgeError: action.payload
        });
    },
    ENDORSEMENT_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            badgeData: action.payload.badgeData[0],
            viewingBadge: false,
            viewBadgeError: null
        });
    }
}, {});


export const theWinner = handleActions({
    DRAW_WINNER_START: state => {
        return Object.assign({}, state, {
            winner: null,
            winnerLoading: true,
            winnerError: null
        });
    },
    DRAW_WINNER_ERROR: (state, action) => {
        return Object.assign({}, state, {
            winner: null,
            winnerLoading: false,
            winnerError: action.payload
        });
    },
    DRAW_WINNER_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            winner: action.payload.winner,
            winnerLoading: false,
            winnerError: null
        });
    },
    SAVE_WINNER_START: state => {
        return Object.assign({}, state, {
            winnerSaved: false
        });
    },
    SAVE_WINNER_ERROR: (state, action) => {
        return Object.assign({}, state, {
            winnerSaved: false
        });
    },
    SAVE_WINNER_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            winnerSaved:true
        });
    }

}, { winner: null });

export const settingsEndorsements = handleActions({
    SETTINGS_VIEW_ENDORSEMENT_SUCCESS: (state,action) => {
        return Object.assign({}, state,{
            endorsements: action.payload.endorsements
        })
    }
}, { endorsements: [] });

export const settingsPage = handleActions({
    SETTINGS_VIEW_START: state => {
        return Object.assign({}, state, {
            settingsData: null,
            settingsLoading: true
        });
    },
    SETTINGS_VIEW_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            settingsData: null,
            settingsLoading: true
        });
    },
    SETTINGS_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            settingsData: null,
            settingsLoading: false
        });
    },
    SETTINGS_VIEW_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            settingsData: action.payload.settings,
            settingsLoading: false
        });
    }
}, { });

export const viewPhotoRequest = handleActions({
    REQUEST_PHOTO_START: state => {
        return Object.assign({}, state, {
            requestData: null,
            requestingData: true
        });
    },
    REQUEST_PHOTO_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            requestData: null,
            requestingData: true
        });
    },
    REQUEST_PHOTO_ERROR: (state, action) => {
        return Object.assign({}, state, {
            requestData: null,
            requestingData: false
        });
    },
    REQUEST_PHOTO_SUCCESS: (state,action) => {
        var returnedData = action.payload.requestUrlDetails;
        return Object.assign({}, state, {
            requestData: returnedData,
            requestingData: false
        });
    },
}, { });

export const personaDetails = handleActions({
    UPDATE_SURVEY_PERSONA_START: state => {
        return Object.assign({}, state, {
            savedSurvey: null
        });
    },
    UPDATE_SURVEY_PERSONA_ERROR: (state, action) => {
        return Object.assign({}, state, {
            savedSurvey: null
        });
    },
    UPDATE_SURVEY_PRESONA_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            savedSurvey: null
        });
    }

}, { });

export const survey = handleActions({
    SURVEY_BEGIN_START: state => {
        return Object.assign({}, state, {
            surveyData: null,
            surveyLoading: true
        });
    },
    SURVEY_BEGIN_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            surveyData: null,
            surveyLoading: true
        });
    },
    SURVEY_BEGIN_ERROR: (state, action) => {
        return Object.assign({}, state, {
            surveyData: null,
            surveyLoading: false
        });
    },
    SURVEY_BEGIN_SUCCESS: (state,action) => {
        var returnedData = action.payload.surveyUrlDetails;

        if(returnedData===undefined){
            returnedData = action.payload.orgSurvey;
        }
        return Object.assign({}, state, {
            surveyData: returnedData,
            surveyLoading: false
        });
    },
    UPDATE_SURVEY_START: state => {
        return Object.assign({}, state, {
            savedSurvey: null
        });
    },
    UPDATE_SURVEY_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            savedSurvey: null
        });
    },
    UPDATE_SURVEY_ERROR: (state, action) => {
        return Object.assign({}, state, {
            savedSurvey: null
        });
    },
    UPDATE_SURVEY_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            savedSurvey: action.payload.surveyUrlDetails
        });
    }
}, { surveyData: null, savedSurvey: null });


export const staff = handleActions({
    GET_DEPT_STAFF_START: state => {
        return Object.assign({}, state, {
            staffData: null,
            statffLoading: true
        });
    },
    GET_DEPT_STAFF_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            staffData: null,
            staffLoading: true
        });
    },
    GET_DEPT_STAFF_ERROR: (state, action) => {
        return Object.assign({}, state, {
            staffData: null,
            staffLoading: false
        });
    },
    GET_DEPT_STAFF_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            staffData: action.payload.staffDetails,
            staffLoading: false
        });
    }
}, {});


export const deleteStaff = handleActions({
    STAFF_DEL_START: state => {
        return Object.assign({}, state, {
            staffData: null,
            deletingStaff: true,
            deleteStaffError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    STAFF_DEL_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            staffData: null,
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    STAFF_DEL_ERROR: (state, action) => {
        return Object.assign({}, state, {
            staffData: null,
            deletingStaff: false,
            deleteStaffError: action.payload
        });
    },
    STAFF_DEL_SUCCESS: (state,action) => {
        return Object.assign({}, state, {
            staffData: action.payload.staffData,
            deletingStaff: false,
            deleteStaffError: null
        });
    }
}, {});

export const editDepartment = handleActions({
    DEPARTMENT_EDIT_START: state => {
        return Object.assign({}, state, {
            editingDepartment: true,
            editDepartmentError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    DEPARTMENT_EDIT_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    DEPARTMENT_EDIT_ERROR: (state, action) => {
        return Object.assign({}, state, {
            editingDepartment: false,
            editDepartmentError: action.payload
        });
    },
    DEPARTMENT_EDIT_SUCCESS: state => {
        return Object.assign({}, state, {
            editingDepartment: false,
            editDepartmentError: null
        });
    }
}, {});

export const addDepartment = handleActions({
    DEPARTMENT_ADD_START: state => {
        return Object.assign({}, state, {
            addingDepartment: true,
            addDepartmentError: null,
            bytesLoaded: null,
            bytesTotal: null
        });
    },
    DEPARTMENT_ADD_PROGRESS: (state, action) => {
        return Object.assign({}, state, {
            bytesLoaded: action.payload.bytesLoaded,
            bytesTotal: action.payload.bytesTotal
        });
    },
    DEPARTMENT_ADD_ERROR: (state, action) => {
        return Object.assign({}, state, {
            addingDepartment: false,
            addDepartmentError: action.payload
        });
    },
    DEPARTMENT_ADD_SUCCESS: state => {
        return Object.assign({}, state, {
            addingDepartment: false,
            addDepartmentError: null
        });
    }
}, {});

export const emailPatient = handleActions({
    EMAIL_PATIENT_START: state => {
        return {
            loading: true,
            error: null
        };
    },
    EMAIL_PATIENT_ERROR: (state, action) => {
        return {
            loading: false,
            error: action.payload
        };
    },
    EMAIL_PATIENT_SUCCESS: () => {
        return {
            loading: false,
            error: null
        };
    }
}, {loading: false, error: null});


export const groupEmailPatient = handleActions({
    GROUP_EMAIL_PATIENT_START: state => {
        return {
            loading: true,
            error: null
        };
    },
    GROUP_EMAIL_PATIENT_ERROR: (state, action) => {
        return {
            loading: false,
            error: action.payload
        };
    },
    GROUP_EMAIL_PATIENT_SUCCESS: () => {
        return {
            loading: false,
            error: null
        };
    }
}, {loading: false, error: null});

export const peerFeedback = handleActions({
    SEND_STAFF_FEEDBACK_IND_START: state => {
        return {
            sending: true,
            error: null
        };
    },
    SEND_STAFF_FEEDBACK_IND_ERROR: (state, action) => {
        return {
            sending: false,
            error: action.payload
        };
    },
    SEND_STAFF_FEEDBACK_IND_SUCCESS: () => {
        return {
            sending: false,
            error: null
        };
    },
    SEND_STAFF_FEEDBACK_DEPT_START: state => {
        return {
            sending: true,
            error: null
        };
    },
    SEND_STAFF_FEEDBACK_DEPT_ERROR: (state, action) => {
        return {
            sending: false,
            error: action.payload
        };
    },
    SEND_STAFF_FEEDBACK_DEPT_SUCCESS: () => {
        return {
            sending: false,
            error: null
        };
    }
}, {sending: false, error: null});


export const textPatient = handleActions({
    TEXT_PATIENT_START: state => {
        return {
            loading: true,
            error: null
        };
    },
    TEXT_PATIENT_ERROR: (state, action) => {
        return {
            loading: false,
            error: action.payload
        };
    },
    TEXT_PATIENT_SUCCESS: () => {
        return {
            loading: false,
            error: null
        };
    }
}, {loading: false, error: null});

export const createAdmin = handleActions({
    CREATE_ADMIN_START: state => {
        return {
            loading: true,
            error: null
        };
    },
    CREATE_ADMIN_ERROR: (state, action) => {
        return {
            loading: false,
            error: action.payload
        };
    },
    CREATE_ADMIN_SUCCESS: () => {
        return {
            loading: false,
            error: null
        };
    }
}, {loading: false, error: null});

export const login = handleActions({
    LOGIN_START: (state) => {
        return Object.assign({}, state, {
            loggingIn: true,
            loginError: null
        });
    },
    LOGIN_FAILURE: (state, action) => {
        return Object.assign({}, state, {
            loggingIn: false,
            tvClient: null,
            user: null,
            reviewFlag: false,
            intakeFlag: false,
            loginError: action.payload,
            mfaRequired: (action.payload && action.payload.type === 'USER.MFA_CODE_REQUIRED') ? true : false,
            resetRequired: (action.payload && action.payload.type==='PWD_RESET_REQ') ? true : false
        });
    },
    LOGIN_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loggingIn: false,
            tvClient: action.payload.tvClient,
            user: action.payload.user,
            reviewFlag: action.payload.reviewFlag,
            intakeFlag: action.payload.intakeFlag,
            reputationFlag: action.payload.reputationFlag,
            loginError: null,
            mfaRequired: false,
            resetRequired: false
        });
    },
    LOGOUT: state => {
        return Object.assign({}, state, {
            tvClient: null,
            user: null,
            mfaRequired: false,
            reviewFlag: false,
            reputationFlag: false,
            resetRequired: false
        });
    }
}, {});

export const flash = handleActions({
    DISPLAY_FLASH_MESSAGE: (state, action) => {
        return [action.payload, ...state];
    },
    REMOVE_FLASH_MESSAGE: (state, action) => {
        return state.filter(flash => flash.key === action.payload);
    }
}, []);

export const patientSignup = handleActions({
    PATIENT_SIGNUP_START: () => {
        return {
            loading: true,
            error: null
        };
    },
    PATIENT_SIGNUP_ERROR: (state, action) => {
        return {
            loading: false,
            error: action.payload
        };
    },
    LOGIN_SUCCESS: () => {
        return {
            loading: false,
            error: null
        };
    }
}, {loading: false, error: null});


export const campaignList = handleActions({
    CAMP_LIST_START: state => {
        return Object.assign({}, state, {
            campaigns: [],
            loading: true,
            error: null,
        });
    },
    CAMP_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    CAMP_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            campaigns: action.payload.campaigns,
            error: null
        });
    }
}, {
    campaigns: []
});

export const groupList = handleActions({
    GROUP_LIST_START: state => {
        return Object.assign({}, state, {
            groups: [],
            loading: true,
            error: null,
        });
    },
    GROUP_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    GROUP_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            groups: action.payload.groups,
            error: null
        });
    }
}, {
    groups: []
});

export const staffPointHistory = handleActions({
    STAFF_POINT_LIST_START: state => {
        return Object.assign({}, state, {
            history: [],
            loading: true,
            error: null,
        });
    },
    STAFF_POINT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    STAFF_POINT_LIST_SUCCESS: (state, action) => {
        let theHistory = [];
        let isMore = false;
        console.log(state.pointHistory);
        if(action.payload.history.pointHistory.length>0){
            theHistory = state.pointHistory.concat(action.payload.history.pointHistory);
            console.log(action.payload.history);
            isMore = theHistory.length < action.payload.history.pointHistory[0].full_count;            
        } else{
            isMore = false;
            theHistory = state.pointHistory;
        }


        return Object.assign({}, state, {
            loading: false,
            pointHistory: theHistory,
            error: null,
            more: isMore
        });
    }
}, {
    pointHistory: [],
    loading: false,
    more: true,
});



export const surveyLocations = handleActions({
    SURVEY_LOCATION_LIST_START: state => {
        return Object.assign({}, state, {
            locations: [],
            loading: true,
            error: null,
        });
    },
    SURVEY_LOCATION_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    SURVEY_LOCATION_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            locations: action.payload.locations,
            error: null
        });
    }
}, {
    locations: []
});


export const locationList = handleActions({
    LOCATION_LIST_START: state => {
        return Object.assign({}, state, {
            locations: [],
            loading: true,
            error: null,
        });
    },
    LOCATION_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    LOCATION_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            locations: action.payload.locations,
            error: null
        });
    }
}, {
    locations: []
});

export const locationMappingGroupList = handleActions({
    LOCATION_MAPPING_GROUP_LIST_START: state => {
        return Object.assign({}, state, {
            mappings: [],
            loading: true,
            error: null
        });
    },
    LOCATION_MAPPING_GROUP_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    LOCATION_MAPPING_GROUP_LIST_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            mappings: action.payload.locationMappingGroups,
            error: null
        });
    }
}, {
    mappings: []
});

export const publicReviewReportData = handleActions({
    PUBLIC_REVIEW_REPORT_START: state => {
        return Object.assign({}, state, {
            results: [],
            loading: true,
            error: null
        });
    },
    PUBLIC_REVIEW_REPORT_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    PUBLIC_REVIEW_REPORT_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            results: action.payload.reviewRecords,
            aggregateResult: action.payload.aggregateResult,
            error: null
        });
    }
}, {
    results: [],
    aggregateResult: {}
});


export const listRecentSurveys = handleActions({
    LIST_RECENT_SURVEYS_START: state => {
        return Object.assign({}, state, {
            recentSurveys: [],
            loading: true,
            error: null,
        });
    },
    LIST_RECENT_SURVEYS_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload,
            recentSurveys: []
        });
    },
    LIST_RECENT_SURVEYS_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            recentSurveys: action.payload.recentSurveys,
            error: null
        });
    }
}, {
    recentSurveys: []
});

export const listEmployers = handleActions({
    LIST_EMPLOYERS_START: state => {
        return Object.assign({}, state, {
            employers: [],
            loading: true,
            error: null,
        });
    },
    LIST_EMPLOYERS_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload,
            employers: []
        });
    },
    LIST_EMPLOYERS_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            employers: action.payload.employers,
            error: null
        });
    }
}, {
    employers: []
});



export const groupJourneyList = handleActions({
    JOURNEY_GROUP_LIST_START: state => {
        return Object.assign({}, state, {
            journeys: [],
            loading: true,
            error: null,
        });
    },
    JOURNEY_GROUP_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    JOURNEY_GROUP_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            journeys: action.payload.journeys,
            error: null
        });
    }
}, {
    journeys: []
});

export const locationMappingList = handleActions({
    LOCATION_MAPPING_LIST_START: state => {
        return Object.assign({}, state, {
            locationMappings: [],
            loading: true,
            error: null,
        });
    },
    LOCATION_MAPPING_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    LOCATION_MAPPING_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            locationMappings: action.payload.locationMapping,
            error: null
        });
    }
}, {
    locationMappings: []
});


export const enablePcc = handleActions({
    PCC_ENABLE_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null
        });
    },
    PCC_ENABLE_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    PCC_ENABLE_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            enabled: action.payload.pccEnabled,
            error: null
        });
    }
}, {
    loading: false,
    enabled: false
});

export const journeyMappingList = handleActions({
    JOURNEY_MAPPING_LIST_START: state => {
        return Object.assign({}, state, {
            journeyMappings: [],
            loading: true,
            error: null,
        });
    },
    JOURNEY_MAPPING_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    JOURNEY_MAPPING_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            journeyMappings: action.payload.journeyMapping,
            error: null
        });
    }
}, {
    journeyMappings: []
});

export const journeyList = handleActions({
    JOURNEY_LIST_START: state => {
        return Object.assign({}, state, {
            journeys: [],
            loading: true,
            error: null,
        });
    },
    JOURNEY_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    JOURNEY_LIST_SUCCESS: (state, action) => {
       
        return Object.assign({}, state, {
            loading: false,
            journeys: action.payload.journeys,
            error: null
        });
    }
}, {
    journeys: []
});

export const reviewTokens = handleActions({
    REVIEW_TOKEN_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null
        });
    },
    REVIEW_TOKEN_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    REVIEW_TOKEN_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            token: action.payload.token,
            error: null
        });
    },
    REVIEW_JWT_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null
        });
    },
    REVIEW_JWT_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    REVIEW_JWT_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            jwt: 'https://reputation.welliq.co?token=' + action.payload.jwt,
            error: null
        });
    }
}, {
    token: {},
    jwt: undefined,
    loading: false
});


export const reviewLocationList = handleActions({
    REVIEW_LOCATION_LIST_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null
        });
    },
    REVIEW_LOCATION_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    REVIEW_LOCATION_LIST_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            locations: action.payload.locations,
            error: null
        });
    }
}, {
    locations: []
});


export const reviewAccountList = handleActions({
    REVIEW_ACCOUNT_LIST_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null
        });
    },
    REVIEW_ACCOUNT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    REVIEW_ACCOUNT_LIST_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            accounts: action.payload.accounts,
            error: null
        });
    }
}, {
    accounts: []
});


export const locationReviewList = handleActions({
    LOCATION_REVIEW_LIST_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null,
            reviews: []
        });
    },
    LOCATION_REVIEW_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    LOCATION_REVIEW_LIST_SUCCESS: (state, action) => {
        var reviewList = action.payload.reviews;
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        var finalReviewList = reviewList.sort((a,b) => new Date(b.review.updateTime) - new Date(a.review.updateTime));
        return Object.assign({}, state, {
            loading: false,
            paginationInfo: paginationInfo,
            reviews: finalReviewList,
            error: null
        });
    }
}, {
    reviews: [],
    paginationInfo: {},
    loading: false
});



export const deptList = handleActions({
    DEPT_LIST_START: state => {
        return Object.assign({}, state, {
            departments: [],
            loading: true,
            error: null,
        });
    },
    DEPT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    DEPT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        paginationInfo.current_page = Math.max(1, paginationInfo.current_page);

        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;

        return Object.assign({}, state, {
            loading: false,
            departments: action.payload.departments,
            paginationInfo: paginationInfo,
            error: null,
        });
    }
}, {
    departments: [],
    paginationInfo: {},
    loading: false
});

export const staffList = handleActions({
    STAFF_LIST_START: state => {
        return Object.assign({}, state, {
            staff: [],
            loading: true,
            error: null,
            showPeerReview: false
        });
    },
    STAFF_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload,
            showPeerReview: false
        });
    },
    STAFF_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        var showPeerReview = action.payload.showPeerReview;
        if(showPeerReview!==undefined && showPeerReview!==null){
            if(showPeerReview===true || showPeerReview==='true'){
                showPeerReview = true;
            } else{
                showPeerReview = false;
            }
        }
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            staff: action.payload.staff,
            paginationInfo: paginationInfo,
            showPeerReview: showPeerReview,
            error: null,
        });
    }
}, {
    staff: [],
    paginationInfo: {},
    showPeerReview: false
});


export const tempStaffList = handleActions({
    TEMP_STAFF_LIST_START: state => {
        return Object.assign({}, state, {
            staff: [],
            loading: true,
            error: null,
            showPeerReview: false
        });
    },
    TEMP_STAFF_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload,
            showPeerReview: false
        });
    },
    TEMP_STAFF_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;

        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            staff: action.payload.staff,
            paginationInfo: paginationInfo,
            error: null,
        });
    }
}, {
    staff: [],
    paginationInfo: {}
});



export const eventReportData = handleActions({
    ACTIONABLE_EVENTS_LIST_START: state => {
        return Object.assign({}, state, {
            eventsReportData: [],
            loading: true,
            error: null
        });
    },
    ACTIONABLE_EVENTS_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    ACTIONABLE_EVENTS_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            eventsReportData: action.payload.eventListData,
            allReportData: action.payload.allData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    eventsReportData: [],
    paginationInfo: {}
});

export const employerWrittenFeedbackData = handleActions({
    EMPLOYER_WRITTEN_FEEDBACK_LIST_START: state => {
        return Object.assign({}, state, {
            writtenFeedback: [],
            loading: true,
            error: null
        });
    },
    EMPLOYER_WRITTEN_FEEDBACK_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    EMPLOYER_WRITTEN_FEEDBACK_LIST_SUCCESS: (state, action) => {
        
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            writtenFeedback: action.payload.writtenFeedbackData,
            error: null
        });
    }
}, {
    writtenFeedback: []
});


export const campaignResults = handleActions({
    CAMPAIGN_RESULTS_LIST_START: state => {
        return Object.assign({}, state, {
            data: [],
            loading: true,
            error: null
        });
    },
    CAMPAIGN_RESULTS_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    CAMPAIGN_RESULTS_LIST_SUCCESS: (state, action) => {

        return Object.assign({}, state, {
            loading: false,
            data: action.payload.results,
            error: null
        });
    }
}, {
    data: []
});




export const deptFeedbackData = handleActions({
    DEPT_FEEDBACK_LIST_START: state => {
        return Object.assign({}, state, {
            deptFeedbackData: [],
            exportDeptFeedbackData: [],
            loading: true,
            error: null
        });
    },
    DEPT_FEEDBACK_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    DEPT_FEEDBACK_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
                let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            deptFeedbackData: action.payload.deptFeedbackData,
            exportDeptFeedbackData: action.payload.exportDeptFeedbackData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    deptFeedbackData: [],
    exportDeptFeedbackData: [],
    paginationInfo: {}
});

export const staffFeedbackData = handleActions({
    STAFF_FEEDBACK_LIST_START: state => {
        return Object.assign({}, state, {
            staffFeedbackData: [],
            exportStaffFeedbackData: [],
            loading: true,
            error: null
        });
    },
    STAFF_FEEDBACK_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    STAFF_FEEDBACK_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        var writtenScore = 0;
        if(action.payload.overallWrittenFeedbackScore!==undefined && action.payload.overallWrittenFeedbackScore!==null){
            writtenScore = action.payload.overallWrittenFeedbackScore;
        }

        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            staffFeedbackData: action.payload.staffFeedbackData,
            exportStaffFeedbackData: action.payload.exportData,
            paginationInfo: paginationInfo,
            error: null,
            overallWrittenFeedbackScore: writtenScore
        });
    }
}, {
    staffFeedbackData: [],
    exportStaffFeedbackData: [],
    paginationInfo: {},
    overallWrittenFeedbackScore: 0
});

export const staffPeerFeedbackData = handleActions({
    STAFF_PEER_FEEDBACK_LIST_START: state => {
        return Object.assign({}, state, {
            staffFeedbackData: [],
            exportStaffFeedbackData: [],
            loading: true,
            error: null
        });
    },
    STAFF_PEER_FEEDBACK_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    STAFF_PEER_FEEDBACK_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            staffFeedbackData: action.payload.staffFeedbackData,
            exportStaffFeedbackData: action.payload.exportData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    staffFeedbackData: [],
    exportStaffFeedbackData: [],
    paginationInfo: {}
});

export const staffPeerEngagementData = handleActions({
    STAFF_PEER_ENGAGEMENT_LIST_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            exportStaffEngagementData: [],
            loading: true,
            error: null
        });
    },
    STAFF_PEER_ENGAGEMENT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    STAFF_PEER_ENGAGEMENT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        
        return Object.assign({}, state, {
            loading: false,
            engagementData: action.payload.staffEngagementData,
            exportStaffEngagementData: action.payload.exportStaffEngagementData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    engagementData: [],
    exportStaffEngagementData: [],
    paginationInfo: {}
});


export const staffPeerPointData = handleActions({
    STAFF_PEER_POINT_LIST_START: state => {
        return Object.assign({}, state, {
            pointData: [],
            exportStaffPointData: [],
            loading: true,
            error: null
        });
    },
    STAFF_PEER_POINT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    STAFF_PEER_POINT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        
        return Object.assign({}, state, {
            loading: false,
            pointData: action.payload.staffPointRecords,
            exportStaffPointData: action.payload.exportStaffPointData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    pointData: [],
    exportStaffPointData: [],
    paginationInfo: {}
});

export const intakeStatsList = handleActions({
    LIST_INTAKE_STATS_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            exportStaffEngagementData: [],
            loading: true,
            error: null
        });
    },
    LIST_INTAKE_STATS_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    LIST_INTAKE_STATS_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        
        return Object.assign({}, state, {
            loading: false,
            badgeDetails: action.payload.badgeDetails,
            overallData: action.payload.overallData,
            surveyList: action.payload.surveyList,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    overallData: {},
    badgeDetails: [],
    surveyList: [],
    paginationInfo: {}    
});

export const staffEngagementData = handleActions({
    STAFF_ENGAGEMENT_LIST_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            exportStaffEngagementData: [],
            loading: true,
            error: null
        });
    },
    STAFF_ENGAGEMENT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    STAFF_ENGAGEMENT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        
        return Object.assign({}, state, {
            loading: false,
            engagementData: action.payload.staffEngagementData,
            exportStaffEngagementData: action.payload.exportStaffEngagementData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    engagementData: [],
    exportStaffEngagementData: [],
    paginationInfo: {}
});

export const individualStaffEngagementData = handleActions({
    INDIVIDUAL_STAFF_ENGAGEMENT_LIST_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            exportStaffEngagementData: [],
            loading: true,
            error: null
        });
    },
    INDIVIDUAL_STAFF_ENGAGEMENT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    INDIVIDUAL_STAFF_ENGAGEMENT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        
        return Object.assign({}, state, {
            loading: false,
            engagementData: action.payload.staffEngagementData,
            exportStaffEngagementData: action.payload.exportStaffEngagementData,
            paginationInfo: paginationInfo,
            error: null,
            badgeWeightedValue: action.payload.badgeWeightedValue
        });
    }
}, {
    engagementData: [],
    exportStaffEngagementData: [],
    paginationInfo: {},
    badgeWeightedValue: 0
});

export const deptEngagementData = handleActions({
    DEPT_ENGAGEMENT_LIST_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            loading: true,
            error: null
        });
    },
    DEPT_ENGAGEMENT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    DEPT_ENGAGEMENT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;

        return Object.assign({}, state, {
            loading: false,
            engagementData: action.payload.deptEngagementData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    engagementData: [],
    paginationInfo: {}
});

export const deptPeerEngagementData = handleActions({
    DEPT_PEER_ENGAGEMENT_LIST_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            loading: true,
            error: null
        });
    },
    DEPT_PEER_ENGAGEMENT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    DEPT_PEER_ENGAGEMENT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;

        return Object.assign({}, state, {
            loading: false,
            engagementData: action.payload.deptEngagementData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    engagementData: [],
    paginationInfo: {}
});


export const employerDeptEngagementData = handleActions({
    EMPLOYER_DEPT_ENGAGEMENT_LIST_START: state => {
        return Object.assign({}, state, {
            engagementData: [],
            loading: true,
            error: null
        });
    },
    EMPLOYER_DEPT_ENGAGEMENT_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    EMPLOYER_DEPT_ENGAGEMENT_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            engagementData: action.payload.deptEngagementData,
            paginationInfo: paginationInfo,
            error: null
        });
    }
}, {
    engagementData: [],
    paginationInfo: {}
});


export const messageList = handleActions({
    MESSAGE_LIST_START: state => {
        return Object.assign({}, state, {
            messages: [],
            loading: true,
            error: null,
        });
    },
    MESSAGE_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    MESSAGE_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            messages: action.payload.messages,
            paginationInfo: paginationInfo,
            error: null,
        });
    }
}, {
    messages: [],
    loading: false,
    paginationInfo: {}
});

export const surveyIntakeList = handleActions({
    SURVEY_INTAKE_LIST_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null,
        });
    },
    SURVEY_INTAKE_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    SURVEY_INTAKE_LIST_SUCCESS: (state, action) => {
        if(state.location_id===0){
            const paginationInfo = action.payload.info;
            let pageList = [];
            for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
              pageList.push({'value': i });            
            }
            paginationInfo.pageList = pageList;
            
            //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
            return Object.assign({}, state, {
                loading: false,
                surveys: action.payload.surveys,
                paginationInfo: paginationInfo,
                error: null
            });
        } else{
            return Object.assign({}, state, { });            
        }
    },
    SURVEY_INTAKE_LIST_LOCATION_CHANGE: (state, action) => {
        var locationIntakes = [];

        if(action.payload.locationId!==0){
                    for(var i=0; i<state.location_data.bylocations.length; i++){
                        if(parseInt(state.location_data.bylocations[i].location_id)===parseInt(action.payload.locationId)){
                            locationIntakes = state.location_data.bylocations[i].intakes;
                            break;
                        }
                    }    
        } else{
            locationIntakes = action.payload.allIntakes.intakes; 
        }

        return Object.assign({}, state, {
            location_id: action.payload.locationId,
            surveys: locationIntakes,
            location_data: state.location_data,
            loading: false,
            error: null
        });
    },    
    SURVEY_LOCATION_INTAKE_LIST_SUCCESS: (state, action) => {
            if(state.location_id!==0){
                var locationIntakes = [];
                for(var i=0; i<action.payload.bylocations.length; i++){
                    if(action.payload.bylocations[i].location_id===state.location_id){
                        locationIntakes = action.payload.bylocations[i].intakes;
                        break;
                    }
                }
                return Object.assign({}, state, {
                    loading: false,
                    surveys: locationIntakes,
                    location_data: action.payload,
                    error: null,
                });

                
            } else{
                return Object.assign({}, state, {  surveys: state.surveys, location_data: action.payload}); 
            }
                       
    }    
}, {
    surveys: [],
    location_data: [],
    location_id: 0,
    paginationInfo: {}
});


export const surveyList = handleActions({
    SURVEY_LIST_START: state => {
        return Object.assign({}, state, {
            surveys: [],
            winners: [],
            loading: true,
            error: null,
        });
    },
    SURVEY_LIST_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    SURVEY_LIST_SUCCESS: (state, action) => {
        const paginationInfo = action.payload.info;
        let pageList = [];
        for(var i=1; i<=parseInt(paginationInfo.num_pages); i++){
          pageList.push({'value': i });            
        }
        paginationInfo.pageList = pageList;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            surveys: action.payload.surveys,
            paginationInfo: paginationInfo,
            winners: action.payload.winners,
            error: null,
        });
    }
}, {
    surveys: [],
    winners: [],
    paginationInfo: {}
});

export const peerLocationView = handleActions({
    PEER_LOCATION_DETAILS_VIEW_START: state => {
        return Object.assign({}, state, {
            currentPeerLocationId: null,
            currentPeerLocationDetail: null,
            currentPeerLocationEndorsements: null,
            loading: true,
            error: null,
        });
    },
    PEER_LOCATION_DETAILS_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    PEER_LOCATION_DETAILS_VIEW_SUCCESS: (state, action) => {
        //const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            currentPeerLocationId: action.payload.locationId,
            error: null
        });
    },
    PEER_LOCATION_DETAILS_BADGES_SUCCESS: (state, action) => {
        //const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            currentPeerLocationEndorsements: action.payload.badges,
            currentPeerLocationEndorsementLoading: false,
            error: null
        });
    },
    PEER_LOCATION_DETAILS_BADGES_START: state => {
        return Object.assign({}, state, {
            currentPeerLocationEndorsements: null,
            currentPeerLocationEndorsementLoading: true,
            error: null,
        });
    },
    PEER_LOCATION_DETAILS_ACTIVITY_SUCCESS: (state, action) => {
        //const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            currentPeerLocationActivity: action.payload.staffList,
            currentPeerLocationActivityLoading: false,
            error: null
        });
    },
    PEER_LOCATION_DETAILS_ACTIVITY_START: state => {
        return Object.assign({}, state, {
            currentPeerLocationActivity: null,
            currentPeerLocationActivityLoading: true,
            error: null,
        });
    },
    PEER_LOCATION_DETAILS_FEEDBACK_SUCCESS: (state, action) => {
        //const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            currentPeerLocationFeedback: action.payload.feedbackDetails,
            currentPeerLocationFeedbackLoading: false,
            error: null
        });
    },
    PEER_LOCATION_DETAILS_FEEDBACK_START: state => {
        return Object.assign({}, state, {
            currentPeerLocationFeedback: null,
            currentPeerLocationFeedbackLoading: true,
            error: null,
        });
    }
}, {
    loading: false,
    currentPeerLocationId: null,
    currentPeerLocationDetail: null,
    currentPeerLocationEndorsements: null,
    currentPeerLocationEndorsementLoading: false,
    currentPeerLocationActivity: null,
    currentPeerLocationActivityLoading: false,
    currentPeerLocationFeedback: null,
    currentPeerLocationFeedbackLoading: false
});

export const surveyView = handleActions({
    SURVEY_VIEW_START: state => {
        return Object.assign({}, state, {
            currentSurvey: null,
            loading: true,
            error: null,
        });
    },
    SURVEY_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    SURVEY_VIEW_SUCCESS: (state, action) => {
        //const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            currentSurvey: action.payload.currentSurvey,
            error: null
        });
    }
}, {
    currentSurvey: null
});

export const intakeView = handleActions({
    INTAKE_VIEW_START: state => {
        return Object.assign({}, state, {
            currentSurvey: null,
            loading: true,
            error: null,
        });
    },
    INTAKE_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    INTAKE_VIEW_SUCCESS: (state, action) => {
        //const paginationInfo = action.payload.info;
        //paginationInfo.current_page = 1; //Math.max(1, paginationInfo.current_page);
        return Object.assign({}, state, {
            loading: false,
            currentSurvey: action.payload.currentSurvey,
            error: null
        });
    }
}, {
    currentSurvey: null
});

export const eventView = handleActions({
    EVENT_VIEW_START: state => {
        return Object.assign({}, state, {
            currentEvent: null,
            loading: true,
            error: null,
        });
    },
    EVENT_VIEW_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    EVENT_VIEW_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            currentEvent: action.payload.currentSurvey,
            error: null
        });
    }
}, {
    currentEvent: null
});

export const deleteAdmin = handleActions({
    DELETE_ADMIN_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null,
        });
    },
    DELETE_ADMIN_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    DELETE_ADMIN_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: null
        });
    }
}, {});


export const eventUpdate = handleActions({
    EVENT_UPDATE_START: state => {
        return Object.assign({}, state, {
            loading: true,
            error: null,
        });
    },
    EVENT_UPDATE_ERROR: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: action.payload
        });
    },
    EVENT_UPDATE_SUCCESS: (state, action) => {
        return Object.assign({}, state, {
            loading: false,
            error: null
        });
    }
}, {});