import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import {createGroup} from "../../actions";

class NewGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: { 'groupName': false }
        };
        this.inlineError = false;
    }

    newGroup(e) {
        e.preventDefault();

        if(!this.groupName.value){
            this.setState({ errors: {'groupName': true}});
        }  else{
            this.props.createGroup(
                this.props.tvClient,
                this.groupName.value
            );           
        }
    }

    render() {
        return <div>
            <h1 className="page-header">
                Add New Group
                <small>Please enter the following Group information</small>
            </h1>
            <Form onSubmit={this.newGroup.bind(this)} className="new-case-form">

                <legend>Group Information</legend>

                {this.props.addGroupError && <Alert variant="danger">
                    {this.props.addGroupError.message}
                </Alert>}

                <FormGroup controlId="locationName">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="Active Users" ref={ref => this.groupName = ref}/>
                    {this.state.errors.groupName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingGroup} className="float-right">
                        Add
                    </Button>
                </FormGroup>


            </Form>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingGroup: state.addGroup.addingGroup,
        addGroupError: state.addGroup.addGroupError,
        tvClient: state.login.tvClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createGroup: (...params) => dispatch(createGroup(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGroup);
