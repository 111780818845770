import React, {Component} from "react";
import {
    Table,
    Dropdown
} from "react-bootstrap";
import { listJourneys, listAllIntakeStats, listLocations, viewSurvey } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges"; 
import PaginationArea from "./../../Utilities/PaginationArea";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
import ExportIntakeResultsCSV from './../../Utilities/ExportIntakeResultsCSV';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import SurveyStatus from "./../../Utilities/SurveyStatus";
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class IntakeReport extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            emptySet: [{'name':'No Data','value': 1}],
            perpage: 10,
            filter: '',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 8,
            activeKey: 1,
            selectedJourney: null,
            selectedJourneyName: '',
            dateFilter: 'thismonth',
            locationFilter: 0,
            currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
            currentFilterLocation: ' All Locations ',
            showCustomDate: false,
            showLocations: true,
            reportFrom: null,
            reportTo: null,
            currentDate: new Date().toLocaleString('en-us', { month: 'long' })
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);   
        this.props.listJourneys(this.props.tvClient);

        this.props.listLocations(this.props.tvClient).then(()=>{
          if(this.props.isLocationOnly){
                    if(this.props.roleLocations!==undefined){
                        var newLocation = null;
                        var newLocationName = '';
                        for(var i=0; i<this.props.roleLocations.length;i++){
                            newLocation = this.props.roleLocations[i];
                            break;
                        }
                        if(newLocation!==null){
                            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                              if(this.props.locations[i].location_id.toString()===newLocation.toString()){
                                    newLocationName = ' ' + this.props.locations[i].name + ' ';     
                                    break;
                              }
                            }
                        }
                        if(newLocation!==null && newLocationName!==''){
                            this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName }, ()=>{
                                this.changeListPage(1);
                            });     
                        }
                    } 
                } 
        });

    }   

    componentDidMount() {
        window.scrollTo(0,0);
        if(!this.props.isLocationOnly){
          this.changeListPage(1);  
        }
        
    }


    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id, 'reports/intakereport');
    }

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
      this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.selectedJourney);            
    } 

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations ' });
        this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, null, null,this.state.selectedJourney);            
      } 
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
            if(this.state.dateFilter==='custom'){
                this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.selectedJourney);            
            } else{
                this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, null, null,this.state.selectedJourney);            
            }
            
            break;
          }
        }
      }
    }

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);            
        });
      } else if(selectedKey==2){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);            
        });
      } else if(selectedKey==3){
        this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);            
        });
      } else if(selectedKey==4){
        this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);            
        });
      } else if(selectedKey==5){
        this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);            
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ', currentFilter: selectedKey }, () => {
          this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);            
        });
        
      }
    }

    handleJourneyFilter(selectedKey){
       if(selectedKey.toString()==='-1'){
              this.setState({ selectedJourney: null, selectedJourneyName: ' All Journeys '}, ()=>{
                if(this.state.dateFilter==='custom'){
                 this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.selectedJourney);            
                } else{
                 this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.selectedJourney);
                }
              });
      } else{
          for(var i=0; i<this.props.journeys.length; i++){
            if(parseInt(this.props.journeys[i].journey_id)===parseInt(selectedKey)){
              this.setState({ selectedJourney: selectedKey, selectedJourneyName: this.props.journeys[i].name }, ()=>{
                if(this.state.dateFilter==='custom'){
                  this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.selectedJourney);            
                } else{
                  this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.selectedJourney);            
                }
              });
              break; 
            }
            
          }
      }
      
    }    

    changeListPage(page) {
        if(this.state.dateFilter==='custom'){
             this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.selectedJourney);            
        } else{
            this.props.listAllIntakeStats(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.selectedJourney);            
        }
        
    }

    render() {
       return <div>
            <h1 className="page-header">
                Intake Report
  
                    <div className="float-right">
                    <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                          <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                          <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                          <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                          <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                          <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                          <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {(this.props.journeys!==null && this.props.journeys.length>0) && <div className="float-right extraRightMargin">
                            <Dropdown
                              title='Choose Journey'
                              id='filterSurvey'
                              drop="down" onSelect={this.handleJourneyFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                 {this.state.selectedJourneyName!=='' ? this.state.selectedJourneyName : ' Choose Journey '}
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                              <Dropdown.Item eventKey={-1}>All Journeys</Dropdown.Item>
                                {Object.values(this.props.journeys).map((d,counter) => {
                                return  <Dropdown.Item eventKey={d.journey_id} key={d.journey_id}>{d.name}</Dropdown.Item>
                              })}
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                      }                      

             {(this.state.showLocations && this.props.locations) && <div className="float-right locationSpecificReportFilter">
                    <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                              {this.state.currentFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                          {!this.props.isLocationOnly && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item> }
                      {Object.values(this.props.locations).map(d => {
                         if(this.props.isLocationOnly){
                                      if(this.props.roleLocations!==undefined){
                                          for(var i=0; i<this.props.roleLocations.length;i++){
                                              if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                                  return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                              }
                                          }
                                      } else{
                                          return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                                      }
                                  } else{
                                      return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                                  }
                      })}
                      </Dropdown.Menu>
                    </Dropdown>
            </div> }   

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }

            </h1>

            <p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
            <div className="case-list-container">
                <h3 className="intakeSpacer">High Level Statistics</h3>
                {!this.props.intakeDataLoading && <div className="row intakeTopRow">

                             <div className="col-md-3 intakeSent">
                                <div className="detailedInfo">
                                    <h3>{this.props.overallStats.total}</h3>
                                    <h5>Sent</h5>
                                </div>
                            </div>
                            <div className="col-md-3 intakeNoSymptoms">
                                <div className="detailedInfo">
                                    <h3>{this.props.overallStats.nosymptoms}</h3>
                                    <h5>No Symptoms</h5>
                                </div>
                            </div>  
                             <div className="col-md-3 intakeWithSymptoms">
                                <div className="detailedInfo">
                                    <h3>{this.props.overallStats.symptoms}</h3>
                                    <h5>With Symptoms</h5>
                                </div>
                            </div> 
                             <div className="col-md-3 intakeNotOpened">
                                <div className="detailedInfo">
                                    <h3>{this.props.overallStats.unopened}</h3>
                                    <h5>Not Opened</h5>
                                </div>
                            </div> 
                    </div>}
                    {this.props.intakeDataLoading && <Spinner/>}
                <h3 className="intakeSpacer">Most Common Badges</h3>
                {!this.props.intakeDataLoading && <Table className="case-list sortable reportBadgeContainer intakeReport">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th>Badge List</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="badgeListContainer">
                          {
                              this.props.badgeList.map(c => {
                                  if(c.image!==undefined){
                                    return <span key={c.image} alt={'Given ' + c.total + ' times'}><p className="badgeCountArea">{c.total}</p><img className='img-responsive' src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image} alt={'Given ' + c.total + ' times'}/><p alt={'Given ' + c.total + ' times'}>{c.localization_options['EN']}</p></span>
                                  }
                              })
                          }
                        </td>
                      </tr>
                    </tbody>

                </Table>}
                {this.props.intakeDataLoading && <Spinner/>}
                <h3 className="intakeSpacer">Intake List With Symptoms 
                <div className="float-right"><PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} /></div>
                </h3>
                {!this.props.intakeDataLoading && <Table className="case-list sortable">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Overall Score</th>
                        <th>Created</th>
                        <th>Badges</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.surveyList.map((c, i) => {
                            return <tr className={(i % 2)===0 ? "surveyRow statDeptContainer reportBadgeContainer intakeListRow" : "surveyRow altRowColor statDeptContainer reportBadgeContainer intakeListRow"} onClick={()=> this.viewSurvey(c.survey_id)} key={c.survey_id}>
                                <td>{c.survey_id}</td>
                                
                                <td>{c.survey_data.nps!==null && <SurveyStatus status={c.survey_data.nps}/>}{c.survey_data.nps===null ? 'Survey Incomplete' : ''}</td>
                                <td>{dateFormat(c.created, "shortDate")}</td>
                              <td onClick={()=> this.viewIntake(c.survey_id)}>
                                    
                                    {
                                        c.survey_data.intakeResults.intakeData.badges.map(c => {
                         

                                            var localeValue = '';
                                            if(c.localization_options!==undefined && c.localization_options!==null){
                                              let tempValue = c.localization_options["EN"];
                                              if(tempValue!==undefined){
                                                localeValue = tempValue;
                                              }
                                            }

                                            return <div className="nopad isBadge text-center intakeBadgeC" key={c.endorsement_id}>
                                                    {c.image!==undefined && <label>
                                                      <img alt="" className={"img-responsive"} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                                      <p className="intakeBadgeName">{localeValue}</p>
                                                    </label>}
                                                  </div>
                                        
                                          
                                        })
                                    }      

                                    {c.survey_data.intakeResults.intakeData.badges.length===0 ? 'None' : ''}
                                    
                                </td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table> }

                {this.props.intakeDataLoading && <Spinner/>}
                {!this.props.intakeDataLoading && <ExportIntakeResultsCSV summaryData={this.props.overallStats} badgeData={this.props.badgeList} journeyName={this.state.selectedJourneyName} fileName='IntakeResultsReport.csv' />}
            </div>
           



               

        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        intakeDataLoading: state.intakeStatsList.loading,
        overallStats: state.intakeStatsList.overallData,  
        badgeList: state.intakeStatsList.badgeDetails,    
        surveyList: state.intakeStatsList.surveyList,    
        locations: state.locationList.locations,
        paginationInfo: state.intakeStatsList.paginationInfo,
        paginationItems: state.intakeStatsList.paginationInfo.pageList,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        roleLocations: state.login.user.attributes.locations,
        journeys: state.journeyList.journeys
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listLocations: (...params) => dispatch(listLocations(...params)),
        listAllIntakeStats: (...params) => dispatch(listAllIntakeStats(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params)),
        listJourneys: (...params) => dispatch(listJourneys(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntakeReport);
