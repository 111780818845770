import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import {createCampaign, listGroups} from "../../actions";

class NewCampaign extends Component {
    constructor(props) {
        super(props);
        this.props.listGroups(this.props.tvClient);
        this.state = {
            selectedGroup: 0,
            errors: { 'campaignName': false, 'selectedGroup': false }
        };
        this.inlineError = false;

    }

    newCampaign(e) {
        e.preventDefault();

        if(!this.campaignName.value){
            this.setState({ errors: {'campaignName': true}});
        } else if(this.state.selectedGroup[0]===0 || this.state.selectedGroup[0].toString()==="0"){
            this.setState({ errors: {'selectedGroup': true}});
        } else{
            this.setState({ errors: {'selectedGroup': false, 'campaignName': false}});
           this.props.createCampaign(
                this.props.tvClient,
                this.campaignName.value,
                this.state.selectedGroup[0]
            );            
        }
    }

    handleGroupList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            selectedGroup: value
        });
    }

    render() {
        return <div>
            <h1 className="page-header">
                Add New Campaign
                <small>Please enter the following Campaign information</small>
            </h1>
            <Form onSubmit={this.newCampaign.bind(this)} className="new-case-form">

                <legend>Campaign Information</legend>

                {this.props.addCampaignError && <Alert variant="danger">
                    {this.props.addCampaignError.message}
                </Alert>}

                <FormGroup controlId="locationName">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="Text blast..." ref={ref => this.campaignName = ref}/>
                    {this.state.errors.campaignName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="selectedGroup">
                    <FormLabel>Choose Group *</FormLabel>
                    <FormControl as="select" placeholder="select"  onChange={this.handleGroupList.bind(this)}>
                        <option value="0">Choose a group</option>
                        {Object.values(this.props.groups).map(d => {
                            return <option value={d.uid} key={d.uid}>{d.name}</option>
                        })}
                    </FormControl>                    
                    {this.state.errors.selectedGroup && <Alert variant="danger">
                        A group is required.
                    </Alert>}
                </FormGroup>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingCampaign} className="float-right">
                        Add
                    </Button>
                </FormGroup>


            </Form>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingCampaign: state.addCampaign.addingCampaign,
        addCampaignError: state.addCampaign.addCampaignError,
        tvClient: state.login.tvClient,
        groups: state.groupList.groups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createCampaign: (...params) => dispatch(createCampaign(...params)),
        listGroups: (...params) => dispatch(listGroups(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCampaign);
