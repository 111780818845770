import React, {Component} from "react";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { getStaffExport } from "../../actions"


class ExportStaffListCSV extends Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        }
        this.csvLinkEl = React.createRef();
    }

    downloadReport = async () => {
        this.setState({ loading: true });
        const data = await this.props.getStaffExport(this.props.token, this.props.locationId);
        this.setState({ data: data.staff, loading: false }, () => {
            this.csvLinkEl.current.link.click();
        });
    }

    render() {
        const { data } = this.state;
        return (

      <div>
        <input type="button" disabled={this.state.loading} className="float-right btn btn-primary exportButton" value={this.state.loading ? "Preparing Download" : "Download Staff List"} onClick={this.downloadReport} />
        <CSVLink
          headers={[{ label: "Well iQ ID", key: "staff_id" },{ label: "Name", key: "name" },{ label: "Title", key: "title" },{ label: "Email", key: "email" },{ label: "Phone", key: "phone" },{ label: "Image", key: "staffimageid" },{ label: "Date of Birth", key: "dob" },{ label: "Hire Date", key: "hire_date" }, { label: "Available Points", key: "total_points"}]}
          filename={"Staff List" +this.props.locationName+".csv"}
          data={data}
          ref={this.csvLinkEl}
          className="hidden"
        >Download Staff</CSVLink>
      </div>

          
        )

    }
}

ExportStaffListCSV.propTypes = {
    locationId: PropTypes.number.isRequired,
    locationName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient
    };
};

export default connect(mapStateToProps, {getStaffExport})(ExportStaffListCSV);

