import React, {Component} from "react";
import {
    Table,
    Dropdown
} from "react-bootstrap";
import { viewSurvey, listEmployerWrittenFeedbackReport, viewCustomEmployerNpsData, viewCustomEmployerEndorsementData, employerDeptEngagementsReport, viewCustomEmployerSurveyOverviewData, listEmployers, listCampaigns, viewAdminDashboardEndorsementsReports } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges"; 
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class EmployerSpecificReport extends Component {

    constructor(props) {
        super(props);
        this.props.listEmployers(this.props.tvClient.accessToken);
        this.props.listCampaigns(this.props.tvClient);

        this.state = {
            emptySet: [{'name':'No Data','value': 1}],
            perpage: 10,
            filter: '',
            currentFilter: 8,
            emptySet: [{'name':'No Data','value': 1}],
            activeKey: 1,
            dateFilter: 'thismonth',
            locationFilter: 0,
            campaignFilter: 0,
            employerFilter: '',
            currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
            currentCampaignName: ' All Campaigns ',
            currentEmployerName: ' All Employers ',
            showCustomDate: false,
            reportFrom: null,
            reportTo: null,
            currentDate: new Date().toLocaleString('en-us', { month: 'long' })
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);

    }   

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id);
    }   

    runReport(){
      this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' }, () => {
        this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
        this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
        this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
        this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
        this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      });
    } 

    handleEmployerFilter(selectedKey){
      var reportFrom = this.state.reportFrom;
      var reportTo = this.state.reportTo;
      if(this.state.dateFilter==='custom'){
        reportFrom = dateFormat(this.state.reportFrom, 'shortDate');
        reportTo = dateFormat(this.state.reportTo, 'shortDate');
      }
      if(selectedKey==='-1'){
        this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, '', this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, '', this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, '', this.state.dateFilter, reportFrom, reportTo);
        this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, '', this.state.dateFilter, reportFrom, reportTo);
        this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, '', this.state.dateFilter, reportFrom, reportTo);
        this.setState({ employerFilter: '', currentEmployerName: ' All Employers ' });
      } else{
        this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.props.employers[selectedKey].organization, this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.props.employers[selectedKey].organization, this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.props.employers[selectedKey].organization, this.state.dateFilter, reportFrom, reportTo);
        this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.props.employers[selectedKey].organization, this.state.dateFilter, reportFrom, reportTo);
        this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.props.employers[selectedKey].organization, this.state.dateFilter, reportFrom, reportTo);
        this.setState({ employerFilter: this.props.employers[selectedKey].organization, currentEmployerName: this.props.employers[selectedKey].organization });
      }
    }

    handleCampaignChange(campaignKey){
      var reportFrom = this.state.reportFrom;
      var reportTo = this.state.reportTo;
      if(this.state.dateFilter==='custom'){
        reportFrom = dateFormat(this.state.reportFrom, 'shortDate');
        reportTo = dateFormat(this.state.reportTo, 'shortDate');
      } 
      if(campaignKey==='-1'){
        this.props.viewCustomEmployerNpsData(this.props.accessToken, 0, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerEndorsementData(this.props.accessToken, 0, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, 0, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.employerDeptEngagementsReport(this.props.accessToken, 0, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, 0, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.setState({ campaignFilter: 0, currentCampaignName: ' All Campaigns '});
      } else{
        this.props.viewCustomEmployerNpsData(this.props.accessToken, campaignKey, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerEndorsementData(this.props.accessToken, campaignKey, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, campaignKey, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        this.props.employerDeptEngagementsReport(this.props.accessToken, campaignKey, this.state.employerFilter, this.state.dateFilter, reportFrom,reportTo);
        this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, campaignKey, this.state.employerFilter, this.state.dateFilter, reportFrom, reportTo);
        var campaignName = '';
        for(var i=0; i<this.props.campaigns.length;i++){
          if(this.props.campaigns[i].uid===campaignKey){
            campaignName=this.props.campaigns[i].name;
            break;
          }
        }
        this.setState({ campaignFilter: campaignKey, currentCampaignName: campaignName});  
      }
      
    }

    handleDateFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        });
      } else if(selectedKey==2){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        });
      } else if(selectedKey==3){
        this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo); 
        });
      } else if(selectedKey==4){
        this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        });
      } else if(selectedKey==5){
        this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
          this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        });        
      }
    }

    componentDidMount() {
        this.props.viewCustomEmployerNpsData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        this.props.viewCustomEmployerEndorsementData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        this.props.viewCustomEmployerSurveyOverviewData(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        this.props.employerDeptEngagementsReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
        this.props.listEmployerWrittenFeedbackReport(this.props.accessToken, this.state.campaignFilter, this.state.employerFilter, this.state.dateFilter, this.state.reportFrom, this.state.reportTo);
    }

    render() {
       return <div>
            <h1 className="page-header">
                Employer Feedback Report
  
                    <div className="float-right">
                       <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleDateFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Filter Report By Date
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                          <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                          <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                          <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                          <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                          <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                          <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>   

             {(this.props.employers) && <div className="float-right locationSpecificReportFilter">
                     <Dropdown
                          title='Refine By Employee'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleEmployerFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Refine By Employer
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                      {Object.values(this.props.employers).map((d, index) => {
                        return  <Dropdown.Item eventKey={index} key={index}>{d.organization}</Dropdown.Item>
                      })}
                       <Dropdown.Divider />
                      <Dropdown.Item eventKey="-1">Reset Filter</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
            </div> }

             {(this.props.campaigns) && <div className="float-right locationSpecificReportFilter">
                     <Dropdown
                          title='Refine By Campaign'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleCampaignChange.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Refine By Campaign
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                      {Object.values(this.props.campaigns).map((d) => {
                        return  <Dropdown.Item eventKey={d.uid} key={d.uid}>{d.name}</Dropdown.Item>
                      })}  <Dropdown.Divider />
                          <Dropdown.Item eventKey="-1">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                    </Dropdown>
            </div> }

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }

            </h1>

            <div>
            <div className="col-md-12 reportBlockspacer">
            <p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentCampaignName}</span> and <span className="text-underline">{this.state.currentEmployerName}</span> for <span className="text-underline">{this.state.currentFilterName}</span></p>
            </div>  
            <div className="case-list-container">
                  {this.props.employerNpsData &&  <div className="locationSpecificReportPieSpace piechart col-md-12 reportBlockspacer"><div className="col-md-4 nps-survey-container float-left">
                        <h5>Overall Satisfaction</h5>
                        {(this.props.employerNpsRating.indexOf('NaN')===-1) &&
                       <PieChart width={350} height={250}>
                       <Pie data={this.props.employerNpsData} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#8884d8" >
                        {
                          this.props.employerNpsData.map((entry, index) => <Cell key={entry.value} fill={ entry.color }/>)
                        }
                        <Label width={100} position="center">{this.props.employerNpsRating}</Label>
                       </Pie>
                        <Legend layout="vertical" align="right" verticalAlign="middle" />
                       </PieChart> }
                       {(this.props.employerNpsRating.indexOf('NaN') > -1) && 
                        <PieChart width={350} height={250} >
                         <Pie data={this.state.emptySet} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#999999" >           
                         <Label width={100} position="center">---</Label>
                         </Pie><Legend layout="vertical" align="right" verticalAlign="middle" />
                       </PieChart> 
                       }
                        </div> <div className="col-md-7 nps-survey-container float-right">

                                                              <h5>NPS Survey Responses</h5>
                                                              <table className="npsSurveyTable">
                                                                  <tbody>

                                                                              <tr>
                                                                                  <th>Value</th>
                                                                                  <th>Name</th>
                                                                                  <th>Quantity</th>
                                                                              </tr>
                                                                          </tbody>

                                                                  <tbody>
                                                                              
                                                                      {
                                                                          this.props.employerNpsData.map(d => {
                                                                              return <tr className="statDeptContainer actionableRecord" key={d.name}>
                                                                                      <td>{d.name}</td>
                                                                                      <td>{this.renderScoreToName(d.name)}</td>
                                                                                      <td>{d.value}</td>
                                                                                    </tr>
                                                                          })
                                                                      }  

                                                                  </tbody> 
                                                                              

                                                              </table>

                                                        </div></div>
                   }
                   {(this.props.employerNpsData && this.props.employerNpsData.length===0) && <p>No data to display.</p>}


            <div className="locationSpecificReportMiddle ">
                    {this.props.employerEndorsementsLoading && <Spinner/>}
                     <div className="col-md-4 nps-survey-container float-left">
                    <div>
                        <h5>Top Endorsements</h5>
                    </div>
                    {this.props.employerEndorsementLoading && <Spinner/>}
                     {this.props.employerEndorsementData && <div>
                                
                            {
                                this.props.employerEndorsementData.comboBadgeData.slice(0,5).map(c => {
                                    return <div className="statBadgeContainer" key={c.endorsement_id}>
                                            <label>
                                              <img alt="" className="img-responsive" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image} />
                                              <p>{c.description}</p>
                                            </label>
                                          </div>
                                })
                            }  
                            {(this.props.employerEndorsementData && this.props.employerEndorsementData.comboBadgeData.length===0) && <p>No data to display.</p>}
                    </div> 
                    }
                    
                  </div>


                  <div className="col-md-4 middleCptContainer float-left">
                    {this.props.employerOverviewLoading && <Spinner/>}
                     {this.props.employerOverviewData && <div className="totalSurveys">
                        <img alt="Total Launched" src={process.env.PUBLIC_URL + '/admin_assets/launchedIcon.png'} />
                        <div className="detailedInfo">
                            <h3>{this.props.employerOverviewData.totalSurveys[0].count}</h3>
                            <h5>Surveys Sent</h5>
                        </div>
                    </div> }
                    {this.props.employerOverviewData && <div className="totalOpened">
                        <img alt="Total Opened" src={process.env.PUBLIC_URL + '/admin_assets/openedIcon.png'} />
                        <div className="detailedInfo">
                            <h3>{this.props.employerOverviewData.openedSurveys[0].count}</h3>
                            <h5>Surveys Opened</h5>
                        </div>
                    </div> }  
                     {this.props.employerOverviewData && <div className="totalCompleted">
                        <img alt="Total Completed" src={process.env.PUBLIC_URL + '/admin_assets/completedIcon.png'} />
                        <div className="detailedInfo">
                            <h3>{this.props.employerOverviewData.completedSurveys[0].count}</h3>
                            <h5>Completed Fully</h5>
                        </div>
                    </div> 
                    }
                  </div>


                  <div className="col-md-4 nps-survey-container float-left">
                    <div>
                        <h5>Top Departments</h5>
                    </div>
                    {this.props.employerDeptEngagementsLoading && <Spinner/>}
                    {this.props.employerDeptEngagements && <div>
                                
                            {
                                this.props.employerDeptEngagements.slice(0,5).map(c => {
                                    return <div className="statDeptContainer" key={c.key}>
                                            <label>
                                              <img alt="" className="img-responsive" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/department_assets/' + c.image}/>
                                              <p>{c.name}</p>
                                              <p className="engagementCount">{c.engagements}</p>
                                            </label>
                                            
                                          </div>
                                })
                            }  

                    </div> 
                    } 
                    {(this.props.employerDeptEngagements && this.props.employerDeptEngagements.length===0) && <p>No data to display.</p>}
                  </div>                  

            </div>
            
                {  this.props.employerDeptEngagements && <Table className="case-list sortable reportBadgeContainer">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th></th>
                        <th>Name</th>
                        <th>Badges</th>
                        <th className="text-center">Engagements</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.employerDeptEngagements.map(c => {
                            return <tr className="surveyRow statDeptContainer reportBadgeContainer" key={c.key}>
                                <td><img className="img-responsive" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/staff_assets/' + c.image}/></td>
                                <td>{c.name}</td>
                                <td><StaffBadges items={c.badges}/></td>
                                <td className="text-center totalTdValue">{c.engagements}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table> }
                {(!this.props.employerDeptEngagementsLoading && this.props.employerDeptEngagements.length===0) && <p className="text-center">No Results Found</p>}

                {  this.props.employerWrittenFeedbackData && <div><h3 className="leftPadding">Written Feedback</h3><Table className="case-list sortable reportBadgeContainer">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th>Name</th>
                        <th>Feedback</th>
                        <th className="text-center">Survey Link</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.employerWrittenFeedbackData.map((c,index) => {
                            return <tr className="surveyRow statDeptContainer reportBadgeContainer" onClick={()=> this.viewSurvey(c.survey_id)} key={index}>
                                <td>{c.response.name}</td>
                                <td>{c.response.feedback}</td>
                                <td className="text-center totalTdValue">View Survey</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table> </div>}
                {(!this.props.employerWrittenFeedbackDataLoading && this.props.employerWrittenFeedbackData.length===0) && <p className="text-center">No Results Found</p>}
                {this.props.employerWrittenFeedbackDataLoading && <Spinner/>}

            </div>    
            </div> 
        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        employers: state.listEmployers.employers,
        campaigns: state.campaignList.campaigns,
        employerEndorsementData: state.employerEndorsementsCpt.endorsementsData,
        employerEndorsementLoading: state.employerEndorsementsCpt.endorsementsLoading,
        employerNpsData: state.employerNpsCpt.npsData,
        employerNpsRating: state.employerNpsCpt.npsRating,
        employerNpsLoading: state.employerNpsCpt.npsLoading,
        employerOverviewData: state.employerSurveyOverviewCpt.employerSurveyOverviewData,
        employerOverviewLoading: state.employerSurveyOverviewCpt.employerSurveyOverviewLoading,
        employerDeptEngagements: state.employerDeptEngagementData.engagementData,
        employerDeptEngagementsLoading: state.employerDeptEngagementData.loading,
        employerWrittenFeedbackDataLoading: state.employerWrittenFeedbackData.loading,
        employerWrittenFeedbackData: state.employerWrittenFeedbackData.writtenFeedback,
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listEmployers: (...params) => dispatch(listEmployers(...params)),
        listCampaigns: (...params) => dispatch(listCampaigns(...params)),
        viewAdminDashboardEndorsementsReports: (...params) => dispatch(viewAdminDashboardEndorsementsReports(...params)),
        viewCustomEmployerNpsData: (...params) => dispatch(viewCustomEmployerNpsData(...params)),
        viewCustomEmployerEndorsementData: (...params) => dispatch(viewCustomEmployerEndorsementData(...params)),
        viewCustomEmployerSurveyOverviewData: (...params) => dispatch(viewCustomEmployerSurveyOverviewData(...params)),
        employerDeptEngagementsReport: (...params) => dispatch(employerDeptEngagementsReport(...params)),
        listEmployerWrittenFeedbackReport: (...params) => dispatch(listEmployerWrittenFeedbackReport(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerSpecificReport);
