import PatientDocument from "./patient-document.js";

class PublicHelper {

    constructor(urlPrefix) {
        this.urlPrefix = urlPrefix;
    }

	async createPatient(internalPatientCreator, tvClient, savedSurvey, surveyId, name, email, phone, type, extra) {
	    
	    const patientDocument = new PatientDocument(name, email, phone, type, process.env.PUBLIC_PATIENT_API_TOKEN, savedSurvey, extra);
	    const newPatient = await Promise.all([tvClient.createDocument(process.env.REACT_APP_PATIENT_VAULT_ID,  process.env.REACT_APP_PATIENT_SCHEMA_ID, patientDocument)]);
	    const newPatientId = newPatient[0].id;
	    const createInternalPatientRequest = internalPatientCreator(tvClient, newPatientId, surveyId, type);
	    await Promise.all([createInternalPatientRequest]);

	}

	async createPatientNoCallback(tvClient, savedSurvey, surveyId, name, email, phone, type, extra) {
	    const patientDocument = new PatientDocument(name, email, phone, type, process.env.PUBLIC_PATIENT_API_TOKEN, savedSurvey, extra);
	    const newPatient = await Promise.all([tvClient.createDocument(process.env.REACT_APP_PATIENT_VAULT_ID,  process.env.REACT_APP_PATIENT_SCHEMA_ID, patientDocument)]);
	    return newPatient[0].id;
	}

}

export default PublicHelper;