import React, {Component} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {Button, Form, FormGroup, InputGroup, Overlay, Popover} from "react-bootstrap";
import {deleteJourney} from "../../actions";

class DeleteJourneyOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    deleteJourney(e) {
       e.preventDefault();
       this.props.deleteJourney(this.props.tvClient, this.props.locationDetail);
    }

    hide2(){
        this.setState({show:false});
    }

    render() {
        return <div>
            <button className="simulateAnchor" ref="target" onClick={e => {e.preventDefault(); this.setState({show: true})}}>Delete</button>
            <Overlay show={this.state.show} rootClose target={props => ReactDOM.findDOMNode(this.refs.target)}
                     onHide={this.hide2.bind(this)} placement="right">
                <Popover id="invite-patient" title="DELETE" className="invite-patient-overlay">
                     <Popover.Title>DELETE</Popover.Title>
                    <Popover.Content>
                        <Form onSubmit={this.deleteJourney.bind(this)}>
                            <FormGroup controlId="email">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <p>Are you sure you want to delete this <b>Journey?</b></p>
                                        <Button type="submit" className="popoverButton" variant="danger">
                                            Yes
                                        </Button>
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient
    };
};

export default connect(mapStateToProps, {deleteJourney})(DeleteJourneyOverlay);
