import React, {Component} from "react";
import {
    Table, 
    Dropdown
} from "react-bootstrap";
import { listAllDeptEngagements, listAllDeptEngagementsReport, listLocations  } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges";
import dateFormat from "dateformat";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PaginationArea from "./../../Utilities/PaginationArea";
// Include the locale utils designed for moment
import {formatDate, parseDate,} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class DeptEndorsementReport extends Component {

   constructor(props) {
        super(props);
        
        this.state = {
            perpage: 10,
            filter: '',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 2,
            activeKey: 1,
            dateFilter: 'thismonth',
            locationFilter: 0,
            currentFilterName: ' This Month ',
            currentFilterLocation: ' All Locations ',
            showCustomDate: false,
            showLocations: true,
            reportFrom: null,
            reportTo: null            
        };       

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);    

        this.props.listLocations(this.props.tvClient).then(()=>{
           if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                    if(this.props.roleLocations!==undefined){
                        var newLocation = null;
                        var newLocationName = '';
                        for(var i=0; i<this.props.roleLocations.length;i++){
                            newLocation = this.props.roleLocations[i];
                            break;
                        }
                        if(newLocation!==null){
                            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                              if(this.props.locations[i].location_id.toString()===newLocation.toString()){
                                    newLocationName = ' ' + this.props.locations[i].name + ' ';     
                                    break;
                              }
                            }
                        }
                        if(newLocation!==null && newLocationName!==''){
                            this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName }, ()=>{
                                this.changeListPage(1);
                            });     
                        }
                    } 
                } 
        });

    }   


    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }

    handleToChange(to) {
      this.setState({ reportTo: to });
    }  

    componentDidMount() {
      if(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly){
        this.changeListPage(1);
      }
    }

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
        if(this.state.dateFilter==='custom'){
            this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
        } else{
            this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, null, null);
        }
        
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
            if(this.state.dateFilter==='custom'){
                this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
            } else{
                this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, null, null);
            }
            break;
          }
        }
        
      }
    }

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
        });
      } else if(selectedKey==2){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
        });
      } else if(selectedKey==3){
        this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null,null);
        });
      } else if(selectedKey==4){
        this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
        });
      } else if(selectedKey==5){
        this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month ', currentFilter: selectedKey }, () => {
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
        });
        
      }
    }

    runReport(){
      this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      //this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
    } 


    changeListPage(page) {
        this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
    }

    render() {
       return <div>
            <h1 className="page-header">
                Most Engaged Departments
                    <div className="float-right">
                        <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                          <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                          <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                          <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                          <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                          <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                          <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }
            {(this.state.showLocations && this.props.locations) && <div className="customLocationRange">
                      <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                    {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item> }
                      {Object.values(this.props.locations).map(d => {
                        if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                              if(this.props.roleLocations!==undefined){
                                  for(var i=0; i<this.props.roleLocations.length;i++){
                                      if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                          return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                      }
                                  }
                              } else{
                                  return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                              }
                          } else{
                              return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                          }
                      })}
                      </Dropdown.Menu>
                    </Dropdown>
            </div> }    
            </h1>
            <p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
            <div className="case-list-container">

                {  this.props.deptEngagementData && <Table className="case-list sortable reportBadgeContainer">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th></th>
                        <th>Name</th>
                        <th className="text-center">Engagements</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.deptEngagementData.map(c => {
                            return <tr className="surveyRow statDeptContainer reportBadgeContainer" key={c.key}>
                                <td><img className="img-responsive" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/staff_assets/' + c.image}/></td>
                                <td>{c.name}</td>
                                <td className="text-center totalTdValue">{c.engagements}</td>
                            </tr>
                        })
                    }
                    { (this.props.deptEngagementData.length === 0 && !this.props.deptEngagementDataLoading) && <tr><td colSpan="3" className="text-center">No Data</td></tr>}
                    </tbody>
                </Table> }
                {this.props.deptEngagementDataLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />         

            </div>    

        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        deptEngagementDataLoading: state.deptEngagementData.loading,
        deptEngagementData: state.deptEngagementData.engagementData,
        paginationInfo: state.deptEngagementData.paginationInfo,
        paginationItems: state.deptEngagementData.paginationInfo.pageList,
        locations: state.locationList.locations,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listLocations: (...params) => dispatch(listLocations(...params)),
        listAllDeptEngagements: (...params) => dispatch(listAllDeptEngagements(...params)),
        listAllDeptEngagementsReport: (...params) => dispatch(listAllDeptEngagementsReport(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeptEndorsementReport);
