import React, {Component} from "react";
import {Nav, Navbar} from "react-bootstrap";
import { withRouter, Link } from 'react-router-dom';
import {connect} from "react-redux";
import {logout} from "../../actions";
import "./Navbar.scss";

class MainNavBar extends Component {

    render() {
        if (this.props.location.pathname === '/login') {
            return null;
        }
        return <Navbar variant="dark" expand="lg">
            
            <Navbar.Toggle/>
            
            <Navbar.Collapse>
                <Nav className="mr-auto" activeKey={'#'+ this.props.activeHref }>
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isLocationOnly) && <Nav.Link as={Link} eventKey="#/admin_dashboard" to="/admin_dashboard" replace={'/admin_dashboard' === this.props.location.pathname}>Dashboard</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isLocationOnly || this.props.isSurveysReportsOnly) && <Nav.Link as={Link} eventKey="#/surveys" to="/surveys" replace={'/surveys' === this.props.location.pathname}>Surveys</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly) && <Nav.Link as={Link} eventKey="#/departments" to="/departments" replace={'/departments' === this.props.location.pathname}>Departments</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isLocationOnly) && <Nav.Link as={Link} eventKey="#/staff" to="/staff" replace={'/staff' === this.props.location.pathname}>Staff</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isEventsOnly || this.props.isLocationOnly || this.props.isSurveysReportsOnly) && <Nav.Link as={Link} eventKey="#/reports" to="/reports" replace={'/reports' === this.props.location.pathname}>Reports</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly) && (this.props.showReviews===true || this.props.showReviews==='true') && <Nav.Link as={Link} eventKey="#/reviews" to="/reviews" replace={'/reviews' === this.props.location.pathname}>Reviews</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly) && (this.props.showReputation===true || this.props.showReputation==='true') && <Nav.Link as={Link} eventKey="#/reputation" to="/reputation" replace={'/reputation' === this.props.location.pathname}>Reputation</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isTextOnly) && (this.props.showIntake===true || this.props.showIntake==='true') && <Nav.Link as={Link} eventKey="#/intake" to="/intake" replace={'/intake' === this.props.location.pathname}>Intake <sup className="text-intake">{this.props.intakeSurveys.length}</sup></Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isLocationOnly) && <Nav.Link as={Link} eventKey="#/settings" to="/settings" replace={'/settings' === this.props.location.pathname}>Settings</Nav.Link>}
                    {this.props.isLoggedIn && (this.props.isAdmin || this.props.isTextOnly) && <Nav.Link as={Link} eventKey="#/sms" to="/sms" replace={'/sms' === this.props.location.pathname}>SMS</Nav.Link>}
                    {!this.props.isLoggedIn && <Nav.Link as={Link} to="/login" replace={'/login' === this.props.location.pathname}>Login</Nav.Link>}
                </Nav>

                <Nav className="logoutNav" activeKey={'#' +this.props.location.pathname}>
                     {this.props.isLoggedIn && (this.props.isAdmin || this.props.isViewOnly || this.props.isTextOnly || this.props.isEventsOnly || this.props.isLocationOnly || this.props.isSurveysReportsOnly) && <Nav.Link eventKey="#/myprofile" as={Link} to="/myprofile" replace={'/myprofile' === this.props.location.pathname}>My Profile</Nav.Link>}
                    {this.props.isLoggedIn && <Nav.Link onClick={() => this.props.logout(this.props.tvClient)}>Logout</Nav.Link>}
                </Nav>
                <Navbar.Text>
                    {this.props.name}
                </Navbar.Text>                
            </Navbar.Collapse>
        </Navbar>;
    }
}

const mapStateToProps = (state, ownArgs) => {

    let activeHref = ownArgs.location.pathname;

    if (activeHref.startsWith('/surveys')) {
        activeHref = '/surveys';
    } else if(activeHref.startsWith('/reports')){
        activeHref = '/reports';
    } else if(activeHref.startsWith('/staff')){
        activeHref = '/staff';
    } else if(activeHref.startsWith('/departments') || activeHref.startsWith('/journey')){
        activeHref = '/departments';
    } else if(activeHref.startsWith('/myprofile')){
        activeHref = '/myprofile';
    } else if(activeHref.startsWith('/group')){
        activeHref = '/sms';
    } else if(activeHref.startsWith('/reviews') || activeHref.startsWith('/reviewlocations')){
        activeHref = '/reviews';
    } else if(activeHref.startsWith('/reputation')){
        activeHref = '/reputation';
    } else if(activeHref.startsWith('/intake')){
        activeHref = '/intake';
    }


   
    return {
        isLoggedIn: !!state.login.user,
        isAdmin: state.login.user && state.login.user.attributes.role === 'admin',
        isPatient: state.login.user && state.login.user.attributes.role === 'patient',
        isTextOnly: state.login.user && state.login.user.attributes.role === 'frontdesk',
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly',
        isEventsOnly: state.login.user && state.login.user.attributes.role === 'eventsonly',
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        showReviews: state.login.user && state.login.reviewFlag,
        showReputation: state.login.user && state.login.reputationFlag,
        showIntake: state.login.user && state.login.intakeFlag,
        name: state.login.user && state.login.user.attributes.name,
        currUsername: state.login.user && state.login.user.username,
        intakeSurveys: state.surveyIntakeList.surveys,
        activeHref: activeHref,
        tvClient: state.login.tvClient
    }
};

export default withRouter(connect(mapStateToProps, {logout})(MainNavBar));
