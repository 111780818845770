import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Table,
    Button
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {viewIntake, updateIntakeResolution } from "../../actions";
import "./ViewIntake.scss";
import dateFormat from "dateformat";
import queryString from 'query-string'


class ViewIntake extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDetails: [],
            historyField: queryString.parse(this.props.location.search)
        };
        this.printIntake = this.printIntake.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let isRefresh =this.props.currentSurvey === null;
        if(isRefresh){
            this.props.viewIntake(this.props.tvClient, this.props.match.params.surveyId);
        }
    }

    printIntake(){
        window.print();
    }

    updateIntake(e, survey_id){
        e.preventDefault();
        e.nativeEvent.srcElement.outerHTML = "<small>Marked For Removal</small>";
        this.props.updateIntakeResolution(this.props.tvClient, survey_id);
    } 

    render() {
        return <div>
            <h1 className="page-header">
               Intake {this.props.currentSurvey!==null && <span>#{this.props.currentSurvey.data.survey_id}</span>} &nbsp; &nbsp; <button className="btn btn-primary" id="yourInfoButton" onClick={this.printIntake}>Print</button>
               <small className="backLink">
                    {(this.state.historyField.from && this.state.historyField.from!=='none') && <Link to={'/'+ this.state.historyField.from +'?refresh=0&dateFilter=' + this.state.historyField.date + '&locationFilter=' + this.state.historyField.location }>Go Back</Link>}
                    {(!this.state.historyField.from || this.state.historyField.from==='none') && <Link to={'/intake'}>Go Back</Link> }
               </small>
            </h1>
            {this.props.currentSurvey===null && <Spinner/>}
            {this.props.currentSurvey!==null && <div className="case-list-container">
                    
                <Table className="case-list surveyReportView">
                    <tbody>                       
                           <tr>
                                <td className="reportCell">Name</td>
                                <td className="reportValue">{this.props.currentSurvey.person!==undefined ? this.props.currentSurvey.person[0].document.name : 'Unavailable'} {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.respondent_type==='Employer') ? '(Employer)' : ''}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Email</td>
                                <td className="reportValue">{this.props.currentSurvey.person!==undefined ? this.props.currentSurvey.person[0].document.email : 'Unavailable'}</td>
                            </tr>
                            <tr>
                                <td className="reportCell">Phone</td>
                                <td className="reportValue">{this.props.currentSurvey.person!==undefined ? this.props.currentSurvey.person[0].document.phone : 'Unavailable'}</td>
                            </tr>                      
                        <tr>
                            <td className="reportCell">Date Created</td>
                            <td className="reportValue">{this.props.currentSurvey.data!==undefined ? dateFormat(this.props.currentSurvey.data.created, "shortDate") : ''}</td>
                        </tr>
                        <tr>
                        <td className="reportCell">Location</td>
                            <td className="reportValue">{this.props.currentSurvey.data!==undefined ? this.props.currentSurvey.data.name : ''} {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.respondent_type==='Employer') ? 'All Locations' : ''}</td>
                        </tr>  
                        {(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.survey_data.intakeResults!==undefined) && <tr>
                            <td className="reportCell">Intake Form</td>
                            <td className="reportValue">
                            {
                                        this.props.currentSurvey.data.survey_data.intakeResults.intakeData.badges.map(c => {
                         

                                            var localeValue = '';
                                            if(c.localization_options!==undefined && c.localization_options!==null){
                                              let tempValue = c.localization_options["EN"];
                                              if(tempValue!==undefined){
                                                localeValue = tempValue;
                                              }
                                            }

                                            return <div className="nopad isBadge text-center intakeBadgeC" key={c.endorsement_id}>
                                                    <label>
                                                      <img alt="" className={"img-responsive"} src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                                      <p className="intakeBadgeName">{localeValue}</p>
                                                    </label>
                                                  </div>
                                        
                                          
                                        })
                                    }      

                                    {this.props.currentSurvey.data.survey_data.intakeResults.intakeData.badges.length===0 ? 'No Badges Selected' : ''}
                            </td>   
                        </tr>}
                        <tr>
                        <td className="reportCell">Status</td>
                            <td className="reportValue">{(this.props.currentSurvey.data!==undefined && this.props.currentSurvey.data.completed) ? 'Complete' : 'In Progress'}</td>
                        </tr>
                        <tr>
                        <td className="text-center" colSpan="2"> <Button variant="primary" size="sm" className="clearBoth" onClick={(e) => this.updateIntake(e, this.props.currentSurvey.data.survey_id)}>
                                        Mark As Resolved
                                    </Button></td>
                        </tr>
    
                    </tbody>
                </Table>                                          
               
                    
                </div>
            }
        </div>
    }
}

const mapStateToProps = state => {
    return {
        currentSurvey: state.intakeView.currentSurvey,
        viewSurveyError: state.intakeView.error,
        tvClient: state.login.tvClient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewIntake: (...params) => dispatch(viewIntake(...params)),
        updateIntakeResolution: (...params) => dispatch(updateIntakeResolution(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewIntake);
