import React, {Component} from "react";
import {connect} from "react-redux";
import localizedData from "./../Localization/Localization";
import {surveyBegin, beginNewSurvey, getDeptStaff, cachedResults, updateSurvey, createPatient, getUserDetails} from "../../survey-actions";
import {Form} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import DayPicker from 'react-day-picker';
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';

import 'react-day-picker/lib/style.css';
import "./../ServeSurvey.scss";

class ServeSurvey extends Component {

    constructor(props){
        super(props);
        let language = 'EN';
        if(props.location && props.location.query && props.location.query.lang!==null && props.location.query.lang !== undefined){
          if(props.location.query.lang.toUpperCase()==='ES' || props.location.query.lang.toUpperCase() === 'EN' || props.location.query.lang.toUpperCase() === 'FR'){
            localizedData.setLanguage(props.location.query.lang.toUpperCase());
            language = localizedData.getLanguage();
          }
        }
        this.handleDayClick = this.handleDayClick.bind(this);
        this.saveSurveyStatus = this.saveSurveyStatus.bind(this);
        //this.handleNpsClick = this.handleNpsClick.bind(this);
        this.state = {
          showNextButton: false,
          currentQuestion: 0,
          selectedDepartments: [],
          selectedStaff: [],
          currentStaffNumber: null,
          currentStaffDetail: {},
          currentDeptNumber: null,
          currentDeptDetail: {},
          staff: null,
          cyclingStaff: false,
          cyclingDept: false,
          cachedStaffRequests : [],
          fullyLoaded: false,
          syncSurvey: false,
          newSurvey: false,
          savedSurvey: { nps: null, selectedDay: null, departments: [], staff: [], completed: false},
          fields: { feedbackType: 'Patient', username: '', mobileno: '', emailid: '' },
          errors: {}
        };
        this.props.surveyBegin(this.props.tvClient, this.props.match.params.survey_url).then(() => {
            if(this.props.surveyData.existingSurvey!==undefined){
                if(this.props.surveyData.language!==undefined&&this.props.surveyData.language!==null){
                  localizedData.setLanguage(this.props.surveyData.language);
                }
                if(this.props.surveyData.existingSurvey.departments.length>0){
                    // We will need the staff for these departments if they go backwards or if they load this page
                    this.props.getDeptStaff(this.props.tvClient,this.props.surveyData.existingSurvey.departments);
                }
                this.setState({ savedSurvey: this.props.surveyData.existingSurvey, currentQuestion: this.props.surveyData.current_state, syncSurvey: true, fullyLoaded: true });
            } else{
                this.setState({ fullyLoaded: true, newSurvey: true });
            }
        });
        this.handleFormChange = this.handleFormChange.bind(this);

    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
      this.validateInlineField(e.target.name);
    }    


    saveSurveyStatus(){
        if(this.state.syncSurvey){
            this.props.updateSurvey(this.props.tvClient, this.state.savedSurvey, this.props.surveyData.urlInfo[0], this.state.currentQuestion, this.state.newSurvey, localizedData.getLanguage()); 
            this.setState({syncSurvey: false, newSurvey: false}); 
        }
    }

    finalSubmit(e){
        e.preventDefault();

        if(this.validateForm()) {
            this.props.createPatient(this.props.tvClient, this.state.savedSurvey, this.props.surveyData.urlInfo[0], this.state.fields["username"], this.state.fields["emailid"], this.state.fields["mobileno"], this.state.fields["feedbackType"], null).then(()=>{
                this.advanceSurveyFrom(this,"submit");
            });
            let fields = {};
            fields["username"] = "";
            fields["emailid"] = "";
            fields["mobileno"] = "";
            this.setState({fields:fields});
        }

    }

    validateInlineField(field){

        let errors = this.state.errors;
        let fields = this.state.fields;

        if(field==="username"){
          if (!fields["username"] || fields["username"].length===0) {
            errors["username"] = "*Please enter your name.";
          }

          if (typeof fields["username"] !== "undefined" && fields["username"].length>0) {
            if (!fields["username"].match(/^[-' a-z\u4e00-\u9eff]{1,20}$/i)) {
              errors["username"] = "*Please enter letters only.";
            } else {
                errors["username"] = '';
            }
          }            
      } else if(field==="mobileno"){
          if (!fields["mobileno"]) {
            errors["mobileno"] = "*Please enter your mobile no.";
          }

          if (typeof fields["mobileno"] !== "undefined") {
            if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
              errors["mobileno"] = "*Please enter a valid mobile no.";
            } else {
                errors["mobileno"] = '';
            }
          }        
      } else if(field==="emailid"){
          if (!fields["emailid"]) {
            errors["emailid"] = "*Please enter your email address.";
          }

          if (typeof fields["emailid"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["emailid"])) {
              errors["emailid"] = "*Please enter a valid email address.";
            } else {
                errors["emailid"] = '';
            }
          }        
      }

      this.setState({
        errors: errors
      });

    }

    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if (!fields["username"] || fields["username"].length===0) {
        formIsValid = false;
        errors["username"] = "*Please enter your name.";
      }

      if (typeof fields["username"] !== "undefined" && fields["username"].length>0) {
        if (!fields["username"].match(/^[-' a-z\u4e00-\u9eff]{1,20}$/i)) {
          formIsValid = false;
          errors["username"] = "*Please enter letters only.";
        }
      }

      if (!fields["emailid"]) {
        formIsValid = false;
        errors["emailid"] = "*Please enter your email address.";
      }

      if (typeof fields["emailid"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["emailid"])) {
          formIsValid = false;
          errors["emailid"] = "*Please enter a valid email address.";
        }
      }

      if (!fields["mobileno"]) {
        formIsValid = false;
        errors["mobileno"] = "*Please enter your mobile no.";
      }

      if (typeof fields["mobileno"] !== "undefined") {
        if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
          formIsValid = false;
          errors["mobileno"] = "*Please enter a valid mobile no.";
        }
      }

      this.setState({
        errors: errors
      });
      return formIsValid;

    }


    handleDayClick(day, { selected }) {
      
      var actualDate = new Date(); 
      var endOfDayDate = new Date(actualDate.getFullYear()
                           ,actualDate.getMonth()
                           ,actualDate.getDate()
                           ,23,59,59);

        if(endOfDayDate > day){
          let survey = this.state.savedSurvey;
          let sync = false;
          let originalDay = this.state.savedSurvey.selectedDay;
          survey.selectedDay = selected ? undefined : day;

          if(originalDay!==survey.selectedDay){
              sync = true;
          }

          this.setState({
              syncSurvey: sync,
              savedSurvey: survey,
              showNextButton: true
          });          
        }

    }

    handleNpsClick(rating){
        let survey = this.state.savedSurvey;
        let sync = false;
        let originalNps = this.state.savedSurvey.nps;
        survey.nps = rating;

        if(originalNps!==survey.nps){
            sync = true;
        }

        this.setState({ syncSurvey: sync, savedSurvey: survey }, () => { this.advanceSurveyFrom(this); });
        
    }

    submitPage(){
        let survey = this.state.savedSurvey;
        if(!survey.completed){
            survey.completed = true;
            this.setState({ syncSurvey: true, savedSurvey: survey }, () => { this.saveSurveyStatus(this); });  
        } 
    }

    retrieveUserDetails(){
        this.props.getUserDetails(this.props.surveyData).then(() => {
            var theQuestion = this.props.surveyData.questionInfo.length-2;
            this.setState({ fullyLoaded: true, currentQuestion: theQuestion });
        });
    }

    localizeDepartment(selectedDept){
      let finalName = selectedDept.name;
      if(localizedData.getLanguage()==='ES'){
        if(selectedDept.name_locale_es!==null && selectedDept.name_locale_es!==undefined){
          finalName = selectedDept.name_locale_es;
        }
      }

      return finalName;
    }

    chooseDepartments(selectedDept, e){
         e.stopPropagation()
        var currentDepartments = this.state.savedSurvey.departments;
        var originalDepts = this.state.savedSurvey.departments.slice(); 
        let sync = false;
        
        var foundIt = false;
        for (var i = 0, l = currentDepartments.length; i < l; i++) {
            if(currentDepartments[i].key === selectedDept.key) {
              foundIt=true;
              e.target.classList.remove("activeDept");
              currentDepartments.splice(i, 1);
              break;
            }
        }   

        if(!foundIt){
            e.target.classList.add("activeDept");
            currentDepartments.push(selectedDept);
        }

        if(currentDepartments!==originalDepts){
            sync = true;
        }

        var currentSurvey = this.state.savedSurvey;
        currentSurvey.departments = currentDepartments;
        this.setState({
            savedSurvey: currentSurvey,
            syncSurvey: sync
        });

    }

    chooseStaff(selectedStaff, e){
         e.stopPropagation();
        var originalStaff= this.state.savedSurvey.staff.slice(); 
        let sync = false;
        var currentStaff = this.state.savedSurvey.staff;
        var foundIt = false;
        for (var i = 0, l = currentStaff.length; i < l; i++) {
            if(currentStaff[i].staff_id === selectedStaff.staff_id) {
              foundIt=true;
              e.target.classList.remove("activeDept");
              currentStaff.splice(i, 1);
              break;
            }
        }   

        if(!foundIt){
            e.target.classList.add("activeDept");
            currentStaff.push(selectedStaff);
        }

        if(currentStaff!==originalStaff){
            sync = true;
        }

        var currentSurvey = this.state.savedSurvey;
        currentSurvey.staff = currentStaff;        

        this.setState({
            savedSurvey: currentSurvey,
            syncSurvey: sync
        });

    }

    chooseStaffEndorsement(selectedEndorsement, person, e){
        e.stopPropagation();
        let originalStaffEndorsements = this.state.savedSurvey.staff.splice();

        for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
            if(this.state.savedSurvey.staff[i].staff_id===person.staff_id){
                if(person.badges!==undefined){
                    var foundOne = false;
                    for (var j = person.badges.length - 1; j >= 0; j--) {
                        if(person.badges[j].endorsement_id===selectedEndorsement.endorsement_id){
                            // remove it
                            foundOne = true;
                            person.badges.splice(j, 1);
                            this.setState({ currentStaffDetail: person });
                            e.target.classList.remove("activeDept");
                            break;
                        }
                    }
                    if(!foundOne){
                        person.badges.push(selectedEndorsement);
                        this.setState({ currentStaffDetail: person });
                        e.target.classList.add("activeDept");
                    }
                    
                }
                else{
                    person.badges=[];
                    person.badges.push(selectedEndorsement);
                    this.setState({ currentStaffDetail: person });
                    e.target.classList.add("activeDept");
                }
                break;
            }
        }

        if(originalStaffEndorsements!==this.state.savedSurvey.staff){
            this.setState({ syncSurvey: true })
        }
    }

    createNewSurvey(){
        this.props.beginNewSurvey(this.props.surveyData).then(() => {
            this.setState({ fullyLoaded: true, newSurvey: true });
        });
    }

    chooseDepartmentEndorsement(selectedEndorsement, department, e){
        e.stopPropagation();
        let originalDeptEndorsements = this.state.savedSurvey.departments.splice();
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
            if(this.state.savedSurvey.departments[i].key===department.key){
                if(department.badges!==undefined){
                    var foundOne = false;
                    for (var i = department.badges.length - 1; i >= 0; i--) {
                        if(department.badges[i].endorsement_id===selectedEndorsement.endorsement_id){
                            // remove it
                            foundOne = true;
                            department.badges.splice(i, 1);
                            this.setState({ currentDeptDetail: department });
                            e.target.classList.remove("activeDept");
                            break;
                        }
                    }
                    if(!foundOne){
                        department.badges.push(selectedEndorsement);
                        this.setState({ currentDeptDetail: department });
                        e.target.classList.add("activeDept");
                    }
                    
                }
                else{
                    department.badges=[];
                    department.badges.push(selectedEndorsement);
                    this.setState({ currentDeptDetail: department });
                    e.target.classList.add("activeDept");
                }
                break;
            }
        }
        if(originalDeptEndorsements!==this.state.savedSurvey.departments){
            this.setState({ syncSurvey: true })
        }
    }


    generateClassNames(endorsement, entity){
        if(entity.badges!==undefined){
            var foundIt = false;
            for (var i = entity.badges.length - 1; i >= 0; i--) {
                if(entity.badges[i].endorsement_id===endorsement.endorsement_id){
                    foundIt=true;
                    break;
                }
            }
            if(foundIt){
                return true;
            }
            else{
                return false;
            }
        } else{
            return false;
        }
    }


    findNextPage(){
        let newSurveyData = this.props.surveyData;
        
        for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
            if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
                newSurveyData.currentPage = this.props.surveyData.questionInfo[i+1].page_id;
                newSurveyData.firstPage = false;
                break;
            }
        }

        return newSurveyData;
    }

    findPreviousPage(){
        let newSurveyData = this.props.surveyData;
        
        for (var i = this.props.surveyData.questionInfo.length - 1; i >= 0; i--) {
            if(this.props.surveyData.questionInfo[i].page_id === newSurveyData.currentPage){
                newSurveyData.currentPage = this.props.surveyData.questionInfo[i-1].page_id;
                if(this.props.surveyData.questionInfo[i-2]===undefined){
                    newSurveyData.firstPage = true;
                }
                break;
            }
        }

        return newSurveyData;
    }

    handleStaffFeedbackChange(event){
        //event.stopPropagation();
        if(event.target.value!==undefined && event.target.value!==null){
            var person = this.state.currentStaffDetail;
            person.feedback = event.target.value;
            this.setState({ currentStaffDetail: person, syncSurvey: true });            
        }
    }

    handleDeptFeedbackChange(event){
        //event.stopPropagation();
        if(event.target.value!==undefined && event.target.value!==null){
            var dept = this.state.currentDeptDetail;
            dept.feedback = event.target.value;
            this.setState({ currentDeptDetail: dept, syncSurvey: true });            
        }
    }

    isDepartmentSelected(dept){
        var isSelected = false;
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
           if(this.state.savedSurvey.departments[i].key === dept){
            isSelected=true;
           }
        }
        return isSelected;
    }

    isStaffSelected(staff){
        var isSelected = false;
        for (var i = this.state.savedSurvey.staff.length - 1; i >= 0; i--) {
           if(this.state.savedSurvey.staff[i].staff_id === staff){
            isSelected=true;
           }
        }
        return isSelected;
    }

    staffCached(){
        var strDepts = '';
        for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
            strDepts += this.state.savedSurvey.departments[i].key;
        }  

        var foundIt = false;

        for (var j = this.state.cachedStaffRequests.length - 1; j >= 0; j--) {
            if(this.state.cachedStaffRequests[j].depts===strDepts){
                foundIt = true;
                break;
            }
        }
    

        return foundIt;

    }

    advanceSurveyFrom(currentStep, requirements){

        if(requirements==='staff'){
            var strDepts = '';
            for (var i = this.state.savedSurvey.departments.length - 1; i >= 0; i--) {
                strDepts += this.state.savedSurvey.departments[i].key;
            }

            if(!this.staffCached()){
                this.props.getDeptStaff(this.props.tvClient, this.state.savedSurvey.departments).then((response)=> {
                    var newRequest = { 'depts': strDepts, 'staff': this.props.staffData };
                    var currStaffRequests = this.state.cachedStaffRequests;
                    currStaffRequests.push(newRequest);
                    this.setState({ cachedStaffRequests: currStaffRequests });
                });                
            }
            else{
                for (var i = this.state.cachedStaffRequests.length - 1; i >= 0; i--) {
                    if(this.state.cachedStaffRequests[i].depts === strDepts){
                        this.props.cachedResults(this.state.cachedStaffRequests[i].staff);
                    }
                }
            }

        }

        this.setState({
            surveyData: this.findNextPage(),
            currentQuestion: this.state.currentQuestion+1
        }, () => { if(this.state.currentQuestion+1 === this.props.surveyData.questionInfo.length-1){ this.submitPage(this); } });

        if(requirements!=="submit"){
            this.saveSurveyStatus();    
        }
        

    }

    cycleSelectedItems(){
        var currentNumber = this.state.currentStaffNumber;
        var currentNumberDept = this.state.currentDeptNumber;

        if(this.state.savedSurvey.staff.length>0){
            // they chose some people so let's go
            if(currentNumber===null || (!this.state.cyclingDept && !this.state.cyclingStaff) ){
                this.setState({ cyclingStaff: true, cyclingDept: false });
                this.setState({ currentStaffNumber: 0, currentStaffDetail: this.state.savedSurvey.staff[0]});
            } else{
                if(this.state.savedSurvey.staff.length>currentNumber+1){
                    this.setState({ cyclingStaff: true, cyclingDept: false });
                    this.setState({ currentStaffNumber: currentNumber+1, currentStaffDetail: this.state.savedSurvey.staff[currentNumber+1]});      
                }else{
                    if(currentNumberDept===null || this.state.cyclingStaff===true){
                        this.setState({ currentDeptNumber: 0, currentDeptDetail: this.state.savedSurvey.departments[0], cyclingDept: true, cyclingStaff: false});
                    } else{
                        if(this.state.savedSurvey.departments.length > currentNumberDept+1){
                            this.setState({ currentDeptNumber: currentNumberDept+1, currentDeptDetail: this.state.savedSurvey.departments[currentNumberDept+1], cyclingDept: true, cyclingStaff: false});
                        } else{
                            this.setState({ cyclingDept: false, cyclingStaff: false})
                            this.advanceSurveyFrom('cycle', null);
                        }
                    }   
                }
            }
        } else{
            // there is no reason to ask about people so go directly to department
            if(currentNumberDept===null  || (!this.state.cyclingDept && !this.state.cyclingStaff)){
                this.setState({ currentDeptNumber: 0, currentDeptDetail: this.state.savedSurvey.departments[0], cyclingDept: true, cyclingStaff: false});
            } else{
                if(this.state.savedSurvey.departments.length > currentNumberDept+1){
                    this.setState({ currentDeptNumber: currentNumberDept+1, currentDeptDetail: this.state.savedSurvey.departments[currentNumberDept+1]})  ;  
                } else{
                    this.setState({ cyclingDept: false, cyclingStaff: false});
                    this.advanceSurveyFrom('cycle', null);
                } 
            }            
        }
        this.saveSurveyStatus();
    };

    cycleBackwardItems(){
        var currentNumber = this.state.currentStaffNumber;
        var currentNumberDept = this.state.currentDeptNumber;

        if(this.state.savedSurvey.staff.length>0){
            // they chose some people so keep that in mind
            if(!this.state.cyclingDept && !this.state.cyclingStaff){
                this.backwardSurveyFrom('cycle', null);
                this.setState({ currentDeptNumber: this.state.savedSurvey.departments.length-1, currentDeptDetail: this.state.savedSurvey.departments[this.state.savedSurvey.departments.length-1], cyclingStaff: false, cyclingDept: true })
            } else if(this.state.cyclingDept){
                if(currentNumberDept===0){
                    // Need to switch to people
                    this.setState({ currentStaffNumber: this.state.savedSurvey.staff.length-1, currentStaffDetail: this.state.savedSurvey.staff[this.state.savedSurvey.staff.length-1], cyclingStaff: true, cyclingDept: false });
                } else{
                    this.setState({ currentDeptNumber: currentNumberDept-1, currentDeptDetail: this.state.savedSurvey.departments[currentNumberDept-1], cyclingStaff: false, cyclingDept: true});
                }
            }
            else{
                if((currentNumber-1) < 0){
                    // Need to switch back to last shown page
                    this.setState({ currentStaffNumber: 0, currentStaffDetail: this.state.savedSurvey.staff[0], cyclingStaff: false, cyclingDept: false });
                } else{
                    this.setState({ currentStaffNumber: currentNumber-1, currentStaffDetail: this.state.savedSurvey.staff[currentNumber-1]});     
                }
            }
           
        } else{
            // there is no reason to ask about people so go directly to department
            if(!this.state.cyclingDept && !this.state.cyclingStaff){
                this.backwardSurveyFrom('cycle', null);
                this.setState({ currentDeptNumber: this.state.savedSurvey.departments.length-1, currentDeptDetail: this.state.savedSurvey.departments[this.state.savedSurvey.departments.length-1], cyclingStaff: false, cyclingDept: true })
            } else if(currentNumberDept===null || currentNumberDept===0){
                this.setState({ currentDeptNumber: 0, currentDeptDetail: this.state.savedSurvey.departments[0], cyclingStaff: false, cyclingDept: false });
            } else{
                this.setState({ currentDeptNumber: currentNumberDept-1, currentDeptDetail: this.state.savedSurvey.departments[currentNumberDept-1]});
            }            
        }

    }

    backwardSurveyFrom(currentStep){
        this.setState({
            surveyData: this.findPreviousPage(),
            currentQuestion: this.state.currentQuestion-1
        }); 
    }

    changeLocale(ev, param){

        localizedData.setLanguage(param);
        this.setState({});
    }


    render() {
        return  <div className="container actualSurvey">

      {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-1") ?  <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a href onClick={this.backwardSurveyFrom.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
        {this.props.surveyData && (this.state.showNextButton && !this.props.surveyData.firstPage) ? <button type="button" onClick={ (ev) => this.advanceSurveyFrom(this, null)} className="btn btn-primary nextBtn">{localizedData.next}</button> : null }
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData.demo_page_1}</h5>
            <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 5)} className="card-img-great overallCard"></a>
          </div> 
                    <div className="card mx-auto" >
              <a onClick={this.handleNpsClick.bind(this, 4)} className="card-img-good overallCard"></a>
          </div> 
                    <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 3)} className="card-img-okay overallCard"></a>
          </div>  
                    <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 2)} className="card-img-meh overallCard"></a>
          </div> 

          <div className="card mx-auto">
              <a onClick={this.handleNpsClick.bind(this, 1)} className="card-img-sad overallCard"></a>
          </div>

          <div className="row cardInstructions"><p>{localizedData.nps_choose}</p></div>
          <br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <a onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>
       </div></div> : null
   }
      {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-2") ?   <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a onClick={this.backwardSurveyFrom.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
        {this.props.surveyData && (this.state.showNextButton || (this.state.savedSurvey.selectedDay!==null) ) ? <button type="button" onClick={ (ev) => this.advanceSurveyFrom(this, null)} className="btn btn-primary nextBtn">{localizedData.next}</button> : null }
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData.demo_page_2}</h5>        <div className="visitCalContainer">
                <DayPicker localeUtils={MomentLocaleUtils} locale={localizedData.getLanguage()} toMonth={new Date()} selectedDays={this.state.savedSurvey.selectedDay} onDayClick={this.handleDayClick} disabledDays={[{after: new Date()}]}/>
                <p className="selectedDay">
          {this.state.savedSurvey.selectedDay
            ? this.state.savedSurvey.selectedDay.toLocaleDateString()
            : localizedData.please_select_day}
        </p>
        
        </div>
         <br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <a onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>
       </div></div> : null
   }

 {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-3") ?   <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a onClick={this.backwardSurveyFrom.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
        {this.props.surveyData && (this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={ (ev) => this.advanceSurveyFrom(this, 'staff')} className="btn btn-primary nextBtn">{localizedData.next}</button> : null }
      </div><div className="row cardholder badges">
          <h5 className="surveyPageTitle">{localizedData.demo_page_3}</h5>  
            {
                        this.props.surveyData.departments.map(c => {

                            return  <div className="nopad col-xs text-center" key={c.key}>
                                        <label className="image-checkbox">
                                          <img onClick={(ev) => this.chooseDepartments(c, ev) } className={"img-responsive" + (this.isDepartmentSelected(c.key) ? ' activeDept' : '')} alt={c.name} src={c.icon.startsWith('http') ? c.icon : process.env.PUBLIC_URL + '/department_assets/' + c.icon} />
                                          
                                          <p className="sDeptName">{this.localizeDepartment(c)}</p>
                                        </label>
                                      </div>
                        })
                    } 
                    <br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <a onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>     
       </div></div> : null
   }




 {this.props.surveyData && (this.props.surveyData.currentPage==="page-4" && !this.state.cyclingStaff && !this.state.cyclingDept && this.state.fullyLoaded) ?   <div> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a onClick={this.backwardSurveyFrom.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
        {this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0 ) ? <button type="button" onClick={this.cycleSelectedItems.bind(this)} className="btn btn-primary nextBtn">{localizedData.next}</button> : null }
      </div><div className="row cardholder staffList">
          <h5 className="surveyPageTitle">{localizedData.demo_page_4}</h5>    
   

            {this.props.staffData ?
                        this.props.staffData.map(c => {

                            return  <div className="nopad col-xs text-center" key={c.staff_id}>
                                        <label className="image-checkbox">
                                          <img alt={c.name} onClick={(ev) => this.chooseStaff(c, ev) } className={"img-responsive" + (this.isStaffSelected(c.staff_id) ? ' activeDept' : '')} src={c.staffimageid.startsWith('http') ? c.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + c.staffimageid} />
                                          <p className="sDeptName">{c.title === 'Dr.' ? c.title : '' } {c.name}</p>
                                        </label>
                                      </div>
                        })
                   : null } 

                    {!this.props.staffData && <Spinner/>}
<br/><br/>
          <div className="row cardInstructions text-right" ><p>Language: <a onClick={(ev) => this.changeLocale(this, 'EN')}>English</a> / <a onClick={(ev) => this.changeLocale(this, 'ES')}>Español</a></p></div>     
       </div><button type="button" onClick={this.cycleSelectedItems.bind(this)} className="addNewButton hidden">Add someone else</button></div> : null
   }

 {this.props.surveyData && (this.state.cyclingStaff && this.state.fullyLoaded) ?   <div className="innerContent"> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a onClick={this.cycleBackwardItems.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
        {this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.cycleSelectedItems.bind(this)} className="btn btn-primary nextBtn">{localizedData.next}</button> : null }
      </div>
      <h5 className="surveyPageTitle separator">{localizedData.formatString(localizedData.how_did, this.state.currentStaffDetail.name)}</h5>    
        <div id="staffList">
            <div className="staffHeader">
              <div className="staffImage">

                <img className="img-responsive" alt={this.state.currentStaffDetail.name} src={this.state.currentStaffDetail.staffimageid.startsWith('http') ? this.state.currentStaffDetail.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + this.state.currentStaffDetail.staffimageid} />
                <div className="staffDetails">
                  <p className="name">{this.state.currentStaffDetail.name}, {this.state.currentStaffDetail.title}</p>
                  <p className="department">{this.state.currentStaffDetail.bio}</p>
                  <div className="mostFrequentBadge">
                    <div className="theBadge"></div>
                    <div className="badgeDetails">
                      <p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
                      <p className="badgeDescription">{localizedData.badge_instruction_staff}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="menu">
              <p>{localizedData.choose_badges} <span className="extraInfo">( {localizedData.scroll_for_more} )</span></p>
            </div>
            <div className="badgeSelector">
                    {
                        this.props.surveyData.staffEndorsements.map(c => {
                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className="badge-checkbox">
                                      <img onClick={(ev) => this.chooseStaffEndorsement(c, this.state.currentStaffDetail, ev) } alt="Choose" className={"img-responsive" + (this.generateClassNames(c, this.state.currentStaffDetail) ? ' activeDept' : '')} src={process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p>{localizedData[c.localization_key]}</p>
                                    </label>
                                  </div>
                        })
                    }      
            </div>

            <div className="menu">
              <p>{localizedData.write_feedback}</p>
            </div>
            <textarea placeholder={localizedData.feedback_placeholder} id="staffFeedback" onChange={this.handleStaffFeedbackChange.bind(this)} value={this.state.currentStaffDetail.feedback!==undefined ? this.state.currentStaffDetail.feedback : ''} />
            
        </div>

      </div> : null
   }

 {this.props.surveyData && (this.state.cyclingDept && this.state.fullyLoaded) ?   <div className="innerContent"> <div className="navbar row text-center">
        {this.props.surveyData && !this.props.surveyData.firstPage ? <a onClick={this.cycleBackwardItems.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
        {this.props.surveyData && (this.state.showNextButton || this.state.savedSurvey.departments.length>0) ? <button type="button" onClick={this.cycleSelectedItems.bind(this)} className="btn btn-primary nextBtn">{localizedData.next}</button> : null }
      </div>
      <h5 className="surveyPageTitle separator">{localizedData.how_was} {this.localizeDepartment(this.state.currentDeptDetail)}?</h5>    
        <div id="staffList">
            <div className="staffHeader">
              <div className="staffImage">

                <img className="img-responsive deptImageFix" alt={this.state.currentDeptDetail.name} src={this.state.currentDeptDetail.icon.startsWith('http') ? this.state.currentDeptDetail.icon : process.env.PUBLIC_URL + '/department_assets/' + this.state.currentDeptDetail.icon} />
                <div className="staffDetails">
                  <p className="name">{this.localizeDepartment(this.state.currentDeptDetail)}</p>
                  <div className="mostFrequentBadge">
                    <div className="theBadge"></div>
                    <div className="badgeDetails">
                      <p className="badgeTitle"><span className="totalNumberEndorsements"></span>{localizedData.endorsements}</p>
                      <p className="badgeDescription">{localizedData.badge_instruction_dept}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="menu">
              <p>{localizedData.choose_badges} <span className="extraInfo">( {localizedData.scroll_for_more} )</span></p>
            </div>
            <div className="badgeSelector">
                    {
                        this.props.surveyData.deptEndorsements.map(c => {

                            return <div className="nopad isBadge text-center" key={c.endorsement_id}>
                                    <label className="badge-checkbox">
                                      <img onClick={(ev) => this.chooseDepartmentEndorsement(c, this.state.currentDeptDetail, ev) } className={"img-responsive" + (this.generateClassNames(c, this.state.currentDeptDetail) ? ' activeDept' : '')} src={process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                      <p>{localizedData[c.localization_key]}</p>
                                    </label>
                                  </div>
                        })
                    }      

            </div>

            <div className="menu">
              <p>{localizedData.write_feedback}</p>
            </div>
            <textarea placeholder={localizedData.feedback_placeholder} id="deptFeedback" onChange={this.handleDeptFeedbackChange.bind(this)} value={this.state.currentDeptDetail.feedback!==undefined ? this.state.currentDeptDetail.feedback : ''} />
            
        </div>

      </div> : null
   }

 {this.props.surveyData && (this.state.fullyLoaded && this.props.surveyData.currentPage==="page-5") ?   <div> <div className="navbar row text-center">
        {this.props.surveyData && (!this.props.surveyData.firstPage && !this.props.surveyData.lastPage) ? <a onClick={this.cycleBackwardItems.bind(this)} className="backButton">&lt; {localizedData.back}</a> : null }
        <p>{process.env.CLIENT_NAME}</p>
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData[this.props.surveyData.questionInfo[this.state.currentQuestion].page_id]}</h5> 
          <p>{localizedData.get_info_text}</p>
           <Form onSubmit={this.finalSubmit.bind(this)} className="selfIdentifyForm">

              <div className="form-group">
                <label htmlFor="nameEntry">{localizedData.name}: <span className="text-danger">*</span></label>
                <input type="textfield" className="form-control" id="nameEntry"  value={this.state.fields.username} name="username" placeholder={localizedData.name_placeholder} onChange={this.handleFormChange}/>
                <div className="errorMsg">{this.state.errors.username}</div>
              </div>
              <div className="form-group">
                <label htmlFor="phoneEntry">{localizedData.phone}: <span className="text-danger">*</span></label>
                <input type="tel" className="form-control" id="phoneEntry" placeholder={localizedData.phone_placeholder} value={this.state.fields.mobileno} name="mobileno" onChange={this.handleFormChange}/>  
                <div className="errorMsg">{this.state.errors.mobileno}</div>            
              </div>
              <div className="form-group">
                <label htmlFor="emailEntry">Email: <span className="text-danger">*</span></label>
                <input type="email" className="form-control" id="emailEntry" placeholder={localizedData.email_placeholder}  name="emailid" value={this.state.fields.emailid} onChange={this.handleFormChange}/>    
                <div className="errorMsg">{this.state.errors.emailid}</div>          
              </div> 
              <div className="form-group">
                <label htmlFor="feedbackType">{localizedData.i_am_a} <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="feedbackType" value="Patient" defaultChecked={true} onChange={this.handleFormChange}/>
                    {localizedData.patient}
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="feedbackType" value="Family Member" onChange={this.handleFormChange}/>
                    {localizedData.family_member}
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="feedbackType" value="Care Giver" onChange={this.handleFormChange}/>
                    {localizedData.care_giver}
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="feedbackType" value="Staff" onChange={this.handleFormChange}/>
                    {localizedData.staff_member}
                  </label>
                </div>
              </div>                            
              <div className="form-group container">
                <button type="submit" className="btn btn-primary" id="yourInfoButton">{localizedData.submit}</button>
              </div>
              </Form>
              <div id="errorMsg"></div>       
       </div></div> : null
   }

 {this.props.surveyData && (this.props.surveyData.currentPage==="page-6" && this.state.fullyLoaded) ?   <div> <div className="navbar row text-center">
        <p>{process.env.CLIENT_NAME}</p>
      </div><div className="row cardholder">
          <h5 className="surveyPageTitle">{localizedData[this.props.surveyData.questionInfo[this.state.currentQuestion].page_id]}</h5> 
                  
       </div></div> : null
   }

 {this.props.surveyData && (this.props.surveyData.recentlyCompleted) ?   <div> <div className="navbar row text-center">
        <p>{process.env.CLIENT_NAME}</p>
      </div><div className="row cardholder completedOptionPage">
          <h5 className="surveyPageTitle">{localizedData.thank_you}</h5> 
          <p>{localizedData.recent_survey_text} {process.env.CLIENT_NAME}.</p>
          <p>{localizedData.would_you_like}</p>
          <ul className="completedOptions">
          <li><a onClick={this.createNewSurvey.bind(this)}>{localizedData.submit_new_survey}</a></li>
          { !this.props.surveyData.hasPatientInfo ? <li><a onClick={this.retrieveUserDetails.bind(this)}>{localizedData.add_contact_info}</a></li> : null }
          </ul>
       </div></div> : null
   }

    </div>

    }
}

const mapStateToProps = (state) => {
    return {
        surveyData: state.survey.surveyData,
        existingSurvey: state.survey.existingSurvey,
        tvClient: state.login.tvClient,
        staffData: state.staff.staffData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      surveyBegin: (...params) => dispatch(surveyBegin(...params)),
      getDeptStaff: (...params) => dispatch(getDeptStaff(...params)),
      cachedResults: (...params) => dispatch(cachedResults(...params)),
      updateSurvey: (...params) => dispatch(updateSurvey(...params)),
      createPatient: (...params) => dispatch(createPatient(...params)),
      beginNewSurvey: (...params) => dispatch(beginNewSurvey(...params)),
      getUserDetails: (...params) => dispatch(getUserDetails(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServeSurvey);
