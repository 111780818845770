import React, {Component} from "react";
import {
    Table,
    Dropdown
} from "react-bootstrap";
import { viewPeerLocationFeedbackDetail, viewPeerLocationStaffActivityDetail, listAllPeerStaffEngagementsReport, viewPeerLocationEndorsementDetail, listAllStaffEngagements, listAllStaffEngagementsReport, listLocations, viewPeerSummaryByLocation, viewAdminLocationOverviewData, viewLocationNpsData, viewCustomLocationNpsDataByLocation, viewAdminDashboardEndorsementsReports } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges"; 
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import ExportActivePeerStaffListCSV from './../../Utilities/ExportActivePeerStaffListCSV';
import ExportRecognizedPeerStaffListCSV from './../../Utilities/ExportRecognizedPeerStaffListCSV';
import { TagCloud } from 'react-tagcloud'
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class PeerLocationDrillDownReport extends Component {

    constructor(props) {
        super(props);
        this.props.listLocations(this.props.tvClient);
        this.state = {
            emptySet: [{'name':'No Data','value': 1}],
            perpage: 10,
            filter: '',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 8,
            activeKey: 1,
            dateFilter: 'thismonth',
            locationFilter: 0,
            currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
            currentFilterLocation: ' Choose One ',
            showCustomDate: false,
            reportFrom: null,
            reportTo: null,
            currentDate: new Date().toLocaleString('en-us', { month: 'long' }),
            colorOptions: {luminosity: 'dark',hue: 'blue'}
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);   

    }   

    viewPeerLocationDetails(locationId){
      this.props.viewPeerLocationDetails(this.props.tvClient, locationId);
    }

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
      this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Dates (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      //this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      //this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      //this.props.viewCustomLocationNpsDataByLocation(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.locationFilter);
      //this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      //this.props.viewAdminLocationOverviewData(this.props.accessToken, 'custom', this.state.locationFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), 0);
      this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));


    } 

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
          this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        });
      } else if(selectedKey==2){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
          this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        });
      } else if(selectedKey==3){
        this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
         this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
         this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
         this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        });
      } else if(selectedKey==4){
        this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
          this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        });
      } else if(selectedKey==5){
        this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
          this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);        
          this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month ', currentFilter: selectedKey }, () => {
          this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
          this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
          this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        });
        
      }
    }

    componentDidMount() {
        //this.props.viewPeerSummaryByLocation(this.props.accessToken, this.state.dateFilter);
        this.props.viewPeerLocationEndorsementDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        this.props.listAllPeerStaffEngagementsReport(this.props.tvClient, 'and', '', null, 1, 10, this.props.peerLocationId, this.state.dateFilter, null, null, 0);
        this.props.viewPeerLocationStaffActivityDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter);
        this.props.viewPeerLocationFeedbackDetail(this.props.tvClient, this.props.peerLocationId, this.state.dateFilter).then(()=>{
                Object.values(this.props.locations).map(d => {
                  if(d.location_id===this.props.peerLocationId){
                    this.setState({currentFilterLocation: d.name});
                  }
                })
        });
    }


    render() {
       return <div>
            <h1 className="page-header">
                {Object.values(this.props.locations).map(d => {
                  if(d.location_id===this.props.peerLocationId){
                    return d.name;
                  }
                })} Peer-To-Peer Drill Down
  
                    <div className="float-right">
                    <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                          <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                          <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                          <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                          <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                          <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                          <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
  

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }

            </h1>


            {this.props.peerLocationDetailsLoading && <Spinner/>}
           
            {!this.props.peerLocationDetailsLoading && <div>

            <div className="case-list-container">

              <div className="row">
                <div className="col-md-4 middleCptContainer">
                     <div className="totalSurveys">
                        <img alt="Total Recognitions" src={process.env.PUBLIC_URL + '/admin_assets/launchedIcon.png'} />
                        <div className="detailedInfo">
                            {(this.props.currentPeerLocationEndorsements!==null && this.props.peerLocationSummaryFeedback) && <h4>{this.props.peerLocationSummaryFeedback.totalFeedback+this.props.currentPeerLocationEndorsements.badgeTotal}</h4>}
                            <h5>Recognitions</h5>
                            <p><span className="smallSubHead">in {this.state.currentFilterName}</span></p>
                        </div>
                    </div> 
                     <div className="totalOpened">
                        <img alt="Total Badges" src={process.env.PUBLIC_URL + '/admin_assets/openedIcon.png'} />
                        <div className="detailedInfo">
                            {this.props.currentPeerLocationEndorsements!==null && <h4>{this.props.currentPeerLocationEndorsements.badgeTotal}</h4>}
                            <h5>Badges</h5>
                            <p><span className="smallSubHead">in {this.state.currentFilterName}</span></p>
                        </div>
                    </div>                    
                     <div className="totalCompleted">
                        <img alt="Total Written" src={process.env.PUBLIC_URL + '/admin_assets/completedIcon.png'} />
                        <div className="detailedInfo">
                            {this.props.peerLocationSummaryFeedback!==null && <h4>{this.props.peerLocationSummaryFeedback.totalFeedback}</h4>}
                            <h5>Written</h5>
                            <p><span className="smallSubHead">in {this.state.currentFilterName}</span></p>
                        </div>
                    </div> 
                </div>
                <div className="col-md-7 nps-survey-container">
                <div>
                            <h5>
                            Trending Written Feedback <span className="smallSubHead">{this.state.currentFilterName}</span>
                            </h5>
                        </div>
                  {this.props.peerLocationSummaryFeedbackLoading && <Spinner/>}
                  {(this.props.peerLocationSummaryFeedback!==null && this.props.peerLocationSummaryFeedback!==undefined && !this.props.peerLocationSummaryFeedbackLoading) && <TagCloud
                    minSize={12}
                    maxSize={40}
                    style={{ textAlign: 'center', height: '275px', verticalAlign: 'middle', padding: '0 15px', overflow: 'hidden' }}
                    colorOptions={this.state.colorOptions}
                    tags={this.props.peerLocationSummaryFeedback.wordFrequency}
                  /> }

                </div>
            </div>


              <div className="row">
                <div className="col-md-4 nps-survey-container adminDashboardBadges peerDrillDown">
                        <div>
                            <h5>
                            Top Peer Endorsements <span className="smallSubHead">{this.state.currentFilterName}</span>
                            </h5>
                        </div>
                        {this.props.peerLocationBadgeLoading && <Spinner/>}

                        {this.props.currentPeerLocationEndorsements!==null && <div>
                                
                              {
                                  this.props.currentPeerLocationEndorsements.badgeData.slice(0,7).map(c => {
                                      return <div className="statBadgeContainer" key={c.endorsement_id}>
                                              <label>
                                                <img alt="" className="img-fluid" src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                                <p>{c.description}</p>
                                              </label>
                                            </div>
                                  })
                              }  

                      </div> 
                      }


                        {(!this.props.peerLocationBadgeLoading && this.props.currentPeerLocationEndorsements!==null && this.props.currentPeerLocationEndorsements.badgeData.length===0) && <p>No data to display</p> }

                </div>
                <div className="col-md-4 nps-survey-container peerDrillDown">
                    <div>
                      <h5>Most Recognized Staff &nbsp;&nbsp;<ExportRecognizedPeerStaffListCSV className="inlineBlock1" locationId={this.props.peerLocationId} dateFilter={this.state.dateFilter} locationName={this.state.currentFilterLocation} token={this.props.tvClient.accessToken} /> <span className="smallSubHead">{this.state.currentFilterName}</span></h5>
                    </div>
                    {this.props.staffEngagementDataLoading && <Spinner/>}
                    {this.props.staffEngagementData && <div>
                                
                            {
                                this.props.staffEngagementData.slice(0,6).map(c => {
                                    return <div className="statDeptContainer" key={c.key}>
                                            <label>
                                              <img alt="" className="img-fluid" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/staff_assets/' + c.image}/>
                                              <p>{c.name}</p>
                                              <p className="engagementCount">{c.engagements}</p>
                                            </label>
                                            
                                          </div>
                                })
                            }  

                    </div> 
                    }           
                    {(!this.props.staffEngagementDataLoading && this.props.staffEngagementData && this.props.staffEngagementData.length===0) && <p> No Data to display </p> }
                </div>      
                <div className="col-md-4 nps-survey-container peerDrillDown">
                    <div>
                      <h5>Most Active Staff &nbsp;&nbsp;<ExportActivePeerStaffListCSV className="inlineBlock1" locationId={this.props.peerLocationId} dateFilter={this.state.dateFilter} locationName={this.state.currentFilterLocation} token={this.props.tvClient.accessToken} /> <span className="smallSubHead">{this.state.currentFilterName}</span></h5>
                    </div>
                    {this.props.currentPeerLocationActivityLoading && <Spinner/>}
                    {(this.props.currentPeerLocationActivity!==null && this.props.currentPeerLocationActivity.badgeData.length>0) && <div>
                                
                            {
                                this.props.currentPeerLocationActivity.badgeData.slice(0,6).map((c,index) => {
                                    return <div className="statDeptContainer" key={index}>
                                            <label>
                                              <img alt="" className="img-fluid" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/staff_assets/' + c.image}/>
                                              <p>{c.name}</p>
                                              <p className="engagementCount">{c.total}</p>
                                            </label>
                                            
                                          </div>
                                })
                            }  

                    </div> 
                    }           
                    {(!this.props.currentPeerLocationActivityLoading && this.props.currentPeerLocationActivity!==null && this.props.currentPeerLocationActivity.badgeData.length===0) && <p> No Data to display </p> }
   
                </div> 

              </div>


            </div> 

            </div>
            }



        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,      
        locations: state.locationList.locations,
        peerLocationId: state.peerLocationView.currentPeerLocationId,
        peerLocationDetails: state.peerLocationView.currentPeerLocationDetail,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        roleLocations: state.login.user.attributes.locations,
        peerLocationSummaryLoading: state.peerLocationSummary.loading,
        peerLocationDetailsLoading: state.peerLocationView.loading,
        peerLocationSummaryBadges: state.peerLocationSummary.badges,
        peerLocationSummaryParticpating: state.peerLocationSummary.totalParticipation,
        peerLocationSummaryTotalStaff: state.peerLocationSummary.totalLocationStaff,
        peerLocationBadgeLoading: state.peerLocationView.currentPeerLocationEndorsementLoading,
        currentPeerLocationEndorsements: state.peerLocationView.currentPeerLocationEndorsements,
        currentPeerLocationActivity: state.peerLocationView.currentPeerLocationActivity,
        currentPeerLocationActivityLoading: state.peerLocationView.currentPeerLocationActivityLoading,
        staffEngagementDataLoading: state.staffPeerEngagementData.loading,
        staffEngagementData: state.staffPeerEngagementData.engagementData,
        peerLocationSummaryBadges: state.peerLocationSummary.badges,
        peerLocationSummaryFeedback: state.peerLocationView.currentPeerLocationFeedback,
        peerLocationSummaryFeedbackLoading: state.peerLocationView.currentPeerLocationFeedbackLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listLocations: (...params) => dispatch(listLocations(...params)),
        viewPeerSummaryByLocation: (...params) => dispatch(viewPeerSummaryByLocation(...params)),
        viewPeerLocationEndorsementDetail: (...params) => dispatch(viewPeerLocationEndorsementDetail(...params)),
        listAllPeerStaffEngagementsReport: (...params) => dispatch(listAllPeerStaffEngagementsReport(...params)),
        viewPeerLocationStaffActivityDetail: (...params) => dispatch(viewPeerLocationStaffActivityDetail(...params)),
        viewPeerLocationFeedbackDetail: (...params) => dispatch(viewPeerLocationFeedbackDetail(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeerLocationDrillDownReport);
