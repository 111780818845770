import React, {Component} from "react";
import {Pagination} from "react-bootstrap";

class PaginationArea extends Component {
    constructor(props) {
        super(props);
    }

    deleteGroupMember(e) {
       e.preventDefault();
       this.props.deleteGroupMember(this.props.tvClient, this.props.groupDetail, this.props.groupMemberDetail, this.hide2.bind(this));
    }

    refreshPage(number){
        this.props.parentRefresh(number);    
    }

    render() {
        return  <div className="text-center">
                { (this.props.numberOfPages > 1) && <Pagination size="sm">
                    <Pagination.First onClick={this.refreshPage.bind(this, 1)} />
                    {(parseInt(this.props.currentPage) > 1) && <Pagination.Prev onClick={this.refreshPage.bind(this, parseInt(this.props.currentPage) - 1)} /> }
                    { 
                        this.props.items.map((c,i) => {
                            if(this.props.numberOfPages > 10){
                                if(c.value===1 || c.value===this.props.numberOfPages){
                                    return <Pagination.Item key={c.value} onClick={this.refreshPage.bind(this, c.value)} active={this.props.currentPage===c.value}>{c.value}</Pagination.Item>  
                                } else {
                                    if(this.props.currentPage <= 5){
                                        if(c.value <= 5){
                                            return <Pagination.Item key={c.value} onClick={this.refreshPage.bind(this, c.value)} active={this.props.currentPage===c.value}>{c.value}</Pagination.Item>    
                                        }
                                        else if(c.value===6){
                                            return <Pagination.Ellipsis key={c.value} />
                                        } 
                                    } else if(this.props.currentPage >= this.props.numberOfPages-5){
                                        if(c.value > (this.props.numberOfPages - 5)){
                                            return <Pagination.Item key={c.value} onClick={this.refreshPage.bind(this, c.value)} active={this.props.currentPage===c.value}>{c.value}</Pagination.Item>    
                                        }
                                        else if(c.value===this.props.numberOfPages - 5){
                                            return <Pagination.Ellipsis key={c.value} />
                                        }
                                    } else {
                                        if((c.value > this.props.currentPage - 2) && (c.value < this.props.currentPage + 2)){
                                         return <Pagination.Item key={c.value} onClick={this.refreshPage.bind(this, c.value)} active={this.props.currentPage===c.value}>{c.value}</Pagination.Item>       
                                        } else if(c.value=== this.props.currentPage - 2 || c.value=== this.props.currentPage + 2){
                                         return <Pagination.Ellipsis key={c.value} />   
                                        } 
                                    }
                                }

                            } else{
                                return <Pagination.Item key={c.value} onClick={this.refreshPage.bind(this, c.value)} active={this.props.currentPage===c.value}>{c.value}</Pagination.Item>                                        
                            }                                    
                        })
                    }
                    {(parseInt(this.props.currentPage) < this.props.numberOfPages) && <Pagination.Next onClick={this.refreshPage.bind(this, parseInt(this.props.currentPage) + 1)} />}
                    <Pagination.Last onClick={this.refreshPage.bind(this, parseInt(this.props.numberOfPages))} />
                    </Pagination>
                }
            </div>
    }
}

export default PaginationArea;
