import React, {Component} from "react";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { getActivePeerStaffExport } from "../../actions"


class ExportActivePeerStaffListCSV extends Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false
        }
        this.csvLinkEl = React.createRef();
    }

    downloadReport = async () => {
        this.setState({ loading: true });
        const data = await this.props.getActivePeerStaffExport(this.props.token, this.props.locationId, this.props.dateFilter);
        this.setState({ data: data.staff, loading: false }, () => {
            this.csvLinkEl.current.link.click();
        });
    }

    render() {
        const { data } = this.state;
        return (

      <div className='inlineBlock2'>
        <FontAwesomeIcon disabled={this.state.loading} icon={faDownload} onClick={this.downloadReport} />
        <CSVLink
          headers={[{ label: "Name", key: "name" },{ label: "Total Recognitions", key: "total" }]}
          filename={"Active Peers List - " +this.props.locationName+".csv"}
          data={data}
          ref={this.csvLinkEl}
          className="hidden"
        >Download Staff</CSVLink>
      </div>

          
        )

    }
}

ExportActivePeerStaffListCSV.propTypes = {
    locationId: PropTypes.number.isRequired,
    locationName: PropTypes.string.isRequired,
    dateFilter: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient
    };
};

export default connect(mapStateToProps, {getActivePeerStaffExport})(ExportActivePeerStaffListCSV);

