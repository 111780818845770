import React, {Component} from "react";
import {
    Dropdown
} from "react-bootstrap";
import { listLocations, viewSpecificEndorsementReport } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import {AreaChart, ScatterChart, Scatter, Area, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate,} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class SpecificBehaviorReport extends Component {

    constructor(props) {
        super(props);
        this.props.listLocations(this.props.tvClient);
        this.state = {
          activeKey: 1,
          currentFilter: 8,
          filter: 'thismonth',
          locationFilter: 0,
          currentFilterName: '  This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
          currentFilterLocation: ' All Locations ',
          showCustomDate: false,
          showLocations: true,
          reportFrom: null,
          reportTo: null
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);

    }   

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
      this.setState({ filter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.props.match.params.behaviorId);
    } 

    handleLocationFilter(selectedKey){
      
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
        this.props.viewSpecificEndorsementReport(this.props.accessToken, parseInt(selectedKey), this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.props.match.params.behaviorId);
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
            this.props.viewSpecificEndorsementReport(this.props.accessToken, selectedKey, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.props.match.params.behaviorId);
            break;
          }
        }
        
      }
    }

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.props.match.params.behaviorId);         
        });
      } else if(selectedKey==2){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.props.match.params.behaviorId);       
        });
      } else if(selectedKey==3){
        this.setState({ filter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null,this.props.match.params.behaviorId); 
        });
      } else if(selectedKey==4){
        this.setState({ filter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.props.match.params.behaviorId); 
        });
      } else if(selectedKey==5){
        this.setState({ filter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.props.match.params.behaviorId); 
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: selectedKey }, () => {
          this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.props.match.params.behaviorId);
        });
        
      }
    }

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    componentDidMount(){
        //this.props.viewAdminDashboardEndorsements(this.props.accessToken);
        //let isRefresh = this.props.specificBehavior === null;
        //if(isRefresh){
        //    this.props.viewSpecificEvent(this.props.tvClient, this.props.match.params.eventId);
        //}

        this.props.viewSpecificEndorsementReport(this.props.accessToken, this.state.locationFilter, this.state.filter, null, null, this.props.match.params.behaviorId);
        window.scrollTo(0, 0);
    } 

    tableHeader(sortKey, label) {
        return <th className="sortable"
                   onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    render() {
       return <div className="reportingPages">
            <h1 className="extraDropDown page-header">
                Specific Behavior Report 

                    <div className="float-right">
                        <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Filter Report By Date
                          </Dropdown.Toggle>
                          <Dropdown.Menu>                    
                            <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                            <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                            <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                            <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                            <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }
            {(this.state.showLocations && this.props.locations) && <div className="customLocationRange">
                      <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Choose a Location
                          </Dropdown.Toggle>
                          <Dropdown.Menu>  

                    <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item>
                      {Object.values(this.props.locations).map(d => {
                        return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                      })}
                      </Dropdown.Menu>
                    </Dropdown>
            </div> }    
            </h1>
            <p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
                        <div className="case-list-container">
            <h2 className="headerSpacer">{(this.props.endorsementData && !this.props.endorsementLoading && this.props.endorsementData.badgeInfo.length>0) && <span><img alt="" className="img-fluid badgeOnReport" src={this.props.endorsementData.badgeInfo[0].image.startsWith('http') ? this.props.endorsementData.badgeInfo[0].image : process.env.PUBLIC_URL + '/endorsement_assets/' + this.props.endorsementData.badgeInfo[0].image}></img>{this.props.endorsementData.badgeInfo[0].description}</span>} Badge Use Over Time</h2>
            </div>
            <div className="case-list-container">
                    {this.props.endorsementLoading && <Spinner/>}

 {(this.props.endorsementData && !this.props.endorsementLoading) && 
<ResponsiveContainer width="90%" height={300} >
                  <ScatterChart width={730} height={250}
                    margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="created" name="Date" interval={2}/>
                    <YAxis dataKey="total" name="Total" />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Legend />
                    <Scatter name="Staff Usage" data={this.props.endorsementData.staffBadgeData} fill="#8884d8" />
                    <Scatter name="Dept. Usage" data={this.props.endorsementData.deptBadgeData} fill="#82ca9d" />
                  </ScatterChart>
</ResponsiveContainer>}

            {(this.props.endorsementData && !this.props.endorsementLoading && this.props.endorsementData.staffBadgeData.length===0) && <div><p>No data to display for selected criteria</p></div>}
            </div><br/><br/>  

        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        endorsementLoading: state.specificEndorsementCpt.loading,
        endorsementData: state.specificEndorsementCpt.endorsementData,
        locations: state.locationList.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listLocations: (...params) => dispatch(listLocations(...params)),
        viewSpecificEndorsementReport: (...params) => dispatch(viewSpecificEndorsementReport(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecificBehaviorReport);
