/**
 * This file contains "actions", which are global methods used to modify global application state. This
 * pattern strikes a balance between the convenience of a single source of global state and avoiding the
 * risk of globally mutating global state. Suppose a particular Component wants to add a flash message.
 * It doesn't directly update the global state. Instead, it uses the `displayFlashMessage` action. That
 * method dispatches an action, which is handled by reducers.js.
 *
 * For more details about this pattern, refer to the [Actions](http://redux.js.org/docs/basics/Actions.html) and
 * [Reducers](http://redux.js.org/docs/basics/Reducers.html) sections of the [redux documentation](http://redux.js.org/).
 * Note that we also use the [redux action helpers](https://github.com/acdlite/redux-actions) to reduce the amount
 * of boilerplate.
 */
import {createAction} from "redux-actions";
import {push} from "connected-react-router";
import TrueVaultClient from "truevault";
import PublicHelper from "./public-helpers.js"
import InternalApiClient from "../src/internal-api-client.js";

export const internalApiClient = new InternalApiClient(process.env.REACT_APP_API_DOMAIN);
export const publicHelpers = new PublicHelper('');

const surveyLocationListStart = createAction('SURVEY_LOCATION_LIST_START');
const surveyLocationListError = createAction('SURVEY_LOCATION_LIST_ERROR');
const surveyLocationListSuccess = createAction('SURVEY_LOCATION_LIST_SUCCESS');

export function findAllLocations(tvClient) {
    return async dispatch => {
        dispatch(surveyLocationListStart());

        try {
            // Download non-PHI data (approver, reviewer) from server
            const locations = await internalApiClient.getSurveyLocationList(tvClient);

            dispatch(surveyLocationListSuccess({locations: locations}));
        } catch (error) {
            dispatch(surveyLocationListError(error));
        }
    };
}

const staffPointListStart = createAction('STAFF_POINT_LIST_START');
const staffPointListError = createAction('STAFF_POINT_LIST_ERROR');
const staffPointListSuccess = createAction('STAFF_POINT_LIST_SUCCESS');

export function retrieveStaffPointHistory(tvClient, urlId, page) {
    return async dispatch => {
        dispatch(staffPointListStart());

        try {
            // Download non-PHI data (approver, reviewer) from server
            const pointHistory = await internalApiClient.retrieveStaffPointHistory(tvClient, urlId, page);
            dispatch(staffPointListSuccess({history: pointHistory}));
        } catch (error) {
            dispatch(staffPointListError(error));
        }
    };
}


const executeSurveyStart = createAction('EXECUTE_SURVEY_START');
const executeSurveyError = createAction('EXECUTE_SURVEY_ERROR');
const executeSurveySuccess = createAction('EXECUTE_SURVEY_SUCCESS');

export function executePersonalSurvey(tvAccessToken, surveyIdObj) {
    return async dispatch => {
        try {
            dispatch(executeSurveyStart());            
            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const surveyUrlDetails = await internalApiClient.executePersonalSurvey(tvAccessToken, surveyIdObj);
            dispatch(executeSurveySuccess({ surveyUrlDetails }));

        } catch (error) {
            dispatch(executeSurveyError(error));
        }
    };
}

const rewardTicketsStart = createAction('REWARD_TICKETS_START');
const rewardTicketsError = createAction('REWARD_TICKETS_ERROR');
const rewardTicketsSuccess = createAction('REWARD_TICKETS_SUCCESS');
export function rewardPrizeTickets(tvAccessToken, prize, url_id){
    return async dispatch => {
        try {
            dispatch(rewardTicketsStart());            
            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            //const surveyUrlDetails = await internalApiClient.updateSurvey(tvAccessToken, surveyDetails, surveyIdObj, currentQuestion, forceNew, language);
            var number = 1;
            if(prize==='3 Raffle Entries'){
                number = 3;
            } else if(prize==='2 Raffle Entries'){
                number = 2;
            }

            if(number!==1){
                // eslint-disable-next-line
                const updatedReview = await(internalApiClient.updateRewardEntries(tvAccessToken, number, url_id));
            }

            dispatch(rewardTicketsSuccess({ }));

        } catch (error) {
            dispatch(rewardTicketsError(error));
        }
    };
} 

const trackReviewReferStart = createAction('TRACK_REVIEW_REFER_CLICK_START');
const trackReviewReferError = createAction('TRACK_REVIEW_REFER_CLICK_ERROR');
const trackReviewReferSuccess = createAction('TRACK_REVIEW_REFER_CLICK_SUCCESS');
export function trackReviewReferClicks(tvAccessToken, site, url_id){
    return async dispatch => {
        try {
            dispatch(trackReviewReferStart());            

            // eslint-disable-next-line
            //const updatedReview = await(internalApiClient.updateRewardEntries(tvAccessToken, number, url_id));

            internalApiClient.trackReferReviewClicks(tvAccessToken, site, url_id);

            dispatch(trackReviewReferSuccess({ }));

        } catch (error) {
            dispatch(trackReviewReferError(error));
        }
    };
} 

const updateSurveyStart = createAction('UPDATE_SURVEY_START');
const updateSurveyError = createAction('UPDATE_SURVEY_ERROR');
const updateSurveySuccess = createAction('UPDATE_SURVEY_SUCCESS');

export function updateSurvey(tvAccessToken, surveyDetails, surveyIdObj, currentQuestion, forceNew, language) {
    return async dispatch => {
        try {
            dispatch(updateSurveyStart());            
            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const surveyUrlDetails = await internalApiClient.updateSurvey(tvAccessToken, surveyDetails, surveyIdObj, currentQuestion, forceNew, language);
            dispatch(updateSurveySuccess({ surveyUrlDetails }));
        } catch (error) {
            dispatch(updateSurveyError(error));
        }
    };
}

const updateSurveyUpdateStart = createAction('UPDATE_SURVEY_PERSONA_START');
const updateSurveyUpdateError = createAction('UPDATE_SURVEY_PERSONA_ERROR');
const updateSurveyUpdateSuccess = createAction('UPDATE_SURVEY_PERSONA_SUCCESS');

export function updateSurveyPersonDetails(tvAccessToken, url_id, name, phone, email){
     return async dispatch => {
        try {
            dispatch(updateSurveyUpdateStart());            
            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const updateSuccess = await internalApiClient.updateSurveyPersonDetails(tvAccessToken, url_id, name, phone, email);
            dispatch(updateSurveyUpdateSuccess({ }));
        } catch (error) {
            dispatch(updateSurveyUpdateError(error));
        }
    };
}

function numDaysBetween(d1, d2) {
  var diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

const updatePhotoStart = createAction('UPDATE_PHOTO_START');
const updatePhotoError = createAction('UPDATE_PHOTO_ERROR');
const updatePhotoSuccess = createAction('UPDATE_PHOTO_SUCCESS');

export function updateStaffPhoto(tvAccessToken, photo, requestId){
    return async dispatch => {
        try {
            dispatch(updatePhotoStart());            

            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const requestUrlDetails = await internalApiClient.updateRequestPhoto(tvAccessToken, photo, requestId);

            dispatch(updatePhotoSuccess({ requestUrlDetails }));
        } catch (error) {
            dispatch(updatePhotoError(error));
        }
    }
}

const requestPhotoStart = createAction('REQUEST_PHOTO_START');
const requestPhotoError = createAction('REQUEST_PHOTO_ERROR');
const requestPhotoSuccess = createAction('REQUEST_PHOTO_SUCCESS');

export function requestTheStaffPhoto(tvAccessToken, requestUrl){
    return async dispatch => {
        try {
            dispatch(requestPhotoStart());            
            
            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const requestUrlDetails = await internalApiClient.getRequestPhotoUrl(tvAccessToken, requestUrl);

            dispatch(requestPhotoSuccess({ requestUrlDetails }));
        } catch (error) {
            dispatch(requestPhotoError(error));
        }
    }
}


const surveyBeginStart = createAction('SURVEY_BEGIN_START');
const surveyBeginError = createAction('SURVEY_BEGIN_ERROR');
const surveyBeginSuccess = createAction('SURVEY_BEGIN_SUCCESS');

/**
 * This action doesn't interact with TrueVault directly from the client, it loads aggregate analytics data from the
 * Internal API Server. This demonstrates how you can easily add your own server side data processing for de-identified
 * data. In this case, we perform relatively simple analytics but you could perform complex data science algorithms in
 * your application.
 * @param tvAccessToken For Internal API authentication. Use the same TrueVault AccessToken that you
 *      would send to TrueVault. The NodeJS server can use it to validate the user is who they claim to be, and
 *      authorize actions accordingly.
 * @returns {function(*)}
 */
export function surveyBegin(tvAccessToken, surveyUrl, parameters=null) {
    return async dispatch => {
        try {
            dispatch(surveyBeginStart());            

            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const surveyUrlDetails = await internalApiClient.getSurveyUrl(tvAccessToken, surveyUrl);

            if(surveyUrlDetails.urlInfo.length===0){
                // Invalid URL so get the org url and take them to that one
                /** TODO:  should we check to see if htey already have something existing here? */
                const orgSurvey = await internalApiClient.getOrgSurveyUrl(tvAccessToken);
                if(orgSurvey.urlInfo.length>0){
                    const path = '/s/'+orgSurvey.urlInfo[0].url_id;
                    orgSurvey.firstPage = true;
                    orgSurvey.currentPage = orgSurvey.questionInfo[0].page_id;
                    dispatch(surveyBeginSuccess({ orgSurvey }));
                    dispatch(push(path));
                } else{
                    dispatch(surveyBeginError(orgSurvey));
                }

            } else if(surveyUrlDetails.urlInfo[0].url_type==="DEVICE" || surveyUrlDetails.urlInfo[0].url_type==="PRODUCT" || surveyUrlDetails.urlInfo[0].url_type==="DEVICEDEPT"){
                // This is a device URL which means we need to generate a new survey for this usage
                // Create a new survey and pass through any parameters we have
                var path;
                var newSurveyUrlDetails = await internalApiClient.getDeviceSurvey(tvAccessToken, surveyUrl);
                
                if(surveyUrlDetails.urlInfo[0].url_type==="DEVICEDEPT"){
                    path = '/e/'+newSurveyUrlDetails.urlInfo[0].url_id;   
                    newSurveyUrlDetails.deviceDept = 1;
                } else{
                    path = '/j/'+newSurveyUrlDetails.urlInfo[0].url_id;                
                }
                
                var incentiveWheel = newSurveyUrlDetails.includeWheel;
                var intakeForm = newSurveyUrlDetails.intakeForm;
                
                if(incentiveWheel===undefined || incentiveWheel===null){
                    incentiveWheel = false;
                } else{
                    (incentiveWheel==="true" || incentiveWheel===true) ? incentiveWheel=false : incentiveWheel=true;
                }
                
                newSurveyUrlDetails.incentiveWheel = incentiveWheel;               
                   
                if(newSurveyUrlDetails.departments!==undefined && newSurveyUrlDetails.departments[0].ip_address!==undefined && newSurveyUrlDetails.departments[0].ip_address.toString()!=="1000000000"){
                    newSurveyUrlDetails.notYourSurvey = true;
                    newSurveyUrlDetails.isCompleted = newSurveyUrlDetails.departments[0].completed;
                }

                if(newSurveyUrlDetails.isCompleted){
                    newSurveyUrlDetails.recentlyCompleted = true;
                    newSurveyUrlDetails.currentPage = newSurveyUrlDetails.questionInfo[0].page_id;
                    newSurveyUrlDetails.firstPage = true;
                    newSurveyUrlDetails.lastPage = false;
                    newSurveyUrlDetails.notYourSurvey = false;
                    dispatch(push(path));
                    dispatch(surveyBeginSuccess({ 'surveyUrlDetails': newSurveyUrlDetails })); 
                } else{
                    // Retrieve this specific patient survey url 
                    newSurveyUrlDetails.currentPage = newSurveyUrlDetails.questionInfo[0].page_id;
                    newSurveyUrlDetails.firstPage = true;
                    newSurveyUrlDetails.lastPage = false;


                    if(intakeForm===true || intakeForm==='true'){
                        newSurveyUrlDetails.needIntake = true;
                    }

                    dispatch(push(path));
                    dispatch(surveyBeginSuccess({ 'surveyUrlDetails': newSurveyUrlDetails }));
                }  

            } else if(surveyUrlDetails.urlInfo[0].url_type==="PATIENT"){
                    const existingSurvey = await internalApiClient.getExistingSurvey(tvAccessToken, surveyUrl);
                    var incentiveWheel = surveyUrlDetails.includeWheel;
                    var intakeForm = surveyUrlDetails.intakeForm;  
                    if(incentiveWheel===undefined || incentiveWheel===null){
                        incentiveWheel = false;
                    } else{
                        (incentiveWheel==="true" || incentiveWheel===true) ? incentiveWheel=false : incentiveWheel=true;
                    }

                    surveyUrlDetails.incentiveWheel = incentiveWheel;
                    if(existingSurvey!==undefined && existingSurvey.length>0){
                        var recentSurvey = existingSurvey[0];

                        if(recentSurvey.completed){
                            surveyUrlDetails.recentlyCompleted = true;
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 
                        } else{
                            surveyUrlDetails.existingSurvey = recentSurvey.survey_data;
                            if(surveyUrlDetails.existingSurvey.staff===undefined){
                                surveyUrlDetails.existingSurvey.staff = [];
                            }
                            if(surveyUrlDetails.existingSurvey.departments===undefined){
                                surveyUrlDetails.existingSurvey.departments = [];
                            }
                            if(surveyUrlDetails.existingSurvey.completed===undefined){
                                surveyUrlDetails.existingSurvey.completed = false;
                            }
                            if(surveyUrlDetails.existingSurvey.nps===undefined){
                                surveyUrlDetails.existingSurvey.nps = null;
                            } 
                            if(surveyUrlDetails.existingSurvey.selectedDay===undefined){
                                surveyUrlDetails.existingSurvey.selectedDay = null;
                            }
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;

                            if(intakeForm===true || intakeForm==='true'){
                                // Look to see if they completed the intake already in the existing survey
                                // If it is complete then we skip and provide the data
                                if(surveyUrlDetails.existingSurvey.intakeResults!==undefined){
                                    surveyUrlDetails.needIntake = false;
                                    surveyUrlDetails.intakeData = surveyUrlDetails.existingSurvey.intakeResults;
                                } else{
                                    // If it is incomplete then we display it
                                    surveyUrlDetails.needIntake = true;
                                }
                            
                            }

                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 
                        }

                    } else{
                        if(surveyUrlDetails.departments!==undefined && surveyUrlDetails.departments[0].ip_address!==undefined && surveyUrlDetails.departments[0].ip_address.toString()!=="1000000000"){
                            surveyUrlDetails.notYourSurvey = true;
                            surveyUrlDetails.isCompleted = surveyUrlDetails.departments[0].completed;
                        }

                        if(surveyUrlDetails.isCompleted){
                            surveyUrlDetails.recentlyCompleted = true;
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            surveyUrlDetails.notYourSurvey = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 
                        } else{

                            // Retrieve this specific patient survey url 
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;


                            if(intakeForm===true || intakeForm==='true'){
                                surveyUrlDetails.needIntake = true;
                            }

                            dispatch(surveyBeginSuccess({ surveyUrlDetails }));  
                        }                      
                    }

            } else if(surveyUrlDetails.urlInfo[0].url_type==="EMPLOYER"){
                    const existingSurvey = await internalApiClient.getExistingSurvey(tvAccessToken, surveyUrl);
                    if(existingSurvey!==undefined && existingSurvey.length>0){
                        // eslint-disable-next-line
                        var recentSurvey = existingSurvey[0];
                        if(recentSurvey.completed){
                            surveyUrlDetails.recentlyCompleted = true;
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 

                        } else{
                            surveyUrlDetails.existingSurvey = recentSurvey.survey_data;
                            if(surveyUrlDetails.existingSurvey.staff===undefined){
                                surveyUrlDetails.existingSurvey.staff = [];
                            }
                            if(surveyUrlDetails.existingSurvey.departments===undefined || surveyUrlDetails.existingSurvey.departments.length===0){
                                var theDepartments = [];
                                if(surveyUrlDetails.departments!==undefined){
                                    theDepartments = surveyUrlDetails.departments;
                                }
                                surveyUrlDetails.existingSurvey.departments = theDepartments;
                            }
                            if(surveyUrlDetails.existingSurvey.completed===undefined){
                                surveyUrlDetails.existingSurvey.completed = false;
                            }
                            if(surveyUrlDetails.existingSurvey.nps===undefined){
                                surveyUrlDetails.existingSurvey.nps = null;
                            } 
                            if(surveyUrlDetails.existingSurvey.selectedDay===undefined){
                                surveyUrlDetails.existingSurvey.selectedDay = null;
                            }                                                       
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 
                        }

                    } else{
                        if(surveyUrlDetails.departments!==undefined && surveyUrlDetails.departments[0].ip_address!==undefined && surveyUrlDetails.departments[0].ip_address.toString()!=="1000000000"){
                            surveyUrlDetails.notYourSurvey = true;
                            surveyUrlDetails.isCompleted = surveyUrlDetails.departments[0].completed;
                        }

                        if(surveyUrlDetails.isCompleted){
                            surveyUrlDetails.recentlyCompleted = true;
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            surveyUrlDetails.notYourSurvey = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 
                        } else{
                            // Retrieve this specific patient survey url 
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails }));  
                        }                      
                    }

            } else if(surveyUrlDetails.urlInfo[0].url_type==="FIELDREP"){
                    const existingSurvey = await internalApiClient.getExistingSurvey(tvAccessToken, surveyUrl);
                    if(existingSurvey!==undefined && existingSurvey.length>0){
                        // eslint-disable-next-line
                        var recentSurvey = existingSurvey[0];

                        if(recentSurvey.completed){
                            surveyUrlDetails.recentlyCompleted = true;
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 

                        } else{
                            surveyUrlDetails.existingSurvey = recentSurvey.survey_data;
                            if(surveyUrlDetails.existingSurvey.staff===undefined){
                                surveyUrlDetails.existingSurvey.staff = [];
                            }
                            if(surveyUrlDetails.existingSurvey.departments===undefined){
                                surveyUrlDetails.existingSurvey.departments = [];
                            }
                            if(surveyUrlDetails.existingSurvey.completed===undefined){
                                surveyUrlDetails.existingSurvey.completed = false;
                            }
                            if(surveyUrlDetails.existingSurvey.nps===undefined){
                                surveyUrlDetails.existingSurvey.nps = null;
                            } 
                            if(surveyUrlDetails.existingSurvey.selectedDay===undefined){
                                surveyUrlDetails.existingSurvey.selectedDay = null;
                            }                                                       
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails })); 
                        }

                    } else{
                            // Retrieve this specific fieldrep survey url 
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails }));                        
                     }                
            } else if(surveyUrlDetails.urlInfo[0].url_type==="MYORGURL" || surveyUrlDetails.urlInfo[0].url_type==="EMP"){
                // If it does exist grab the IP at the internalAPIClient and look to see if a survey has already been created for this person
                const existingSurvey = await internalApiClient.getExistingSurvey(tvAccessToken, surveyUrl); 
                var invalidEmployee = false;
                var previousBadges = [];
                var totalPoints = '0';
                if(surveyUrlDetails.urlInfo[0].url_type==="EMP"){
                    const employeeCheck = await internalApiClient.checkValidEmpUrl(tvAccessToken, surveyUrl);
                    if(employeeCheck.validEmployee.length>0){
                        invalidEmployee = !employeeCheck.validEmployee[0].active;
                    } else{
                        invalidEmployee=true;
                    }

                    if(employeeCheck.previousBadges!==undefined){
                        previousBadges=employeeCheck.previousBadges;
                    }
                    if(employeeCheck.total!==undefined){
                        totalPoints = employeeCheck.total;
                    }
                }            
                if(existingSurvey.length>0 && !invalidEmployee){
                    var recentSurvey = existingSurvey[0];
                    // We could have multiple surveys returned here
                    // check the created date, the T.V. person identifier and the completed flag

                    // If the created date is within 72 hours and it is completed serve up the "you recently completed a survey.  Did you want to fill out a new one?"
                    var createdDate = new Date(recentSurvey.created);
                    var today = new Date();
                    var difference = numDaysBetween(createdDate, today);
                            
                    // TODO: problem of missing surveys could be here.  Peer to Peer
                    if(difference >=0 && difference <=3){
                        // check survey for being completed
                        if(!recentSurvey.completed){
                            surveyUrlDetails.existingSurvey = recentSurvey.survey_data;
                            surveyUrlDetails.language = recentSurvey.survey_lang;

                            if(surveyUrlDetails.existingSurvey.selectedDay!==undefined && surveyUrlDetails.existingSurvey.selectedDay!==null){
                                surveyUrlDetails.existingSurvey.selectedDay = new Date(surveyUrlDetails.existingSurvey.selectedDay);    
                            }

                            if(recentSurvey.current_state===null){
                                surveyUrlDetails.current_state=0;
                            } else{
                                for (var i = surveyUrlDetails.questionInfo.length - 1; i >= 0; i--) {
                                    if(surveyUrlDetails.questionInfo[i].question_id.toString()===recentSurvey.current_state.toString()){
                                        surveyUrlDetails.current_state = i;
                                        break;
                                    }
                                }                                
                            }
                             var currentQuestion =  surveyUrlDetails.current_state-1;
                             if(currentQuestion<0){
                                currentQuestion = 0;
                             }
                             currentQuestion = 0;

                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[currentQuestion].page_id;                                
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            surveyUrlDetails.totalPoints = totalPoints;
                            surveyUrlDetails.invalidEmployee = invalidEmployee;
                            surveyUrlDetails.previousBadges = previousBadges;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails }));                                
                        }
                        else{
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            surveyUrlDetails.newSurvey = true;
                            surveyUrlDetails.totalPoints = totalPoints;
                            surveyUrlDetails.invalidEmployee = invalidEmployee;
                            surveyUrlDetails.previousBadges = previousBadges;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails }));
                        }

                    } else{
                        surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                        surveyUrlDetails.firstPage = true;
                        surveyUrlDetails.lastPage = false;
                        surveyUrlDetails.newSurvey = true;
                        surveyUrlDetails.totalPoints = totalPoints;
                        surveyUrlDetails.invalidEmployee = invalidEmployee;
                        surveyUrlDetails.previousBadges = previousBadges;
                        dispatch(surveyBeginSuccess({ surveyUrlDetails }));
                    }

                }
                else{
                    // Configure required first time view variables
                    surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                    surveyUrlDetails.firstPage = true;
                    surveyUrlDetails.lastPage = false;
                    surveyUrlDetails.totalPoints = totalPoints;
                    surveyUrlDetails.invalidEmployee = invalidEmployee;
                    surveyUrlDetails.previousBadges = previousBadges;
                    dispatch(surveyBeginSuccess({ surveyUrlDetails }));
                }                
            } else{
                // If it does exist grab the IP at the internalAPIClient and look to see if a survey has already been created for this person
                const existingSurvey = await internalApiClient.getExistingSurvey(tvAccessToken, surveyUrl);

                if(existingSurvey.length>0){
                    // eslint-disable-next-line
                    var recentSurvey = existingSurvey[0];
                    // We could have multiple surveys returned here
                    // if it does take them to the step and retrieve the requried data to do it
                    // check the created date, the T.V. person identifier and the completed flag

                    //TODO:  if this is a URL for a specific person then we need to see if it's been filled out and if so don't serve anything up
                        // If the created date is within 72 hours and it is completed serve up the "you recently completed a survey.  Did you want to fill out a new one?"
                        var createdDate = new Date(recentSurvey.created);
                        var today = new Date();
                        var difference = numDaysBetween(createdDate, today);

                        if(difference >=0 && difference <=3){
                            // check survey for being completed
                            if(recentSurvey.completed){
                                surveyUrlDetails.recentlyCompleted = true;
                                surveyUrlDetails.hasPatientInfo = false;

                                if(recentSurvey.tv_survey_id!==null && recentSurvey.tv_survey_id!==undefined){
                                    surveyUrlDetails.hasPatientInfo = true;
                                }
                                dispatch(surveyBeginSuccess({ surveyUrlDetails }));  

                            } else{

                                surveyUrlDetails.existingSurvey = recentSurvey.survey_data;
                                surveyUrlDetails.language = recentSurvey.survey_lang;
                                if(surveyUrlDetails.existingSurvey.selectedDay!==undefined && surveyUrlDetails.existingSurvey.selectedDay!==null){
                                    surveyUrlDetails.existingSurvey.selectedDay = new Date(surveyUrlDetails.existingSurvey.selectedDay);    
                                }

                                // Need to find the true state because the saved state is the question
                                for (var i = surveyUrlDetails.questionInfo.length - 1; i >= 0; i--) {
                                    if(surveyUrlDetails.questionInfo[i].question_id.toString()===recentSurvey.current_state.toString()){
                                        surveyUrlDetails.current_state = i;
                                        break;
                                    }
                                }
                                
                                 var currentQuestion =  surveyUrlDetails.current_state

                                // If it is not completed but the state is second from the last it means they are endorsing staff or depts.  
                                if((surveyUrlDetails.questionInfo.length - 2)<=currentQuestion){
                                    // Take them to the staff selection page.
                                    var questionLen = surveyUrlDetails.questionInfo.length-1;
                                    surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[questionLen-2].page_id;
                                    surveyUrlDetails.current_state = questionLen-2;
                                } else{
                                    surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[currentQuestion].page_id;    
                                }                        
                                
                                surveyUrlDetails.firstPage = false;
                                surveyUrlDetails.lastPage = false;
                                dispatch(surveyBeginSuccess({ surveyUrlDetails }));                                
                            }

                        } else{
                            surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                            surveyUrlDetails.firstPage = true;
                            surveyUrlDetails.lastPage = false;
                            surveyUrlDetails.newSurvey = true;
                            dispatch(surveyBeginSuccess({ surveyUrlDetails }));
                        }

                }
                else{
                    // Configure required first time view variables

                    surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
                    surveyUrlDetails.firstPage = true;
                    surveyUrlDetails.lastPage = false;
                    dispatch(surveyBeginSuccess({ surveyUrlDetails }));
                }
            }

        } catch (error) {
            dispatch(surveyBeginError(error));
        }
    };
}


const getDeptStaffBeginStart = createAction('GET_DEPT_STAFF_START');
const getDeptStaffBeginError = createAction('GET_DEPT_STAFF_ERROR');
const getStaffBeginSuccess = createAction('GET_DEPT_STAFF_SUCCESS');

/**
 * This action doesn't interact with TrueVault directly from the client, it loads aggregate analytics data from the
 * Internal API Server. This demonstrates how you can easily add your own server side data processing for de-identified
 * data. In this case, we perform relatively simple analytics but you could perform complex data science algorithms in
 * your application.
 * @param tvAccessToken For Internal API authentication. Use the same TrueVault AccessToken that you
 *      would send to TrueVault. The NodeJS server can use it to validate the user is who they claim to be, and
 *      authorize actions accordingly.
 * @returns {function(*)}
 */
export function getDeptStaff(tvAccessToken, departments) {
    return async dispatch => {
        try {
            dispatch(getDeptStaffBeginStart()); 
            var deptIds = [];           
            for (var i = departments.length - 1; i >= 0; i--) {
                deptIds.push(departments[i].key);
            }
            // Does the survey url ID actually exist?  If not redirect to the generic page for this organization
            const staffDetails = await internalApiClient.getDeptStaff(tvAccessToken, deptIds);

            if(staffDetails){
                // Invalid URL so get the org url and take them to that one
                dispatch(getStaffBeginSuccess({ staffDetails }));
            } else{
                dispatch(getDeptStaffBeginError(staffDetails));
            }

        } catch (error) {
            dispatch(getDeptStaffBeginError(error));
        }
    };
}


const patientAddStart = createAction('PATIENT_ADD_START');
const patientAddError = createAction('PATIENT_ADD_ERROR');
const patientAddSuccess = createAction('PATIENT_ADD_SUCCESS');

/**
 * This method does a lot of work under the covers to de-identify the case data and store the non-PII in our Internal
 * API and the PII (or possible PII like images and free-text) in TrueVault. See the Api Helper by the same name
 * for details on the approach.
 */
export function createPatient(tvClient, savedSurvey, surveyId, name, email, phone, type, extra) {
    return async dispatch => {
        dispatch(patientAddStart());

        try {
            await publicHelpers.createPatient(internalApiClient.associatePatient.bind(internalApiClient),
                new TrueVaultClient({'apiKey': process.env.REACT_APP_PATIENT_API_TOKEN}), savedSurvey,
                surveyId,
                name, email, phone, type, extra);
            dispatch(patientAddSuccess());
        } catch (error) {
            dispatch(patientAddError(error));
        }
    };
}


export function cachedResults(staffDetails) {
    return async dispatch => {
         dispatch(getStaffBeginSuccess({ staffDetails }));
    };
}

export function beginNewSurvey(oldSurveyData, submittedSurvey=null){
    return async dispatch => {
       var surveyUrlDetails = oldSurveyData;

       if(submittedSurvey!==null && submittedSurvey!==undefined){
            
            for(var i=0; i<submittedSurvey.length;i++){
                var staffNotFound = true;
                for(var j=0; j<surveyUrlDetails.previousBadges.length;j++){
                    if(surveyUrlDetails.previousBadges[j].staff_id.toString()===submittedSurvey[i].staff_id.toString()){
                        staffNotFound = false;
                        if(surveyUrlDetails.previousBadges[j].badges!==undefined){
                            surveyUrlDetails.previousBadges[j].badges = surveyUrlDetails.previousBadges[j].badges.concat(submittedSurvey[i].badges);    
                        } else{
                            surveyUrlDetails.previousBadges[j].badges = [];
                            surveyUrlDetails.previousBadges[j].badges = surveyUrlDetails.previousBadges[j].badges.concat(submittedSurvey[i].badges);
                        }
                        
                    }
                }
                if(staffNotFound){
                    surveyUrlDetails.previousBadges.push({ 'staff_id': submittedSurvey[i].staff_id.toString(), 'badges': submittedSurvey[i].badges });
                }
            }
            
        }

        surveyUrlDetails.currentPage = oldSurveyData.questionInfo[0].page_id;
        surveyUrlDetails.firstPage = true;
        surveyUrlDetails.lastPage = false;
        surveyUrlDetails.recentlyCompleted = false;
        dispatch(surveyBeginSuccess({ surveyUrlDetails }));
    } 
}

export function restartPatientSurvey(tvClient, survey_url, oldSurveyData){
    
    return async dispatch => {
        // eslint-disable-next-line
        var updatedIp = await internalApiClient.updateSurveyForUser(tvClient, survey_url.url_id);
        var surveyUrlDetails = oldSurveyData;     

        // Retrieve this specific patient survey url 
        surveyUrlDetails.currentPage = surveyUrlDetails.questionInfo[0].page_id;
        surveyUrlDetails.firstPage = true;
        surveyUrlDetails.lastPage = false;
        surveyUrlDetails.notYourSurvey = false;
        surveyUrlDetails.recentlyCompleted = false;

        dispatch(surveyBeginSuccess({ surveyUrlDetails }));
    } 
}

export function findStaffByDept(tvAccessToken, departments){
     return async dispatch => {
        try {
            dispatch(getDeptStaffBeginStart()); 
            const staffDetails = await internalApiClient.getDeptStaff(tvAccessToken, departments);

            if(staffDetails){
                dispatch(getStaffBeginSuccess({ staffDetails }));
            } else{
                dispatch(getDeptStaffBeginError(staffDetails));
            }
        } catch (error) {
            dispatch(getDeptStaffBeginError(error));
        }
    };   
}

export function findStaffByName(tvAccessToken, query){
    return async dispatch => {
        try {
            //dispatch(staffSearchStart());            
            const staffList = await internalApiClient.findStaffByName(tvAccessToken, query);
            return staffList;
            //dispatch(staffSearchSuccess({ staffList }));

        } catch (error) {
            console.log(error);
            //dispatch(staffSearchError(error));
        }
    };
}

export function getUserDetails(oldSurveyData){
    return async dispatch => {
        var surveyUrlDetails = oldSurveyData;
        surveyUrlDetails.currentPage = oldSurveyData.questionInfo[oldSurveyData.questionInfo.length-2].page_id;
        surveyUrlDetails.firstPage = false;
        surveyUrlDetails.lastPage = true;
        surveyUrlDetails.recentlyCompleted = false;
        dispatch(surveyBeginSuccess({ surveyUrlDetails }));
    } 
}