import React, {Component} from "react";
import {connect} from "react-redux";
import {login} from "../../actions";
import {FormLabel, FormControl, FormGroup} from "react-bootstrap";
import AuthForm from "./AuthForm/AuthForm";

class LoginForm extends Component {
    login(e) {
        e.preventDefault();

        let mfaCode = this.mfaCode ? this.mfaCode.value : null;
        let newPassword = this.newPassword ? this.newPassword.value : null;
        let newPasswordConfirm = this.newPasswordConfirm ? this.newPasswordConfirm.value : null;

        this.props.login(this.username.value, this.password.value, mfaCode, newPassword, newPasswordConfirm);
        this.password.value='';
        if(this.mfaCode!==undefined){
            this.mfaCode.value=''
        }
    }

    render() {
        return <AuthForm onSubmit={this.login.bind(this)} authError={this.props.loginError} submitLabel="Login"
                         authInProgress={this.props.loggingIn}>
            <FormGroup controlId="username">
                <FormLabel>Username</FormLabel>
                <FormControl type="text" autoComplete="username" ref={ref => this.username = ref}/>
            </FormGroup>

            <FormGroup controlId="password">
                <FormLabel>{this.props.resetRequired && <span>Current </span>}Password</FormLabel>
                <FormControl type="password" autoComplete="current-password" ref={ref => this.password = ref}/>
            </FormGroup>

            {(this.props.resetRequired && !this.props.loggingIn) && <FormGroup controlId="newPassword">
                <FormLabel>New Password</FormLabel>
                <FormControl type="password" autoComplete="new-password" ref={ref => this.newPassword = ref}/>
            </FormGroup>}

            {(this.props.resetRequired && !this.props.loggingIn) && <FormGroup controlId="newPasswordConfirm">
                <FormLabel>Confirm Password</FormLabel>
                <FormControl type="password" ref={ref => this.newPasswordConfirm = ref}/>
            </FormGroup>}

            {this.props.mfaRequired && <FormGroup controlId="mfaCode">
                <FormLabel>MFA Code</FormLabel>
                <FormControl type="number" placeholder="000000" ref={ref => this.mfaCode = ref}/>
            </FormGroup>}
        </AuthForm>;
    }
}

const mapStateToProps = (state) => {
    return {
        loggingIn: state.login.loggingIn,
        loginError: state.login.loginError,
        mfaRequired: state.login.mfaRequired,
        resetRequired: state.login.resetRequired
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password, mfaCode, newPassword, newPasswordConfirm) => {
            dispatch(login(username, password, mfaCode, newPassword, newPasswordConfirm));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
