import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";
import dateFormat from "dateformat";

class ExportDeptCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    cleanData(dirtyData){

        let exportData = [];

        for (var i = dirtyData.length - 1; i >= 0; i--) {
            var thisLine = dirtyData[i].response;
            // eslint-disable-next-line no-useless-escape
            thisLine.feedback = thisLine.feedback.replace(/(\r\n|\n|\r|\")/gm,"");
            exportData.push({ 'Name': thisLine.name, 'Date': dateFormat(dirtyData[i].created, "shortDate"), 'Badges Given': thisLine.badges!==undefined ? thisLine.badges.length : 0,'Feedback': thisLine.feedback });
        }

        return exportData.reverse();
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
        )

    }
}

ExportDeptCSV.propTypes = {
    csvData: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired
};

export default ExportDeptCSV;
