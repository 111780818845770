import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Image,
    ProgressBar,
    Row,
    Table,
    Dropdown,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import IndividualStaffBadges from "./../../Utilities/IndividualStaffBadges"; 
import PaginationArea from "./../../Utilities/PaginationArea";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
import {listIndividualStaffEngagementsReport, viewSurvey, listIndividualStaffFeedbackReport, viewCustomAdminDashboardNps, viewAdminDashboardNps, viewStaff, listDepartments, listLocations, viewStaffNpsData} from "../../../actions";
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class IndividualStaffReport extends Component {

    componentDidMount() { 

        if(this.props.departments===undefined || this.props.departments===null || this.props.departments.length===0){
            this.props.listDepartments(this.props.tvClient, 'and', null, 'asc', 1, 100);
        }

         this.props.listLocations(this.props.tvClient).then(() => {
            let defaultValue = [];
            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                defaultValue.push(this.props.locations[i].location_id);
            }
            this.setState({
                primaryLocation: defaultValue
            });
        });
        var prom0 = this.props.viewStaff(this.props.tvClient, this.props.match.params.documentId);
        var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
        var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
        //if(this.state.dateFilter==='custom'){
        var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
        var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
        Promise.all([prom0, prom1,prom2,prom3,prom4]).then(()=>{
            this.updateWIQScore();
        });
        //return this.props.viewStaff(this.props.tvClient, this.props.match.params.documentId);
    }

    constructor(props) {
        super(props);
        this.state = {
            primaryLocation: 0,
            errors: { 'name': false, 'title': false, 'primaryDepartment': false },
            editLoading: true,
            departmentId: 0,
            emptySet: [{'name':'No Data','value': 1}],
            changed: false,
            image: null,
            preview: null,
            filter: 'thismonth',
            locationFilter: 0,
            currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
            currentFilterLocation: ' All Locations',
            departmentFilter: 0,
            currentDepartment: ' All Departments',
            showCustomDate: false,
            perpage: 10,
            wellIQRank: 'Calculating',
            badgeScore: '-',
            writtenScore: '-',
            npsScore: '-',
            surveysScore: '-'
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);  

    }

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    updateWIQScore(){
        var badgeValue = 0;
        var patSatValue = 0;
        var surveyNumbers = 0;
        var writtenFeedback = 0;

        badgeValue = this.props.staffBadgeValue===null ? 0 : this.props.staffBadgeValue;
        surveyNumbers = parseFloat(this.props.npsSurveyValue).toFixed(1);        
        writtenFeedback = this.props.staffFeedbackScore;
        if(surveyNumbers===0 && writtenFeedback===5){
            /* this means there were no reviews */
            writtenFeedback = 0;
        }
        var orgRating = this.props.orgSpecificRating;
        var staffRating = this.props.npsSpecificRating;
        if(orgRating.toString()!=='NaN' && staffRating.toString()!=='NaN'){
            orgRating = parseFloat(orgRating);
            staffRating = parseFloat(staffRating);
            patSatValue = parseFloat(20 - (20 * ((orgRating - staffRating) / 5 ))).toFixed(1);
        }

        var totalScore = (parseFloat(badgeValue) + parseFloat(patSatValue) + parseFloat(surveyNumbers) + parseFloat(writtenFeedback)).toFixed(1).toString();

        this.setState({ wellIQRank: totalScore, badgeScore: badgeValue, writtenScore: writtenFeedback, npsScore: patSatValue, surveysScore: surveyNumbers.toString()==='0.0' ? 0 : surveyNumbers });

    }

    changeWrittenFeedbackListPage(page) {
        if(this.state.dateFilter==='custom'){
            this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate")).then(()=>{
                this.updateWIQScore();
            });
        } else{
            this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.filter, null, null).then(()=>{
                this.updateWIQScore();
            });
        }
    }    

    changeStaffEngagementListPage(page){
        if(this.state.dateFilter==='custom'){
            this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.departmentFilter, this.props.match.params.documentId).then(()=>{
                this.updateWIQScore();
            });
        } else{
            this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId).then(()=>{
                this.updateWIQScore();
            });
        }
    }

    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id);
    }    

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    runReport(){
        this.setState({ filter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' }, () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.reportFrom, this.state.reportTo);    
            var prom2 = this.props.viewCustomAdminDashboardNps(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));            
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.departmentFilter, this.props.match.params.documentId);
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });        
    }

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '}, ()=>{
            this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            if(this.state.dateFilter==='custom'){
                var prom1 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));            
                var prom2 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.departmentFilter, this.props.match.params.documentId);
                Promise.all([prom1, prom2]).then(()=>{
                    this.updateWIQScore();
                });
            } else{
                var prom1 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
                var prom2 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
                Promise.all([prom1, prom2]).then(()=>{
                    this.updateWIQScore();
                });
            }
        });
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' }, () =>{
                var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
                if(this.state.dateFilter==='custom'){
                    var prom2 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));            
                    var prom3 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.departmentFilter, this.props.match.params.documentId);
                    Promise.all([prom1, prom2, prom3]).then(()=>{
                        this.updateWIQScore();
                    });
                } else{
                    var prom2 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
                    var prom3 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
                    Promise.all([prom1, prom2, prom3]).then(()=>{
                        this.updateWIQScore();
                    });
                }
            });
            
            break;
          }
        }
      }
    }

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ filter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });
      } else if(selectedKey==2){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });
      } else if(selectedKey==3){
        this.setState({ filter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });
      } else if(selectedKey==4){
        this.setState({ filter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId)
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });
      } else if(selectedKey==5){
        this.setState({ filter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ', currentFilter: selectedKey }, () => {
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            var prom2 = this.props.viewAdminDashboardNps(this.props.accessToken, this.state.filter);
            var prom3 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);
            var prom4 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
            Promise.all([prom1,prom2,prom3,prom4]).then(()=>{
                this.updateWIQScore();
            });
        });
        
      }
    }

    filterByDepartment(selectedKey){
        if(parseInt(selectedKey)===0){
          this.setState({ departmentFilter: 0, currentDepartment: ' All Departments '}, ()=>{
            var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
            if(this.state.dateFilter==='custom'){
                var prom2 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));            
                var prom3 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.departmentFilter, this.props.match.params.documentId)
                Promise.all([prom1,prom2,prom3]).then(()=>{
                    this.updateWIQScore();
                });
            } else{
                var prom2 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
                var prom3 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
                Promise.all([prom1,prom2,prom3]).then(()=>{
                    this.updateWIQScore();
                });
            }

          });
        }
        else{
          for (var i = this.props.departments.length - 1; i >= 0; i--) {
            if(this.props.departments[i].key===selectedKey){
              this.setState({ departmentFilter: selectedKey, currentDepartment: ' ' + this.props.departments[i].name + ' ' }, () => {
                var prom1 = this.props.viewStaffNpsData(this.props.accessToken, this.state.filter, this.props.match.params.documentId, this.state.departmentFilter, this.state.locationFilter, this.state.fromDate, this.state.toDate);
                if(this.state.dateFilter==='custom'){
                    var prom2 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));            
                    var prom3 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.departmentFilter, this.props.match.params.documentId);
                    Promise.all([prom1,prom2,prom3]).then(()=>{
                        this.updateWIQScore();
                    });
                } else{
                    var prom2 = this.props.listIndividualStaffFeedbackReport(this.props.tvClient, 'and', this.props.match.params.documentId, this.state.departmentFilter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null);            
                    var prom3 = this.props.listIndividualStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.filter, null, null, this.state.departmentFilter, this.props.match.params.documentId);
                    Promise.all([prom1,prom2,prom3]).then(()=>{
                        this.updateWIQScore();
                    });
                }
              });
              
              break;
            }
          }
          
        }
    }  

    render() {
        return <div>
            {this.props.viewingStaff && <div className="page-header"><Spinner/></div>}
            {this.props.staffData && <div className="page-header">
                <h3>Individual Staff Report</h3>
                <div className="staffReportDetails row">
                    <Col className="thumbnail-col" md={2} key={1}>
                        {this.props.staffData.staffimageid && <img className="editStaffImgThumb" alt={this.props.staffData.staffimageid} src={this.props.staffData.staffimageid.startsWith('http') ? this.props.staffData.staffimageid : process.env.PUBLIC_URL + '/staff_assets/' + this.props.staffData.staffimageid} /> || <img className="editStaffImgThumb" alt="default" src={process.env.PUBLIC_URL + '/staff_assets/default-employee-avatar.png'} />} 
                    </Col>
                    <div className="col-md-5">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="staffHeaderCell">Name:</td>
                                    <td>{this.props.staffData.name}</td>
                                </tr>
                                <tr>
                                    <td className="staffHeaderCell">Title:</td>
                                    <td>{this.props.staffData.title}</td>
                                </tr>
                                <tr>
                                    <td className="staffHeaderCell">Departments:</td>
                                    <td>
                                        {Object.values(this.props.departments).map(d => {
                                            if(this.props.staffData.departments.includes(d.key)){
                                                return <span key={d.key}>{d.name}, </span>    
                                            }
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="staffHeaderCell">Locations:</td>
                                    <td>
                                        {Object.values(this.props.locations).map(d => {
                                            if(this.props.staffData.locations.includes(d.location_id)){
                                                return <span key={d.location_id}>{d.name}, </span>    
                                            }
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="wellIQRank">Score: <i>{(this.props.staffFeedbackDataLoading || this.props.staffEngagementDataLoading || this.props.npsLoading) ? 'Calculating' : this.state.wellIQRank}</i></p>
                        <p className="rankVariables">{this.state.currentFilterLocation}, {this.state.currentDepartment}, {this.state.currentFilterName}</p>
                    </div>
                    <div className="col-md-3 nps-survey-container float-left">
                       {this.props.npsLoading && <Spinner/>}
                         {(this.props.npsData && this.props.npsData.length>0) &&  <div>
                            <h5>Patient Satisfaction Score 
<OverlayTrigger
      key={`bottom`} 
      placement={`bottom`}
      overlay={
        <Tooltip id={`tooltip-bottom`}>
          Compares staff members NPS score to that of the organization.
        </Tooltip>
      }
    >
      <span className="specificScore">{this.state.npsScore} / 20</span>
    </OverlayTrigger>
                            </h5>
                           <PieChart width={275} height={150}>
                           <Pie data={this.props.npsData} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#8884d8" >
                            {
                              this.props.npsData.map((entry, index) => <Cell key={entry.value} fill={ entry.color }/>)
                            }
                            <Label width={100} position="center">{this.props.npsRating}</Label>
                           </Pie>
                            <Legend layout="vertical" align="right" verticalAlign="middle" />
                           </PieChart> 
                            </div>
                       }

                       {(this.props.npsData && this.props.npsData.length===0) && <div>
                            <h5>Patient Satisfaction Score 
                            <OverlayTrigger
      key={`bottom`} 
      placement={`bottom`}
      overlay={
        <Tooltip id={`tooltip-bottom`}>
          Compares staff members NPS score to that of the organization.
        </Tooltip>
      }
    >
      <span className="specificScore">{this.state.npsScore} / 20</span>
    </OverlayTrigger></h5>
                           <PieChart width={275} height={150}>
                             <Pie data={this.state.emptySet} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#999999" >           
                             <Label width={100} position="center">---</Label>
                             </Pie>
                             <Legend layout="vertical" align="right" verticalAlign="middle" />
                           </PieChart> 
                        </div>
                       }
                    {(!this.orgNpsLoading && this.props.npsData && this.props.orgNpsRating!=='NaN / 5') && <p className="orgStatDetail">The organization reported a score of {this.props.orgNpsRating} over this same timeline.</p>}
                    {(!this.orgNpsLoading && this.props.npsData && this.props.orgNpsRating==='NaN / 5') && <p className="orgStatDetail">The organization does not have a score over this timeframe.</p>}
                    </div>

                </div>
            </div> }

            {this.props.staffData && <div className="case-list-container staffReportDetailContent">

            <div className="col-md-10 extraBottomMargin">
                <div className="customLocationRange float-right">
                    <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Filter Report By Date
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                          <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                          <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                          <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                          <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                          <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                          <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                {(this.props.departments) && <div className="customLocationRange float-right extraRightMargin">

                           <Dropdown
                              title='Choose a Department'
                              id='filterDeptSurvey'
                              drop="down" onSelect={this.filterByDepartment.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                Choose a Department
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                        <Dropdown.Item eventKey={0}>All Departments</Dropdown.Item>
                          {Object.values(this.props.departments).map(d => {
                            return  <Dropdown.Item eventKey={d.key} key={d.key}>{d.name}</Dropdown.Item>
                          })}
                            </Dropdown.Menu>
                        </Dropdown>
                </div> }  

                {(this.props.locations) && <div className="customLocationRange float-right extraRightMargin">

                           <Dropdown
                              title='Choose a Location'
                              id='filterSurvey'
                              drop="down" onSelect={this.handleLocationFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                Choose a Location
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                        <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item>
                          {Object.values(this.props.locations).map(d => {
                            return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                          })}
                            </Dropdown.Menu>
                        </Dropdown>
                </div> }  
                             {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

                <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }
            </div> 

            <div className="col-md-10 nps-survey-container float-left">
                   {this.props.npsLoading && <Spinner/>}
                     {this.props.npsData &&  <div>
                        <h5>Patient Survey Response Detail 
                        <OverlayTrigger
      key={`bottom`} 
      placement={`bottom`}
      overlay={
        <Tooltip id={`tooltip-bottom`}>
          Compares staff member to their peers for number of surveys they were selected in.
        </Tooltip>
      }
    >
      <span className="specificScore">{this.state.surveysScore} / 20</span>
    </OverlayTrigger></h5>
                            <table className="npsSurveyTable">
                            <tbody>
                                <tr>
                                    <th>Value</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {
                                    this.props.npsData.map(c => {
                                        return <tr className="statDeptContainer actionableRecord" key={c.name}>
                                                <td>{c.name}</td>
                                                <td>{this.renderScoreToName(c.name)}</td>
                                                <td>{c.value}</td>
                                              </tr>
                                    })
                                }  
                            </tbody> 
                        </table>
                        {(this.props.npsData && this.props.npsData.length===0) && <p className="noDataLine">No data to display.</p>}
                </div>}
                </div>


            <div className="col-md-10 nps-survey-container componentSpacer float-left">
                {  (this.props.staffEngagementData && !this.props.staffEngagementDataLoading) && <div>
                    <h5>Top Endorsements <OverlayTrigger
      key={`bottom`} 
      placement={`bottom`}
      overlay={
        <Tooltip id={`tooltip-bottom`}>
          Compares staff member to their peers for number of badges received.
        </Tooltip>
      }
    >
      <span className="specificScore">{this.state.badgeScore} / 50</span>
    </OverlayTrigger></h5>
                    {this.props.staffEngagementData.length>0 && <Table className="npsSurveyTable">
                        <IndividualStaffBadges items={this.props.staffEngagementData[0].badges}/>
                    </Table> }
                </div>

                }

              {(!this.props.staffEngagementDataLoading && this.props.staffEngagementData.length===0) && <p className="noDataLine">No data to display.</p>}
                {this.props.staffEngagementDataLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.staffEngagementPaginationInfo.current_page} parentRefresh={this.changeStaffEngagementListPage.bind(this)} numberOfPages={this.props.staffEngagementPaginationInfo.num_pages} items={this.props.staffEngagementPaginationItems} />
            </div>  




            <div className="col-md-10 nps-survey-container componentSpacer float-left">
                {  this.props.staffFeedbackData && <div>
                        <h5>Written Feedback <OverlayTrigger
      key={`bottom`} 
      placement={`bottom`}
      overlay={
        <Tooltip id={`tooltip-bottom`}>
          Sentiment analysis of written feedback as positive or negative.  No feedback defaults to 5.
        </Tooltip>
      }
    >
      <span className="specificScore">{this.state.writtenScore} / 10</span>
    </OverlayTrigger></h5>
                    <Table className="npsSurveyTable">
                    <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Feedback</th>
                        <th scope="col">Badge Count</th>
                        <th scope="col">Survey Score</th>
                        <th scope="col" className="text-center">Survey Link</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.staffFeedbackData.map((c,index) => {
                            return <tr className="surveyRow statDeptContainer reportBadgeContainer" onClick={()=> this.viewSurvey(c.survey_id)} key={index}>
                                <td>{c.survey_id}</td>
                                <td>{c.response.feedback}</td>
                                <td className="text-center">{c.response.badges!==undefined ? c.response.badges.length : '0'}</td>
                                <td className="text-center">{c.nps!==null ? c.nps : 'N/A'}</td>
                                <td className="text-center totalTdValue">View Survey</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table> 
                </div>

                }

              {(!this.props.staffFeedbackDataLoading && this.props.staffFeedbackData.length===0) && <p className="noDataLine">No data to display.</p>}
                {this.props.staffFeedbackDataLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.writtenFeedbackPaginationInfo.current_page} parentRefresh={this.changeWrittenFeedbackListPage.bind(this)} numberOfPages={this.props.writtenFeedbackPaginationInfo.num_pages} items={this.props.writtenFeedbackPaginationItems} />
            </div>    



            </div>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.login.tvClient.accessToken,
        viewingStaff: state.viewStaff.viewingStaff,
        viewStaffError: state.viewStaff.viewStaffError,
        tvClient: state.login.tvClient,
        staffData: state.viewStaff.staffData,
        departments: state.deptList.departments,
        locations: state.locationList.locations,
        npsData: state.staffNpsCpt.npsData,
        npsLoading: state.staffNpsCpt.npsLoading,
        npsRating: state.staffNpsCpt.npsRating,
        npsSpecificRating: state.staffNpsCpt.specificRating,
        npsSurveyValue: state.staffNpsCpt.surveyValue,
        orgNpsLoading: state.npsCpt.npsLoading,
        orgNpsRating: state.npsCpt.npsRating,
        orgSpecificRating: state.npsCpt.specificRating,
        staffFeedbackDataLoading: state.staffFeedbackData.loading,
        staffFeedbackData: state.staffFeedbackData.staffFeedbackData,
        staffFeedbackScore: state.staffFeedbackData.overallWrittenFeedbackScore,
        writtenFeedbackPaginationInfo: state.staffFeedbackData.paginationInfo,
        writtenFeedbackPaginationItems: state.staffFeedbackData.paginationInfo.pageList,    
        staffEngagementDataLoading: state.individualStaffEngagementData.loading,
        staffEngagementData: state.individualStaffEngagementData.engagementData, 
        staffEngagementPaginationInfo: state.individualStaffEngagementData.paginationInfo,
        staffEngagementPaginationItems: state.individualStaffEngagementData.paginationInfo.pageList,
        staffBadgeValue: state.individualStaffEngagementData.badgeWeightedValue
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewStaff: (...params) => dispatch(viewStaff(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        listDepartments: (...params) => dispatch(listDepartments(...params)),
        viewStaffNpsData: (...params) => dispatch(viewStaffNpsData(...params)),
        viewAdminDashboardNps: (...params) => dispatch(viewAdminDashboardNps(...params)),
        viewCustomAdminDashboardNps: (...params) => dispatch(viewCustomAdminDashboardNps(...params)),
        listIndividualStaffFeedbackReport: (...params) => dispatch(listIndividualStaffFeedbackReport(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params)),
        listIndividualStaffEngagementsReport: (...params) => dispatch(listIndividualStaffEngagementsReport(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualStaffReport);

