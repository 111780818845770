import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Table
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {updateGroup, viewGroup } from "../../actions";
import DeleteGroupOverlay from "./../Overlays/DeleteGroupOverlay";
import DeleteGroupMemberOverlay from "./../Overlays/DeleteGroupMemberOverlay";

class EditGroup extends Component {

    componentDidMount() {
        return this.props.viewGroup(this.props.tvClient, this.props.match.params.documentId);
    }

    constructor(props) {
        
        super(props);

        this.state = {
            errors: { 'groupName': false },
            editLoading: true,
            changed: false
        };

    }

    refreshHandler(){
        this.props.viewGroup(this.props.tvClient, this.props.match.params.documentId);
    }

    deleteGroup(e){
        console.log('deleting this group');
    }

    editGroup(e) {
        e.preventDefault();

        if(!this.groupName.value){
            this.setState({ errors: {'groupName': true}});
        } else{
           this.props.updateGroup(
                this.props.tvClient,
                this.props.match.params.documentId,
                this.groupName.value
            );           
        }        
    }

    render() {
        return <div>
            <h1 className="page-header">
                Edit Group
                <small>Please update any of the following group information <DeleteGroupOverlay groupDetail={this.props.match.params.documentId} /> </small>
            </h1>
            
            {this.props.viewingGroup && <Spinner/>}

            {this.props.groupData && <Form onSubmit={this.editGroup.bind(this)} className="new-case-form">

                {this.props.viewGroupError && <Alert variant="danger">
                    {this.props.viewGroupError.message}
                </Alert>}

                <FormGroup controlId="locationName">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="North County Office"  defaultValue={this.props.groupData.name} ref={ref => this.groupName = ref}/>
                    {this.state.errors.groupName && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingGroup} className="pull-left">
                        Update
                    </Button>
                </FormGroup>


            </Form>}

            {(!this.props.viewingGroup && this.props.peopleData) && <div className="case-list-container">
                <br/><br/><br/>
                <h3>View List</h3>
                <Table className="case-list employerTabler sortable">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Organization</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.peopleData.map((c, index) => {
                            return <tr key={c.uid}>
                                <td>{index + 1}</td>
                                <td>{c.organization}</td>
                                <td>{c.name}</td>
                                <td>{c.email}</td>
                                <td><DeleteGroupMemberOverlay groupDetail={this.props.match.params.documentId} parentRefresh={this.refreshHandler.bind(this)} groupMemberDetail={c.uid} /></td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table>   
                {(!this.props.viewingGroup && this.props.peopleData.length===0) && <p>No people in this list</p>}             

            </div>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingGroup: state.viewGroup.viewingGroup,
        viewGroupError: state.viewGroup.viewGroupError,
        tvClient: state.login.tvClient,
        groupData: state.viewGroup.groupData,
        peopleData: state.viewGroup.peopleData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewGroup: (...params) => dispatch(viewGroup(...params)),
        updateGroup: (...params) => dispatch(updateGroup(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);

