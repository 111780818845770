import React, {Component} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {Button, Form, FormGroup, InputGroup, Overlay, Popover} from "react-bootstrap";
import {deleteEndorsement} from "../../actions";

class DeleteEndorsementOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    deleteEndorsement(e) {
       e.preventDefault();
       this.props.deleteEndorsement(this.props.tvClient, this.props.endorsementDetail);
    }

    hide2(){
        this.setState({show:false});
    }

    render() {
        return <span>
            <button className="simulateAnchor" ref="target" onClick={e => {e.preventDefault(); this.setState({show: true})}}>Delete</button>

            <Overlay show={this.state.show} rootClose target={props => ReactDOM.findDOMNode(this.refs.target)}
                     onHide={this.hide2.bind(this)} placement="right">
                <Popover id="invite-patient" title="DELETE" className="invite-patient-overlay">
                <Popover.Title>DELETE</Popover.Title>
                <Popover.Content>
                    <Form onSubmit={this.deleteEndorsement.bind(this)}>
                        <FormGroup controlId="email">
                            <InputGroup>
                            <InputGroup.Prepend>
                                <p>Are you sure you want to delete this endorsement?</p>
                                    <Button type="submit" variant="danger">
                                        Yes
                                    </Button>
                                    </InputGroup.Prepend>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </span>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient
    };
};

export default connect(mapStateToProps, {deleteEndorsement})(DeleteEndorsementOverlay);
