import PatientDocument from "./patient-document.js";

class ApiHelper {

    constructor(urlPrefix) {
        this.urlPrefix = urlPrefix;
    }

    async createPatient(internalPatientCreator, tvClient, surveyId, vaultId, schemaId, name, email, phone, type, creator, extra) {
        if (creator===undefined || creator===null) {
            throw Error("Creator cannot be omitted");
        }

        const patientDocument = new PatientDocument(name, email, phone, type, creator, extra);
        const newPatient = await Promise.all([tvClient.createDocument(vaultId, schemaId, patientDocument)]);

        const newPatientId = newPatient.document.id;

        const createInternalPatientRequest = internalPatientCreator.associatePatient(tvClient.accessToken, newPatientId, surveyId);
        await Promise.all([createInternalPatientRequest]);

    }
}

export default ApiHelper;
