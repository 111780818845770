import LocalizedStrings from 'react-localization';

let localizedDashboardData = new LocalizedStrings({
	 EN:{
	 	passwordsMatch: "Your new passwords do not match",
	 	passwordsCapital: "Your password must have an uppercase character",
	 	passwordsLowercase: "Your password must have a lowercase character",
	 	passwordsNumber: "Your password must have a number",
	 	passwordsMinLength: "Your password must be at least {0} characters long",
	 	passwordsSpecialChar: "Your password must have a special character [!@#$%^&()?*+]",
	 	resetPasswordSuccess: "Your password has been changed.   Please login with your new password.",
	 	passwordExpired: "Your password has expired.",
	 	mfaCodeError: "There was an error with your code.  We have sent you a new code. Please enter it below to proceed.",
	 	newMfaCode: "We have sent a code to your mobile phone.  Please enter the code below to proceed.",
	 	verifyDeviceMfa: "You are required to verify your device.  We have sent a code to your mobile phone. Please enter it below to proceed.",
	 	staffAdded: "Staff Added",
	 	locationAdded: "Location Added",
	 	locationUpdated: "Location Updated",
	 	locationRemoved: "Location Removed",
	 	campaignAdded: "Campaign Added",
	 	campaignUpdated: "Campaign Updated",
	 	groupAdded: "Group Added",
	 	groupUpdated: "Group Updated",
	 	journeyCreated: "Journey Created",
	 	journeyMappingCreated: "Journey Mapping Created",
	 	journeyUpdated: "Journey Updated",
	 	journeyMappingUpdated: "Journey Mapping Updated",
	 	journeyRemoved: "Journey Removed",
	 	journeyMappingRemoved: "Journey Mapping Removed",
	 	locationMappingCreated: "Location Mapping Created",
	 	locationMappingUpdated: "Location Mapping Updated",
	 	locationMappingRemoved: "Location Mapping Removed",
	 	adminPasswordUpdated: "Admin Password Updated",
	 	profilePasswordUpdated: "Password Updated - Please login with your new credentials.",
	 	emailMessaging: "Complete your check-in at {0} now. Visit: ",
	 	textMessaging: "Complete your check-in at {0} now. Visit: ",
	 	textMessageSent: "Text message sent!",
	 	emailSent: "Email was sent!",
	 	emailsHaveSent: "Emails have been sent.",
	 	issueCreatingUser: "There was an issue creating that user.",
	 	userCreated: "User Created!",
	 	adminUpdated: "Admin Updated",
	 	departmentAdded: "Department Added",
	 	departmentUpdated: "Department Updated",
	 	departmentRemoved: "Department Removed",
	 	adminRemoved: "Admin Removed",
	 	personRemoved: "Person Removed",
	 	personToGroup: "Person Added to Group",
	 	campaignRemoved: "Campaign Removed",
	 	groupRemoved: "Group Removed",
	 	groupMemberRemoved: "Group Member Removed",
	 	personAlreadyExists: "This person already exists in the group"
	 },
	 ES: {
	 	emailMessaging: "Completa su check-in en {0} ahora. Visita: ",
	 	textMessaging: "Completa su check-in en {0} ahora. Visita: "
	 }
	});  		


export default localizedDashboardData;

