import React, {Component} from "react";
import {connect} from "react-redux";
import localizedData from "./../Localization/Localization";
import { directEmpSurvey, bulkSendMessages, listIntakeSurveys, getAdminFlags, groupTextPatient, groupEmailPatient, textPatient, emailPatient, listLocations, listJourneys, listGroupJourneys, listRecentSurveys, listGroups, listCampaigns, listSentMessages } from "../../actions";
import {
    Table,
    Nav,
    Navbar,
    FormLabel,
    FormControl,
    Button,
    FormGroup,

} from "react-bootstrap";
import {Form} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Spinner from "react-spinner";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import "react-spinner/react-spinner.css";
import dateFormat from "dateformat";
import queryString from 'query-string';
import PaginationArea from "./../Utilities/PaginationArea";
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import "./../ServeSurvey.scss";

class ServeCheckin extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getAdminFlags(this.props.tvClient).then(()=>{
            var truth=false;
            if(this.props.flags!==null){
              truth = this.props.flags.employerFlag.toString()==='true' ? true : false;  
            }
            this.setState({employerEnabled: truth});
        });

        if(this.props.showIntake===true || this.props.showIntake==='true'){
          this.props.listIntakeSurveys(this.props.tvClient, 'and', null, null, 1, 40, this.props.intakeLocation);
        }
    }

    constructor(props){
        super(props);
        this.props.listLocations(this.props.tvClient);
        this.props.listJourneys(this.props.tvClient);
        this.props.listGroupJourneys(this.props.tvClient);
        this.props.listGroups(this.props.tvClient);
        this.props.listCampaigns(this.props.tvClient);

        const queryStringValues = queryString.parse(props.location.search);

        let language = 'EN';
        if(queryStringValues.lang!==null && queryStringValues.lang !== undefined){
          if(queryStringValues.lang.toUpperCase()==='ES' || queryStringValues.lang.toUpperCase() === 'EN' || queryStringValues.lang.toUpperCase() === 'FR'){
            localizedData.setLanguage(queryStringValues.lang.toUpperCase());
            language = localizedData.getLanguage();
          }
        }
       
        let activeTab = 1;
        let showSendManualTab=true;
        let showViewRecentTab=false;
        let showSendGroupTab=false;
        let showGroupsTab=false;
        let showCampaignsTab=false;
        let showSendBulkMessagesTab=false;

        if(queryStringValues.active!==null && queryStringValues.active !== undefined){
            activeTab = parseInt(queryStringValues.active);
            if(activeTab===1){
                showSendBulkMessagesTab=false; showSendGroupTab=false; showViewRecentTab=false; showSendManualTab=true; showGroupsTab=false; showCampaignsTab=false;
            } else if(activeTab===2){
                showSendBulkMessagesTab=false; showSendGroupTab=false; showViewRecentTab=true; showSendManualTab=false; showGroupsTab=false; showCampaignsTab=false;
            } else if(activeTab===3){
                showSendBulkMessagesTab=false; showSendGroupTab=true; showViewRecentTab=false; showSendManualTab=false; showGroupsTab=false; showCampaignsTab=false;
            } else if(activeTab===4){
                showSendBulkMessagesTab=false; showGroupsTab=true; showSendGroupTab=false; showViewRecentTab=false; showSendManualTab=false; showCampaignsTab=false; 
            } else if(activeTab===5){
                showSendBulkMessagesTab=false; showCampaignsTab=true; showGroupsTab=false; showSendGroupTab=false; showViewRecentTab=false; showSendManualTab=false;  
            } else if(activeTab===7){
              showSendBulkMessagesTab=true; showCampaignsTab=false; showGroupsTab=false; showSendGroupTab=false; showViewRecentTab=false; showSendManualTab=false;  
            }

        }

        this.state = {
          filter: '',
          sort: null,
          fields: { language: 'EN', username: '', mobileno: '', emailaddress: '', consent: "1", emp_username: '', emp_mobileno:'', emp_emailaddress:'', emp_consent: "1", emp_language: 'EN'},
          errors: {},
          showEmailForm: false,
          showEmpEmailForm: false,
          showSendManual: showSendManualTab,
          showViewRecents: showViewRecentTab,
          showSendGroup: showSendGroupTab,
          showGroups: showGroupsTab,
          showCampaigns: showCampaignsTab,
          showBulkMessages: showSendBulkMessagesTab,
          activeKey: activeTab,
          selectedCampaign: 0,
          showDirectSend: false,
          employerEnabled: false,
          cols:  null,
          rows: null,
          dataLoaded: false
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.useEmail = this.useEmail.bind(this);
        this.useEmpEmail = this.useEmpEmail.bind(this);

    }

    changeListPage(page) {
      this.props.listSentMessages(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, 40, this.state.locationFilter, this.state.campaignFilter, this.props.showIntake);            
    }

    handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showBulkMessages: false, showSendManual: true, showViewRecents: false, showSendGroup:false, activeKey: selectedKey, showCampaigns: false, showGroups: false });
      } else if(parseInt(selectedKey)===2){
        this.changeListPage(1);
        this.setState({ showBulkMessages: false, showSendManual: false, showViewRecents: true, showSendGroup:false, activeKey: selectedKey, showCampaigns: false, showGroups: false });
      } else if(parseInt(selectedKey)===3){
        this.setState({ showBulkMessages: false, showSendManual: false, showViewRecents: false, showSendGroup:true, activeKey: selectedKey, showCampaigns: false, showGroups: false });
      } else if(parseInt(selectedKey)===4){
          this.setState({ showBulkMessages: false, showSendManual: false, showViewRecents: false, showSendGroup:false, activeKey: selectedKey, showCampaigns: false, showGroups: true });
      } else if(parseInt(selectedKey)===5){
          this.setState({ showBulkMessages: false, showSendManual: false, showViewRecents: false, showSendGroup:false, activeKey: selectedKey, showCampaigns: true, showGroups: false });
      } else if(parseInt(selectedKey)===7){
          this.setState({ showBulkMessages: true, showSendManual: false, showViewRecents: false, showSendGroup:false, activeKey: selectedKey, showCampaigns: false, showGroups: false });
      }
    }



    changeLocale(ev, param){
        localizedData.setLanguage(param);
        this.setState({});
    }

    fileHandler(event) {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
            this.setState({
              cols: resp.cols,
              rows: resp.rows,
              dataLoaded: true
            });
          }
        });               
    }; 

    useEmail(){
      this.setState({ showEmailForm: !this.state.showEmailForm });
    }

    useEmpEmail(){
      this.setState({ showEmpEmailForm: !this.state.showEmpEmailForm });
    }

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }    

    validateGroupForm(){
      if(this.state.selectedCampaign[0]===0 || this.state.selectedCampaign[0]==="0"){
        return false;
      } else{
        return true;    
      }  
    }

    validateSingleEmployerForm(){

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if(this.state.selectedCampaign[0]===0 || this.state.selectedCampaign[0]==="0"){
        formIsValid = false;
        errors["emp_campaign"] = "*Please choose your campaign";
      }

      if (!fields["emp_username"] || fields["emp_username"].length===0) {
        formIsValid = false;
        errors["emp_username"] = "*Please enter a name.";
      }

      if (typeof fields["emp_username"] !== "undefined" && fields["emp_username"].length>0) {
        if (!fields["emp_username"].match(/^[-' a-z\u4e00-\u9eff]{1,35}$/i)) {
          formIsValid = false;
          errors["emp_username"] = "*Please enter letters only.";
        }
      }

      if(this.state.showEmpEmailForm){
        if (!fields["emp_emailaddress"]) {
          formIsValid = false;
          errors["emp_emailaddress"] = "*Please enter an email address.";
        }

        if (typeof fields["emp_emailaddress"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["emp_emailaddress"])) {
            formIsValid = false;
            errors["emp_emailaddress"] = "*Please enter a valid email address.";
          }
        }
      } else{
        if (!fields["emp_mobileno"]) {
          formIsValid = false;
          errors["emp_mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof fields["emp_mobileno"] !== "undefined") {
          var mobileNoTest = fields["emp_mobileno"];
          var replacement = "-";
          var dashRegEx = new RegExp(replacement, "g");
          mobileNoTest = mobileNoTest.replace(dashRegEx, '');
          mobileNoTest = mobileNoTest.replace('(', '');
          mobileNoTest = mobileNoTest.replace(')', '');
          replacement = " ";
          dashRegEx = new RegExp(replacement, "g");
          mobileNoTest = mobileNoTest.replace(dashRegEx, '');
          
          if(!mobileNoTest.startsWith("+")){
              if (!mobileNoTest.match(/^\+?[0-9]{10}$/) && !mobileNoTest.match(/^\+?[0-9]{11}$/) && !mobileNoTest.match(/^\+?[0-9]{12}$/)) {
                formIsValid = false;
                errors["emp_mobileno"] = "*Please enter a valid mobile no.";
              }
            } else {
              if(mobileNoTest.length<10){
                formIsValid = false;
                errors["emp_mobileno"] = "*Please enter a valid mobile no."; 
              }
            } 
          }
      }


      if (!fields["emp_location"]) {
        formIsValid = false;
        errors["emp_location"] = "*Please select a location.";
      }

      if (!fields["emp_journey"] && this.props.journeys.length>0) {
        formIsValid = false;
        errors["emp_journey"] = "*Please select a valid journey.";
      }

      var theConsent = fields["emp_consent"].trim();
      if(theConsent!==1 && theConsent!=="1"){
        formIsValid = false;
        errors["emp_consent"] = "You must have consent to send a text message.";
      }

      this.setState({
        errors: errors
      });


      return formIsValid;
    }

    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if (!fields["username"] || fields["username"].length===0) {
        formIsValid = false;
        errors["username"] = "*Please enter your name.";
      }

      if (typeof fields["username"] !== "undefined" && fields["username"].length>0) {
        if (!fields["username"].match(/^[-' a-z\u4e00-\u9eff]{1,35}$/i)) {
          formIsValid = false;
          errors["username"] = "*Please enter letters only.";
        }
      }

      if(this.state.showEmailForm){
        if (!fields["emailaddress"]) {
          formIsValid = false;
          errors["emailaddress"] = "*Please enter an email address.";
        }

        if (typeof fields["emailaddress"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["emailaddress"])) {
            formIsValid = false;
            errors["emailaddress"] = "*Please enter a valid email address.";
          }
        }
      } else{
        if (!fields["mobileno"]) {
          formIsValid = false;
          errors["mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof fields["mobileno"] !== "undefined") {
          var mobileNoTest = fields["mobileno"];
          var replacement = "-";
          var dashRegEx = new RegExp(replacement, "g");
          mobileNoTest = mobileNoTest.replace(dashRegEx, '');
          mobileNoTest = mobileNoTest.replace('(', '');
          mobileNoTest = mobileNoTest.replace(')', '');
          replacement = " ";
          dashRegEx = new RegExp(replacement, "g");
          mobileNoTest = mobileNoTest.replace(dashRegEx, '');
          
          if(!mobileNoTest.startsWith("+")){
              if (!mobileNoTest.match(/^\+?[0-9]{10}$/) && !mobileNoTest.match(/^\+?[0-9]{11}$/) && !mobileNoTest.match(/^\+?[0-9]{12}$/)) {
                formIsValid = false;
                errors["mobileno"] = "*Please enter a valid mobile no.";
              }
            } else {
              if(mobileNoTest.length<10){
                formIsValid = false;
                errors["mobileno"] = "*Please enter a valid mobile no."; 
              }
            } 
          }
      }


      if (!fields["location"]) {
        formIsValid = false;
        errors["location"] = "*Please select a location.";
      }

      if (!fields["journey"] && this.props.journeys.length>0) {
        formIsValid = false;
        errors["journey"] = "*Please select a patient journey.";
      }

      if(fields["consent"]!==1 && fields["consent"]!=="1"){
        formIsValid = false;
        errors["consent"] = "You must have consent to send a text message.";
      }

      this.setState({
        errors: errors
      });
      return formIsValid;

    }

    beginMultipleMessageUpload(e){
        e.preventDefault();
        this.props.bulkSendMessages(this.props.tvClient, this.state.rows).then(() => {
            this.setState({ rows: null, cols: null, dataLoaded: false });
            window.scrollTo(0,0);
        }); 
    };

    handleCampaignList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            selectedCampaign: value
        });
    }  

    findGroupJourney(journeyId){
      var journey = null;
      for (var i = this.props.groupJourneys.length - 1; i >= 0; i--) {
        if(this.props.groupJourneys[i].journey_id.toString() === journeyId.toString()){
          journey = this.props.groupJourneys[i];
          break;
        }
      }
      return journey;
    }
    findJourney(journeyId){
      var journey = null;
      for (var i = this.props.journeys.length - 1; i >= 0; i--) {
        if(this.props.journeys[i].journey_id.toString() === journeyId.toString()){
          journey = this.props.journeys[i];
          break;
        }
      }
      return journey;
    }

    refreshSentMessages(e){
      e.preventDefault();
      this.props.listSentMessages(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, 40, this.state.locationFilter, this.state.campaignFilter, this.props.showIntake);
    }

    groupSubmit(e){
        e.preventDefault();

        if(this.validateGroupForm()) {
            let currentJourney = this.findGroupJourney(this.state.fields["g_journey"]);
            //this.props.groupTextPatient(this.props.tvClient, this.state.selectedCampaign[0], currentJourney, this.state.fields["language"], this.state.fields["location"]); 
     
            this.props.groupEmailPatient(this.props.tvClient, this.state.selectedCampaign[0], currentJourney, this.state.fields["language"], null, this.props.adminAttr);

            let fields = {};
            fields["language"] = this.state.fields["language"];
            fields["g_journey"] = this.state.fields["g_journey"];
            this.setState({fields:fields, showEmailForm: false });  

        }      
    }

    singleEmployerSubmit(e){
        e.preventDefault();

        if(this.validateSingleEmployerForm()) {
          let currentJourney = this.findGroupJourney(this.state.fields["emp_journey"]);
          var mobileNoTest = this.state.fields["emp_mobileno"];
          var replacement = "-";
          var dashRegEx = new RegExp(replacement, "g");
          mobileNoTest = mobileNoTest.replace(dashRegEx, '');
          mobileNoTest = mobileNoTest.replace('(', '');
          mobileNoTest = mobileNoTest.replace(')', '');
          replacement = " ";
          dashRegEx = new RegExp(replacement, "g");
          mobileNoTest = mobileNoTest.replace(dashRegEx, '');

          this.props.directEmpSurvey(this.props.tvClient, this.state.showEmailForm, this.state.fields['emp_username'], mobileNoTest, this.state.fields['emp_emailaddress'],this.state.selectedCampaign[0], currentJourney.journey_id, this.state.fields['emp_language'], this.state.fields['emp_location'], this.state.fields['emp_consent'],this.props.adminAttr);

          let fields = {};
          fields["emp_username"] = '';
          fields["emp_mobileno"] = '';
          fields["emp_emailaddress"] = '';
          fields["emp_consent"] = "1";
          fields["emp_location"] = this.state.fields["emp_location"];
          fields["emp_language"] = this.state.fields["emp_language"];
          fields["emp_journey"] = this.state.fields["emp_journey"];
          this.setState({fields:fields, showEmailForm: false });  
 
          window.scrollTo(0, 0);          
        }      
    }

    finalSubmit(e){
        e.preventDefault();

        if(this.validateForm()) {
            let currentJourney = this.findJourney(this.state.fields["journey"]);

            var mobileNoTest = this.state.fields["mobileno"];
            var replacement = "-";
            var dashRegEx = new RegExp(replacement, "g");
            mobileNoTest = mobileNoTest.replace(dashRegEx, '');
            mobileNoTest = mobileNoTest.replace('(', '');
            mobileNoTest = mobileNoTest.replace(')', '');
            replacement = " ";
            dashRegEx = new RegExp(replacement, "g");
            mobileNoTest = mobileNoTest.replace(dashRegEx, '');

            if(this.state.showEmailForm){
              this.props.emailPatient(this.props.tvClient, this.props.adminAttr, this.state.fields["username"], this.state.fields["emailaddress"], this.state.fields["language"], null, this.state.fields["location"], currentJourney); 
            }
            else{
              this.props.textPatient(this.props.tvClient, this.props.adminAttr, this.state.fields["username"], mobileNoTest, this.state.fields["language"], null, this.state.fields["location"], currentJourney); 
            }
            let fields = {};
            fields["username"] = "";
            fields["mobileno"] = "";
            fields["emailaddress"] = "";
            fields["consent"] = "1";
            fields["location"] = this.state.fields["location"];
            fields["language"] = this.state.fields["language"];
            fields["journey"] = this.state.fields["journey"];
            this.setState({fields:fields, showEmailForm: false });   
            window.scrollTo(0, 0);          
        }

    }

    render() {
        return  <div>
          <h1 className="page-header">
            Message Patient
              <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={1} href="#">
                      Send Manual Msg
                    </Nav.Link> 
                    <Nav.Link eventKey={7} href="#">
                      Send Bulk Msgs
                    </Nav.Link>   
                    <Nav.Link  eventKey={2} href="#">
                      Sent Recently
                    </Nav.Link >    
                    {(this.props.adminAttr.role==='admin' && this.state.employerEnabled) &&
                      <Nav.Link  eventKey={4} className='employerTabs' href="#">
                        Employer List
                      </Nav.Link >
                    }
                    {(this.props.adminAttr.role==='admin' && this.state.employerEnabled) && 
                      <Nav.Link  eventKey={5} className='employerTabs' href="#">
                        Campaigns
                      </Nav.Link >                                            
                    }
                    {(this.props.adminAttr.role==='admin' && this.state.employerEnabled) &&
                      <Nav.Link  eventKey={3} className='employerTabs' href="#">
                        Send Employer Survey
                      </Nav.Link >
                    }
                  </Nav>
                </Navbar>
          </h1>
          <div className="case-list-container">
          { (this.state.showSendManual) &&
            <div><Form onSubmit={this.finalSubmit.bind(this)} className="selfIdentifyForm messagePatientForm">
              <div className="form-group">
                <label htmlFor="nameEntry">{localizedData.name}: <span className="text-danger">*</span></label>
                <input type="textfield" className="form-control" id="nameEntry" autoComplete="on" value={this.state.fields.username} name="username" placeholder={localizedData.name_placeholder} onChange={this.handleFormChange}/>
                <div className="errorMsg">{this.state.errors.username}</div>
              </div>
               {!this.state.showEmailForm &&              <div className="form-group">
                <label htmlFor="phoneEntry">{localizedData.phone}: <span className="text-danger">*</span></label>
                <input type="tel" className="form-control" id="phoneEntry" placeholder="##########" value={this.state.fields.mobileno} name="mobileno" onChange={this.handleFormChange}/>  
                <p><i><small>For international use the + sign.  Ex. Mexico +52 followed by the number</small></i></p>
                <div className="errorMsg">{this.state.errors.mobileno}</div>  
                <button className="alternateTextLink simulateAnchor" onClick={this.useEmail}>Use email instead</button>          
              </div> }
              {this.state.showEmailForm &&               <div className="form-group">
                <label htmlFor="emailEntry">{localizedData.email}: <span className="text-danger">*</span></label>
                <input type="email" className="form-control" id="emailEntry" placeholder={localizedData.email_placeholder} value={this.state.fields.emailaddress} name="emailaddress" onChange={this.handleFormChange}/>  
                <div className="errorMsg">{this.state.errors.emailaddress}</div> 
                 <button className="alternateTextLink simulateAnchor" onClick={this.useEmail}>Use phone instead</button>            
              </div>}

              {(this.props.journeys && this.props.journeys.length>0) && <div className="form-group">
                <label htmlFor="journey">Patient Journey: <span className="text-danger">*</span></label>      

                {(this.props.journeys.length <= 5) && <div>

                  {Object.values(this.props.journeys).map(d => {
                                              return <div className="radio" key={d.journey_id}>
                                    <label>
                                      <input type="radio" name="journey" value={d.journey_id} onChange={this.handleFormChange} />
                                      {d.name}
                                    </label>
                                  </div>
                                     })}</div>

                  }
                  {(this.props.journeys.length > 5) && <div className="maxWidthSelectContainer">
                  <FormControl as="select" placeholder="select" name="journey" id="journey" value={this.state.fields["journey"]} onChange={this.handleFormChange.bind(this)}>
                        <option value='' key=''>Select</option>
                        {Object.values(this.props.journeys).map(d => {
                            return <option value={d.journey_id} key={d.journey_id}>{d.name}</option>
                        })}
                    </FormControl></div>

                  }

                   <div className="errorMsg">{this.state.errors.journey}</div>

              </div>   }          
              <div className="form-group">
                <label htmlFor="location">Location: <span className="text-danger">*</span></label>      
               
{(this.props.locations.length <= 5) && <div>

                  {Object.values(this.props.locations).map(d => {
                                              return <div className="radio" key={d.location_id}>
                                    <label>
                                      <input type="radio" name="location" value={d.location_id} onChange={this.handleFormChange} />
                                      {d.name}
                                    </label>
                                  </div>
                                     })}</div>

                  }
                  {(this.props.locations.length > 5) && <div className="maxWidthSelectContainer">
                  <FormControl as="select" placeholder="select" name="location" id="location" value={this.state.fields["location"]} onChange={this.handleFormChange.bind(this)}>
                        <option value='' key=''>Select</option>
                        {Object.values(this.props.locations).map(d => {
                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                        })}
                    </FormControl></div>

                  }


                   <div className="errorMsg">{this.state.errors.location}</div>

              </div>
              <div className="form-group">
                <label htmlFor="language">Default Language: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="language" value="EN" defaultChecked={true} onChange={this.handleFormChange}/>
                    English
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="language" value="ES" onChange={this.handleFormChange}/>
                    Spanish
                  </label>
                </div>
              </div> 
              <div className="form-group">
                <label htmlFor="consent">You have been given consent to text: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="consent" value="1" defaultChecked={true} onChange={this.handleFormChange}/>
                    Yes
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="consent" value="0" onChange={this.handleFormChange}/>
                    No
                  </label>
                </div>
                <div className="errorMsg">{this.state.errors.consent}</div>
              </div>
              <div className="form-group container">
                <button type="submit" className="btn btn-primary" disabled={this.props.textLoading || this.props.emailLoading} id="yourInfoButton">{(this.props.textLoading || this.props.emailLoading) ? 'Sending' : localizedData.submit}</button>
              </div>
              </Form>
              <div id="errorMsg"></div>   
              </div>
              }
              { (this.state.showViewRecents) && 
                <div>
                <h3>Recents 
                
                  <Button variant="primary" size="sm" className="padLeft" onClick={this.refreshSentMessages.bind(this)}>Refresh</Button>
                
 
                </h3>

                {this.props.messagesLoading && <Spinner/>}
                {  this.props.messages && <Table className="case-list sortable reportBadgeContainer">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th>ID</th>
                        {this.props.showIntake && <th>Name</th> }
                        <th>Status</th>
                        <th>Sent at</th>
                        <th>Twilio Id</th>
                        <th>Message Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.messages.map(c => {
                            return <tr className="surveyRow statDeptContainer reportBadgeContainer" key={c.uid}>
                                <td>{c.tv_id}</td>
                               {this.props.showIntake &&  <td>{c.name!==undefined ? c.name : '---'}</td>}
                                <td>{c.status}</td>
                                <td>{dateFormat(c.sent, "shortDate")}</td>
                                <td>{c.message_id}</td>
                                <td>{c.msg_type}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table> }
                
                <PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />

                </div>
              }
              { (this.state.showSendGroup && this.state.employerEnabled) &&<div> <h4>Send Campaign Group</h4><Form onSubmit={this.groupSubmit.bind(this)} className="selfIdentifyForm messagePatientForm">
              
                  <div className="form-group">
                    <FormLabel>Choose Campaign *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleCampaignList.bind(this)}>
                        <option value="0">Choose a campaign</option>
                        {Object.values(this.props.campaigns).map(d => {
                            return <option value={d.uid} key={d.uid}>{d.name}</option>
                        })}
                    </FormControl>                    
                    {this.state.errors.selectedCampaign && <div className="errorMsg">
                        A campaign is required.
                    </div>}
                </div>
              {(this.props.groupJourneys && this.props.groupJourneys.length>0) && <div className="form-group">
                <label htmlFor="g_journey">Journey: <span className="text-danger">*</span></label>      
               
                  {Object.values(this.props.groupJourneys).map(d => {
                            return <div className="radio" key={d.journey_id}>
                  <label>
                    <input type="radio" name="g_journey" value={d.journey_id} onChange={this.handleFormChange} />
                    {d.name}
                  </label>
                </div>
                   })}
                   <div className="errorMsg">{this.state.errors.g_journey}</div>

              </div>   }          
              <div className="form-group">
                <label htmlFor="language">Default Language: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="language" value="EN" defaultChecked={true} onChange={this.handleFormChange}/>
                    English
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="language" value="ES" onChange={this.handleFormChange}/>
                    Spanish
                  </label>
                </div>
              </div> 
              <div className="form-group">
                <label htmlFor="consent">You have been given consent to send: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="consent" value="1" defaultChecked={true} onChange={this.handleFormChange}/>
                    Yes
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="consent" value="0" onChange={this.handleFormChange}/>
                    No
                  </label>
                </div>
                <div className="errorMsg">{this.state.errors.consent}</div>
              </div>
              <div className="form-group container">
                <button type="submit" className="btn btn-primary" disabled={this.props.groupEmail} id="yourInfoButton">{this.props.groupEmail ? 'Sending' : localizedData.submit}</button>
              </div>
              </Form>
              <div id="errorMsg"></div>   
              </div>
              }

          { (this.state.showSendGroup && this.state.employerEnabled && this.state.showDirectSend) &&<div> <br/><hr/><br/><h4>Send Direct</h4><Form onSubmit={this.singleEmployerSubmit.bind(this)} className="selfIdentifyForm messagePatientForm">
              <div className="form-group">
                    <FormLabel>Choose Campaign *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleCampaignList.bind(this)}>
                        <option value="0">Choose a campaign</option>
                        {Object.values(this.props.campaigns).map(d => {
                            return <option value={d.uid} key={d.uid}>{d.name}</option>
                        })}
                    </FormControl>                    
                    {this.state.errors.selectedCampaign && <div className="errorMsg">
                        A campaign is required.
                    </div>}
                </div>
              <div className="form-group">
                <label htmlFor="nameEntry">{localizedData.name}: <span className="text-danger">*</span></label>
                <input type="textfield" className="form-control" id="nameEntry" autoComplete="on" value={this.state.fields.emp_username} name="emp_username" placeholder={localizedData.name_placeholder} onChange={this.handleFormChange}/>
                <div className="errorMsg">{this.state.errors.emp_username}</div>
              </div>
               {!this.state.showEmpEmailForm &&              <div className="form-group">
                <label htmlFor="phoneEmpEntry">{localizedData.phone}: <span className="text-danger">*</span></label>
                <input type="tel" className="form-control" id="phoneEmpEntry" placeholder="##########" value={this.state.fields.emp_mobileno} name="emp_mobileno" onChange={this.handleFormChange}/>  
                <p><i><small>For international use the + sign.  Ex. Mexico +52 followed by the number</small></i></p>
                <div className="errorMsg">{this.state.errors.emp_mobileno}</div>  
                <button className="alternateTextLink simulateAnchor" onClick={this.useEmpEmail}>Use email instead</button>          
              </div> }
              {this.state.showEmpEmailForm &&               <div className="form-group">
                <label htmlFor="emailEmpEntry">{localizedData.email}: <span className="text-danger">*</span></label>
                <input type="email" className="form-control" id="emailEmpEntry" placeholder={localizedData.email_placeholder} value={this.state.fields.emp_emailaddress} name="emp_emailaddress" onChange={this.handleFormChange}/>  
                <div className="errorMsg">{this.state.errors.emp_emailaddress}</div> 
                 <button className="alternateTextLink simulateAnchor" onClick={this.useEmpEmail}>Use phone instead</button>            
              </div>} 
              <div className="form-group">
                <label htmlFor="location">Location: <span className="text-danger">*</span></label>      
               
{(this.props.locations.length <= 5) && <div>

                  {Object.values(this.props.locations).map(d => {
                                              return <div className="radio" key={d.location_id}>
                                    <label>
                                      <input type="radio" name="emp_location" value={d.location_id} onChange={this.handleFormChange} />
                                      {d.name}
                                    </label>
                                  </div>
                                     })}</div>

                  }
                  {(this.props.locations.length > 5) && <div className="maxWidthSelectContainer">
                  <FormControl as="select" placeholder="select" name="emp_location" id="location" value={this.state.fields["location"]} onChange={this.handleFormChange.bind(this)}>
                        <option value='' key=''>Select</option>
                        {Object.values(this.props.locations).map(d => {
                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                        })}
                    </FormControl></div>

                  }


                   <div className="errorMsg">{this.state.errors.emp_location}</div>

              </div>
              {(this.props.groupJourneys && this.props.groupJourneys.length>0) && <div className="form-group">
                <label htmlFor="emp_journey">Journey: <span className="text-danger">*</span></label>      
               
                  {Object.values(this.props.groupJourneys).map(d => {
                            return <div className="radio" key={d.journey_id}>
                  <label>
                    <input type="radio" name="emp_journey" value={d.journey_id} onChange={this.handleFormChange} />
                    {d.name}
                  </label>
                </div>
                   })}
                   <div className="errorMsg">{this.state.errors.emp_journey}</div>

              </div>   }          
              <div className="form-group">
                <label htmlFor="emp_language">Default Language: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="emp_language" value="EN" defaultChecked={true} onChange={this.handleFormChange}/>
                    English
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="emp_language" value="ES" onChange={this.handleFormChange}/>
                    Spanish
                  </label>
                </div>
              </div> 
              <div className="form-group">
                <label htmlFor="consent">You have been given consent to send: <span className="text-danger">*</span></label>      
                <div className="radio">
                  <label>
                    <input type="radio" name="emp_consent" value="1" defaultChecked={true} onChange={this.handleFormChange}/>
                    Yes
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="emp_consent" value="0" onChange={this.handleFormChange}/>
                    No
                  </label>
                </div>
                <div className="errorMsg">{this.state.errors.emp_consent}</div>
              </div>
              <div className="form-group container">
                <button type="submit" className="btn btn-primary" disabled={this.props.groupEmail} id="yourInfoButton">{this.props.groupEmail ? 'Sending' : localizedData.submit}</button>
              </div>
              </Form>
              <div id="errorMsg"></div>   
              </div>
              }

              { (this.state.showGroups && this.state.employerEnabled) && 
                <div><h3>Groups
                <Link to={'/groups/new'} className="btn btn-primary float-right">+ New Group</Link>
                </h3>
                
                    {
                        this.props.groups.map(c => {
                            return  <div key={c.uid} className="locationContainer journeyContainer groupContainer">
                                      <h5>{c.name}</h5>
                                      <Link className="addPeople" to={'/group/people/' + c.uid}>Add People</Link> 
                                      <Link to={'/group/edit/' + c.uid}>Edit/View</Link> 
                                </div>   
                        })
                    }     
                </div>

              }
              { (this.state.showCampaigns && this.state.employerEnabled) && 
                <div><h3>Campaigns
                    <Link to={'/campaign/new'} className="btn btn-primary float-right">+ New Campaign</Link></h3>
                
                    {
                        this.props.campaigns.map(c => {
                            return  <div key={c.uid} className="locationContainer journeyContainer groupContainer">
                                      <h5>{c.name}</h5>
                                       <Link to={'/campaign/edit/' + c.uid}>Edit/View</Link> 
                                </div>   
                        })
                    }     
                </div>

              }    
              { (this.state.showBulkMessages) && <div><h3>Send Bulk Messages By File</h3><br/>

                  <Form onSubmit={this.beginMultipleMessageUpload.bind(this)}>

                      <legend>Batch Addition <small>(Download the <a href="/WelliQ-BulkManualSend.xlsx">Excel Template</a>)</small></legend>
                      <p>Rows Processed: {this.props.messagesSent} / {this.props.totalMessages>0 ? this.props.totalMessages : '-'}</p>

                      <FormGroup controlId="locationName">
                          <FormLabel>Upload *</FormLabel>
                          <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
                      </FormGroup>
                      <FormGroup className="submit-buttons">
                          <Button type="submit" disabled={this.props.sendingBulkMessages} className="pull-left">
                              {this.props.sendingBulkMessages ? 'Adding' : 'Add'}
                          </Button>
                          {this.props.sendingBulkMessages && <Spinner/>}
                      </FormGroup>
                      {this.state.dataLoaded && 
                          <div className="case-list-container">
                              <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                          </div>
                      }


                  </Form>

                </div>

              }              

               </div>            
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        tvClient: state.login.tvClient,
        textLoading: state.textPatient.loading,
        emailLoading: state.emailPatient.loading,
        locations: state.locationList.locations,
        groups: state.groupList.groups,
        campaigns: state.campaignList.campaigns,
        journeys: state.journeyList.journeys,
        groupJourneys: state.groupJourneyList.journeys,
        messages: state.messageList.messages,
        messagesLoading: state.messageList.loading,
        paginationInfo: state.messageList.paginationInfo,
        paginationItems: state.messageList.paginationInfo.pageList,
        adminAttr: state.login.user.attributes,
        groupEmail: state.groupEmailPatient.loading,
        flags: state.adminFlags.flags,
        showIntake: state.login.user && state.login.intakeFlag,
        intakeLocation: state.surveyIntakeList.location_id,
        messagesSent: state.bulkSendMessages.bulkMessagesSent,
        totalMessages: state.bulkSendMessages.totalMessages,
        sendingBulkMessages: state.bulkSendMessages.sendingBulkMessages,
        bulkErrors: state.bulkSendMessages.errorRecords
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
       textPatient: (...params) => dispatch(textPatient(...params)),
       emailPatient: (...params) => dispatch(emailPatient(...params)),
       listLocations: (...params) => dispatch(listLocations(...params)),
       listJourneys: (...params) => dispatch(listJourneys(...params)),
       listGroupJourneys: (...params) => dispatch(listGroupJourneys(...params)),
       listGroups: (...params) => dispatch(listGroups(...params)),
       listCampaigns: (...params) => dispatch(listCampaigns(...params)),
       listRecentSurveys: (...params) => dispatch(listRecentSurveys(...params)),
       groupTextPatient: (...params) => dispatch(groupTextPatient(...params)),
       groupEmailPatient: (...params) => dispatch(groupEmailPatient(...params)),
       listSentMessages: (...params) => dispatch(listSentMessages(...params)),
       getAdminFlags: (...params) => dispatch(getAdminFlags(...params)),
       listIntakeSurveys: (...params) => dispatch(listIntakeSurveys(...params)),
       bulkSendMessages: (...params) => dispatch(bulkSendMessages(...params)),
       directEmpSurvey: (...params) => dispatch(directEmpSurvey(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServeCheckin);
