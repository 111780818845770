import React, {Component} from "react";
import PropTypes from "prop-types";

import "./SurveyStatus.scss";

class SurveyStatus extends Component {
    static surveyStatus(status) {
        return {
                5: 'Excellent',
                4: 'Great',
                3: 'Good',
                2: 'Bad',
                1: 'Very Bad'
            }[status] || status;
    }

    render() {
        return <span className={`survey-status-${this.props.status}`}>{this.props.bullet && '\u2022 '}{SurveyStatus.surveyStatus(this.props.status)}</span>;
    }
}

SurveyStatus.propTypes = {
    status: PropTypes.number.isRequired,
    bullet: PropTypes.bool
};

export default SurveyStatus;
