import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    FormLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {sendReviewReply, viewLocationReview } from "../../actions";

class ReplyToReview extends Component {

    componentDidMount() {
        return this.props.viewLocationReview(this.props.tvClient, this.props.match.params.reviewId);
    }

    constructor(props) {
        
        super(props);
        this.state = {
            errors: { 'reviewReply': false, 'locationAddress1': false, 'locationCity': false, 'locationState': false, 'locationZip': false  },
            editLoading: true,
            changed: false
        };

    }

    renderRatingPercent(param) {
        switch(param){
            case 'FIVE': return (5*20).toString() + '%';
            case 'FOUR': return (4*20).toString() + '%';
            case 'THREE': return (3*20).toString() + '%';
            case 'TWO': return (2*20).toString() + '%';
            case 'ONE': return (1*20).toString() + '%';
            default: return '0%';
        }
    }

    renderRating(param) {
        switch(param){
            case 'FIVE': return 5;
            case 'FOUR': return 4;
            case 'THREE': return 3;
            case 'TWO': return 2;
            case 'ONE': return 1;
            default: return 0;
        }
    }


    sendTheReply(e) {
        e.preventDefault();

        if(!this.reviewReply.value){
            this.setState({ errors: {'reviewReply': true}});
        } else{
           this.props.sendReviewReply(
                this.props.tvClient,
                this.props.match.params.reviewId,
                this.reviewReply.value.trim()
            );          
        }        
    }

    render() {
        return <div>
            <h1 className="page-header">
                Reply to Review
                <small>Use the below form to reply to a review.</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Go Back</button></small>
            </h1>
            <div className="case-list-container">            
            {this.state.viewingReview && <Spinner/>}
            {this.props.reviewData && <div className="reviewDetailPage">
                <h4>Review Information</h4>
                <div className="replyWithStars">
                    <div className="rating">
                        <div className="rating-upper" style={{width: this.renderRatingPercent(this.props.reviewData.rating) }}>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                        </div>
                        <div className="rating-lower">
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                        </div>
                    </div>

                    <p className="averageRatingScore">
                        {this.renderRating(this.props.reviewData.rating)} / 5
                    </p>
                </div>

                <p>{this.props.reviewData.review.comment!==undefined ? this.props.reviewData.review.comment : 'Rating Only'}</p>
                <p>-{this.props.reviewData.review.reviewer.displayName}</p>
            </div>}

            {(this.props.reviewData && this.props.reviewData.review.reviewReply===undefined) && <Form onSubmit={this.sendTheReply.bind(this)}>

                <FormGroup controlId="reviewReply">
                    <FormLabel>Your Reply:</FormLabel>
                    <FormControl type="text" placeholder="Your reply"  ref={ref => this.reviewReply = ref}/>
                </FormGroup>             
                {this.state.errors.reviewReply && <Alert variant="danger">
                    This is required to reply.
                </Alert>}

                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.viewingLocation} className="float-right">
                        Reply
                    </Button>
                </FormGroup>

            </Form>}
            {(this.props.reviewData && this.props.reviewData.review.reviewReply!==undefined) && <div>
                <p className="reviewReply">{this.props.reviewData.review.reviewReply.comment}</p>}
            </div>}
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        viewingReview: state.viewLocationReview.viewingReview,
        viewReviewError: state.viewLocationReview.viewReviewError,
        tvClient: state.login.tvClient,
        reviewData: state.viewLocationReview.reviewData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewLocationReview: (...params) => dispatch(viewLocationReview(...params)),
        sendReviewReply: (...params) => dispatch(sendReviewReply(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyToReview);