import React, {Component} from "react";
import {
    Table,
    Dropdown,
    Modal,
    Button
} from "react-bootstrap";
import { searchEventsByPatient, listAllActionableEvents, viewSpecificEvent, viewCustomSpecificEvent } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import PaginationArea from "./../../Utilities/PaginationArea";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import queryString from 'query-string'
import ExportAddressableEventsCSV from './../../Utilities/ExportAddressableEventsCSV';

class EventsReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            perpage: 15,
            filter: '',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 8,
            currentFilterName: '',
            showNames: false,
            confirmOpen: false,
            processWithNames: false,
            newFileAvailable: false,
            fields: { username: '' },
            searching: 0,
            validPatientEntry: 1,
            roleLocations: null
        };  

        this.handleFormChange = this.handleFormChange.bind(this);      
    }   

    handleFormChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    }

    exportWithAllNames(){
        this.setState({ confirmOpen: true });
    }

    handleClose(e) {
        this.setState({ confirmOpen: false, newFileAvailable: false, processWithNames: false });
    }

    handleConfirm(e){
        this.setState({processWithNames: true});
        this.props.listAllActionableEvents(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, true, true, this.state.roleLocations).then(()=>{
            this.setState({newFileAvailable: true});
        });
    }

    handleFilter(selectedKey) {
      if(parseInt(selectedKey,10)===1){
        this.setState({ filter: 'notaddressed', showNames: false, currentFilter: selectedKey, currentFilterName: ' Open / Pending ' });
        this.props.listAllActionableEvents(this.props.tvClient, 'and', 'notaddressed', this.state.sort, 1, this.state.perpage, false, false, this.state.roleLocations);
      } else if(parseInt(selectedKey,10)===2){
        this.setState({ filter: 'addressed', showNames: false, currentFilter: selectedKey, currentFilterName: ' Closed ' });
        this.props.listAllActionableEvents(this.props.tvClient, 'and', 'addressed', this.state.sort, 1, this.state.perpage, false, false, this.state.roleLocations);
      } else if(parseInt(selectedKey,10)===3){
        this.setState({ filter: 'thismonth', showNames: false, currentFilter: selectedKey, currentFilterName: ' This Month ' });
        this.props.listAllActionableEvents(this.props.tvClient, 'and', 'thismonth', this.state.sort, 1, this.state.perpage, false, false, this.state.roleLocations);
      } else if(parseInt(selectedKey,10)===4){
        this.setState({ filter: 'lastmonth', showNames: false, currentFilter: selectedKey, currentFilterName: ' Last Month ' });
        this.props.listAllActionableEvents(this.props.tvClient, 'and', 'lastmonth', this.state.sort, 1, this.state.perpage, false, false, this.state.roleLocations);
      } else if(parseInt(selectedKey,10)===5){
        this.setState({ filter: '', showNames: false, currentFilter: selectedKey, currentFilterName: ' Listed With Names For Export' });
        this.props.listAllActionableEvents(this.props.tvClient, 'and', '', this.state.sort, 1, this.state.perpage, true, true, this.state.roleLocations);
      }else if(parseInt(selectedKey,10)===8){
        this.setState({ filter: '', showNames: false, currentFilter: selectedKey, currentFilterName: '' }, () => {
            this.changeListPage(1);    
        });
        
      }
    }

    componentDidMount() {
       const queryStringValues = queryString.parse(this.props.location.search);

        if(queryStringValues.refresh!==null && queryStringValues.refresh!==undefined){
            if(queryStringValues.refresh==1){
                this.changeListPage(1);
            } else {
                if(this.props.eventReportData.length===0){
                    this.changeListPage(1);
                } else if(this.props.eventReportData[0].name!==undefined && this.props.eventReportData[0].name!==null ){
                    this.setState({ filter: '', showNames: false, currentFilter: '5', currentFilterName: ' Listed With Names For Export' });
                } 
                
                if(queryStringValues.searching!==null && queryStringValues.searching!==undefined){
                    if(queryStringValues.searching.toString()==='1'){
                        this.setState({searching: 1});
                    }
                }

                if(queryStringValues.eventId!==null && queryStringValues.eventId!==undefined && queryStringValues.resolved!==null && queryStringValues.resolved!==undefined){
                    document.getElementById('event_'+queryStringValues.eventId).outerHTML= '<td id="event_' + queryStringValues.eventId +'" class="'+this.determineStatusClass(queryStringValues.resolved, 'nonrefresh')+'">'+this.determineStatus(queryStringValues.resolved, 'nonrefresh')+'</td>';
                }
            }
        } else{
            this.changeListPage(1);    
        }

    }

    addPatientFilter(e){
        e.preventDefault();
        var isValid = true;
        // test email first
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(this.state.fields.username)) {
            isValid = false;
        }
        var mobileNoTest = this.state.fields.username;
        // test phone next
        if(!isValid){

            var replacement = "-";
            var dashRegEx = new RegExp(replacement, "g");
            mobileNoTest = mobileNoTest.replace(dashRegEx, '');
            mobileNoTest = mobileNoTest.replace('(', '');
            mobileNoTest = mobileNoTest.replace(')', '');
            replacement = " ";
            dashRegEx = new RegExp(replacement, "g");
            mobileNoTest = mobileNoTest.replace(dashRegEx, '');

            if(!mobileNoTest.startsWith("+")){
              if (!mobileNoTest.match(/^\+?[0-9]{10}$/) && !mobileNoTest.match(/^\+?[0-9]{11}$/) && !mobileNoTest.match(/^\+?[0-9]{12}$/)) {
                isValid = false;
              } else{
                isValid = true;
              }
            } else {
              if(mobileNoTest.length<10){
                isValid = false;
              } else{
                isValid =true;
              }
            } 

        }

        if(isValid){
            this.props.searchEventsByPatient(this.props.tvClient, mobileNoTest, 1, this.state.perpage, false);
            this.setState({searching: 1, validPatientEntry: 1});
        } else{
            this.setState({validPatientEntry: 0});
        }
        

    }

    removePatientFilter(){
        let fields = this.state.fields;
        fields['username'] = '';
       
        this.setState({fields: fields, searching: 0, validPatientEntry: 1}, () => {
            this.props.listAllActionableEvents(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.showNames, false, this.state.roleLocations);
       });
    }

    changeListPage(page) {
        if(!this.state.searching){
            let roleLocations = null;
            if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                roleLocations = this.props.roleLocations;
            } 
            this.setState({roleLocations: roleLocations});
            this.props.listAllActionableEvents(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.showNames, false, roleLocations);    
        }
        else{
            this.props.searchEventsByPatient(this.props.tvClient, this.state.fields.username, page, this.state.perpage, false);
        }    
    }

    viewEvent(eventId, eventType){
      if(eventType==='NPS'){
        this.props.viewSpecificEvent(this.props.tvClient, eventId,'report', this.state.searching);  
      } else if(eventType==='FEEDBACK'){
        this.props.viewCustomSpecificEvent(this.props.tvClient, eventId,'report', this.state.searching);  
      }
      
    }

    determineStatus(status, action){
        var finalStatus = 'Open';
        if(status===true || status==='true' || status===1 || status==='1'){
            return 'Closed';
        } else{
            if(action!==undefined && action!==null && action.toString().length>0){
                return 'Pending';
            } else {
                return 'Open';
            }
        }
    }

    determineStatusClass(status, action){
        var finalStatus = 'Open';
        if(status===true || status==='true' || status===1 || status==='1'){
            return 'text-center totalTdValue';
        } else{
            if(action!==undefined && action!==null && action.toString().length>0){
                return 'text-center totalTdValue text-orange';
            } else {
                return 'text-center totalTdValue text-danger';
            }
        }         
    }

    render() {
       return <div>
            <h1 className="page-header">
                Addressable Events
                <div className="float-right">
                        <Link to={'/events/new'} className="btn btn-primary">Create Custom Event</Link> 
                </div>
            </h1>

        
            <div className="case-list-container">

            {  this.props.eventReportData && <div className="patientSearchBox"><div className="customLocationRange searchEmployeeBoxHolder">
                        <label htmlFor="searchByNameInput">Search Patient By Phone or Email:{(this.state.fields.username!=='' || this.state.searching===1) && <a className="removeStaffFilter" onClick={this.removePatientFilter.bind(this)}><h3> (Reset)</h3></a>}</label>
                        <div className="form-group">
                            <input type="textfield" className="form-control" id="nameEntry" value={this.state.fields.username} name="username" placeholder="Enter email or phone" onChange={this.handleFormChange} />
                            <button className="btn btn-primary" id="yourInfoButton" onClick={this.addPatientFilter.bind(this)}>Search</button>
                          </div>
                </div>
                {!this.state.validPatientEntry && <p className="text-danger">Use valid email or 10 digit phone number.</p>}
                </div>
            }
             
                 {  (this.props.eventReportData && !this.state.searching) && <div className="float-left"> <Dropdown
                          title='Filter Events'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            Filter Events
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                    <Dropdown.Item eventKey="1">Open / Pending</Dropdown.Item>
                    <Dropdown.Item eventKey="5">With Names For Export</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Closed</Dropdown.Item>
                    <Dropdown.Item eventKey="3">This Month</Dropdown.Item>
                    <Dropdown.Item eventKey="4">Last Month</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <p>Showing <b>{this.state.currentFilterName}</b> Events</p>
                  </div>
            }
                

                {  this.props.eventReportData  && <Table className="case-list sortable reportBadgeContainer table-hover">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th className="text-center">ID</th>
                        {!this.state.showNames && <th className="text-center">Patient Name</th>}
                        {this.state.showNames && <th className="text-center">Patient</th>}
                        <th>Location</th>
                        <th>Description</th>
                        <th className="text-center">Survey Type</th>
                        <th className="text-center">Survey Sent</th>
                        <th className="text-center">Completed On</th>
                        <th className="text-center">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.eventReportData.map(c => {
                            return <tr onClick={()=> this.viewEvent(c.event_id, c.event_type)} className="surveyRow statDeptContainer reportBadgeContainer" key={c.event_id}>
                                <td className="text-center">{c.event_id}</td>
                                {!this.state.showNames && <td className="text-center">{c.name}</td> }
                                {this.state.showNames && <td className="text-center">{c.name}</td> }
                                <td>{(c.location_name===null || c.location_name===undefined) ? 'N/A' : c.location_name}</td>
                                <td>{c.event_overview}</td>
                                <td className="text-center">{c.respondent_type===undefined ? 'N/A' : c.respondent_type}</td>
                                <td className="text-center">{dateFormat(c.survey_date, "shortDate")}</td>
                                <td className="text-center">{dateFormat(c.created, "shortDate")}</td>
                                <td id={"event_" + c.event_id} className={this.determineStatusClass(c.addressed, c.action)}>{this.determineStatus(c.addressed, c.action)}</td>
                            </tr>
                        })
                    }
                {(!this.props.eventReportLoading && this.props.eventReportData.length===0) && <tr><td colSpan="8"><p className="text-center">No Results</p></td></tr>}
                    </tbody>
                </Table> }
                
                {this.props.eventReportLoading && <Spinner/>}
                {(!this.props.eventReportLoading && (this.props.eventReportData!==undefined && this.props.eventReportData.length>0)) && <div className="float-right">
                  {(this.state.showNames) && <Button onClick={this.exportWithAllNames.bind(this)}>Export CSV</Button>}
                  {(!this.state.showNames) && <ExportAddressableEventsCSV csvData={this.props.allReportData} withNames={true} fileName='AddressableEvents.csv' />}
                  
              </div>}
              {!this.props.eventReportLoading && 
                <div><PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} /> </div>
                }
            <Modal size="md" show={this.state.confirmOpen} onHide={this.handleClose.bind(this)}>
              <Modal.Header className="modalPadding" >
                <Modal.Title>Confirm Export With Names</Modal.Title>
              </Modal.Header>
              {(!this.state.processWithNames) && <Modal.Body>The process to retrieve Patient Names as part of the export can take several minutes and you will need to wait to retrieve your Excel file.  Are you sure you would like to export with Patient Names?</Modal.Body>}
              {(this.state.processWithNames && !this.state.newFileAvailable) && <Modal.Body><b>Processing.</b> Please wait for the Export CSV button to become available in this window. Do not close until you have your file.</Modal.Body>}
              {(this.state.processWithNames && this.state.newFileAvailable) && <Modal.Body><b>Completed.</b> Please download your file here. <ExportAddressableEventsCSV csvData={this.props.allReportData} withNames={this.state.showNames} fileName='AddressableEvents.csv' /> </Modal.Body>}
               {(!this.state.processWithNames) && <Modal.Footer>
                <Button  onClick={this.handleClose.bind(this)}>
                  No
                </Button>
                <Button onClick={this.handleConfirm.bind(this)}>
                  Yes
                </Button>
              </Modal.Footer>}

              {(this.state.processWithNames) && <Modal.Footer>
                <Button  onClick={this.handleClose.bind(this)}>
                  Close
                </Button>
              </Modal.Footer>}

            </Modal> 

            </div>    

        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        eventReportLoading: state.eventReportData.loading,
        eventReportData: state.eventReportData.eventsReportData,
        allReportData: state.eventReportData.allReportData,
        paginationInfo: state.eventReportData.paginationInfo,
        paginationItems: state.eventReportData.paginationInfo.pageList,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listAllActionableEvents: (...params) => dispatch(listAllActionableEvents(...params)),
        viewSpecificEvent: (...params) => dispatch(viewSpecificEvent(...params)),
        viewCustomSpecificEvent: (...params) => dispatch(viewCustomSpecificEvent(...params)),
        searchEventsByPatient: (...params) => dispatch(searchEventsByPatient(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsReport);
