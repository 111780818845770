import React, {Component} from "react";
import {connect} from "react-redux";
import {listIntakeSurveys, viewAdminDashboardSurveys, viewCustomSpecificEvent, viewAdminDashboardEndorsementsReports, listAllActionableEvents, listAllDeptEngagementsReport, listAllStaffEngagementsReport, viewSpecificEvent, viewActionableEvents, viewAdminDashboardOnlyNps, viewAdminDashboardOnlyOverview, viewAdminDashboardDeptEngagements, viewAdminDashboardStaffEngagements} from "../../actions";
import {Alert} from 'react-bootstrap';
import Spinner from 'react-spinner';
import {AreaChart, Area, XAxis, YAxis, Tooltip, PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';

class AdminDashboard extends Component {

    constructor(){
        super();
         this.state = {
          currentDate: new Date().toLocaleString('en-us', { month: 'long' })
        }
    }

    componentDidMount() {
        let roleLocations = null;
        if(this.props.isLocationOnly){
            roleLocations = this.props.roleLocations;
         } 
          this.props.viewAdminDashboardSurveys(this.props.accessToken, roleLocations);
          this.props.viewAdminDashboardOnlyNps(this.props.accessToken, 'thismonth', null, null, roleLocations);
          this.props.viewAdminDashboardOnlyOverview(this.props.accessToken, 'thismonth', null, null, roleLocations);
          this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, 0, 'thismonth', null, null, null, roleLocations);
          this.props.listAllDeptEngagementsReport(this.props.tvClient, 'and', null, 'desc', 1, 10, 0, 'thismonth', null, null, roleLocations);
          this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', null, 'desc', 1, 10, 0, 'thismonth', null, null, 0, null, roleLocations);
          this.props.listAllActionableEvents(this.props.tvClient, 'and', 'thismonth', 'desc', 1, 10, false, false, roleLocations);
          

        if(this.props.showIntake===true || this.props.showIntake==='true'){
          this.props.listIntakeSurveys(this.props.tvClient, 'and', null, null, 1, 40, this.props.intakeLocation);
        }

    }

    viewEvent(eventId, eventType){
      if(eventType==='NPS'){
        this.props.viewSpecificEvent(this.props.tvClient, eventId);  
      } else if(eventType==='FEEDBACK'){
        this.props.viewCustomSpecificEvent(this.props.tvClient, eventId);  
      }
    }

    determineStatus(status, action){
        if(status===true || status==='true'){
            return 'Closed';
        } else{
            if(action!==undefined && action!==null && action.toString().length>0){
                return 'Pending';
            } else {
                return 'Open';
            }
        }
    }

    render() {
                
        return <div>
            <h1 className="page-header">{process.env.REACT_APP_CLIENT_NAME} Dashboard</h1>
            <div className="total-survey-chart-container">
                <h5 className="case-list-container dashboardCmpTitle">Recent Surveys <span className="smallSubHead">(Last 30 Days)</span></h5>
                {this.props.surveyDataLoading && <Spinner/>}
                    {this.props.surveyData && 
                    <ResponsiveContainer width="90%" height={300} >
                    <AreaChart data={this.props.surveyData} 
                        margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <XAxis dataKey="date" interval={2} />
                    <YAxis allowDecimals={false} />
                    <Tooltip labelFormatter={(value) => "Date:  " + value }  />
                    <Area type='monotone' dataKey='surveys' stroke='#5a98d5' fill='#5a98d5' />
                  </AreaChart>
                  </ResponsiveContainer>
                }
          </div>
          <div className="case-list-container piechart">
              <div className="row">
                  <div className="col-md-4 nps-survey-container ">
                   {this.props.npsLoading && <Spinner/>}
                     {this.props.npsData &&  <div>
                        <h5>
                        <Link to={'/reports/nps'}>Overall Patient Satisfaction <span className="smallSubHead">{this.state.currentDate}</span></Link> 
                        </h5>
                       <PieChart width={350} height={250}>
                       <Pie data={this.props.npsData} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#8884d8" >
                        {
                          this.props.npsData.map((entry, index) => <Cell key={entry.value} fill={ entry.color }/>)
                        }
                        <Label width={100} position="center">{this.props.npsRating}</Label>
                       </Pie>
                        <Legend layout="vertical" align="right" verticalAlign="middle" />
                       </PieChart> 
                        </div>
                   }
                   {(this.props.npsData && this.props.npsData.length===0) && <p>No data to display.</p>}
                  </div>
                  <div className="col-md-4 middleCptContainer">
                    {this.props.overviewLoading && <Spinner/>}
                     {this.props.overviewData && <div className="totalSurveys">
                        <img alt="Total Launched" src={process.env.PUBLIC_URL + '/admin_assets/launchedIcon.png'} />
                        <div className="detailedInfo">
                            <h4>{this.props.overviewData.totalSurveys[0].count}</h4>
                            <h5>Surveys Sent</h5>
                            <p><span className="smallSubHead">in {this.state.currentDate}</span></p>
                        </div>
                    </div> }
                     {this.props.overviewData && <div className="totalOpened">
                        <img alt="Total Opened" src={process.env.PUBLIC_URL + '/admin_assets/openedIcon.png'} />
                        <div className="detailedInfo">
                            <h4>{this.props.overviewData.openedSurveys[0].count}</h4>
                            <h5>Surveys Opened</h5>
                            <p><span className="smallSubHead">in {this.state.currentDate}</span></p>
                        </div>
                    </div> }                    
                     {this.props.overviewData && <div className="totalCompleted">
                        <img alt="Total Completed" src={process.env.PUBLIC_URL + '/admin_assets/completedIcon.png'} />
                        <div className="detailedInfo">
                            <h4>{this.props.overviewData.completedSurveys[0].count}</h4>
                            <h5>Completed Fully</h5>
                            <p><span className="smallSubHead">in {this.state.currentDate}</span></p>
                        </div>
                    </div> 
                    }
                  </div>
                  <div className="col-md-4 nps-survey-container adminDashboardBadges">
                    <div>
                        <h5>
                        <Link to={'/reports/behaviors'}>Top Patient Endorsements <span className="smallSubHead">{this.state.currentDate}</span></Link> 
                        </h5>
                    </div>
                    {this.props.endorsementsLoading && <Spinner/>}
                     {this.props.endorsementsData && <div>
                                
                            {
                                this.props.endorsementsData.comboBadgeData.slice(0,5).map(c => {
                                    return <div className="statBadgeContainer" key={c.endorsement_id}>
                                            <label>
                                              <img alt="" className="img-fluid" src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
                                              <p>{c.description}</p>
                                            </label>
                                          </div>
                                })
                            }  

                    </div> 
                    }
                    {(this.props.endorsementsData && this.props.endorsementsData.comboBadgeData.length===0) && <p>No data to display.</p>}
                  </div>
              </div>
               <div className="row">
                  <div className="col-md-6 nps-survey-container first-survey-container">
                     <div>
                        <h5>
                        <Link to={'/reports/deptendorsements'}>Top Department By Engagement <span className="smallSubHead">{this.state.currentDate}</span></Link> 
                        </h5>
                    </div>
                    {this.props.deptEngagementsLoading && <Spinner/>}
                    {this.props.deptEngagementsData && <div>
                                
                            {
                                this.props.deptEngagementsData.slice(0,5).map(c => {
                                    return <div className="statDeptContainer" key={c.key}>
                                            <label>
                                              <img alt="" className="img-fluid" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/department_assets/' + c.image}/>
                                              <p>{c.name}</p>
                                              <p className="engagementCount">{c.engagements}</p>
                                            </label>
                                            
                                          </div>
                                })
                            }  

                    </div> 
                    }                    

                  </div>
                  <div className="col-md-6 nps-survey-container ">
                     <div>
                        <h5>
                        <Link to={'/reports/staffendorsements'}>Top Staff By Engagement <span className="smallSubHead">{this.state.currentDate}</span></Link> 
                        </h5>
                    </div>
                    {this.props.staffEngagementsLoading && <Spinner/>}
                    {this.props.staffEngagementsData && <div>
                                
                            {
                                this.props.staffEngagementsData.slice(0,5).map(c => {
                                    return <div className="statDeptContainer" key={c.key}>
                                            <label>
                                              <img alt="" className="img-fluid" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/staff_assets/' + c.image}/>
                                              <p>{c.name}</p>
                                              <p className="engagementCount">{c.engagements}</p>
                                            </label>
                                            
                                          </div>
                                })
                            }  

                    </div> 
                    }                    
                  </div>                  
               </div>

                <div className="row">
                  <div className="nps-survey-container addressable-events">
                     <div>
                        <h5>
                        <Link to={'/reports/events'}>All Addressable Events <span className="smallSubHead">{this.state.currentDate}</span></Link> 
                        </h5>
                    </div>
                    <div>
                        <table>
                            <tbody>

                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-center">EVENT TYPE</th>
                                    <th className="text-center">EVENT DETAILS</th>
                                    <th className="text-center">STATUS</th>
                                    <th className="text-center">SURVEY TYPE</th>
                                    <th className="text-center">SURVEY SENT</th>
                                    <th className="text-center">SURVEY COMPLETED</th>
                                </tr>
                            </tbody>

                    {this.props.actionableData && <tbody>
                                
                            {
                                this.props.actionableData.slice(0,10).map(c => {
                                    return <tr className="statDeptContainer actionableRecord" onClick={()=> this.viewEvent(c.event_id, c.event_type)} key={c.event_id}>
                                            <td className="text-center">{c.event_id}</td>
                                            <td className="text-center">{c.event_type}</td>
                                            <td className="text-center">{c.event_overview}</td>
                                            <td className="text-center">{this.determineStatus(c.addressed, c.action)}</td>
                                            <td className="text-center">{c.respondent_type===undefined ? 'N/A' : c.respondent_type}</td>
                                            <td className="text-center">{dateFormat(c.survey_date, "shortDate")}</td>
                                            <td className="text-center">{dateFormat(c.created, "shortDate")}</td>
                                          </tr>
                                })
                            }  

                    </tbody> 
                    }             

                        </table>
                                            {this.props.actionableDataLoading && <Spinner/>}
                    </div>
                  </div>
                </div>

          </div>

            {this.props.statsError && <Alert variant="danger">{this.props.statsError.message}</Alert>}    
        <footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer>    
        </div>
        
    }
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.login.tvClient.accessToken,
        tvClient: state.login.tvClient,
        stats: state.statsView.stats,
        surveyData: state.statsView.surveyData,
        surveyDataLoading: state.statsView.surveyLoading,
        actionableData: state.eventReportData.eventsReportData,
        actionableDataLoading: state.eventReportData.loading,
        npsData: state.npsCpt.npsData,
        npsLoading: state.npsCpt.npsLoading,
        npsRating: state.npsCpt.npsRating,
        overviewLoading: state.overviewCpt.overviewLoading,
        overviewData: state.overviewCpt.overviewData,
        endorsementsLoading: state.endorsementsCpt.endorsementsLoading,
        endorsementsData: state.endorsementsCpt.endorsementsData,
        deptEngagementsLoading: state.deptEngagementData.loading,
        deptEngagementsData: state.deptEngagementData.engagementData,
        staffEngagementsLoading: state.staffEngagementData.loading,
        staffEngagementsData: state.staffEngagementData.engagementData,
        statsLoading: state.statsView.loading,
        statsError: state.statsView.error,
        showIntake: state.login.user && state.login.intakeFlag,
        intakeLocation: state.surveyIntakeList.location_id,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        viewAdminDashboardSurveys: (...params) => dispatch(viewAdminDashboardSurveys(...params)),
        viewAdminDashboardOnlyNps: (...params) => dispatch(viewAdminDashboardOnlyNps(...params)),
        viewAdminDashboardOnlyOverview: (...params) => dispatch(viewAdminDashboardOnlyOverview(...params)),
        viewAdminDashboardEndorsementsReports: (...params) => dispatch(viewAdminDashboardEndorsementsReports(...params)),
        viewAdminDashboardDeptEngagements: (...params) => dispatch(viewAdminDashboardDeptEngagements(...params)),
        viewAdminDashboardStaffEngagements: (...params) => dispatch(viewAdminDashboardStaffEngagements(...params)),
        listAllStaffEngagementsReport: (...params) => dispatch(listAllStaffEngagementsReport(...params)),
        listAllDeptEngagementsReport: (...params) => dispatch(listAllDeptEngagementsReport(...params)),
        viewActionableEvents: (...params) => dispatch(viewActionableEvents(...params)),
        viewSpecificEvent: (...params) => dispatch(viewSpecificEvent(...params)),
        listAllActionableEvents: (...params) => dispatch(listAllActionableEvents(...params)),
        viewCustomSpecificEvent: (...params) => dispatch(viewCustomSpecificEvent(...params)),
        listIntakeSurveys: (...params) => dispatch(listIntakeSurveys(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
