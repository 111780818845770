import React, {Component}  from "react";
import {connect} from "react-redux";
import {Route, Redirect, withRouter, useRouteMatch } from "react-router-dom";
import ServeJourneySurvey from "./../ServeJourneySurvey/ServeJourneySurvey";
import ServeHRJourneySurvey from "./../ServeHRJourneySurvey/ServeHRJourneySurvey";
import ServeEmployerSurvey from "./../ServeEmployerSurvey/ServeEmployerSurvey";
import ServeDeptSurvey from "./../ServeDeptSurvey/ServeDeptSurvey";
import ServeSurvey from "./../ServeSurvey/ServeSurvey";
import RequestStaffPhoto from "./../RequestStaffPhoto/RequestStaffPhoto";
import "./SurveyApp.scss";

class SurveyApp extends Component {
	constructor(props){
		super(props);
	}
	render() {
	    return (
	      <div className="SurveyApp">
	          <Route path="/s/:survey_url" component={ServeSurvey} />
	          <Route path="/j/:survey_url" component={ServeJourneySurvey} />
	          <Route path="/hr/:survey_url" component={ServeHRJourneySurvey} />
	          <Route path="/me/:survey_url" component={ServeHRJourneySurvey} />
	          <Route path="/e/:survey_url" component={ServeEmployerSurvey} />
	          <Route path="/d/:survey_url" component={ServeDeptSurvey} />
	          <Route path="/photo/:request_url" component={RequestStaffPhoto} />
	      </div>
	    )
	}
}

export default withRouter(connect(false)(SurveyApp));
